import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";
import Swal from "sweetalert2";
import "../styles/Payment_Report.css";


function Payment_Received_Details() {
        const ID = Cookies.get("user_id");
        const [payment,setPayment] = useState([]);
        const [totamount,setTotamount] = useState([]);
        const [totbal,setTotbal] = useState([]);
        const [comp,setCompany] = useState([]);
        const [startDate, setStartDate] = useState("");
        const [endDate, setEndDate] = useState("");
    
        function toggleContent() {
            var contentDiv = document.getElementById("contentDiv");
            if (contentDiv.style.display === "block") {
                contentDiv.style.display = "none";
            } else {
                contentDiv.style.display = "block";
            }
        }
    
        const fetchPayment_recieved_details = () =>{
            axios.get(`${config.base_url}/Fetch_Payment_Recieved_Details/${ID}/`).then((res)=>{
                if(res.data.status){
                    setPayment(res.data.details);
                    setTotamount(res.data.totalamount);
                    setTotbal(res.data.totalbalance);
                    setCompany(res.data.cmpname);
                    setStartDate(res.data.startdate);
                    setEndDate(res.data.enddate);
                    }
                  }).catch((err)=>{
                    console.log('ERR',err)
                  })
        };
        useEffect(()=>{
            fetchPayment_recieved_details();
          },[])
    

          function reportPDF() {
            const st = startDate;
            const en = endDate;
            let date = "";
        
          
            if (st && en) {
                date = `_${st}_${en}`;
            }
            const element = document.getElementById("printReport");
  
            const opt = {
                margin: [0.5, 0.3, 0.3, 0.5],
                filename: `Report_Payment_Received_Details${date}`, 
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
            };
        

            html2pdf().set(opt).from(element).save();
        }
        
        function printSheet() {
            var divToPrint = document.getElementById("printReport");
            var printWindow = window.open("", "", "height=700,width=1000");
        
            printWindow.document.write("<html><head><title></title>");
            printWindow.document.write(`
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
            `);
            printWindow.document.write("</head>");
            printWindow.document.write("<body>");
            printWindow.document.write(divToPrint.outerHTML);
            printWindow.document.write("</body>");
            printWindow.document.write("</html>");
            printWindow.document.close();
            printWindow.print();
            printWindow.addEventListener('afterprint', function() {
              printWindow.close();
            });
        
          }
    
          function ExportToExcel() {
            var st = startDate; 
            var en = endDate;  
            var date = "";
        
            
            if (st && en) {
                date = `_${st}_${en}`; 
            }
        
            const Table = document.getElementById("reportTable"); 
            const ws = XLSX.utils.table_to_sheet(Table); 
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); 
        
       
            const filename = `Report_Payment_Received_Details${date}.xlsx`;
            XLSX.writeFile(wb, filename); 
        }
        
          const currentUrl = window.location.href;
        const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            currentUrl
        )}`;
        const [emailIds, setEmailIds] = useState("");
      const [emailMessage, setEmailMessage] = useState("");
    
      function handleShareEmail(e) {
        e.preventDefault();
    
        var st = startDate;
        var end = endDate;
    
        if ((st != "" && end == "") || (st == "" && end != "")) {
          alert("Please select both date inputs or select none");
          return;
        } else {
          var emailsString = emailIds.trim();
    
          var emails = emailsString.split(",").map(function (email) {
            return email.trim();
          });
    
          var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    
          var invalidEmails = [];
          if (emailsString === "") {
            alert("Enter valid email addresses.");
          } else {
            for (var i = 0; i < emails.length; i++) {
              var currentEmail = emails[i];
    
              if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
                invalidEmails.push(currentEmail);
              }
            }
    
            if (invalidEmails.length > 0) {
              alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
            } else {
              var em = {
                Id: ID,
                start: st,
                end: end,
                email_ids: emailIds,
                email_message: emailMessage,
              };
              axios
                .post(
                  `${config.base_url}/Share_Payment_Recieved_Details/`,
                  em
                )
                .then((res) => {
                  if (res.data.status) {
                    Toast.fire({
                      icon: "success",
                      title: "Shared via mail.",
                    });
                    setEmailIds("");
                    setEmailMessage("");
                  }
                })
                .catch((err) => {
                  console.log("ERROR=", err);
                  if (
                    err.response &&
                    err.response.data &&
                    !err.response.data.status
                  ) {
                    Swal.fire({
                      icon: "error",
                      title: `${err.response.data.message}`,
                    });
                  }
                });
            }
          }
        }
      }
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
    
      const handleCustomize = (e) => {
        e.preventDefault();
    
        var det = {
          Id: ID,
          start_date: startDate,
          end_date: endDate,
        };
    
        axios
          .post(`${config.base_url}/Payment_Recieved_details_customized/`, det)
          .then((res) => {
            console.log(res.data)
            if (res.data.status) {
                setPayment(res.data.details);
                setTotamount(res.data.totalamount);
                setTotbal(res.data.totalbalance);
                setCompany(res.data.cmpname);
                setStartDate(res.data.startdate);
                setEndDate(res.data.enddate);
    
              var contentDiv = document.getElementById("contentDiv");
              if (contentDiv.style.display === "block") {
                toggleContent();
              }
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      };

        return (
            <>
            
            <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper d-flex">
                    <Sidebar />
                    <div
                         className="main-panel px-3 py-2"
                       style={{ background: "#a9a9a961", width:"100%" }}
                          >
                        <div className="content-wrapper">
                            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
                                <div className="container-fluid">
                                    <div className="card radius-15 h-20 mt-3">
                                        <div className="row w-100">
                                            <div className="col-md-12">
                                                <center>
                                                    <h2 className="mt-3">PAYMENT RECEIVED DETAILS</h2>
                                                </center>
                                                <hr className="w-100 ml-3"  />
                                                
                                            </div>
                                        
           
                                            <div
    className="bar p-3"
    style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap', 
    }}
>
    <div
        className="left"
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: '30px',
            flexWrap: 'wrap',
        }}
    >
        <div className="position-relative">
            <button
                className="btn btn-secondary"
                onClick={toggleContent}
                style={{ height: 'fit-content', width: 'fit-content' }}
            >
                <i className="fa-solid fa fa-gear"></i> Customize Report
            </button>
            <div id="contentDiv" className="salescontent">
                <h6>Customize Report</h6>
                <form
                    onSubmit={handleCustomize}
                    className="form reportCustomizeForm px-1"
                    method="get"
                >
                    <div className="px-2 w-100">
                        <label style={{ textAlign: 'left' }}>From</label><br />
                        <input
                            className="inputdate form-control"
                            type="date"
                            name="start_date"
                            id="startDate"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            required={endDate !== ""}
                        />
                    </div>
                    <div className="px-2 w-100">
                        <label style={{ textAlign: 'left' }}>To</label><br />
                        <input
                            type="date"
                            className="inputdate form-control"
                            name="end_date"
                            id="endDate"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            required={startDate !== ""}
                        />
                    </div>
                    <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                            type="submit"
                            className="btn btn-outline-dark w-50"
                            style={{ width: 'fit-content', height: 'fit-content' }}
                        >
                            Run Report
                        </button>
                        <button
                            type="reset"
                            onClick={toggleContent}
                            className="btn btn-outline-dark ml-1 w-50"
                            style={{ width: 'fit-content', height: 'fit-content' }}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div
        className="d-flex justify-content-end"
        style={{
            display: 'flex',
            flexWrap: 'wrap', 
            gap: '10px', 
            marginTop: '10px', 
        }}
    >
        <a
            className="btn btn-outline-secondary text-grey fa fa-file"
            role="button"
            id="pdfBtn"
            onClick={reportPDF}
            style={{ height: 'fit-content', width: 'fit-content' }}
        >
            &nbsp;PDF
        </a>
        <a
            className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
            role="button"
            id="printBtn"
            onClick={printSheet}
            style={{ height: 'fit-content', width: 'fit-content' }}
        >
            &nbsp;Print
        </a>
        <a
            className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
            role="button"
            id="exportBtn"
            onClick={ExportToExcel}
            style={{ height: 'fit-content', width: 'fit-content' }}
        >
            &nbsp;Export
        </a>
        <div
            className="dropdown p-0 nav-item"
            id="shareBtn"
            style={{
                height: 'fit-content',
                width: 'fit-content',
            }}
        >
            <li
                className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                data-toggle="dropdown"
                style={{ height: 'fit-content', width: 'fit-content' }}
            >
                &nbsp;Share
            </li>
            <ul className="dropdown-menu" style={{ backgroundColor: 'black' }} id="listdiv">
                <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                    <li style={{ textAlign: 'center' }}>Whatsapp</li>
                </a>
                <li
                    style={{
                        textAlign: 'center',
                        color: '#e5e9ec',
                        cursor: 'pointer',
                    }}
                    id="toggleEmailModal"
                    data-toggle="modal"
                    data-target="#shareToEmail"
                >
                    Email
                </li>
            </ul>
        </div>
    </div>
</div>
</div>
</div>

        <div className="card radius-15 print-only mt-3" id="pdf-card">
            <div className="card-body">
                <div className="container-fluid">
    
                    <div  id="printReport"  className="printReportTemplate" style={{display:'block'}}>
                        <div className="my-5 page" size="A4" >
                            <div id="printdiv2">
                                <div className="py-4 bg-secondary" >
                                    <div className="col-12">
                                            <center className="h5 text-white"><b>{comp}</b></center>
                                            <center className="h3 text-white text-uppercase"><b>payment received DETAILS</b></center>
                                            {startDate && endDate ? (
                                            <center className="h6 text-white">
                                             {startDate} {" TO "} {endDate}
                                            </center>
)                                           : null}
                                    </div>  
                                </div>
                                <div className="row px-1 py-1">
                                    <div className="col-12">
    
                                        <section className="product-area mt-2">
                                            <table className="table table-responsive-md mt-4 table-hover" id="reportTable">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center text-uppercase">payment no.</th>
                                                        <th className="text-center text-uppercase">DATE</th>
                                                        <th className="text-center text-uppercase">CUSTOMER NAME</th>
    
                                                        <th className="text-center text-uppercase">PAYMENT METHOD</th>
                                                        <th className="text-center text-uppercase">INVOICE NO.</th>
                                                        <th className="text-center text-uppercase"> AMOUNT</th>
                                                        <th className="text-center text-uppercase"> BALANCE DUE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {payment && payment
                                                  .sort((a, b) => {
                                                    const getNumericValue = (payno) => {
                                                        const match = payno.match(/\d+/);
                                                        return match ? parseInt(match[0], 10) : 0;
                                                    };
                                        
                                                    const numA = getNumericValue(a.payno);
                                                    const numB = getNumericValue(b.payno);
                                      
                                                    return numA - numB;
                                                }).map((p,index) =>(
                                                        <tr>
                                                         <td className="text-center">{p.payno}</td>
                                                         <td className="text-center">{p.date}</td>
                                                         <td className="text-center">{p.name}</td>
     
                                                         <td className="text-center">{p.method}</td>
                                                         <td className="text-center">{p.invno}</td>
                                                         <td className="text-center">Rs. {p.total}</td> 
                                                         <td className="text-center">Rs. {p.balance }</td>
                                                         </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                              {payment.length == 0 ? (
                                              <center>
                              <h4 className="text-dark">No data available.!</h4>
                            </center>
                          ) : null}
                                        </section>
                                       
                                      
 <section className="balance-info text-dark">
    <div className="row p-4">
  
        <div className="col-md-6 d-flex align-items-center">
            <div>
                <h5>Total Amount:</h5>
                <h4 className="text-dark" id="expense" style={{ fontWeight: '600', fontFamily: "sans-serif" }}>
                    ₹<span id="totalCreditnote">{totamount}</span>
                </h4>
            </div>
        </div>
        <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div>
                <h5>Total Balance:</h5>
                <h4 className="text-dark" style={{ fontWeight: '600', fontFamily: "sans-serif" }} id="totvalue">
                    ₹<span id="superTotal">{totbal}</span>
                </h4>
            </div>
        </div>
    </div>
</section>



                                        
                                        
                            
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
        </div>
    {/* <!-- Share To Email Modal --> */}
    <div className="modal fade" id="shareToEmail">
            <div className="modal-dialog modal-lg">
              <div className="modal-content" >
                <div className="modal-header">
                  <h5 className="m-3">Share Report</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    onSubmit={handleShareEmail}
                    className="needs-validation px-1"
                    id="share_to_email_form"
                  >
                    <div className="card p-3 w-100">
                      <div className="form-group">
                        <label for="emailIds">Email IDs</label>
                        <textarea
                          className="form-control"
                          name="email_ids"
                          id="emailIds"
                          rows="3"
                          placeholder="Multiple emails can be added by separating with a comma(,)."
                          value={emailIds}
                          onChange={(e) => setEmailIds(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label for="item_unitname">Message(optional)</label>
                        <textarea
                          name="email_message"
                          id="email_message"
                          className="form-control"
                          cols=""
                          rows="4"
                          value={emailMessage}
                          onChange={(e) => setEmailMessage(e.target.value)}
                          placeholder="This message will be sent along with Report details."
                        />
                      </div>
                    </div>
                    <div
                      className="modal-footer d-flex justify-content-center w-100"
                      style={{ borderTop: "1px solid #ffffff" }}
                    >
                      <button
                        type="submit"
                        id="share_with_email"
                        className="submitShareEmailBtn w-50 text-uppercase"
                      >
                        SEND MAIL
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
    
            </>
        )
        
    }
export default Payment_Received_Details;
