import React, { useEffect, useState } from 'react'
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import axios from 'axios';
import Cookies from "js-cookie";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import Select from 'react-select';
import ReactDOM from 'react-dom';



function Sales_Item_Discount_Details() {

  const ID = Cookies.get("user_id");
  const [items, setItems] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [vendors, setVendors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [party, setParty] = useState("");
  const [itemreport, setItemreport] = useState([]);
  const [totalSalesQty, setTotalSalesQty] = useState("");
  const [totalSalesAmt, setTotalSalesAmt] = useState("");
  const [totalPurchaseQty, setTotalPurchaseQty] = useState("");
  const [totalPurchaseAmt, setTotalPurchaseAmt] = useState("");
  const [partyTitle, setPartyTitle] = useState("");
  const [partyFirstName, setPartyFirstName] = useState("");
  const [partyLastName, setPartyLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [partyList, setPartyList] = useState("");

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  const [combinedOptions, setCombinedOptions] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  
  const [reportData, setReportData] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgb(255 255 255 / 14%)",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      // width: '200px', // Adjust the width of the dropdown menu
      // maxHeight: '150px' // Adjust the height of the dropdown menu
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999 // Ensure the dropdown appears above other elements

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "lightgray"
        : state.isFocused
        ? "lightgray"
        : "white",
      color: state.isSelected ? "black" : "black",
    }),
    input: (provided) => ({
      ...provided,
      color: "",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "",
    }),
  };



  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
        contentDiv.style.display = "none";
    } else {
        contentDiv.style.display = "block";
    }
  }

  useEffect(() => {
    axios.get(`${config.base_url}/report_party_details/${ID}/`)
    .then((res) => {
      // console.log(res);


      // setVendors(res.data.vendors);
      // console.log(res.data.vendors);
      // setCustomers(res.data.customers);
      // console.log(res.data.customers);

      setCompanyName(res.data.company);
      // console.log(res.data.company);


      let cust = res.data.customers;
      let vend = res.data.vendors;

      // Map customers and vendors
      const customers_l = cust.map((item) => ({
        label: `${item.title} ${item.first_name} ${item.last_name}`,
        value: `customer-${item.id}`,
        type: 'customer'
      }));

      // console.log(customers_l);
      setCustomers(customers_l);

      const vendors_l = vend.map((itm) => ({
        label: `${itm.Title} ${itm.First_name} ${itm.Last_name}`,
        value: `vendor-${itm.id}`,
        type: 'vendor'
      }));

      // console.log(vendors_l);
      setVendors(vendors_l);

      // Combine customers and vendors with headings
      

      // setIsLoading(false); // Set loading to false when data is fetched

      // console.log(combinedOptions);


    })
    .catch((err) => {
      console.log("ERROR=", err);
      // setIsLoading(false); // Ensure loading is set to false even on error
    });

  }, []);


  useEffect(() => {
    // console.log('Updated Combined Options:', combinedOptions);
  }, [combinedOptions]);

  useEffect(() => {
    setCombinedOptions([
      {
        label: 'Customers',
        options: customers
      },
      {
        label: 'Vendors',
        options: vendors
      }
    ]);
  }, [customers,vendors]);


  // Custom option renderer to display headings
  const customGroupLabel = (data) => (
    <div style={{ fontWeight: 'bold', padding: '5px 0' }}>
      {data.label}
    </div>
  );

  const customOptionLabel = ({ label }) => (
    <div style={{ padding: '5px' }}>
      {label}
    </div>
  );


  const [report, setReport] = useState("");

  const handleCustomize = (e) => {
    e.preventDefault();
  
    const requestData = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
    };
    
    axios
      .post(`${config.base_url}/fetch_Sales_item_discount_details_customized/`, requestData)
      .then((res) => {
        console.log("REPORT DATA=", res.data);
  
        if (res.data.status) {
          setReportData(res.data.reportData); // Set report data
          setOtherDetails(res.data.otherDetails); // Set additional details
        }
        
        // Hide customization panel after successful response
        const contentDiv = document.getElementById("contentDiv");
        if (contentDiv.style.display === "block") {
          toggleContent();
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        Swal.fire({
          icon: "error",
          title: err.response?.data?.message || "Error fetching data",
        });
      });
  };
  
useEffect(() => {
  axios
      .get(`${config.base_url}/report_party_details/${ID}/`)
      .then((res) => {
          setCompanyName(res.data.company); // Set company name from response
      })
      .catch((err) => {
          console.error("Error fetching company details:", err);
      });
}, []);


  const getId = (value) => {
    if (value && value.includes('-')) {
      return value.split('-')[1];
    }
    return ''; // or handle as needed
  };


  const getType = (value) => {
    if (value.startsWith('customer-')) {
      return 'customer';
    }
    if (value.startsWith('vendor-')) {
      return 'vendor';
    }
    return '';
  };


  

  useEffect(() => {
    // console.log('Item Report:', itemreport);
  }, [itemreport]);

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Sales_Item_Discount_Details" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  } 



  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Sales_Item_Discount_Details" + date + ".xlsx");
  }


  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }



  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;



  function handleShareEmail(e) {
    e.preventDefault();

    // Prepare email data
    var emailsString = emailIds.trim();
    var emails = emailsString.split(",").map((email) => email.trim());
    var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    var invalidEmails = [];
    if (emailsString === "") {
        alert("Enter valid email addresses.");
    } else {
        for (var i = 0; i < emails.length; i++) {
            var currentEmail = emails[i];
            if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
                invalidEmails.push(currentEmail);
            }
        }

        if (invalidEmails.length > 0) {
            alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
            // Construct the email payload with relevant item data
            var emailData = {
                email_ids: emailIds,
                email_message: emailMessage,
                items: items.map(itm => ({
                    name: itm.name,
                    total_quantity_sold: itm.total_quantity_sold,
                    selling_price: itm.selling_price,
                    total_discount: itm.total_discount,
                })),
            };

            // Make the POST request to share the report
            axios
                .post(`${config.base_url}/Sales_Item_Discount_Details/`, emailData)
                .then((res) => {
                    if (res.data.status) {
                        Toast.fire({
                            icon: "success",
                            title: "Shared via mail.",
                        });
                        setEmailIds("");
                        setEmailMessage("");
                    }
                })
                .catch((err) => {
                    console.log("ERROR=", err);
                    if (err.response && err.response.data && !err.response.data.status) {
                        Swal.fire({
                            icon: "error",
                            title: `${err.response.data.message}`,
                        });
                    }
                });
        }
    }
}

  const handleSelectChange = (selectedOption) => {
    // Check if selectedOption is not null
    if (selectedOption) {
      setParty(selectedOption.value);
    } else {
      setParty(""); // Handle clearing selection if needed
    }
  };

  // const Portal = ({ children }) => {
  //   return ReactDOM.createPortal(
  //     children,
  //     document.body // or another DOM element where you want to render the dropdown
  //   );
  // };
  







  // const [inputValue, setInputValue] = useState('');
  // const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [filteredList, setFilteredList] = useState([...customers, ...vendors]);

  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   setInputValue(value);
  //   filterCustomers(value);
  // };

  // const filterCustomers = (value) => {
  //   // Simple filter logic; adjust as needed
  //   const lowerCaseValue = value.toLowerCase();
  //   const filtered = [...customers, ...vendors].filter(
  //     item =>
  //       item.title.toLowerCase().includes(lowerCaseValue) ||
  //       item.first_name.toLowerCase().includes(lowerCaseValue) ||
  //       item.last_name.toLowerCase().includes(lowerCaseValue)
  //   );
  //   setFilteredList(filtered);
  //   setDropdownVisible(true);
  // };

  // const handleItemClick = (id, name) => {
  //   // Handle item click; adjust based on your needs
  //   console.log(`Selected ID: ${id}, Name: ${name}`);
  //   setInputValue(name);
  //   setDropdownVisible(false);
  // };
  useEffect(() => {
    const fetchItems = async () => {
      try {
        // First, fetch the party details using the same ID to get the company name
        const partyDetailsResponse = await axios.get(`${config.base_url}/report_party_details/${ID}/`);
        
        // Log the full party details response
        console.log("Party Details Response:", partyDetailsResponse.data);
        
        // Extract the company name from the response
        const companyName = partyDetailsResponse.data.company; // Assuming 'company' is the field containing the company name
        console.log("Company Name:", companyName);

        // Update the state for the company name
        setCompanyName(companyName);

        
        const itemsResponse = await axios.get(`${config.base_url}/item-report/`, {
          params: {
              company_name: companyName // Pass company_name as a filter parameter
          }
      });

      setItems(itemsResponse.data);  // Set the items data to state
      console.log("Items:", itemsResponse.data); // Log the items to the console

  } catch (error) {
      console.error("Error fetching data:", error.response ? error.response.data : error.message);
  }
};
    fetchItems();
}, []); // Empty dependency array to run once on mount




  return (
    <div>
 <Navbar />
      <div className="container-fluid page-body-wrapper d-flex" style={{marginTop:"8vh"}}>
        <Sidebar />
      <div
        className="page-content mt-0 pt-0"
        id="page-content"
        style={{ backgroundColor: "",width:"100%", minHeight: "100vh",marginLeft:"1vw" }}
      >

        <div className="card radius-15">
          <div className="card-body" style={{ width: "100%" }}>
            <div className="card-title">
              <center>
                <h2 className="text-uppercase" id="headline">
                SALES ITEM DISCOUNT DETAILS
                </h2>
              </center>
              <hr />
            </div>
            <div className="bar">
  <div className="left d-flex justify-content-start">
    <div className="position-relative mr-2">
      <button
        className="btn btn-secondary"
        onClick={toggleContent}
        style={{ width: "fit-content", height: "fit-content" }}
      >
        <i className="fa fa-solid fa-gear"></i> Customize Report
      </button>
      <div id="contentDiv" className="salescontent" style={{ display: 'none' }}>
        <h6>Customize Report</h6>
        <form onSubmit={handleCustomize} className="form reportCustomizeForm px-1">
          <div className="px-2 w-100">
            <label style={{ textAlign: "left" }}>From</label>
            <br />
            <input
              className="inputdate form-control"
              type="date"
              name="start_date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </div>
          <div className="px-2 w-100">
            <label style={{ textAlign: "left" }}>To</label>
            <br />
            <input
              type="date"
              className="inputdate form-control"
              name="end_date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
            />
          </div>

          <div className="d-flex px-2 mt-3 mb-4 w-100">
            <button
              type="submit"
              className="btn btn-outline-dark w-50"
              style={{
                width: "fit-content",
                height: "fit-content",
              }}
            >
              {isLoading ? 'Run Report...' : 'Run Report'}
            </button>
            <button
              type="reset"
              onClick={toggleContent}
              className="btn btn-outline-dark ml-1 w-50"
              style={{
                width: "fit-content",
                height: "fit-content",
              }}
            >
              Cancel
            </button>
          </div>
        </form>
        </div>
                </div>
              </div>
<div></div>
<div className="right d-flex">
                <a
                  className="btn btn-outline-secondary text-grey fa fa-file"
                  role="button"
                  id="pdfBtn"
                  onClick={reportPDF}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  {" "}
                  &nbsp;PDF
                </a>
                <a
                  className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                  role="button"
                  id="printBtn"
                  onClick={printSection}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  &nbsp;Print
                </a>
                <a
                  className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
                  role="button"
                  id="exportBtn"
                  onClick={ExportToExcel}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  &nbsp;Export
                </a>
                <div className="dropdown p-0 nav-item" id="shareBtn">
                  <li
                    className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                    data-toggle="dropdown"
                    style={{
                      height: "fit-content",
                      width: "fit-content",
                    }}
                  >
                    &nbsp;Share
                  </li>
                  <ul
                    className="dropdown-menu"
                    style={{ backgroundColor: "black" }}
                    id="listdiv"
                  >
                    <a
                      href={shareUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <li
                        style={{
                          textAlign: "center",
                          color: "#e5e9ec",
                          cursor: "pointer",
                        }}
                      >
                        WhatsApp
                      </li>
                    </a>
                    <li
                      style={{
                        textAlign: "center",
                        color: "#e5e9ec",
                        cursor: "pointer",
                      }}
                      data-toggle="modal"
                      data-target="#shareToEmail"
                    >
                      Email
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="card radius-15 print-only" id="pdf-card">
          <div className="card-body">
            <div className="container-fluid">
              <div
                id="printReport"
                className="printReportTemplate"
                style={{ display: "block " }}
              >
                <div className="my-5 page" size="A4">
                  <div id="printdiv2">
                    <div className="py-4 bg-secondary">
                      <div className="col-12">
                        <center className="h5 text-white">
                          <b>{companyName}</b>
                        </center>
                        <center className="h3 text-white">
                          <b>SALES ITEM DISCOUNT DETAILS</b>
                        </center>
                        {startDate != "" && endDate != "" ? (
                          <center className="h6 text-white">
                            {startDate} {"TO"} {endDate}
                          </center>
                        ) : null}
                      </div>
                    </div>
                    <div className="row px-1 py-1">
                      <div className="col-12">
                      <section className="product-area mt-2 py-1">
  <div className="row mb-2">

  </div>
  <table className="table table-responsive-md mt-4 table-hover" id="reportTable">
    <thead>
      <tr>
        <th className="text-center">ITEM NAME</th>
        <th className="text-center">TOTAL QUANTITY SOLD</th>
        <th className="text-center">SALES AMOUNT</th>
        <th className="text-center">DISCOUNT AMOUNT</th>
      </tr>
    </thead>
    <tbody>
      {items.length > 0 ? (
        items.map((itm) => (
          <tr key={itm.id}>
            <td className="text-center">{itm.name}</td>
            <td className="text-center">{itm.total_quantity_sold}</td>
            <td className="text-center">{itm.sales_amount}</td>
            <td className="text-center">{itm.total_discount || 0}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="4" className="text-center">No items found.</td>
        </tr>
      )}
    </tbody>
  </table>

  {items.length > 0 && (
    <section className="balance-info text-dark">
      <div className="row p-3">
        <div className="col-12">
          <div className="row mb-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="col-3 " style={{ textAlign: 'left', marginLeft:'15rem' }}>
              Total Quantity Sold: 
              <p id="sales" style={{ fontSize: 19, fontWeight: 'bold' }}>
                {items.reduce((total, itm) => total + itm.total_quantity_sold, 0)}
              </p>
            </div>
            <div className="col-3" style={{ textAlign: 'right' }}>
              Total Discount Amount: 
              <p id="purchase" style={{ fontSize: 19, fontWeight: 'bold' }}>
                ₹{items.reduce((total, itm) => total + itm.total_discount, 0)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )}
</section>

                     
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    

       
  
  
   
  )
}


export default Sales_Item_Discount_Details
