import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

function Cashinhand_history() {
    const { id } = useParams();  // Get 'id' from URL params
    const [history, setHistory] = useState([]);
  
    const fetchInvoiceHistory = () => {
      axios
        .get(`${config.base_url}/cash_in_hand_history/${id}/`)  // Use 'id' from URL params
        .then((res) => {
          if (res.data.status) {
            setHistory(res.data.cash_in_hand_history);  // Directly set history data
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (err.response && !err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    };
  
    useEffect(() => {
      fetchInvoiceHistory();
    }, [id]);

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
      <div
        className="page-content"
        style={{ backgroundColor: "#a9a9a961", minHeight: "100vh", width: '100%', padding:'20px' }}
      >
        <Link
          className="d-flex justify-content-end p-2"
          style={{ cursor: "pointer" }}
          to="/cashinhand"
        >
          <i
            className="fa fa-times-circle text-white"
            style={{ fontSize: "1.2rem" }}
          ></i>
        </Link>
        <div className="card radius-15">
          <div className="card-body" style={{ width: "100%" }}>
            <div className="card-title">
              <center>
                <h3
                  className="card-title"
                  style={{ textTransform: "Uppercase" }}
                >
                  CASH ADJUST HISTORY
                </h3>
              </center>
            </div>
          </div>
        </div>

        <div
          className="card card-registration card-registration-2 mt-3"
          style={{ borderRadius: "15px" }}
        >
          <div className="card-body p-0">
            <div id="history">
              <center>
                <h3 className="mt-3 text-uppercase">
                  TRANSACTIONS
                </h3>
              </center>
              <div className="table-responsive px-2">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">SL NO.</th>
                      <th className="text-center">DATE</th>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">DONE BY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((h, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{h.Date}</td>
                        <td
                          className={`text-center ${
                            h.Action === "Created" ? "text-success" : "text-warning"
                          }`}
                        >
                          {h.Action}
                        </td>
                        <td style={{ textAlign: "center" }}>{h.done_by}</td>
                      </tr>
                    ))}
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Cashinhand_history;
