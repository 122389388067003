import React, { useEffect, useState } from 'react'
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import axios from 'axios';
import Cookies from "js-cookie";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import Select from 'react-select';
import "../styles/CreditNoteDetails.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';




function DebitNoteDetails() {




  const ID = Cookies.get("user_id");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  
  const [companyName, setCompanyName] = useState("");
  
  const [status, setStatus] = useState("all");

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  


  const [debitNoteDetails, setDebitNoteDetails] = useState([]);
  const [totalVendors, setTotalVendors] = useState("");
  const [totalBalance, setTotalBalance] = useState("");

  const [buttonClicked, setButtonClicked] = useState(false);


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgb(255 255 255 / 14%)",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      // width: '200px', // Adjust the width of the dropdown menu
      // maxHeight: '150px' // Adjust the height of the dropdown menu
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999 // Ensure the dropdown appears above other elements

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "lightgray"
        : state.isFocused
        ? "lightgray"
        : "white",
      color: state.isSelected ? "black" : "black",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
  };



  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
      // Position the div just below the button
      // var buttonRect = event.target.getBoundingClientRect();
      // contentDiv.style.top = (buttonRect.bottom + window.scrollY) + "px";
      // contentDiv.style.left = buttonRect.left + "px";
    }
  }

  useEffect(() => {


    console.log("fetching debit note details")

    axios
      .get(`${config.base_url}/fetch_debit_note_details_report/${ID}/`)
      .then((res) => {
        console.log("REPRT DATA=", res);
        setDebitNoteDetails(res.data.reportData);
        setTotalVendors(res.data.otherDetails.totVendors);
        setTotalBalance(res.data.otherDetails.totBalance);
        setCompanyName(res.data.otherDetails.cmpName);
        
      

      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });


      setButtonClicked(false);


    
  }, [buttonClicked])




  const fetchDebitNoteDetailsCustomised = (e) => {
    e.preventDefault();

    toggleContent();

    console.log("fetching Debit note details")

  
    

    var params  = {
      id: ID,
      start_date: startDate,
      end_date: endDate,
      status: status,
    };

    console.log(status)

    axios
      .get(`${config.base_url}/fetch_debit_note_details_customised/`, { params })
      .then((res) => {
        console.log("REPRT DATA=", res);
        setDebitNoteDetails(res.data.reportData);
        setTotalVendors(res.data.otherDetails.totVendors);
        setTotalBalance(res.data.otherDetails.totBalance);
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }



  





  


  

 

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Debit_Note_Details" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  } 



  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Debit_Note_Details" + date + ".xlsx");
  }


  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }



  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;







  async function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var emailsString = emailIds.trim();
    var emails = emailsString.split(",").map((email) => email.trim());
    var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    var invalidEmails = [];

    if (emailsString === "") {
        alert("Enter valid email addresses.");
    } else {
        for (var i = 0; i < emails.length; i++) {
            var currentEmail = emails[i];
            if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
                invalidEmails.push(currentEmail);
            }
        }

        if (invalidEmails.length > 0) {
            alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
            try {
                // Generate the PDF using html2pdf
                const element = document.getElementById("printReport");
                const opt = {
                    margin: [0.5, 0.3, 0.3, 0.5],
                    filename: "Debit_Note_Details.pdf",
                    image: { type: "jpeg", quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
                };

                // Convert the HTML element to PDF Blob using async/await
                const pdfBlob = await html2pdf().set(opt).from(element).toPdf().outputPdf('blob');

                // Create FormData object to send the PDF and other data
                const formData = new FormData();
                formData.append('pdf', pdfBlob, 'Debit_Note_Details.pdf');
                formData.append('Id', ID);
                formData.append('start', st);
                formData.append('end', end);
                formData.append('email_ids', emailIds);
                formData.append('email_message', emailMessage);

                // Send the form data with PDF to the backend
                const res = await axios.post(`${config.base_url}/share_debit_note_details_email/`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                if (res.data.status) {
                    Toast.fire({
                        icon: "success",
                        title: "Shared via mail.",
                    });
                    setEmailIds("");
                    setEmailMessage("");
                }
            } catch (err) {
                console.log("ERROR=", err);
                if (err.response && err.response.data && !err.response.data.status) {
                    Swal.fire({
                        icon: "error",
                        title: `${err.response.data.message}`,
                    });
                }
            }
        }
    }
  }






  return (
    <>
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />

        <div
          className="main-panel pt-2"
          style={{ background: "#a9a9a961", maxWidth:"100vw" }}
        >
          <div className="content-wrapper">
            <div className="body-wrapper" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">

              <div className="card radius-15 h-20">
                <div className="card-body" style={{ width: "100%" }}>
                    <div className="card-title text-center">
                        <h2 className="text-uppercase" id="headline">DEBIT NOTE DETAILS</h2>
                        <hr />
                    </div>

                    <div className="bar row">
                        <div className="col-12 col-md-6 d-flex justify-content-start mb-3">
                            <div className="position-relative">
                                <button
                                    className="btn btn-secondary"
                                    onClick={toggleContent}
                                    style={{ width: "fit-content", height: "fit-content" }}
                                >
                                    <i className="fas fa-solid fa-gear"></i> Customize Report
                                </button>
                                <div id="contentDiv" className="salescontent" style={{ display: "none" }}>
                                    <h6>Customize Report</h6>
                                    <form
                                        onSubmit={fetchDebitNoteDetailsCustomised}
                                        className="form reportCustomizeForm px-1"
                                        method="get"
                                    >
                                        <div className="form-group px-2">
                                            <label>From</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="start_date"
                                                id="startDate"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group px-2">
                                            <label>To</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="end_date"
                                                id="endDate"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group px-2">
                                            <label>Status</label>
                                            <select
                                                name="status"
                                                id="salesStatus"
                                                className="form-control"
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                            >
                                                <option value="all">All</option>
                                                <option value="saved">Saved</option>
                                                <option value="draft">Draft</option>
                                            </select>
                                        </div>

                                        <div className="d-flex px-2 mt-3 mb-4">
                                            <button type="submit" className="btn btn-outline-secondary w-50 mr-2">Run Report</button>
                                            <button type="reset" onClick={toggleContent} className="btn btn-outline-secondary w-50">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 d-flex justify-content-end flex-wrap">
                            <button className="btn btn-outline-secondary text-grey fa fa-file mr-2 mb-2" id="pdfBtn" onClick={reportPDF}>
                                &nbsp;PDF
                            </button>
                            <button className="btn btn-outline-secondary text-grey fa fa-print mr-2 mb-2" id="printBtn" onClick={printSection}>
                                &nbsp;Print
                            </button>
                            <button className="btn btn-outline-secondary text-grey fa fa-table mr-2 mb-2" id="exportBtn" onClick={ExportToExcel}>
                                &nbsp;Export
                            </button>
                            <div className="dropdown">
                                <button
                                    className="btn btn-outline-secondary text-grey fa fa-share-alt dropdown-toggle mb-2"
                                    data-toggle="dropdown"
                                >
                                    &nbsp;Share
                                </button>
                                <div className="dropdown-menu">
                                    <a href={shareUrl} target="_blank" rel="noopener noreferrer" className="dropdown-item">WhatsApp</a>
                                    <a href="#" className="dropdown-item" data-toggle="modal" data-target="#shareToEmail">Email</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





                <div className="card card-registration card-registration-2 card radius-15 mt-3">
                <div className="card-body p-0">

                <div className="container-fluid">
                    <div
                        id="printReport"
                        className="printReportTemplate"
                        style={{ display: "block " }}
                    >
                        <div className="my-5 page" size="A4">
                        <div id="printdiv2">
                            <div className="py-4 bg-secondary">
                            <div className="col-12">
                                <center className="h5 text-white">
                                <b>{companyName}</b>
                                </center>
                                <center className="h3 text-white">
                                <b>DEBIT NOTE DETAILS</b>
                                </center>
                                {startDate != "" && endDate != "" ? (
                                <center className="h6 text-white">
                                    {startDate} {"TO"} {endDate}
                                </center>
                                ) : null}
                            </div>
                            </div>
                            <div className="row px-1 py-1">
                            <div className="col-12">

                                <section className="product-area mt-2 py-1">
                                <table className="table table-responsive-md mt-4 table-hover"
                                id="reportTable">
                                    <thead>
                                    <tr>
                                      <th>STATUS</th>
                                      <th>DEBIT NOTE DATE</th>
                                      <th>DEBIT NOTE NO</th>
                                      <th>VENDOR NAME</th>
                                      <th>BALANCE AMT</th>
                                      <th>DEBIT NOTE AMT</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                  {debitNoteDetails.length > 0 &&
                                    debitNoteDetails.map((d) => (
                                      <tr>
                                        <td className="text-center">{d.status}</td>
                                        <td className="text-center">{d.date}</td>
                                        <td className="text-center">
                                          {d.debit_note_no}
                                        </td>
                                        <td className="text-center">{d.vendor_name}</td>
                                        <td className="text-center">
                                          {d.balance_amt}
                                        </td>
                                        <td className="text-center">
                                          {d.debit_note_amt}
                                        </td>
                                      
                                      </tr>
                                    ))}


                                  </tbody>

                                </table>

                                {debitNoteDetails.length == 0 ? (
                                  <center>
                                    <h4 className="text-dark">No data available.!</h4>
                                  </center>
                                ) : null}


                                
                                </section>

                                {debitNoteDetails.length != 0 ? (
                                  <section className="balance-info text-dark">
                                  <div className="row p-2">
                                    <div className="col-12">
                                      <div className="row mb-2">
                                        <div className="col-3" style={{ fontSize: 19 , fontWeight: 'bold' }}>Total Vendors: <p id="sales" >{totalVendors}<span id="totalInvoice"></span></p></div>
                                        <div className="col-3"></div>
                                        <div className="col-3"></div>
                                        <div className="col-3" style={{ fontSize: 19 , fontWeight: 'bold' }}>Total Balance: <p id="expense" >₹{totalBalance}<span id="totalCreditnote"></span></p></div>
                                      </div>
                                      
                                    </div>
                                    
                                  </div>
                                </section>

                              ) : null}

                                
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>


                </div>
                </div>



                {/* <!-- Share To Email Modal --> */}
                  <div className="modal fade" id="shareToEmail">
                    <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="m-3">Share Debit Note Details</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <form
                            onSubmit={handleShareEmail}
                            className="needs-validation px-1"
                            id="share_to_email_form"
                        >
                            <div className="card p-3 w-100">
                            <div className="form-group">
                                <label for="emailIds">Email IDs</label>
                                <textarea
                                className="form-control"
                                name="email_ids"
                                id="emailIds"
                                rows="3"
                                placeholder="Multiple emails can be added by separating with a comma(,)."
                                value={emailIds}
                                onChange={(e) => setEmailIds(e.target.value)}
                                required
                                />
                            </div>
                            <div className="form-group mt-2">
                                <label for="item_unitname">Message(optional)</label>
                                <textarea
                                name="email_message"
                                id="email_message"
                                className="form-control"
                                cols=""
                                rows="4"
                                value={emailMessage}
                                onChange={(e) => setEmailMessage(e.target.value)}
                                placeholder="This message will be sent along with Debit Note details."
                                />
                            </div>
                            </div>
                            <div
                            className="modal-footer d-flex justify-content-center w-100"
                            style={{ borderTop: "1px solid #ffffff" }}
                            >
                            <button
                                type="submit"
                                id="share_with_email"
                                className="submitShareEmailBtn w-50 text-uppercase"
                            >
                                SEND MAIL
                            </button>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>




              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default DebitNoteDetails