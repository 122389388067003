import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
// import config from "../../functions/config";
import config from '../../../functions/config';

import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "../styles/Staff.css"
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
// import "./styles/salesbyCustomerreport.css";


function Salesordercustomer() {
  const [requests, setRequests] = useState([]);
  const user = Cookies.get("role");
  const ID = Cookies.get("user_id");
  const [reportData, setReportData] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [trans, settrans] = useState("");
  const [report, setReport] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);



  const fetchSalesByCustomerReport = () => {
    axios
      .get(`${config.base_url}/SalesOrderByCustomer/${ID}/`)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setOtherDetails(res.data.otherDetails);
          setStartDate("");
          setEndDate("");
          settrans("");
          setReport("");
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchSalesByCustomerReport();
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  const navigate = useNavigate();

  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      report: report,
      transaction: trans,
    };

    axios
      .post(`${config.base_url}/SalesOrderByCustomerCustomized/`, det)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setOtherDetails(res.data.otherDetails);
          setStartDate(res.data.startDate);
          setEndDate(res.data.endDate);
          settrans(res.data.filtertrans);
          setReport(res.data.report);

          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {














































































































































































            toggleContent();
          }
        }
        console.log("API Response:", res.data);
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  

  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Report_SalesOrderByCustomer" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Report_SalesOrderByCustomer" + date + ".xlsx");
  }

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var tra = trans;
    var rpt = report;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          // document.getElementById("share_to_email_form").submit();
          var em = {
            Id: ID,
            start: st,
            end: end,
            transaction: tra,
            report: rpt,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/Share_SalesOrderByCustomerToEmail/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
  function toggleContent() {
   
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
      
    }
  }

  
  let sortDirection = true; 
  let lastSortedColumn = -1; 

  function sortTable(columnIndex) {
      var table, rows, switching, i, x, y, shouldSwitch;
      table = document.getElementById("reportTable");
      switching = true;

      while (switching) {
          switching = false;
          rows = table.rows;

          for (i = 1; i < rows.length - 1; i++) {
              shouldSwitch = false;
              x = rows[i].getElementsByTagName("td")[columnIndex].textContent.toLowerCase();
              y = rows[i + 1].getElementsByTagName("td")[columnIndex].textContent.toLowerCase();

              if (sortDirection ? x > y : x < y) {
                  shouldSwitch = true;
                  break;
              }
          }
          if (shouldSwitch) {
              rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
              switching = true;
          }
      }
      updateArrowIcons(columnIndex);

      sortDirection = !sortDirection;
  }
  function updateArrowIcons(columnIndex) {
      const arrows = document.querySelectorAll(".arrow");
      arrows.forEach(arrow => {
          arrow.classList.remove("fa-caret-up", "fa-caret-down");
          arrow.classList.add("fa-caret-up"); 
      });

      if (columnIndex === lastSortedColumn) {
          const arrow = arrows[columnIndex];
          arrow.classList.toggle("fa-caret-up", sortDirection);
          arrow.classList.toggle("fa-caret-down", !sortDirection);
      } else {
          arrows[columnIndex].classList.add("fa-caret-down");
          lastSortedColumn = columnIndex;
      }
  }
  
  


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961", maxWidth:"100vw" }}
          
        >
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
              <div className="card radius-15 h-20 mt-3">
              <div className="row w-100">
                <div className="col-md-12">
                  <center>
                    <h2 className="mt-3">SALES ORDER BY CUSTOMER</h2>
                  </center>
                  <hr />
                  <div
                        className="bar"
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="left d-flex justify-content-start"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                          }}
                        >
                          <div className="position-relative mr-2">
                            <button
                              className="btn btn-secondary"
                              onClick={toggleContent}
                              style={{ width: "fit-content", height: "fit-content" }}
                            >
                              <i className="fa-solid fa fa-gear"></i> Customize Report
                            </button>
                            <div
                              id="contentDiv"
                              className="salescontent"
                              style={{
                                position: "absolute",
                                backgroundColor: "#f4f5f7",
                                minWidth: "350px",
                                minHeight: "250px",
                                padding: "10px",
                                border: "1px solid #ccc",
                                height: "fit-content",
                                zIndex: 1,
                              }}
                            >
                              <h6>Customize Report</h6>
                              <form
                                onSubmit={handleCustomize}
                                className="form reportCustomizeForm px-1"
                                method="get"
                              >
                                <div className="px-2 mb-2 w-100">
                                  <label style={{ textAlign: "left" }}>From:</label>
                                  <br />
                                  <input
                                    name="from_date"
                                    className="inputdate form-control"
                                    type="date"
                                    id="startDate"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                  />
                                </div>
                                <div className="px-2 mb-2 w-100">
                                  <label style={{ textAlign: "left" }}>To:</label>
                                  <br />
                                  <input
                                    name="to_date"
                                    type="date"
                                    className="inputdate form-control"
                                    id="endDate"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                  />
                                </div>
                                
                                <div
                                  className="d-flex px-2 mt-3 mb-4 w-100"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <button
                                    type="submit"
                                    className="btn btn-outline-dark"
                                    style={{ width: "fit-content", height: "fit-content",color:"black" }}
                                  >
                                    Run Report
                                  </button>
                                  <button
                                    type="reset"
                                    onClick={toggleContent}
                                    className="btn btn-outline-dark ml-1 w-50"
                                    style={{ width: "fit-content", height: "fit-content" }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="right d-flex">
                          <a
                            className="btn btn-outline-secondary text-grey fa fa-file"
                            role="button"
                            id="pdfBtn"
                            onClick={reportPDF}
                            style={{ width: "fit-content", height: "fit-content" }}
                          >
                            &nbsp;PDF
                          </a>
                          <a
                            className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                            role="button"
                            id="printBtn"
                            onClick={printSection}
                            style={{ width: "fit-content", height: "fit-content" }}
                          >
                            &nbsp;Print
                          </a>
                          <a
                            className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
                            role="button"
                            id="exportBtn"
                            onClick={ExportToExcel}
                            style={{ width: "fit-content", height: "fit-content" }}
                          >
                            &nbsp;Export
                          </a>
                          <div className="dropdown p-0 nav-item" id="shareBtn">
                            <li
                              className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                              data-toggle="dropdown"
                              style={{ width: "fit-content", height: "fit-content" }}
                            >
                              &nbsp;Share
                            </li>
                            <ul
                              className="dropdown-menu mt-1"
                              style={{ backgroundColor: "black" }}
                              id="listdiv"
                            >
                              <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                                <li style={{ textAlign: "center" }}>Whatsapp</li>
                              </a>
                              <li
                                className="mb-2 ml-4"
                                id="toggleEmailModal"
                                style={{
                                  cursor: "pointer",
                                  color: "#e5e9ec",
                                  textAlign: "center",
                                }}
                                data-toggle="modal"
                                data-target="#shareToEmail"
                              >
                                Email
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>





                </div>
                {/* <div className="col-md-12 d-flex justify-content-between align-items-center flex-wrap">
                  <div className="d-flex mb-2">
                    <button
                      className="btn btn-secondary"
                      onClick={() => setContentVisible(!contentVisible)}
                      style={{ width: "fit-content", height: "fit-content" }}
                    >
                      <i className="fas fa-solid fa-gear"></i> Customize Report
                    </button>

                    <div
                      id="contentDiv"
                      style={{
                        display: contentVisible ? "block" : "none",
                        padding: "10px 0",
                        marginTop: "10px",
                      }}
                    >
                      <h6>Customize Report</h6>
                      <form
                        onSubmit={handleCustomize}
                        style={{ padding: "0 10px" }}
                        method="get"
                      >
                        <div style={{ padding: "10px 0", width: "100%" }}>
                          <label style={{ textAlign: "left" }}>From</label>
                          <br />
                          <input
                            className="form-control"
                            type="date"
                            name="start_date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            required={endDate !== "" ? true : false}
                          />
                        </div>
                        <div style={{ padding: "10px 0", width: "100%" }}>
                          <label style={{ textAlign: "left" }}>To</label>
                          <br />
                          <input
                            type="date"
                            className="form-control"
                            name="end_date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            required={startDate !== "" ? true : false}
                          />
                        </div>
                        <div className="d-flex px-2 mt-3 mb-4 w-100">
                                    <button
                                      type="submit"
                                      className="btn btn-outline-light w-50"
                                      style={{
                                        width: "fit-content",
                                        height: "fit-content",
                                      }}
                                    >
                                      Run Report
                                    </button>
                                    <button
                                      type="reset"
                                      onClick={toggleContent}
                                      className="btn btn-outline-light ml-1 w-50"
                                      style={{
                                        width: "fit-content",
                                        height: "fit-content",
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                      </form>
                    </div>
                  </div>
                  
                  <div className="d-flex flex-wrap">
                    <a
                      className="btn btn-outline-secondary text-grey fa fa-file mb-2"
                      role="button"
                      id="pdfBtn"
                      onClick={reportPDF}
                      style={{ width: "fit-content", height: "fit-content" }}
                    >
                      &nbsp;PDF
                    </a>
                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-print mb-2"
                      role="button"
                      id="printBtn"
                      onClick={printSection}
                      style={{ width: "fit-content", height: "fit-content" }}
                    >
                      &nbsp;Print
                    </a>
                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-table mb-2"
                      role="button"
                      id="exportBtn"
                      onClick={ExportToExcel}
                      style={{ width: "fit-content", height: "fit-content" }}
                    >
                      &nbsp;Export
                    </a>
                    
                    <div className="dropdown p-0 nav-item ml-2 mb-2" id="shareBtn">
                      <button
                        className="btn btn-outline-secondary text-grey fa fa-share-alt dropdown-toggle"
                        data-toggle="dropdown"
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                        }}
                      >
                        &nbsp;Share
                      </button>
                      <ul
                        className="dropdown-menu"
                        style={{ backgroundColor: "black" }}
                        id="listdiv"
                      >
                        <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                          <li
                            style={{
                              textAlign: "center",
                              color: "#e5e9ec",
                              cursor: "pointer",
                            }}
                          >
                            WhatsApp
                          </li>
                        </a>
                        <li
                          style={{
                            textAlign: "center",
                            color: "#e5e9ec",
                            cursor: "pointer",
                          }}
                          data-toggle="modal"
                          data-target="#shareToEmail"
                        >
                          Email
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

                
                        






                  
                  
                <div className="card radius-15  print-only mt-3"  id="pdf-card">
                  <div className="card-body">
                    <div className="container-fluid">
                      <div
                        id="printReport"
                        className="printReportTemplate"
                        style={{ display: "block " , boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: '5px'}}
                      >
                        <div className="my-5 page" size="A4">
                          <div id="printdiv2">
                            <div className="py-4 bg-secondary"  >
                              <div className="col-12">
                                <center className="h5 text-white">
                                  <b>{otherDetails.cmpName}</b>
                                </center>
                                <center className="h3 text-white">
                                  <b> SALES ORDER BY CUSTOMER</b>
                                </center>
                                {startDate != "" && endDate != "" ? (
                                  <center className="h6 text-white">
                                    {startDate} {"TO"} {endDate}
                                  </center>
                                ) : null}

                              </div>
                            </div>
                            <div className="row px-1 py-1">
                              <div className="col-12">
                                <section className="product-area mt-2 py-1">
                                  <table
                                    className="table table-responsive-md mt-4 table-hover table-bordered"
                                    id="reportTable"
                                  >
                                    <thead>
                                      <tr>
                                        
                                      <th className="text-center">CUSTOMER NAME</th>
                                      <th className="text-center">SALES ORDER COUNT</th>
                                      <th className="text-center">SALES ORDER AMOUNT</th>
                                    
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {reportData.length > 0 &&
                                        reportData.map((customer) => (
                                          <tr>
                                            <td className="text-center">{customer.customer_name}</td>
                                            <td className="text-center">{customer.sales_order_count}</td>
                                            <td className="text-center">{customer.sales_amount}</td>
                                          
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                  {reportData.length == 0 ? (
                                    <center>
                                      <h4 className="text-dark">No data available.!</h4>
                                    </center>
                                  ) : null}

                                </section>
                                <section className="balance-info text-dark">
                                  <div className="row p-3">
                                    <div className="col-10">
                                      
                                      <div className="row mb-2">
                                        <div className="col-4">Total Customers: <p id="customers" style={{ fontSize: 19 }}><span id="totalCustomers">{otherDetails.total_customers}</span></p></div>
                                        <div className="col-4 font-weight-bold">Total Order Count: <p id="salesCount" style={{ fontSize: 19 }}><span id="totalsalesorder">{otherDetails.total_sales_orders}</span></p></div>
                                        <div className="col-4 font-weight-bold">Total Amount: <p id="sale" style={{ fontSize: 19 }}>₹<span id="totalAmount"></span>{otherDetails.total_sales_amount}</p></div>
                                      </div>
                                    
                                    </div>
                                    </div>
                                </section>









                              </div>
                            </div>






                          </div>
                        </div>





                      </div>
                    </div>
                          

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#f4f5f7" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>








    </div>
  );
}

export default Salesordercustomer;

