import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import Swal from "sweetalert2";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import "./styles/customer.css";
import "bootstrap/dist/css/bootstrap.css";
import html2pdf from "html2pdf.js";



function Customer_view() {
  const ID = Cookies.get("user_id");
  const { customerId } = useParams();
  const [customerDetails, setCustomerDetails] = useState({});
  const [balance, setBalance] = useState(0);
  const [companyDetails,setCompanyDetails] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [extraDetails, setExtraDetails] = useState({
    paymentTerms: 'Nill',
    priceList: 'Nill'
  })
  const [comments, setComments] = useState([]);
  const [history, setHistory] = useState({
    action: "",
    date: "",
    doneBy: "",
  });
  

  const fetchCustomerDetails = () => {
    axios
      .get(`${config.base_url}/get_customer_details/${customerId}/${ID}/`)
      .then((res) => {
        console.log("CUST DATA=", res);
        if (res.data.status) {
          const itm = res.data.customer || {};
          const ext = res.data.extraDetails || {};
          const hist = res.data.history || [];
          const cmt = res.data.comments || [];
          const trans = res.data.all_transactions || []; 
          var bal = res.data.Balance;
          var company = res.data.company;

  
          setComments([]);
          cmt.forEach((c) => {
            setComments((prevState) => [...prevState, c]);
          });
  
          setExtraDetails(ext);
          setCustomerDetails(itm);
          setHistory(hist);
          setTransactions(trans);  
          setCompanyDetails(company || []);
        }
        setBalance(bal)
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (err.response && err.response.data) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "An error occurred while fetching customer details.",
          });
        }
      });
  };

  useEffect(() => {
    fetchCustomerDetails();
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  const navigate = useNavigate();

  const changeStatus = (status) => {
    var st = {
      id: customerId,
      status: status,
    };
    axios
      .post(`${config.base_url}/change_customer_status/`, st)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Status Updated",
          });
          fetchCustomerDetails();
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };
  const [comment, setComment] = useState("");
  const saveItemComment = (e) => {
    e.preventDefault();
    var cmt = {
      Id: ID,
      customer: customerId,
      comments: comment,
    };
    console.log("ID:", ID);
    axios
      .post(`${config.base_url}/add_customer_comment/`, cmt)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Comment Added",
          });
          setComment("");
          fetchCustomerDetails();
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  function handleDeleteCustomer() {
    Swal.fire({
      title: `Delete Customer - ${customerDetails.first_name}?`,
      text: "All transactions will be deleted.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_customer/${customerId}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Customer Deleted successfully",
            });
            navigate("/customers");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  function deleteComment(id) {
    Swal.fire({
      title: "Delete Comment?",
      text: "Are you sure you want to delete this.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_customer_comment/${id}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Comment Deleted",
            });
            fetchCustomerDetails();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [filteredBalance, setFilteredBalance] = useState(0);
  
  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };
  
  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };
  
  const calculateBalance = (transactions) => {
    let initialBalance = 0; 
    transactions.forEach(transaction => {
      initialBalance += parseFloat(transaction.balance);
    });
   
    return initialBalance;
  };
  
  const filterTransactions = () => {

    const from = new Date(fromDate);
    const to = new Date(toDate);

    const parseTransactionDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return new Date(`${year}-${month}-${day}`);
    };

    const filtered = transactions.filter((transaction) => {
        const transactionDate = parseTransactionDate(transaction.date);
       

        return transactionDate >= from && transactionDate <= to;
    });


    setFilteredTransactions(filtered);
    const newBalance = calculateBalance(filtered);
    setFilteredBalance(newBalance);
  };

  useEffect(() => {
    setFilteredTransactions(transactions);
    setFilteredBalance(calculateBalance(transactions));
  }, [transactions]);


  function overview() {
    document.getElementById("overview").style.display = "block";
    document.getElementById("transaction").style.display = "none";
    document.getElementById("statement").style.display = "none";
    document.getElementById("printBtn").style.display = "none";
    document.getElementById("pdfBtn").style.display = "none";
    document.getElementById("shareBtn").style.display = "none";
    document.getElementById("editBtn").style.display = "block";
    document.getElementById("deleteBtn").style.display = "block";
    document.getElementById("exportBtn").style.display = "none";
    document.getElementById("overviewBtn").style.backgroundColor = '#a9a9a961';
    document.getElementById("transactionBtn").style.backgroundColor = "transparent";
    document.getElementById("statementBtn").style.backgroundColor = "transparent";
    document.getElementById("historyBtn").style.display = "block";
    document.getElementById("statusBtn").style.display = "block";
    document.getElementById("commentsBtn").style.display = "block";
  }
  function transaction() {
    document.getElementById("overview").style.display = "none";
    document.getElementById("transaction").style.display = "block";
    document.getElementById("statement").style.display = "none";
    document.getElementById("printBtn").style.display = "none";
    document.getElementById("pdfBtn").style.display = "none";
    document.getElementById("shareBtn").style.display = "none";
    document.getElementById("editBtn").style.display = "none";
    document.getElementById("deleteBtn").style.display = "none";
    document.getElementById("exportBtn").style.display = "block";
    document.getElementById("overviewBtn").style.backgroundColor = "transparent";
    document.getElementById("transactionBtn").style.backgroundColor ='#a9a9a961';
    document.getElementById("statementBtn").style.backgroundColor = "transparent";
    document.getElementById("historyBtn").style.display = "none";
    document.getElementById("statusBtn").style.display = "none";
    document.getElementById("commentsBtn").style.display = "none";
  }

  function statement() {
    document.getElementById("overview").style.display = "none";
    document.getElementById("transaction").style.display = "none";
    document.getElementById("statement").style.display = "block";
    document.getElementById("printBtn").style.display = "block";
    document.getElementById("pdfBtn").style.display = "block";
    document.getElementById("shareBtn").style.display = "block";
    document.getElementById("editBtn").style.display = "none";
    document.getElementById("deleteBtn").style.display = "none";
    document.getElementById("exportBtn").style.display = "none";
    document.getElementById("overviewBtn").style.backgroundColor = "transparent";
    document.getElementById("transactionBtn").style.backgroundColor ="transparent";
    document.getElementById("statementBtn").style.backgroundColor ='#a9a9a961';
    document.getElementById("historyBtn").style.display = "none";
    document.getElementById("statusBtn").style.display = "none";
    document.getElementById("commentsBtn").style.display = "none";
  }

  function ExportToExcel(type, fn, dl) {
    var elt = document.getElementById("transactionTable");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(
          wb,
          fn || `${customerDetails.first_name}_transactions.` + (type || "xlsx")
        );
  }

  function printSheet() {
    var divToPrint = document.getElementById("printContent");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function reportPDF() {
    var element = document.getElementById("printContent");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: `${customerDetails.first_name} ${customerDetails.last_name}_transactions` ,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }
  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  async function handleShareEmail(e) {
    e.preventDefault();

    const emails = emailIds.trim().split(",").map(email => email.trim());
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const invalidEmails = emails.filter(email => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
      return;
    }

    const element = document.getElementById("printContent");
    const opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: `${customerDetails.first_name} ${customerDetails.last_name}_transactions`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };

    const pdfBlob = await html2pdf().set(opt).from(element).outputPdf('blob');

    const formData = new FormData();
    formData.append('Id', ID);
    formData.append("pdf", pdfBlob, `${customerDetails.first_name} ${customerDetails.last_name}_transactions.pdf`);
    formData.append("email_ids", emailIds);
    formData.append("c_id", customerId,)

    axios
      .post(`${config.base_url}/transaction_email/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data);
        
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Shared via mail.",
          });
          setEmailIds("");
          setEmailMessage("");
        }
      })
      .catch((err) => {
        console.log(err)
        if (err.response && err.response.data && !err.response.data.status) {
          Toast.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }

  return (
    <>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper d-flex">
            <Sidebar />
      <div
        className="page-content  px-3 py-2"
        style={{ minHeight: "100vh",background: "#a9a9a961",width: "100%",marginRight: "0" }}
      >
        
        
        <div className="card radius-15 mt-5"
        style={{
            width: "100%",
            marginRight: "0", 
          }}
        >
        
          <div className="card-body  ">
            <div className="card-title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <a
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                       
                      }}
                      onClick={overview}
                      id="overviewBtn"
                    >
                      Overview
                    </a>
                    <a
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                      }}
                      onClick={transaction}
                      id="transactionBtn"
                    >
                      {" "}
                      Transactions
                    </a>
                    <Link
                      style={{
                        color:"black",
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                      }}
                      onClick={statement}
                      id="statementBtn"
                    >
                      {" "}
                      Statement
                    </Link>
                  </div>

                  <div className="col-md-6 d-flex justify-content-end">
                    {customerDetails.status == "Inactive" ? (
                      <a
                        onClick={() => changeStatus("Active")}
                        id="statusBtn"
                        className="ml-2 fa fa-ban btn btn-outline-secondary text-grey custom-btn"
                        role="button"
                        style={{ height: "fit-content", width: "fit-content" }}
                      >
                        &nbsp;Inactive
                      </a>
                    ) : (
                      <a
                        onClick={() => changeStatus("Inactive")}
                        id="statusBtn"
                        className="ml-2 fa fa-check-circle btn btn-outline-secondary text-grey custom-btn"
                        role="button"
                        style={{ height: "fit-content", width: "fit-content" }}
                      >
                        &nbsp;Active
                      </a>
                    )}
                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-table custom-btn"
                      role="button"
                      id="exportBtn"
                      style={{
                        display: "none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                      onClick={() => ExportToExcel("xlsx")}
                    >
                      &nbsp;Export
                    </a>
                    <Link
                      onClick={reportPDF}
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-file"
                      role="button"
                      id="pdfBtn"
                      style={{
                        display: "none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                    >
                      {" "}
                      &nbsp;PDF
                    </Link>
                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-print custom-btn"
                      role="button"
                      id="printBtn"
                      onClick={() => printSheet()}
                      style={{
                        display: "none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                    >
                      &nbsp;Print
                    </a>
                    <div
                      className="dropdown p-0 nav-item"
                      id="shareBtn"
                      style={{ display: "none" }}
                    >
                      <li
                        className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt custom-btn"
                        data-toggle="dropdown"
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                        }}
                      >
                        &nbsp;Share
                      </li>
                      <ul
                        className="dropdown-menu"
                        style={{ backgroundColor: "black" }}
                        id="listdiv"
                      >
                        <a
                          href={shareUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li
                            style={{
                              textAlign: "center",
                              color: "#e5e9ec",
                              cursor: "pointer",
                            }}
                          >
                            WhatsApp
                          </li>
                        </a>
                        <li
                          style={{
                            textAlign: "center",
                            color: "#e5e9ec",
                            cursor: "pointer",
                          }}
                          data-toggle="modal"
                          data-target="#shareToEmail"
                        >
                          Email
                        </li>
                      </ul>
                    </div>
                    <Link
                      to={`/customer_edit/${customerId}/`}
                      className="ml-2 fa fa-pencil btn btn-outline-secondary text-grey custom-btn"
                      id="editBtn"
                      role="button"
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;Edit
                    </Link>
                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-trash custom-btn"
                      id="deleteBtn"
                      role="button"
                      onClick={handleDeleteCustomer}
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;Delete
                    </a>
                    <a
                      href="#"
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-comments custom-btn"
                      id="commentsBtn"
                      role="button"
                      data-toggle="modal"
                      data-target="#commentModal"
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;Comment
                    </a>
                    <Link
                      to={`/customer_history/${customerDetails.id}/`}
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-history custom-btn"
                      id="historyBtn"
                      role="button"
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;History
                    </Link>
                  </div>
                </div>
              </div>
              <center>
                <h3 className="card-title">CUSTOMER OVERVIEW</h3>
              </center>
            </div>
          </div>
        </div>

        <div
          className="card card-registration card-registration-2 mt-5"
          style={{ borderRadius: "15px" }}
        >
          <div className="card-body p-0">
            <div id="overview">
              <div className="row g-0 mx-0">
                <div className="col-lg-8">
                  <div className="history_highlight pt-3 px-2 d-flex">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      {history.action == "Created" ? (
                        <p
                          className="text-success m-0"
                          style={{ fontSize: "1.07rem", fontWeight: "500" }}
                        >
                          Created by :
                        </p>
                      ) : (
                        <p
                          className="text-success m-0"
                          style={{ fontSize: "1.07rem", fontWeight: "500" }}
                        >
                          Last Edited by :
                        </p>
                      )}
                      <span
                        className="ml-2"
                        style={{ fontSize: "1.15rem", fontWeight: "500" }}
                      >
                        {history.doneBy}
                      </span>
                      <span className="ml-5">{history.date}</span>
                    </div>
                  </div>

                  <div className="pb-3 px-2">
                    <div className="card-body">
                      <div className="card-title">
                        <div className="row">
                          <div className="col mt-3">
                            <h2 className="mb-0">
                              {customerDetails.title}
                              {"."} {customerDetails.first_name}{" "}
                              {customerDetails.last_name}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                      {customerDetails.company && (
                        <>
                          <div className="col-md-2 mt-3">
                            <h6 className="mb-0">Company</h6>
                          </div>
                          <div className="col-md-1 mt-3">:</div>
                          <div className="col-md-3 mt-3">
                            <p className="mb-0">{customerDetails.company}</p>
                          </div>
                        </>
                      )}

                        <div className="col-md-2 mt-3 vl">
                          <h6 className="mb-0">Email</h6>
                        </div>
                        <div className="col-md-1 mt-3">:</div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0">{customerDetails.email}</p>
                        </div>
                      </div>

                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Mobile</h6>
                        </div>
                        <div className="col-md-1 mt-3">:</div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0">{customerDetails.mobile}</p>
                        </div>
                        {customerDetails.website ? (
                          <>
                            <div className="col-md-2 mt-3 vl">
                              <h6 className="mb-0">Website</h6>
                            </div>
                            <div className="col-md-1 mt-3">:</div>
                            <div className="col-md-3 mt-3">
                              <p className="mb-0">{customerDetails.website? customerDetails.website : 'Nill'}</p>
                            </div>
                          </>
                        ): (
                          <>
                            <div className="col-md-2 mt-3 vl">
                              <h6 className="mb-0"></h6>
                            </div>
                            <div className="col-md-1 mt-3"></div>
                            <div className="col-md-3 mt-3">
                            </div>
                          </>
                        ) }
                      </div>

                      <div className="row mb-4 d-flex justify-content-start align-items-center">
  {customerDetails.location && customerDetails.place_of_supply ? (
    <>
      {/* When both 'Location' and 'Place of Supply' have data */}
      <div className="col-md-2 mt-3">
        <h6 className="mb-0">Location</h6>
      </div>
      <div className="col-md-1 mt-3">:</div>
      <div className="col-md-3 mt-3">
        <p className="mb-0">{customerDetails.location}</p>
      </div>
      <div className="col-md-2 mt-3">
        <h6 className="mb-0">Place of Supply</h6>
      </div>
      <div className="col-md-1 mt-3">:</div>
      <div className="col-md-3 mt-3">
        <p className="mb-0">{customerDetails.place_of_supply}</p>
      </div>
    </>
  ) : (
    <>
      {/* When only 'Location' has data */}
      {customerDetails.location && (
        <>
          <div className="col-md-2 mt-3">
            <h6 className="mb-0">Location</h6>
          </div>
          <div className="col-md-1 mt-3">:</div>
          <div className="col-md-3 mt-3">
            <p className="mb-0">{customerDetails.location}</p>
          </div>
        </>
      )}

      {/* When only 'Place of Supply' has data */}
      {customerDetails.place_of_supply && (
        <>
          <div className="col-md-2 mt-3">
            <h6 className="mb-0">Place of Supply</h6>
          </div>
          <div className="col-md-1 mt-3">:</div>
          <div className="col-md-3 mt-3">
            <p className="mb-0">{customerDetails.place_of_supply}</p>
          </div>
        </>
      )}
    </>
  )}
</div>





                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        {extraDetails.paymentTerms && (
                          <>
                            <div className="col-md-2 mt-3">
                              <h6 className="mb-0">Payment Terms</h6>
                            </div>
                            <div className="col-md-1 mt-3">:</div>
                            <div className="col-md-3 mt-3">
                              <p className="mb-0">{extraDetails.paymentTerms}</p>
                            </div>
                          </>
                        )}

                        {extraDetails.priceList && (
                          <>
                            <div className="col-md-2 mt-3 vl">
                              <h6 className="mb-0">Price List</h6>
                            </div>
                            <div className="col-md-1 mt-3">:</div>
                            <div className="col-md-3 mt-3">
                              <p className="mb-0">{extraDetails.priceList}</p>
                            </div>
                          </>
                        )}
                      </div>


                      <hr />
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="card-content bg-transparent border-0 ">
                            <div className="row">
                              <div className="col-md-12">
                                <h5
                                  className="ml-3"
                                  style={{ textAlign: "center" }}
                                >
                                  Billing Address
                                </h5>
                                <hr />
                              </div>
                            </div>
                            <br />
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Street</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-7">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {customerDetails.billing_street}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">City</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-7">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {customerDetails.billing_city}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">State</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-7">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {customerDetails.billing_state}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Pincode</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-7">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {customerDetails.billing_pincode}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Country</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-7">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {customerDetails.billing_country}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                        <div className="card-content bg-transparent border-0">
                          <div className="row">
                            <div className="col-md-12">
                              <h5 className="ml-3" style={{ textAlign: "center" }}>
                                Shipping Address
                              </h5>
                              <hr />
                            </div>
                          </div>
                          <br />
                          {/* Conditionally render each row based on the availability of data */}
                          {customerDetails.ship_street && (
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Street</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-7">
                                <p className="mb-0" style={{ textAlign: "right" }}>
                                  {customerDetails.ship_street}
                                </p>
                              </div>
                            </div>
                          )}

                          {customerDetails.ship_city && (
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">City</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-7">
                                <p className="mb-0" style={{ textAlign: "right" }}>
                                  {customerDetails.ship_city}
                                </p>
                              </div>
                            </div>
                          )}

                          {customerDetails.ship_state && (
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">State</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-7">
                                <p className="mb-0" style={{ textAlign: "right" }}>
                                  {customerDetails.ship_state}
                                </p>
                              </div>
                            </div>
                          )}

                          {customerDetails.ship_pincode && (
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Pincode</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-7">
                                <p className="mb-0" style={{ textAlign: "right" }}>
                                  {customerDetails.ship_pincode}
                                </p>
                              </div>
                            </div>
                          )}

                          {customerDetails.ship_country && (
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Country</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-7">
                                <p className="mb-0" style={{ textAlign: "right" }}>
                                  {customerDetails.ship_country}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 bg-grey"
                  style={{
                    backgroundColor: "#6b6b6b61",
                    borderTopRightRadius: "2vh",
                    borderBottomRightRadius: "2vh",
                  }}
                >
                  <div className="px-3">
                    <h3 className="fw-bold mb-2 mt-4 pt-1">Customer Details</h3>
                    <hr className="my-4" />
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Status</h6>
                      {customerDetails.status == "Active" ? (
                        <i className="fa fa-check-circle text-success">
                          &nbsp;ACTIVE
                        </i>
                      ) : (
                        <i className="fa fa-ban text-danger">&nbsp;INACTIVE</i>
                      )}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="mr-5">GST Type</h6>
                      {customerDetails.gst_type}
                    </div>
                    {customerDetails.gstin ? (
                      <div className="d-flex justify-content-between mb-4">
                        <h6 className="">GSTIN</h6>
                        {customerDetails.gstin ? customerDetails.gstin : 'Nill'}
                      </div>
                    ):null}
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">PAN</h6>
                      {customerDetails.pan_no}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Opening Bal.</h6>
                      {customerDetails.opening_balance}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Credit Limit</h6>
                      {customerDetails.credit_limit}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Balance</h6>
                      {customerDetails.current_balance}
                    </div>
                  </div>
                </div>
                
              </div>
            </div>

            <div id="transaction" style={{ display: "none" }}>
              <div id="printContent1">
                <center>
                  <h3 className="mt-3 text-uppercase">
                    {customerDetails.first_name} {customerDetails.last_name} -
                    TRANSACTIONS
                  </h3>
                </center>
                <div className="row mt-5">
                  <div className="col d-flex justify-content-between px-5">
                    <div className="customer_data">
                      <p>Email: {customerDetails.email}</p>
                      <p>GSTIN: {customerDetails.gstin}</p>
                      <p>
                        Address:
                        <br />
                        {customerDetails.billing_street},{" "}
                        {customerDetails.billing_city},{" "}
                        {customerDetails.billing_state} <br />
                        {customerDetails.billing_country}-
                        {customerDetails.billing_pincode}
                      </p>
                    </div>
                    <div className="customer_data">
                      <p>Mobile: {customerDetails.mobile}</p>
                      <p>Credit Limit: {customerDetails.credit_limit}</p>
                      <p>Balance: {balance}</p>
                    </div>
                  </div>
                </div>
                <div className="table-responsive px-2">
                  <table className="table table-bordered" id="transactionTable">
                    <thead>
                      <tr>
                        <th className="text-center text-uppercase">
                          Transaction
                        </th>
                        <th className="text-center text-uppercase">Type</th>
                        <th className="text-center text-uppercase">Number</th>
                        <th className="text-center text-uppercase">Date</th>
                        <th className="text-center text-uppercase">Total</th>
                        <th className="text-center text-uppercase">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                    {transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{transaction.transaction}</td>
                        <td style={{ textAlign: "center" }}>{transaction.type}</td>
                        <td style={{ textAlign: "center" }}>{transaction.number}</td>
                        <td style={{ textAlign: "center" }}>{transaction.date}</td>
                        <td style={{ textAlign: "center" }}>{transaction.total}</td>
                        <td style={{ textAlign: "center" }}>{transaction.balance}</td>
                      </tr>
                    ))}
                  </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
      
      <div id="statement" style={{ display: 'none' }}>
  <div className="container-fluid">
    <div className="py-5">
      <div className="row">
        <div className="col-12 col-md-4 d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="date_range d-flex flex-column flex-md-row">
            <div className="form-group">
              <label htmlFor="from">From</label>
              <input
                type="date"
                className="form-control"
                id="fromDate"
                value={fromDate}
                onChange={handleFromDateChange}
              />
            </div>
            <div className="form-group ml-md-2 mt-3 mt-md-0">
              <label htmlFor="to">To</label>
              <input
                type="date"
                className="form-control"
                id="toDate"
                value={toDate}
                onChange={handleToDateChange}
              />
            </div>
          </div>
          <div className="form-group ml-md-2 mt-3 mt-md-4">
            <button className="btn btn-outline-secondary btn-sm" onClick={filterTransactions}>
              Run Report
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div className="row mt-4">
        <div className="col-md-8 mx-auto">
          <div className="card">
            <div className="card-block1">
              <div id="printContent" className="print-container card-body">
                <div className="container">
                  <div className="row">
                    <div className="col-12" style={{ padding: '20px' }}>
                      <div className="widget-box">
                        <div className="widget-header widget-header-large text-center py-3 px-4" style={{ backgroundColor: '#343a40' }}>
                          <h5 className="widget-title text-white mb-4">
                            <strong>Statement of {customerDetails.title}. {customerDetails.first_name} {customerDetails.last_name}</strong>
                          </h5>
                        </div>

                        <div className="widget-body bg-white py-3 px-2">
                          <div className="widget-main">
                            <div className="row text-center" style={{ padding: '20px' }}>
                              <div className="col-md-6">
                                <p>
                                  <strong className="text-dark" style={{ fontSize: '1.3rem' }}>{companyDetails.company_name}</strong><br />
                                  {companyDetails.address}, {companyDetails.city}<br />
                                  {companyDetails.state}, {companyDetails.country}<br />
                                  {companyDetails.pincode}<br />
                                  {companyDetails.email}
                                </p>
                              </div>
                              <div className="col-md-6">
                                <p>
                                  <strong className="text-dark" style={{ fontSize: '1.3rem' }}>{customerDetails.first_name} {customerDetails.last_name}</strong><br />
                                  {customerDetails.billing_street}, {customerDetails.billing_city}<br />
                                  {customerDetails.billing_state}, {customerDetails.billing_country}<br />
                                  {customerDetails.billing_pincode}<br />
                                  {customerDetails.email}
                                </p>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 text-right">
                                <table className="table border-0">
                                  <thead>
                                    <tr className="border-0">
                                      <th className="border-0">Account Summary</th>
                                      <th className="border-0"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="border-0">
                                      <td className="border-0">Opening balance</td>
                                      <td className="border-0">{customerDetails.opening_balance}</td>
                                    </tr>
                                    <tr className="border-0">
                                      <td className="border-0">Balance</td>
                                      <td className="border-0">{filteredBalance}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="table-responsive">
                              <table id="statementTable" className="table table-hover text-center">
                                <thead style={{ backgroundColor: '#22b8d1' }}>
                                  <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Number</th>
                                    <th>Total</th>
                                    <th>Balance</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredTransactions.map((transaction, index) => (
                                    <tr key={index}>
                                      <td>{transaction.date}</td>
                                      <td><strong>{transaction.type}</strong></td>
                                      <td>{transaction.number}</td>
                                      <td>{transaction.total}</td>
                                      <td>{transaction.balance}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

        </div>
        </div>
    

      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content bg-secondary" >
            <div className="modal-header">
              <h5 className="m-3">Share Item Transactions</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Bill details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      <div
        className="modal fade"
        id="commentModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content bg-secondary" >
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Add Comments
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form onSubmit={saveItemComment} className="px-1">
              <div className="modal-body w-100">
                <textarea
                  type="text"
                  className="form-control"
                  name="comment"
                  value={comment}
                  required
                  onChange={(e) => setComment(e.target.value)}
                />
                {comments.length > 0 ? (
                  <div className="container-fluid">
                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th className="text-center">sl no.</th>
                          <th className="text-center">Comment</th>
                          <th className="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {comments.map((c, index) => (
                          <tr className="table-row">
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{c.comments}</td>
                            <td className="text-center">
                              <a
                                className="text-danger"
                                onClick={() => deleteComment(`${c.id}`)}
                              >
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    fontSize: "1.1rem",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="my-2 font-weight-bold d-flex justify-content-center">
                    No Comments.!
                  </span>
                )}
              </div>

              <div className="modal-footer w-100">
                <button
                  type="button"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn "
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn btn-secondary"
                  id="commentSaveBtn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      
      
    </>
  );
}

export default Customer_view;
