import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import { Link, useNavigate , useParams} from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "./styles/Staff.css"

function Loanholderhistory() {
  const [requests, setRequests] = useState([]);
  const user = Cookies.get("role");
  const ID = Cookies.get("user_id");
  const { loanId } = useParams();
  const [history, setHistory] = useState([]);
  const [loan, setLoan] = useState({});
  const [emp, setEmp] = useState({});

  const fetchLoanHistory = () => {
    axios
      .get(`${config.base_url}/fetch_loan_account_history/${loanId}/`)
      .then((res) => {
        console.log("HOLDER HIST=", res);
        if (res.data.status) {
          var hldr = res.data.holderr;
          var hist = res.data.history;
          var empl = res.data.holderr.holder
          setLoan(hldr);
          setEmp(empl)
          setHistory([]);
          hist.map((i) => {
            setHistory((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchLoanHistory();
  }, []);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961", width:"100%" }}
          
        >
            <Link
            className="d-flex justify-content-end p-2"
            style={{ cursor: "pointer" }}
            to={`/viewloanholder/${loanId}/`}
            >
            <i
                className="fa fa-times-circle text-white"
                style={{ fontSize: "1.2rem" }}
            ></i>
            </Link>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
                <div className="card radius-15 h-20 mt-3">
                  <div className="row w-100">
                    <div className="col-md-12">
                      <center>
                        <h2 className="mt-3">All</h2>
                        {loan.status == "Inactive" ? (
                        <h6
                            className="blinking-text"
                            style={{ color: "red", width: "140px", fontWeight: "bold" }}
                        >
                            INACTIVE
                        </h6>
                        ) : (
                        <h6
                            style={{
                            width: "140px",
                            color: "green",
                            fontWeight: "bold",
                            }}
                        >
                            ACTIVE
                        </h6>
                        )}
                        


                      </center>
                      <hr />
                    </div>
                  </div>
                </div>
                <div className="card radius-15 mt-3">
                    <div className="card-body p-0">
                        <div id="history">
                            <center>
                                <h3 className="mt-3 text-uppercase">
                                {loan.account_name}  - LOAN HISTORY
                                </h3>
                            </center>
                            <div className="table-responsive px-2">
                                <table className="table table-bordered">
                                <thead>
                                    <tr>
                                    <th className="text-center">SL NO.</th>
                                    <th className="text-center">DATE</th>
                                    <th className="text-center">ACTION</th>
                                    <th className="text-center">DONE BY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history &&
                                    history.map((h, index) => (
                                        <tr>
                                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                                        <td style={{ textAlign: "center" }}>{h.date}</td>
                                        {h.action == "Created" ? (
                                            <td className="text-success text-center">
                                            {h.action}
                                            </td>
                                        ) : (
                                            <td className="text-warning text-center">
                                            {h.action}
                                            </td>
                                        )}
                                        <td style={{ textAlign: "center" }}>{h.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                
                
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loanholderhistory;

