import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import "./styles/Sidebar.css";
import Cookies from "js-cookie";
import config from "../../functions/config";
import axios from "axios";

const Sidebar = () => {
  const [menuState, setMenuState] = useState({});
  const location = useLocation();

  const ID = Cookies.get("user_id");

  const user = Cookies.get("role");
  var is_company = false;
  if (user === "Company" || user === "Staff") {
    is_company = true;
  }

  const [modules, setModules] = useState({});

  const fetchModules = () => {
    axios
      .get(`${config.base_url}/get_modules/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const modules = res.data.modules;

          setModules(modules);
        }
      })
      .catch((err) => {
        console.log("MODULES ERROR==", err);
      });
  };

  useEffect(() => {
    fetchModules();
  }, []);

  const toggleMenuState = (menu) => {
    setMenuState((prevState) => {
      const newState = { ...prevState };
      newState[menu] = !prevState[menu];
      return newState;
    });
  };


  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});

    const dropdownPaths = [
      { path: "/company_home", state: "appsMenuOpen" },
      { path: "/company_staffs", state: "companyStaffMenuOpen" },
      { path: "/Items", state: "ItemsMenuOpen" },
      { path: "/sales", state: "salesMenuOpen" },
      
     
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setMenuState((prevState) => ({ ...prevState, [obj.state]: true }));
      }
    });
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, [location]);

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li
          className={
            isPathActive("/company_home") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/company_home">
            <span className="menu-title">
              <Trans>Dashboard</Trans>
            </span>
            <i className="mdi mdi-home menu-icon mb-0"></i>
          </Link>
        </li>
        {is_company ? (
          <li
            className={
              isPathActive("/company_staffs") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.companyStaffMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("companyStaffMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Staff</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-account menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.companyStaffMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/company_staffs/staff_requests")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/company_staffs/staff_requests"
                  >
                    <Trans>Staff Request</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/company_staffs/all_staffs")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/company_staffs/all_staffs"
                  >
                    <Trans>All Staff</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        ) : null}
         <li
            className={
              isPathActive("/company_staffs") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.companyStaffMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("companyStaffMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Cash and Bank</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-package menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.companyStaffMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/banking/bankinglistout")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/banking/bankinglistout"
                  >
                    <Trans>Offline Banking</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  
                </li>
                   <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/banklist") ? "nav-link active" : "nav-link"
                    }
                    to="/banklist"
                  >
                    <Trans>Bank Holder</Trans>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/cashinhand") ? "nav-link active" : "nav-link"
                    }
                    to="/cashinhand"
                  >
                    <Trans>Cash In Hand</Trans>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/ChequePayments") ? "nav-link active" : "nav-link"
                    }
                    to="/ChequePayments"
                  >
                    <Trans>Cheques</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/loanholderlist") ? "nav-link active" : "nav-link"
                    }
                    to="/loanholderlist"
                  >
                    <Trans>Loan Account</Trans>
                  </Link>
                </li>






                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/UPIPayments") ? "nav-link active" : "nav-link"
                    }
                    to="/UPIPayments"
                  >
                    <Trans>UPI</Trans>
                  </Link>
                </li>


              </ul>
            </Collapse>
          </li>   
        
        

        
          <li
            className={
              isPathActive("/Items") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.ItemsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("ItemsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Item</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-package menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.ItemsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/Items")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/Items"
                  >
                    <Trans>Items</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>

            <Collapse in={menuState.ItemsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/PriceList")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/PriceList"
                  >
                    <Trans>Price Lists</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.ItemsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/stock_adjustment") ? "nav-link active" : "nav-link"
                    }
                    to="/stock_adjustment"
                  >
                    <Trans>Stock Adjustment</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>


          </li>
          <li
            className={
              isPathActive("/customers") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.customerMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => toggleMenuState("customerMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Sales</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-file-chart menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/customers") ? "nav-link active" : "nav-link"
                    }
                    to="/customers"
                  >
                    <Trans>Customers</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>


<Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/invoice") ? "nav-link active" : "nav-link"
                    }
                    to="/invoice"
                  >
                    <Trans>Invoice</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>


            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/ret_invoice") ? "nav-link active" : "nav-link"
                    }
                    to="/ret_invoice"
                  >
                    <Trans>Retainer Invoice</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.customerMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link
                  className={
                    isPathActive("/sales_order")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/Salesorder"
                >
                  <Trans>Sales Order</Trans>
                </Link>
              </li>
            
            </ul>
          </Collapse>


            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/Estimate") ? "nav-link active" : "nav-link"
                    }
                    to="/Estimate"
                  >
                    <Trans>Estimate</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/payment_received") ? "nav-link active" : "nav-link"
                    }
                    to="/payment_received"
                  >
                    <Trans>Payments Received</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/recurringinvoice_listoutpage") ? "nav-link active" : "nav-link"
                    }
                    to="/recurringinvoice_listoutpage"
                  >
                    <Trans>Recurring Invoice</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/credit_note") ? "nav-link active" : "nav-link"
                    }
                    to="/credit_note"
                  >
                    <Trans>Credit Note</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/delivery_challan") ? "nav-link active" : "nav-link"
                    }
                    to="/delivery_challan"
                  >
                    <Trans>Delivery Challans</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li
            className={
              isPathActive("/vendor") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.VendorMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("VendorMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Purchase</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-package menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/vendor")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/vendor"
                  >
                    <Trans>Vendor</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/expense") ? "nav-link active" : "nav-link"
                    }
                    to="/expense"
                  >
                    <Trans>Expense</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/purchase_order") ? "nav-link active" : "nav-link"
                    }
                    to="/purchase_order"
                  >
                    <Trans>Purchase Order</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/recurr_expense") ? "nav-link active" : "nav-link"
                    }
                    to="/recurr_expense"
                  >
                    <Trans>Recurring Expense</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/bill") ? "nav-link active" : "nav-link"
                    }
                    to="/bill"
                  >
                    <Trans>Bill</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link className={
                      isPathActive("/Rec_bill") ? "nav-link active" : "nav-link"
                    } to="/Rec_bill">
                  <span className="menu-title">
                    <Trans>Recurring Bill</Trans>
                  </span>
                  
          </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/receipt_note") ? "nav-link active" : "nav-link"
                    }
                    to="/receipt_note"
                  >
                    <Trans>Goods Receipt Note</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/debitnote")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/debitnote"
                  >
                    <Trans>Debit Note</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/payment_made")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/payment_made"
                  >
                    <Trans>Payment Made</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            </li>
            <li
            className={
              isPathActive("/eway") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.EwayMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => toggleMenuState("EwayMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Eway bills</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-file-chart menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.EwayMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/eway") ? "nav-link active" : "nav-link"
                    }
                    to="/eway"
                  >
                    <Trans>Eway Bills</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            </li>

          <li
            className={
              isPathActive("/listoutemployee") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.ItemsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("ItemsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Employee</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-package menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.ItemsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/listoutemployee")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/listoutemployee"
                  >
                    <Trans>Employee</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li
            className={
              isPathActive("/manualjournal") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.customerMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => toggleMenuState("accountingSubmenu")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Accounting</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-file-chart menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.accountingSubmenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/chart_accounts") ? "nav-link active" : "nav-link"
                    }
                    to="/chart_accounts"
                  >
                    <Trans>Chart of Accounts</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>



            <Collapse in={menuState.accountingSubmenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/manualjournal") ? "nav-link active" : "nav-link"
                    }
                    to="/manualjournal"
                  >
                    <Trans>Manual Journal</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            </li>
            <li
            className={
              isPathActive("/attendance") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.customerMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => toggleMenuState("payrollSubmenu")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Payroll</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-file-chart menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.payrollSubmenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/attendence") ? "nav-link active" : "nav-link"
                    }
                    to="/attendence"
                  >
                    <Trans>Attendance</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.payrollSubmenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/holiday") ? "nav-link active" : "nav-link"
                    }
                    to="/holiday"
                  >
                    <Trans>Holidays</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>


              <Collapse in={menuState.payrollSubmenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/loanlist") ? "nav-link active" : "nav-link"
                    }
                    to="/loanlist"
                  >
                    <Trans>Employee Loan</Trans>
                  </Link>
                </li>

              </ul>
            </Collapse>
            <Collapse in={menuState.payrollSubmenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/salarydetails") ? "nav-link active" : "nav-link"
                    }
                    to="/salarydetails"
                  >
                    <Trans>Salary Details</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>




            </li>
            <li
            className={
              isPathActive("/sales") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.salesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("salesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Reports</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-file-document-box-outline menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.salesMenuOpen}>
    <ul className="nav flex-column sub-menu " >
      {/* Transaction Reports */}
      <li className="nav-item">
        <div
          className={menuState.transactionReportsMenuOpen ? "nav-link menu-expanded hover-effect" : "nav-link hover-effect"}
          onClick={() => toggleMenuState("transactionReportsMenuOpen")}
        >
          <Trans>Transaction Reports</Trans>
        </div>
        <Collapse in={menuState.transactionReportsMenuOpen}>
          <ul className="nav flex-column sub-menu" style={{paddingLeft: '8px', color: 'black'}}>
            {/* Sales */}
            <li className="nav-item">




              <div
                className={menuState.salesMenuOpen ? "nav-link menu-expanded" : "nav-link"}
 
              >
                <Trans>Sales</Trans>
              </div>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '10px'}}>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/sales_by_customer") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/sales_by_customer"
                    >
                      <Trans>Sales by Customer</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/sales_by_item") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/sales_by_item"
                    >
                      <Trans>Sales by Item</Trans>
                    </Link>
                  </li>
                  



                </ul>
              </Collapse>
            </li>
            {/* Purchases */}
            <li className="nav-item">
              <div
                className={menuState.salesMenuOpen ? "nav-link menu-expanded" : "nav-link"}
 
              >
                <Trans>Purchase</Trans>
              </div>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '6px'}}>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/purchases_by_vendor") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/purchases_by_vendor"
                    >
                      <Trans>Purchase by Vendor</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/purchases_by_item") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/purchases_by_item"
                    >
                      <Trans>Purchase by Item</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li className="nav-item">
                    <Link
                      className={isPathActive("/all_transactions") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/all_transactions"
                    >
                      <Trans>All Transactions</Trans>
                    </Link>
            </li>

            <li className="nav-item">
                    <Link
                      className={isPathActive("/daybook") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/daybook"
                    >
                      <Trans>Daybook</Trans>
                    </Link>
                  </li>
            <li className="nav-item">
              <Link
                className={isPathActive("/cashflow") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/cashflow"
              >
                <Trans>Cash Flow</Trans>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={isPathActive("/Trialbalance") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/Trialbalance"
              >
                <Trans>Trial Balance</Trans>
              </Link>
            </li>
           
      
            <Collapse in={menuState.transactionReportsMenuOpen}>
    <ul className="nav flex-column sub-menu">
      <li className="nav-item">
        <div
          className={
            menuState.p_lreports_sub
            ? "nav-link menu-expanded hover-effect" : "nav-link hover-effect"}
          onClick={(e) => {
            e.stopPropagation(); // Prevent parent from collapsing
            toggleMenuState("p_lreports_sub");
          }}
          data-toggle="collapse"
        >
          
            <Trans>Profit and Loss</Trans>

          
          {/* <i className="menu-arrow"></i> */}
        </div>

        {/* Submenu Collapse for Accounts Receivables */}
        <Collapse in={menuState.p_lreports_sub}>
          <ul className="nav flex-column sub-menu" style={{ paddingLeft: '10px' }}>
            <li className="nav-item">
              <Link
                className={isPathActive("Horizontal_profit_loss") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/Horizontal_profit_loss"
                onClick={(e) => e.stopPropagation()}  // Ensure link doesn't trigger parent collapse
              >
                
                  <Trans>Horizontal Profit and Loss</Trans>
                
              </Link>
              <Link
                className={isPathActive("Vertical_profit_and_loss") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/Vertical_profit_and_loss"
                onClick={(e) => e.stopPropagation()}  // Ensure link doesn't trigger parent collapse
              >
                
                  <Trans>Vertical Profit and Loss</Trans>
                
              </Link>
            </li>
          </ul>
        </Collapse>
      </li>
    </ul>
  </Collapse>

  <li className="nav-item">
        <div
          className={menuState.balancesheetMenuOpen ? "nav-link menu-expanded hover-effect" : "nav-link hover-effect"}
          onClick={() => toggleMenuState("balancesheetMenuOpen")}
        >
          <Trans>Balance Sheet</Trans>
        </div>
        <Collapse in={menuState.balancesheetMenuOpen}>
          <ul className="nav flex-column sub-menu" style={{paddingLeft: '8px', color: 'black'}}>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '10px'}}>
                <li className="nav-item">
                    <Link
                      className={isPathActive("/horizontal_balancesheet") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/horizontal_balancesheet"
                    >
                      <Trans>Horizontal Balance Sheet</Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={isPathActive("/vertical_balancesheet") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/vertical_balancesheet"
                    >
                      <Trans>Vertical Balance Sheet</Trans>
                    </Link>
                  </li>
                  
                </ul>
              </Collapse>
          </ul>
        </Collapse>
        </li>
        
          </ul>
        </Collapse>
      </li>
      <Collapse in={menuState.salesMenuOpen}>
    <ul className="nav flex-column sub-menu">
      <li className="nav-item">
        <div
          className={
            menuState.partyreports_sub
              ? "nav-link menu-expanded hover-effect"
              : "nav-link hover-effect"
          }
          onClick={(e) => {
            e.stopPropagation(); // Prevent parent from collapsing
            toggleMenuState("partyreports_sub");
          }}
          data-toggle="collapse"
        >
          
            <Trans>Party Reports</Trans>
          
          {/* <i className="menu-arrow"></i> */}
        </div>

        {/* Submenu Collapse for Accounts Receivables */}
        <Collapse in={menuState.partyreports_sub}>
          <ul className="nav flex-column sub-menu" style={{ paddingLeft: '10px' }}>

          <li className="nav-item">
              <Link
                className={isPathActive("/Partystatement") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/Partystatement"
                onClick={(e) => e.stopPropagation()}  
              >
                <span className="menu-title">
                  <Trans>Party Statements</Trans>
                </span>
              </Link>
            </li>
            <li className="nav-item">
                    <Link
                      className={isPathActive("/all_parties_report") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/all_parties_report"
                    >
                      <Trans>All Parties Report</Trans>
                    </Link>
              </li>
            <li className="nav-item">
              <Link
                className={isPathActive("Party_report_by_item") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/Party_report_by_item"
                onClick={(e) => e.stopPropagation()}  // Ensure link doesn't trigger parent collapse
              >
                <span className="menu-title">
                  <Trans>Party Report By Item</Trans>
                </span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={isPathActive("/sales_purchase_by_party") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/sales_purchase_by_party"
                onClick={(e) => e.stopPropagation()}  // Ensure link doesn't trigger parent collapse
              >
                <span className="menu-title">
                  <Trans>Sales Purchase By Party</Trans>
                </span>
              </Link>
            </li>


          </ul>
        </Collapse>
      </li>
    </ul>
  </Collapse>
  <Collapse in={menuState.salesMenuOpen}>
    <ul className="nav flex-column sub-menu">
      <li className="nav-item">
        <div
          className={
            menuState.stockreports_sub
              ? "nav-link menu-expanded hover-effect"
              : "nav-link hover-effect"
          }
          onClick={(e) => {
            e.stopPropagation(); // Prevent parent from collapsing
            toggleMenuState("stockreports_sub");
          }}
          data-toggle="collapse"
        >
          
            <Trans>Stock Reports</Trans>
          
          {/* <i className="menu-arrow"></i> */}
        </div>

        {/* Submenu Collapse for Accounts Receivables */}
        <Collapse in={menuState.stockreports_sub}>
          <ul className="nav flex-column sub-menu" style={{ paddingLeft: '10px' }}>
            <li className="nav-item">
              <Link
                className={isPathActive("Item_report_by_party") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/Item_report_by_party"
                onClick={(e) => e.stopPropagation()}  // Ensure link doesn't trigger parent collapse
              >
                <span className="menu-title">
                  <Trans>Item Report By Party</Trans>
                </span>
              </Link>
            </li>
            <li className="nav-item">
            <Link
                className={isPathActive("Stock_valuation_summary") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/Stock_valuation_summary"
                onClick={(e) => e.stopPropagation()}  // Ensure link doesn't trigger parent collapse
              >
                <span className="menu-title">
                  <Trans>Stock Valuation Summary</Trans>
                </span>
              </Link>
              </li>
              <li className="nav-item">
              <Link
                className={isPathActive("Sales_Item_Discount_Details") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/Sales_Item_Discount_Details"
                onClick={(e) => e.stopPropagation()}  // Ensure link doesn't trigger parent collapse
              >
                <span className="menu-title">
                  <Trans>Sales Item Discount Details</Trans>
                </span>
              </Link>
              </li>
            <li className="nav-item">
                    <Link
                      className={isPathActive("/stocksummary") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/stocksummary"
                    >
                      <Trans>Stock Summary</Trans>
                    </Link>
            </li>
             <li className="nav-item">
                    <Link
                      className={isPathActive("/low_stock_details") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/low_stock_details"
                    >
                      <Trans>Low Stock Summary</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/stock_details") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/stock_details"
                    >
                      <Trans>Stock Details</Trans>
                    </Link>
                  </li>
				  
            

          </ul>
        </Collapse>
      </li>
    </ul>
  </Collapse>

     {/* Accounts Receivable */}
     <li className="nav-item">
        <div
          className={menuState.accountsreceivableMenuOpen ? "nav-link menu-expanded hover-effect" : "nav-link hover-effect"}
          onClick={() => toggleMenuState("accountsreceivableMenuOpen")}
        >
          <Trans>Accounts Receivable</Trans>
        </div>
        <Collapse in={menuState.accountsreceivableMenuOpen}>
          <ul className="nav flex-column sub-menu" style={{paddingLeft: '8px', color: 'black'}}>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '10px'}}>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/customer_balance_report") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/customer_balance_report"
                    >
                      <Trans>Customer Balances</Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={isPathActive("/agingsummary") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/agingsummary"
                    >
                      <Trans>Aging Summay</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/agingdetails") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/agingdetails"
                    >
                      <Trans>Aging Details</Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={isPathActive("/invoice_report") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/invoice_report"
                    >
                      <Trans>Invoice Details</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/rec_invoice_report") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/rec_invoice_report"
                    >
                      <Trans>Recurring Invoice Details</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
              <Link
                className={isPathActive("/Estimate_details") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/Estimate_details"
              
              >
                <span className="menu-title">
                  <Trans>Estimate Details</Trans>
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={isPathActive("/Retainer_invoice_Report") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/Retainer_invoice_Report"
                
              >
                <span className="menu-title">
                  <Trans>Retainer Invoice Details</Trans>
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={isPathActive("/outstandingreceivable") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/outstandingreceivable"
                
              >
                <span className="menu-title">
                  <Trans>Outstanding Receivable</Trans>
                </span>
              </Link>
            </li>
            
            <li className="nav-item">
                    <Link
                      className={isPathActive("/saleorderdetailsreport") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/saleorderdetailsreport"
                    >
                      <Trans>Sales Order Details</Trans>
                    </Link>
                  </li>

            <li className="nav-item">
              <Link
                className={isPathActive("/salesorderitemreport") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/salesorderitemreport"
              >
                <Trans>Sales Order Item</Trans>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={isPathActive("/salesorder_by_customer") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/salesorder_by_customer"
              >
                <Trans>Sales Order By Customer</Trans>
              </Link>
            </li>



             <li className="nav-item">
              <Link
                className={isPathActive("/delivery_challan_report") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/delivery_challan_report"
                
              >
                
                  <Trans>Delivery Challan Report</Trans>
             
              </Link>
            </li>


              <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/sales_summary_report") ? "nav-link active" : "nav-link"
                    }
                    to="/sales_summary_report"
                  >
                    <Trans>Sales Summary Details</Trans>
                  </Link>
                </li>


            

             

                </ul>
              </Collapse>
          </ul>
        </Collapse>
      </li>

       {/* Accounts Payable */}
       <li className="nav-item">
        <div
          className={menuState.accountspayableMenuOpen ? "nav-link menu-expanded hover-effect" : "nav-link hover-effect"}
          onClick={() => toggleMenuState("accountspayableMenuOpen")}
        >
          <Trans>Accounts Payable</Trans>
        </div>
        <Collapse in={menuState.accountspayableMenuOpen}>
          <ul className="nav flex-column sub-menu" style={{paddingLeft: '8px', color: 'black'}}>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '10px'}}>
                <li className="nav-item">
                    <Link
                      className={isPathActive("/vendor_balance_report") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/vendor_balance_report"
                    >
                      <Trans>Vendor Balances</Trans>
                    </Link>
                  </li>
                  
                  
                  <li className="nav-item">
                    
              <Link
                className={isPathActive("/purchase_order_details") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/purchase_order_details"
                
              >
                <span className="menu-title">
                  <Trans>Purchase Order Details</Trans>
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={isPathActive("/purchase_order_by_vendor") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/purchase_order_by_vendor"
                
              >
                <span className="menu-title">
                  <Trans>Purchase Order By Vendor</Trans>
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={isPathActive("/grnreport") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/grnreport"
                
              >
                <span className="menu-title">
                  <Trans>GRN Details</Trans>
                </span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={isPathActive("/debit_note_details") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/debit_note_details"
                
              >
                <span className="menu-title">
                  <Trans>Debit Note Details</Trans>
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={isPathActive("/outstanding_payable") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/outstanding_payable"
                
              >
                <span className="menu-title">
                  <Trans>Outstanding payable</Trans>
                </span>
              </Link>
            </li>
           




               <li className="nav-item">
              <Link
                className={isPathActive("/billReport") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/billReport"
                
              >
                <span className="menu-title">
                  <Trans>Bill Details</Trans>
                </span>
              </Link>
            </li>

 <li className="nav-item">
              <Link
                className={isPathActive("/recurringbillReport") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/recurringbillReport"
                
              >
                <span className="menu-title">
                  <Trans>Recurring Bill Details</Trans>
                </span>
              </Link>
            </li>
            
            <li className="nav-item">
              <Link
                className={isPathActive("/purchase_orderitem_report") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                to="/purchase_orderitem_report"
                
              >
                <span className="menu-title">
                  <Trans>Purchase Order Item</Trans>
                </span>
              </Link>
            </li>
            <li className="nav-item">
                    <Link
                      className={isPathActive("/payment_made_summary") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/payment_made_summary"
                    >
                      <Trans>Payment Made Summary</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/payment_made_details") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/payment_made_details"
                    >
                      <Trans>Payment Made Details</Trans>
                    </Link>
                  </li>


                 <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/purchase_summary_report") ? "nav-link active" : "nav-link"
                    }
                    to="/purchase_summary_report"
                  >
                    <Trans>Purchase Summary Details</Trans>
                  </Link>
                </li>








                </ul>
              </Collapse>
          </ul>
        </Collapse>
        
        
      </li>



      {/* Payment Received */}
      <li className="nav-item">
        <div
          className={menuState.paymentreceivedMenuOpen ? "nav-link menu-expanded hover-effect" : "nav-link hover-effect"}
          onClick={() => toggleMenuState("paymentreceivedMenuOpen")}
        >
          <Trans>Payment Received</Trans>
        </div>
        <Collapse in={menuState.paymentreceivedMenuOpen}>
          <ul className="nav flex-column sub-menu" style={{paddingLeft: '8px', color: 'black'}}>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '10px'}}>
                
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/payment_received_summary") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/payment_received_summary"
                    >
                      <Trans>Payment Received Summary</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/payment_received_details") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/payment_received_details"
                    >
                      <Trans>Payment Received Details</Trans>
                    </Link>
                  </li>
                <li className="nav-item">
                    <Link
                      className={isPathActive("/credit_note_details") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/credit_note_details"
                    >
                      <Trans>Credit Note Details</Trans>
                    </Link>
                  </li>
                  



                </ul>
              </Collapse>
          </ul>
        </Collapse>
        
        
      </li>


      {/* gst */}
      <li className="nav-item">
        <div
          className={menuState.gstMenuOpen ? "nav-link menu-expanded hover-effect" : "nav-link hover-effect"}
          onClick={() => toggleMenuState("gstMenuOpen")}
        >
          <Trans>GST Report</Trans>
        </div>
        <Collapse in={menuState.gstMenuOpen}>
          <ul className="nav flex-column sub-menu" style={{paddingLeft: '8px', color: 'black'}}>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '10px'}}>
                <li className="nav-item">
                    <Link
                      className={isPathActive("/sales_by_hsn") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/sales_by_hsn"
                    >
                      <Trans>Sales Summary By HSN</Trans>
                    </Link>
                  </li>



                  <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/gstr1") ? "nav-link active" : "nav-link"
                    }
                    to="/gstr1"
                  >
                    <Trans>GSTR1</Trans>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/gstr2") ? "nav-link active" : "nav-link"
                    }
                    to="/gstr2"
                  >
                    <Trans>GSTR2</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/gstr3b") ? "nav-link active" : "nav-link"
                    }
                    to="/gstr3b"
                  >
                    <Trans>GSTR3B</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/gstr9") ? "nav-link active" : "nav-link"
                    }
                    to="/gstr9"
                  >
                    <Trans>GSTR9</Trans>
                  </Link>
                </li>

				  



                </ul>
              </Collapse>
             
          </ul>
        </Collapse>
        
        
      </li>



      <li className="nav-item">
        <div
          className={menuState.expenseMenuOpen ? "nav-link menu-expanded hover-effect" : "nav-link hover-effect"}
          onClick={() => toggleMenuState("expenseMenuOpen")}
        >
          <Trans>Expense Report</Trans>
        </div>
        <Collapse in={menuState.expenseMenuOpen}>
          <ul className="nav flex-column sub-menu" style={{paddingLeft: '8px', color: 'black'}}>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '10px'}}>
                <li className="nav-item">
                    <Link
                      className={isPathActive("/expensereport") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/expensereport"
                    >
                      <Trans>Expense Details</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={isPathActive("/reccurring_expense_details") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/reccurring_expense_details"
                    >
                      <Trans>Reccurring Expense Details</Trans>
                    </Link>
                  </li>
                  </ul>
              </Collapse>
          </ul>
        </Collapse>
      </li>

       {/* Accountant */}
       <li className="nav-item">
        <div
          className={menuState.accountantMenuOpen ? "nav-link menu-expanded hover-effect" : "nav-link hover-effect"}
          onClick={() => toggleMenuState("accountantMenuOpen")}
        >
          <Trans>Accountant</Trans>
        </div>
        <Collapse in={menuState.accountantMenuOpen}>
          <ul className="nav flex-column sub-menu" style={{paddingLeft: '8px', color: 'black'}}>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '10px'}}>
                <li className="nav-item">
                    <Link
                      className={isPathActive("/journal_report") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/journal_report"
                    >
                      <Trans>Journal Report</Trans>
                    </Link>
                  </li>
				  <li className="nav-item">
                    <Link
                      className={isPathActive("/company_staffs/account_type_summary") ?  "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/company_staffs/account_type_summary"
                    >
                      <Trans>Account Type Summary</Trans>
                    </Link>
                  </li>
                  



                </ul>
              </Collapse>
          </ul>
        </Collapse>
        
        
      </li>
      <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/e_way_bill_details") ? "nav-link active" : "nav-link"
                    }
                    to="/e_way_bill_details"
                  >
                    <Trans>E-way bill report</Trans>
                  </Link>
                </li>
      <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/employee loan statement") ? "nav-link active" : "nav-link"
                    }
                    to="/employee loan statement"
                  >
                    <Trans>Employee Loan
                    Statement</Trans>
                  </Link>
      </li>
      
      {/* Business Status */}
       <li className="nav-item">
        <div
          className={menuState.businessMenuOpen ? "nav-link menu-expanded hover-effect" : "nav-link hover-effect"}
          onClick={() => toggleMenuState("businessMenuOpen")}
        >
          <Trans>Business Status</Trans>
        </div>
        <Collapse in={menuState.businessMenuOpen}>
          <ul className="nav flex-column sub-menu" style={{paddingLeft: '8px', color: 'black'}}>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '10px'}}>
                <li className="nav-item">
                    <Link
                      className={isPathActive("/company_staffs/bank_statements") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/company_staffs/bank_statements"
                    >
                      <Trans>Bank Statement</Trans>
                    </Link>
                  </li>
				  
                 <li className="nav-item">
                    <Link
                      className={isPathActive("/loan_account_statement") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/loan_account_statement"
                    >
                      <Trans>Loan Account Statement</Trans>
                    </Link>
                  </li>


                </ul>
              </Collapse>
              <Collapse in={menuState.salesMenuOpen}>
                <ul className="nav flex-column sub-menu" style={{paddingLeft: '10px'}}>
                <li className="nav-item">
                    <Link
                      className={isPathActive("/discountreport") ? "nav-link active hover-effect" : "nav-link hover-effect"}
                      to="/discountreport"
                    >
                      <Trans>Discount Report</Trans>
                    </Link>
                  </li>
				  



                </ul>
              </Collapse>
          </ul>
        </Collapse>
        
        
      </li>


      


      
     
    </ul>
  </Collapse>
          </li>

      </ul>
    </nav>
  );
};

export default Sidebar;
