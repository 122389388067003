import React, {  useState, useEffect  } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {  useParams } from 'react-router-dom'; // Import useParams here
import Navbar from "./Navbar"; // Ensure this path is correct
import Sidebar from "./Sidebar"; // Ensure this path is correct
import config from "../../functions/config"; // Ensure this path is correct
import "./styles/Staff.css"; // Ensure this path is correct
import "./SalesOrderDetails.css"; // Assuming external CSS file
import { toast } from 'react-toastify';


import Select from 'react-select';




function SALESTORECURRING() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [subTotal, setSubTotal] = useState(0);
  const user = Cookies.get("role");
  const ID = Cookies.get("user_id");
  const [terms, setTerms] = useState([]); // Initialize terms state
  const [customerPriceLists, setCustomerPriceLists] = useState([]);
  const [igst, setIgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [adjustment, setAdjustment] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [paid, setPaid] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [balance, setBalance] = useState(0);
  const [items, setItems] = useState([]); 
  const [salesDetails, setSalesDetails] = useState({});
  const [newTermName, setNewTermName] = useState('');
  const [newTermDays, setNewTermDays] = useState('');
  const [newUnit, setNewUnit] = useState('');

    // Define the states
  const [companyState, setCompanyState] = useState(''); // Assuming you want to store the company's state here
  const [showIGST, setShowIGST] = useState(false); // To show/hide IGST field
  const [showCGSTSGST, setShowCGSTSGST] = useState(false); // To show/hide CGST/SGST f
  const [otherDetails, setOtherDetails] = React.useState({
    Company_name: '',
    Email: '',
    Mobile: '',
    Address: '',
    City: '',
    State: '',
    Pincode: '',
    customerName: '',
    customerEmail: ''
  });
  const [newCustomer, setNewCustomer] = useState({
    title: '',
    firstName: '',
    lastName: '',
    company: '',
    // Add other fields here
  });
  
  const [formData, setFormData] = useState({
    sales_order_no: "",
    customer_name: "",
    customer_email: "",
    billing_address: "",
    billing_street: '',
    billing_city: '',
    billing_state: '',
    billing_pincode: '',
    billing_country: '',
    gst_type: "",
    gstin: "",
    place_of_supply: "",
    reference_no: "",
    payment_terms: "",
    sales_order_date: "",
    exp_ship_date: "",
    price_list_applied: false,
    price_list: "",
    payment_method: "",
    cheque_no: "",
    upi_no: "",
    bank_acc_no: "",
    subtotal: "",
    igst: "",
    cgst: "",
    sgst: "",
    tax_amount: "",
    adjustment: "",
    shipping_charge: "",
    grandtotal: "",
    paid_off: "",
    balance: "",
    converted_to_invoice: "",
    converted_to_rec_invoice: "",
    note: "",
    file: null,
    status: ""
  });


   // Fetch sales order data when the component loads
   useEffect(() => {
    fetchSalesOrder();
    fetchCustomers();
  }, [orderId]);
  
  const fetchSalesOrder = async () => {
    try {
      const response = await axios.get(`${config.base_url}/editsalesget/${orderId}/`);
      
      // Assuming response.data.sales_order contains the data from the backend
      const salesOrder = response.data.sales_order;
      
      
      // Set all the state variables using the data fetched from the backend
      setFormData(salesOrder);  // For the overall form data
      setSalesOrderItems(response.data.sales_order_items); // Add this line if your API returns items
  
      // Set individual values based on fetched sales order
      setSubTotal(salesOrder.subtotal || 0);  // Default to 0 if not provided
      setTaxAmount(salesOrder.tax_amount || 0);
     //  setShippingCharge(salesOrder.shipping_Charge || 0);
      setAdjustment(salesOrder.adjustment || 0);
      setPaid(salesOrder.paid_off || 0);
      setGrandTotal(salesOrder.grandtotal || 0);
      setBalance(salesOrder.balance || 0);
      setShipmentDate(salesOrder.exp_ship_date || 0);
     
      
      
      
    } catch (error) {
      Swal.fire('Error', 'Unable to fetch sales order details', 'error');
    }
  };
  


  

  const fetchCustomers = async () => {
    
  };
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const [salesOrderItems, setSalesOrderItems] = useState([
    {
      id: 1,
      item: '',
      itemId:'',
      hsnSac: '',
      quantity: 0,
      price: 0,
      taxGst: '',
      discount: 0,
      total: 0,
      taxAmount: 0,
    },
  ]);

  

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value
    });
  };
  const [priceLists, setPriceLists] = useState([]);

  const fetchPriceList = () => {
    axios
      .get(`${config.base_url}/pricelist_dropdown/${ID}/`)  // Correct endpoint
      .then((res) => {
        if (res.data.status) {
          const priceLists = res.data.pricelist; // Match with API response
          setPriceLists(priceLists);  // Use state to store price lists
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  // Fetch price lists when ID changes
  useEffect(() => {
    if (ID) {
      fetchPriceList();
    }
  }, [ID]);



  const fetchPaymentTerms = () => {
    axios
      .get(`${config.base_url}/paymentterm_dropdown/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const paymentTerms = res.data.terms; // Corrected to match API response
          setTerms(paymentTerms); // Set terms directly from API response
        }
      })
      .catch((err) => {
        console.log(err);
      });
};

// Fetch payment terms when ID changes
useEffect(() => {
    if (ID) {
      fetchPaymentTerms();
    }
}, [ID]);
const setStatus = (statusValue) => {
  setFormData({ ...formData, status: statusValue });
};

const handleSaveDraft = () => {
  setStatus("Draft");  // Set status to "Draft"
  handleSubmit(null);   // Call submit without an event, pass null
};

const handleSubmit = (e) => {
  if (e) {
    e.preventDefault(); // Prevent default behavior if event exists
  }

  // Create new FormData object from form element if event is present
  const formData = e ? new FormData(e.target) : new FormData();

  // Append additional fields (subtotal, CGST, SGST, etc.) to FormData
  formData.append('subtotal', subTotal);
  formData.append('cgst', (Number(taxAmount) / 2).toFixed(2)); // CGST calculated as 50% of taxAmount
  formData.append('sgst', (Number(taxAmount) / 2).toFixed(2)); // SGST calculated as 50% of taxAmount
  formData.append('tax_amount', Number(taxAmount).toFixed(2)); // Total tax amount
  // formData.append('shipping_charge', shippingCharge); // Shipping charge value
  formData.append('adjustment', adjustment); // Adjustment value
  formData.append('grandtotal', grandTotal); // Grand total value
  formData.append('paid_off', paid); // Paid amount
  formData.append('balance', balance); // Remaining balance
  formData.append("exp_ship_date", shipmentDate);
  formData.append("Id", ID); // Use the correct field name based on your backend expectations


  // Ensure `salesOrderItems` is properly appended as JSON string
  formData.append('salesOrderItems', JSON.stringify(salesOrderItems));

  // Log the formData for debugging
  console.log("Form Data: ", Array.from(formData.entries()));

  // Perform PUT request to update the sales order
  axios
    .put(`${config.base_url}/convertreccuring/${orderId}/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => {
      if (res.data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Sales Order converted to recurring invoice",
          text: "The sales order has been successfully updated.",
        });
        navigate(`/Salesorder/`);
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: `${err.response?.data?.message || "Error updating sales order"}`,
        text: JSON.stringify(err.response?.data.errors),
      });
    });
};

  
  
  
  


   
  
  // Function to add a new row
  const addNewRow = () => {
    setSalesOrderItems([
      ...salesOrderItems,
      {
        id: salesOrderItems.length + 1,
        item: '',
        itemId:'',
        hsnSac: '',
        quantity: 0,
        price: 0,
        taxGst: '',
        discount: 0,
        total: 0,
        taxAmount: 0,
      },
    ]);
  };

  // Function to remove a row
  const removeRow = (id) => {
    setSalesOrderItems(salesOrderItems.filter(item => item.id !== id));
  };
  const refreshValues = () => {
    // Implement the logic to refresh or recalculate values here
    // For example, you might want to recalculate totals, taxes, or other fields
    const updatedItems = salesOrderItems.map((item) => {
      // Recalculate totals, taxes, etc., based on item properties
      const total = (item.price * item.quantity) - item.discount;
      const taxAmount = total * (item.taxGst / 100);
      
      return {
        ...item,
        total,
        taxAmount
      };
    });
    
    setSalesOrderItems(updatedItems);
  };
  const [current_stock, setCurrentStock] = useState(0);

   // Handle input change for sales order items
const handleSalesOrderItemsInputChange = (id, field, value) => {
  setSalesOrderItems((prevItems) => {
    const updatedItems = prevItems.map((item) => {
      if (item.id === id) {
        const updatedItem = { ...item, [field]: value };
        
        // Recalculate total and taxAmount
        const quantity = parseFloat(updatedItem.quantity) || 0;
        const price = parseFloat(updatedItem.price) || 0;
        const discount = parseFloat(updatedItem.discount) || 0;
        const taxGst = parseFloat(updatedItem.taxGst) || 0;
        const current_stock = parseFloat(updatedItem.current_stock) || 0;

        updatedItem.total = price * quantity - discount;
        updatedItem.taxAmount = updatedItem.total * (taxGst / 100);

        return updatedItem;
      }
      return item;
    });

    // Calculate subtotal
const newSubTotal = updatedItems.reduce((acc, item) => acc + (parseFloat(item.total) || 0), 0);
setSubTotal(Math.round(newSubTotal)); // Round to nearest integer

    // Calculate tax amounts
    const totalTaxAmount = updatedItems.reduce((acc, item) => acc + (parseFloat(item.taxAmount) || 0), 0);
    setTaxAmount(totalTaxAmount.toFixed(2));

    // Update grand total
    const newGrandTotal = parseFloat(newSubTotal) + parseFloat(totalTaxAmount) + parseFloat(shippingCharge || 0) + parseFloat(adjustment || 0);
    setGrandTotal(newGrandTotal.toFixed(2));

    // Update balance
    const newBalance = newGrandTotal - parseFloat(paid || 0);
    setBalance(newBalance.toFixed(2));

    return updatedItems;
  });
};

// Handle Shipping Charge
const handleShippingCharge = (value) => {
  setShippingCharge(value);
  const newGrandTotal = parseFloat(subTotal) + parseFloat(taxAmount) + parseFloat(value || 0) + parseFloat(adjustment || 0);
  setGrandTotal(newGrandTotal.toFixed(2));
  const newBalance = newGrandTotal - parseFloat(paid || 0);
  setBalance(newBalance.toFixed(2));
};

// Handle Adjustment
const handleAdjustment = (value) => {
  setAdjustment(value);
  const newGrandTotal = parseFloat(subTotal) + parseFloat(taxAmount) + parseFloat(shippingCharge || 0) + parseFloat(value || 0);
  setGrandTotal(newGrandTotal.toFixed(2));
  const newBalance = newGrandTotal - parseFloat(paid || 0);
  setBalance(newBalance.toFixed(2));
};

// Handle Paid amount
const handlePaid = (value) => {
  setPaid(value);
  const newBalance = parseFloat(grandTotal) - parseFloat(value || 0);
  setBalance(newBalance.toFixed(2));
};


  
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'purple' : 'gray',
      boxShadow: state.isFocused ? '0 0 0 1px purple' : 'none',
      '&:hover': {
        borderColor: 'purple',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'purple' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'lightgray',
      },
    }),
    // Add other styles as needed
  };
  
   // Handle change for the file input
   const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };
  
   

  // Function to calculate grand total
  const calculateGrandTotal = (shipping, adjustment) => {
    const total = subTotal + igst + cgst + sgst + taxAmount + shipping - adjustment;
    setGrandTotal(total);
    setBalance(total - paid);
  };

   // Function to show success notifications
   const notifySuccess = (message) => {
    toast.success(message);
  };

  // Function to show error notifications
  const notifyError = (message) => {
    toast.error(message);
  };



   /// Fetch items from the backend
   const fetchItems = async () => {
    if (!ID) return; // Ensure ID is available
    try {
      const response = await axios.get(`${config.base_url}/item_dropdown/${ID}/`);
      const fetchedItems = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
        hsnSac: item.hsn || item.sac,
        price: item.selling_price,
        current_stock:item.current_stock,
        inter_state_tax: item.inter_state_tax, // Tax field included
      }));
      setItems(fetchedItems);
    } catch (error) {
      console.error("Error fetching items:", error);
      Swal.fire({
        icon: "error",
        title: "Error fetching items",
        text: error.response?.data?.message || "Something went wrong while fetching items.",
      });
    }
  };
  
  useEffect(() => {
    fetchItems();
  }, [ID]);


  // Handle item selection change
  const handleItemChange = (selectedItem, rowId) => {
    const updatedItems = salesOrderItems.map((item) => {
      if (item.id === rowId) {
        const price = selectedItem ? selectedItem.price : 0;
        const quantity = item.quantity;
        const total = price * quantity;
        const current_stock = selectedItem?.current_stock || 0;
  
        return {
          ...item,
          item: selectedItem ? selectedItem.label : "",
          itemId: selectedItem ? selectedItem.value : "", // Capture correct itemId
          hsnSac: selectedItem ? selectedItem.hsnSac : "",
          price: price,
          current_stock:current_stock, // Ensure avl is updated correctly
          taxGst: selectedItem ? selectedItem.inter_state_tax : "",
          total: total, // Update total based on price and quantity
        };
      }
      return item;
    });
    setSalesOrderItems(updatedItems);
  };
  

  
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`${config.base_url}/customer_dropdown/${ID}/`);
        if (response.data.status) {
          setCustomers(response.data.data);
          setCompanyState(response.data.company_state); // Store the company state
        }
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);
  const handleTaxDisplay = () => {
    if (formData.place_of_supply === companyState) {
      // Show CGST and SGST, hide IGST
      setShowIGST(false);
      setShowCGSTSGST(true);
    } else {
      // Show IGST, hide CGST and SGST
      setShowIGST(true);
      setShowCGSTSGST(false);
    }
  };
  
  // Add useEffect to trigger tax display logic when place_of_supply or companyState changes
  useEffect(() => {
    handleTaxDisplay();
    fetchCustomers()
  }, [formData.place_of_supply, companyState]);
  
  const handleCustomerSelect = (event) => {
    const selectedCustomerName = event.target.value;
    const selectedCustomer = customers.find(customer => customer.name === selectedCustomerName);
  
    if (selectedCustomer) {
      const billingAddress = `${selectedCustomer.billing_street || ''}, ${selectedCustomer.billing_city || ''}, ${selectedCustomer.billing_state || ''}, ${selectedCustomer.billing_pincode || ''}, ${selectedCustomer.billing_country || ''}`;
      
      setFormData({
        ...formData,
        customer_name: selectedCustomer.name,
        customer_email: selectedCustomer.email,
        billing_street: selectedCustomer.billing_street || '',
        billing_city: selectedCustomer.billing_city || '',
        billing_state: selectedCustomer.billing_state || '',
        billing_pincode: selectedCustomer.billing_pincode || '',
        billing_country: selectedCustomer.billing_country || '',
        billing_address: billingAddress, // Combining all fields into one for display
        place_of_supply: selectedCustomer.place_of_supply || '',
        gst_type: selectedCustomer.gst_type || '',
        gstin: selectedCustomer.gstin || ''
      });
    } else {
      setFormData({
        ...formData,
        customer_name: '',
        customer_email: '',
        billing_street: '',
        billing_city: '',
        billing_state: '',
        billing_pincode: '',
        billing_country: '',
        billing_address: '',
        place_of_supply: '',
        gst_type: '',
        gstin: ''
      });
    }
  };
  
 



  
  // NEW CUSTOMER

  const [newPaymentTerm, setNewPaymentTerm] = useState("");
  const [newPaymentTermDays, setNewPaymentTermDays] = useState("");
  function handlePaymentTermModalSubmit(e) {
    e.preventDefault();
    var name = newPaymentTerm;
    var dys = newPaymentTermDays;
    if (name != "" && dys != "") {
      var u = {
        Id: ID,
        term_name: newPaymentTerm,
        days: newPaymentTermDays,
      };
      axios
        .post(`${config.base_url}/create_new_company_payment_term/`, u)
        .then((res) => {
          console.log("PTRM RES=", res);
          if (!res.data.status && res.data.message != "") {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
          if (res.data.status) {
            Toast.fire({
              icon: "success",
              title: "Term Created",
            });
            fetchPaymentTerms();

            setNewPaymentTerm("");
            setNewPaymentTermDays("");
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    } else {
      alert("Invalid");
    }
  }

  const [title, setTitle] = useState("Mr");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [customerPlaceOfSupply, setCustomerPlaceOfSupply] = useState("");
  const [customerGstType, setCustomerGstType] = useState("");
  const [customerGstIn, setCustomerGstIn] = useState("");
  const [panNo, setPanNo] = useState("");
  const [oBalType, setOBalType] = useState("");
  const [oBal, setOBal] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  const [paymentTerm, setPaymentTerm] = useState("");
  const [customerPriceList, setCustomerPriceList] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [mobile, setMobile] = useState("");

  const [bStreet, setBStreet] = useState("");
  const [bCity, setBCity] = useState("");
  const [bState, setBState] = useState("");
  const [bPincode, setBPincode] = useState("");
  const [bCountry, setBCountry] = useState("");

  const [sStreet, setSStreet] = useState("");
  const [sCity, setSCity] = useState("");
  const [sState, setSState] = useState("");
  const [sPincode, setSPincode] = useState("");
  const [sCountry, setSCountry] = useState("");

  function placeShipAddress() {
    var chkbtn = document.getElementById("shipAddress");
    if (chkbtn.checked == true) {
      setSStreet(bStreet);
      setSCity(bCity);
      setSPincode(bPincode);
      setSCountry(bCountry);
      setSState(bState);
    } else {
      setSStreet("");
      setSCity("");
      setSPincode("");
      setSCountry("");
      setSState("");
    }
  }

  function checkLastName() {
    var fName = firstName.replace(/\d/g, "");
    var lName = lastName.replace(/\d/g, "");
    if (fName != "" && lName != "") {
      checkCustomerName(fName, lName);
    } else {
      alert("Please enter a valid Full Name.!");
      return false;
    }
  }
  function checkFirstName() {
    var fName = firstName.replace(/\d/g, "");
    var lName = lastName.replace(/\d/g, "");
    if (fName != "" && lName != "") {
      checkCustomerName(fName, lName);
    } else if (fName == "" && lName != "") {
      alert("Please enter a valid First Name.!");
    }
  }

  function checkCustomerName(fname, lname, cmp) {
    if (fname !== "" && lname !== "" && cmp !== "") {
      const params = {
        fName: fname,
        lName: lname,
        cmp: cmp,
      };
  
      axios
        .get(`${config.base_url}/check_customer_name/${ID}/`, { params })
        .then((res) => {
          console.log(res);
          if (res.data.is_exist) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status && err.response.data.message) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }

  function checkCustomerGSTIN(gstin) {
    var gstNo = gstin;
    if (gstNo != "") {
      var u = {
        Id: ID,
        gstin: gstNo,
      };
      axios
        .get(`${config.base_url}/check_gstin/${ID}/`, { params: u })
        .then((res) => {
          console.log(res);
          if (res.data.is_exist) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status && err.response.data.message) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }

  function checkCustomerPAN(pan) {
    var panNo = pan;
    if (panNo != "") {
      var u = {
        Id: ID,
        pan: panNo,
      };
      axios
        .get(`${config.base_url}/check_pan/${ID}/`, { params: u })
        .then((res) => {
          console.log(res);
          if (res.data.is_exist) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status && err.response.data.message) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }

  function checkCustomerPhone(phone) {
    var phoneNo = phone;
    if (phoneNo != "") {
      var u = {
        Id: ID,
        phone: phoneNo,
      };
      axios
        .get(`${config.base_url}/check_phone/${ID}/`, { params: u })
        .then((res) => {
          console.log(res);
          if (res.data.is_exist) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status && err.response.data.message) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }

  function checkCustomerEmail(email) {
    var custEmail = email;
    if (custEmail != "") {
      var u = {
        Id: ID,
        email: custEmail,
      };
      axios
        .get(`${config.base_url}/check_email/${ID}/`, { params: u })
        .then((res) => {
          console.log(res);
          if (res.data.is_exist) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status && err.response.data.message) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }

  function handleGstType(value) {
    setCustomerGstType(value);
    checkGstType(value);
  }

  function checkGstType(value) {
    var gstTypeElement = document.getElementById("gstType");
    var gstINElement = document.getElementById("gstIN");
    var gstRowElements = document.getElementsByClassName("gstrow");

    var x = value;
    if (x === "Unregistered Business" || x === "Overseas" || x === "Consumer") {
      Array.prototype.forEach.call(gstRowElements, function (element) {
        element.classList.remove("d-block");
        element.classList.add("d-none");
      });
      gstINElement.required = false;
    } else {
      gstINElement.required = true;
      Array.prototype.forEach.call(gstRowElements, function (element) {
        element.classList.remove("d-none");
        element.classList.add("d-block");
      });
    }
  }

  function checkgst(val) {
    var gstinput = val;
    var gstregexp =
      "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}";

    if (gstinput.length === 15) {
      if (gstinput.match(gstregexp)) {
        document.getElementById("warngst").innerHTML = "";
        checkCustomerGSTIN(val);
      } else {
        document.getElementById("warngst").innerHTML =
          "Please provide a valid GST Number";
        alert("Please provide a valid GST Number");
      }
    } else {
      document.getElementById("warngst").innerHTML =
        "Please provide a valid GST Number";
      alert("Please provide a valid GST Number");
    }
  }

  function checkpan(val) {
    var paninput = val;
    var panregexp = ["[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"];
    if (val != "") {
      if (paninput.match(panregexp)) {
        document.getElementById("warnpan").innerHTML = "";
        checkCustomerPAN(val);
      } else {
        document.getElementById("warnpan").innerHTML =
          "Please provide a valid PAN Number";
        alert("Please provide a valid PAN Number");
      }
    }
  }

  function checkweb(val) {
    var webinput = val;
    var webregexp = "www.";
    if (val != "") {
      if (webinput.startsWith(webregexp)) {
        document.getElementById("warnweb").innerHTML = "";
      } else {
        document.getElementById("warnweb").innerHTML =
          "Please provide a valid Website Address";
        alert("Please provide a valid Website Address");
      }
    }
  }

  function checkphone(val) {
    var phoneinput = val;
    var phoneregexp = /^\d{10}$/;
    if (val != "") {
      if (phoneinput.match(phoneregexp)) {
        document.getElementById("warnphone").innerHTML = "";
        checkCustomerPhone(val);
      } else {
        document.getElementById("warnphone").innerHTML =
          "Please provide a valid Phone Number";
        alert("Please provide a valid Phone Number");
      }
    }
  }

  function checkemail(val) {
    var emailinput = val;
    var emailregexp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (val != "") {
      if (emailinput.match(emailregexp)) {
        //   document.getElementById("warnemail").innerHTML = "";
        checkCustomerEmail(val);
      } else {
        //   document.getElementById("warnemail").innerHTML =
        //     "Please provide a valid Email ID";
        alert("Please provide a valid Email id");
      }
    }
  }

  function setOpeningBalanceValue(value) {
    var openbal = value;
    if (oBalType == "credit") {
      if (openbal.slice(0, 1) != "-") {
        if (parseFloat(openbal) != 0) {
          setOBal(-1 * openbal);
        } else {
          setOBal(openbal);
        }
      } else {
        if (parseFloat(openbal) != 0) {
          setOBal(openbal);
        } else {
          setOBal(-1 * parseFloat(openbal));
        }
      }
    } else {
      setOBal(openbal);
    }
  }

  function handleOpenBalType(val) {
    setOBalType(val);
    changeOpenBalType(val);
  }

  function changeOpenBalType(type) {
    var openbal = oBal;
    if (openbal != "") {
      if (type == "credit") {
        if (parseFloat(openbal) != 0) {
          setOBal(-1 * openbal);
        } else {
          setOBal(openbal);
        }
      } else {
        if (parseFloat(openbal) < 0) {
          setOBal(Math.abs(openbal));
        } else {
          setOBal(openbal);
        }
      }
    }
  }

  const handleNewCustomerModalSubmit = (e) => {
    e.preventDefault();

    var dt = {
      Id: ID,
      title: title,
      first_name: firstName,
      last_name: lastName,
      company: company,
      location: location,
      place_of_supply: customerPlaceOfSupply,
      gst_type: customerGstType,
      gstin: customerGstIn,
      pan_no: panNo,
      email: customerEmail,
      mobile: mobile,
      website: website,
      price_list: customerPriceList,
      payment_terms: paymentTerm,
      opening_balance: oBal,
      open_balance_type: oBalType,
      current_balance: oBal,
      credit_limit: creditLimit,
      billing_street: bStreet,
      billing_city: bCity,
      billing_state: bState,
      billing_pincode: bPincode,
      billing_country: bCountry,
      ship_street: sStreet,
      ship_city: sCity,
      ship_state: sState,
      ship_pincode: sPincode,
      ship_country: sCountry,
      status: "Active",
    };

    axios
      .post(`${config.base_url}/create_new_customer/`, dt)
      .then((res) => {
        console.log("CUST RES=", res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Customer Created",
          });
         
        }
        if (!res.data.status && res.data.message != "") {
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  // New Item

  function validateHSN() {
    var hsnField = document.getElementById("hsnField");
    var errorText = document.getElementById("hsnError");
    var hsnValue = hsnField.value;

    if (hsnValue.length < 6) {
      errorText.innerText = "HSN must contain at least 6 digits";
      hsnField.setCustomValidity("HSN must contain at least 6 digits");
      hsnField.style.borderColor = "red";
    } else {
      errorText.innerText = "";
      hsnField.setCustomValidity("");
      hsnField.style.borderColor = "";
    }
  }

  function validateSAC() {
    var sacField = document.getElementById("sacField");
    var errorText = document.getElementById("sacError");
    var sacValue = sacField.value;

    if (sacValue.length < 6) {
      errorText.innerText = "SAC must contain at least 6 digits";
      sacField.setCustomValidity("SAC must contain at least 6 digits");
      sacField.style.borderColor = "red";
    } else {
      errorText.innerText = "";
      sacField.setCustomValidity("");
      sacField.style.borderColor = "";
    }
  }

  function showdiv() {
    document.getElementById("taxableDiv").style.display = "flex";
  }

  function hidediv() {
    document.getElementById("taxableDiv").style.display = "none";
  }

  function itemTypeChange() {
    var value = document.getElementById("itemType").value;
    var sacField = document.getElementById("sacField");
    var hsnField = document.getElementById("hsnField");
    var hsnDiv = document.getElementById("hsnDiv");
    var sacDiv = document.getElementById("sacDiv");
    var sacError = document.getElementById("sacError");
    var hsnError = document.getElementById("hsnError");
    if (value === "Goods") {
      sacField.value = "";
      hsnField.required = true;
      sacField.required = false;
      hsnDiv.style.display = "block";
      sacDiv.style.display = "none";
      sacError.textContent = "";
      sacField.style.borderColor = "white";
    } else {
      hsnField.value = "";
      hsnField.required = false;
      sacField.required = true;
      sacDiv.style.display = "block";
      hsnDiv.style.display = "none";
      hsnError.textContent = "";
      hsnField.style.borderColor = "white";
    }
  }

  const [units, setUnits] = useState([]);
  const [accounts, setAccounts] = useState([]);

  

  const fetchPurchaseAccounts = () => {
    axios
      .get(`${config.base_url}/get_company_accounts/${ID}/`)
      .then((res) => {
        console.log("ACCNTS==", res);
        if (res.data.status) {
          let acc = res.data.accounts;
          setAccounts([]);
          acc.map((i) => {
            let obj = {
              account_name: i.account_name,
            };
            setAccounts((prevState) => [...prevState, obj]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchPurchaseAccounts();
  }, []);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [unit, setUnit] = useState("");
  const [hsn, setHsn] = useState("");
  const [sac, setSac] = useState("");
  const [taxRef, setTaxRef] = useState("");
  const [interStateTax, setInterStateTax] = useState("");
  const [intraStateTax, setIntraStateTax] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [purchaseAccount, setPurchaseAccount] = useState("");
  const [purchaseDescription, setPurchaseDescription] = useState("");
  const [salesPrice, setSalesPrice] = useState(0);
  const [salesAccount, setSalesAccount] = useState("");
  const [salesDescription, setSalesDescription] = useState("");
  const [inventoryAccount, setInventoryAccount] = useState("");
  const [stock, setStock] = useState(0);
  const [stockUnitRate, setStockUnitRate] = useState(0);
  const [minStock, setMinStock] = useState(0);

  const handleItemModalSubmit = (e) => {
    e.preventDefault();

    var dt = {
      Id: ID,
      name: name,
      item_type: type,
      unit: unit,
      hsn: hsn,
      sac: sac,
      tax_reference: taxRef,
      intra_state_tax: intraStateTax,
      inter_state_tax: interStateTax,
      sales_account: salesAccount,
      selling_price: salesPrice,
      sales_description: salesDescription,
      purchase_account: purchaseAccount,
      purchase_price: purchasePrice,
      purchase_description: purchaseDescription,
      min_stock: minStock,
      inventory_account: inventoryAccount,
      opening_stock: stock,
      current_stock: stock,
      stock_in: 0,
      stock_out: 0,
      stock_unit_rate: stockUnitRate,
      status: "Active",
    };

    axios
      .post(`${config.base_url}/create_new_Item/`, dt)
      .then((res) => {
        console.log("ITM RES=", res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Item Created",
          });
        fetchItems() 
        }
        if (!res.data.status && res.data.message != "") {
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };
  const [shipmentDate, setShipmentDate] = useState(null);
  // Function to handle payment term change and calculate shipment date
function handlePaymentTermChange(e) {
  const selectedTerm = e.target.options[e.target.selectedIndex];
  const days = parseInt(selectedTerm.getAttribute("data-days"), 10) || 0;

  setFormData((prevData) => ({
    ...prevData,
    payment_terms: selectedTerm.value,
  }));

  findShipmentDate(formData.sales_order_date, days);
}

// Function to find the expected shipment date
function findShipmentDate(orderDate, days) {
  if (orderDate && !isNaN(days)) {
    const orderDateObj = new Date(orderDate);
    orderDateObj.setDate(orderDateObj.getDate() + days);

    const formattedDate = orderDateObj.toISOString().slice(0, 10); // YYYY-MM-DD format
    setShipmentDate(formattedDate);
  } else {
    alert("Please enter a valid order date and select a payment term.");
  }
}

function handleTermModalSubmit(e) {
  e.preventDefault();

  const term = newTermName.trim();
  const days = parseInt(newTermDays, 10);

  if (term && !isNaN(days) && days >= 0) {
    const payload = {
      Id: ID,  // Ensure ID is correctly populated
      term_name: term,
      days: days,
    };

    console.log("Payload being sent:", payload); // Log the payload

    axios
      .post(`${config.base_url}/create_new_payment_term/`, payload)
      .then((res) => {
        console.log("New Term Response=", res);
        if (res.data.status) {
          Swal.fire({
            icon: "success",
            title: "Term Created",
            text: "The new payment term has been successfully created.",
            timer: 2000,
            showConfirmButton: false,
          });
          fetchPaymentTerms();  // Refresh payment terms list
          setNewTermName("");   // Reset form values
          setNewTermDays("");

          document.getElementById("termModalDismiss").click(); // Close modal
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (err.response && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: "Failed to create term",
            text: `${err.response.data.message || 'An error occurred while creating the term.'}`,
          });
        }
      });
  }
}
 
function handleUnitModalSubmit(e) {
  e.preventDefault();
  var name = newUnit;

  if (name !== "") {
    var u = {
      Id: ID,  // Ensure that this `ID` exists and matches a valid user in your DB
      name: newUnit,
    };

    axios
      .post(`${config.base_url}/create_new_unit/`, u)
      .then((res) => {
        console.log("UNIT RES=", res);
        if (res.data.status) {
          Swal.fire({
            icon: "success",
            title: "Unit Created",
            text: "The new unit has been successfully created.",
            timer: 2000,
            showConfirmButton: false,
          });
          fetchItemUnits();  // Fetch the updated list of units
          setUnit(u.name);   // Set the newly created unit
          setNewUnit("");    // Clear the input field for new unit
        } else {
          Swal.fire({
            icon: "error",
            title: "Creation Failed",
            text: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response?.data?.message || "An error occurred while creating the unit.",
        });
      });
  } else {
    alert("Please enter a valid unit name.");
  }
}


  
  const fetchItemUnits = () => {
    axios
      .get(`${config.base_url}/unit_dropdown/${ID}/`)
      .then((res) => {
        console.log("UNITS==", res);
        if (res.data.status) {
          let unitList = res.data.units;
          setUnits([]);
          unitList.forEach((unitItem) => {
            let obj = {
              id: unitItem.id,
              name: unitItem.name,
            };
            setUnits((prevState) => [...prevState, obj]);
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchItemUnits();
  }, []);
// Fetch the reference number
const [referenceNo, setReferenceNo] = useState(''); // Initialize state
useEffect(() => {
  const fetchReferenceNo = async () => {
    try {
      const response = await axios.get(`${config.base_url}/get_reference_norecurring/`, { params: { Id: ID } });
      if (response.data.status) {
        setReferenceNo(response.data.next_reference_no);
        setFormData((prevData) => ({
          ...prevData,
        reference_no: response.data.next_reference_no, // Set initial reference number
        }));
      }
    } catch (error) {
      console.error('Error fetching reference number:', error);
    }
  };

  fetchReferenceNo();
}, []);
// Fetch the next sales order number when the component mounts
const [nextSalesOrderNo, setNextSalesOrderNo] = useState('');
useEffect(() => {
  const fetchNextSalesOrderNo = async () => {
    try {
      const response = await axios.get(`${config.base_url}/get_next_recurringnumber/`, { params: { Id: ID } });
      if (response.data.status) {
        setNextSalesOrderNo(response.data.next_sales_order_no);
        setFormData((prevData) => ({
          ...prevData,
        rec_invoice_no: response.data.next_sales_order_no, // Set initial sales order number
        }));
      }
    } catch (error) {
      console.error('Error fetching next sales order number:', error);
    }
  };

  fetchNextSalesOrderNo();
}, []);

  

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel" style={{ background: "#a9a9a961", maxWidth: "100vw" }}>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
                
                <div className="card radius-15">
                <button
  className="btn btn-dark btn-sm ml-auto"
  onClick={() => navigate('/Salesorder')}
>
  <i className="fa fa-close"></i> {/* Icon for Close Button */}
</button>
                  <div className="card-body">
                    <h2 className="mt-3 text-center">CONVERT SALES TO RECURRING</h2>
                    <hr />
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        {/* Sales Order No */}
                     
{/* Customer Name Dropdown */}
<div className="col-md-4">
  <div className="form-group">
    <label htmlFor="customer_name">Customer Name</label>
    <div className="d-flex align-items-center">
      <select
        className="form-control"
        id="customer_name"
        name="customer_name"
        value={formData.customer_name}
        onChange={handleCustomerSelect}
        required
      >
        <option value="">Select Customer</option>
        {customers.map((customer) => (
          <option key={customer.id} value={customer.name}>
            {customer.name}
          </option>
        ))}
      </select>
      <button
        type="button"
        data-toggle="modal"
        data-target="#newCustomer"
        className="btn btn-outline-secondary ml-2"
        style={{ width: "fit-content", height: "fit-content" }}
      >
        +
      </button>
    </div>
    
  </div>
</div>




 {/* Customer Email */}
 <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="customer_email">Customer Email</label>
        <input
          type="email"
          className="form-control"
          id="customer_email"
          name="customer_email"
          value={formData.customer_email}
          onChange={handleChange}
          required
        />
      </div>
    </div>

   {/* Billing Address */}
<div className="col-md-4">
  <div className="form-group">
    <label htmlFor="billing_address">Billing Address</label>
    <textarea
      className="form-control"
      id="billing_address"
      name="billing_address"
      value={formData.billing_address} // This should be the combined billing address
      onChange={handleChange} // You'll need to define how changes are handled below
      required
    />
  </div>
</div>


    {/* GST Type */}
    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="gst_type">GST Type</label>
        <input
          type="text"
          className="form-control"
          id="gst_type"
          name="gst_type"
          value={formData.gst_type}
          onChange={handleChange}
        />
      </div>
    </div>

    {/* GSTIN */}
{formData.gstin && (
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="gstin">GSTIN</label>
      <input
        type="text"
        className="form-control"
        id="gstin"
        name="gstin"
        value={formData.gstin}
        onChange={handleChange}
        readOnly // This makes the field read-only
      />
    </div>
  </div>
)}

<div className="col-md-4">
  <div className="form-group">
    <label htmlFor="place_of_supply">Place of Supply</label>
    <select
      className="form-control"
      id="place_of_supply"
      name="place_of_supply"
      value={formData.place_of_supply}
      onChange={handleChange}
    >
      <option value="">Select Place of Supply</option>
      <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
      <option value="Andhra Pradesh">Andhra Pradesh</option>
      <option value="Arunachal Pradesh">Arunachal Pradesh</option>
      <option value="Assam">Assam</option>
      <option value="Bihar">Bihar</option>
      <option value="Chandigarh">Chandigarh</option>
      <option value="Chhattisgarh">Chhattisgarh</option>
      <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
      <option value="Daman and Diu">Daman and Diu</option>
      <option value="Delhi">Delhi</option>
      <option value="Goa">Goa</option>
      <option value="Gujarat">Gujarat</option>
      <option value="Haryana">Haryana</option>
      <option value="Himachal Pradesh">Himachal Pradesh</option>
      <option value="Jammu and Kashmir">Jammu and Kashmir</option>
      <option value="Jharkhand">Jharkhand</option>
      <option value="Karnataka">Karnataka</option>
      <option value="Kerala">Kerala</option>
      <option value="Ladakh">Ladakh</option>
      <option value="Lakshadweep">Lakshadweep</option>
      <option value="Madhya Pradesh">Madhya Pradesh</option>
      <option value="Maharashtra">Maharashtra</option>
      <option value="Manipur">Manipur</option>
      <option value="Meghalaya">Meghalaya</option>
      <option value="Mizoram">Mizoram</option>
      <option value="Nagaland">Nagaland</option>
      <option value="Odisha">Odisha</option>
      <option value="Puducherry">Puducherry</option>
      <option value="Punjab">Punjab</option>
      <option value="Rajasthan">Rajasthan</option>
      <option value="Sikkim">Sikkim</option>
      <option value="Tamil Nadu">Tamil Nadu</option>
      <option value="Telangana">Telangana</option>
      <option value="Tripura">Tripura</option>
      <option value="Uttar Pradesh">Uttar Pradesh</option>
      <option value="Uttarakhand">Uttarakhand</option>
      <option value="West Bengal">West Bengal</option>
      <option value="Other Territory">Other Territory</option>
    </select>
  </div>
</div>

 {/* Sales Order No. */}
 <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="sales_order_no">Sales Order No.</label>
        <input
          type="text"
          className="form-control"
          id="sales_order_no"
          name="salesOrder_no"
          value={formData.sales_order_no}
          onChange={handleChange}
          required
        />
      </div>
    </div>
    {/* Sales Order No. */}
    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="sales_order_no">recurring invoice No.</label>
        <input
          type="text"
          className="form-control"
          id=" rec_invoice_no"
          name=" rec_invoice_no"
          placeholder={nextSalesOrderNo} // Use placeholder for the sales order number
          onChange={handleChange}
          required
        />
      </div>
    </div>

    {/* Reference No */}
    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="reference_no">Reference No.</label>
        <input
          type="number"
          className="form-control"
          id="reference_no"
          name="reference_no"
          value={referenceNo || ""} 
          onChange={handleChange}
        />
      </div>
    </div>

  
    {/* Sales Order Date */}
<div className="col-md-4">
  <div className="form-group">
    <label htmlFor="sales_order_date">Start Date</label>
    <input
      type="date"
      className="form-control"
      id="sales_order_date"
      name="start_date" // Remove the space before 'start_date'
      value={formData.sales_order_date}
      onChange={handleChange}
    />
  </div>
</div>

{/* Expected Ship Date */}
<div className="col-md-4">
  <div className="form-group">
    <label htmlFor="exp_ship_date">End Date</label>
    <input
      type="date"
      className="form-control"
      id="exp_ship_date"
      name="end_date" // Match this with backend
      value={shipmentDate}
      onChange={handleChange}
    />
  </div>
</div>


<div className="col-md-4">
  <div className="form-group">
    <label htmlFor="payment_terms">Payment Terms</label>
    <div className="input-group">
      <select
        className="form-control"
        id="payment_terms"
        name="payment_terms"
        value={formData.payment_terms}
        onChange={handlePaymentTermChange}
      >
        <option value="">Select Payment Term</option>
        {terms.map((term) => (
          <option key={term.id} value={term.id} data-days={term.days}>
            {term.name}
          </option>
        ))}
      </select>
      <div className="input-group-append">
        <button
          type="button"
          data-toggle="modal"
          data-target="#newPaymentTerm"
          className="btn btn-outline-secondary ml-2"
          style={{ width: "fit-content", height: "fit-content" }}
        >
          +
        </button>
      </div>
    </div>
  </div>
</div>





    

    <div className="col-md-4">
  <div className="form-group">
    <label htmlFor="price_list">Select Price List</label>
    <select
      id="price_list"
      name="price_list"
      value={formData.price_list}
      onChange={(e) => setFormData({ ...formData, price_list: e.target.value })}
      className="form-control"
    >
      <option value="">Select a Price List</option>
      {priceLists.map((list) => (
        <option key={list.id} value={list.id}>
          {list.name} ({list.currency})
        </option>
      ))}
    </select>
  </div>
</div>

                      {/* Payment Method */}
<div className="col-md-4">
  <div className="form-group">
    <label htmlFor="payment_method">Payment Method</label>
    <select
      className="form-control"
      id="payment_method"
      name="payment_method"
      value={formData.payment_method}
      onChange={handleChange}
    >
      <option value="">Select Payment Method</option>
      <option value="cash">Cash</option>
      <option value="cheque">Cheque</option>
      <option value="upi">UPI</option>
     
    </select>
  </div>
</div>

{/* Conditional fields based on Payment Method */}
{formData.payment_method === "cheque" && (
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="cheque_no">Cheque No.</label>
      <input
        type="text"
        className="form-control"
        id="cheque_no"
        name="cheque_no"
        value={formData.cheque_no}
        onChange={handleChange}
      />
    </div>
  </div>
)}

{formData.payment_method === "upi" && (
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="upi_no">UPI No.</label>
      <input
        type="text"
        className="form-control"
        id="upi_no"
        name="upi_no"
        value={formData.upi_no}
        onChange={handleChange}
      />
    </div>
  </div>
)}

{formData.payment_method === "bank" && (
  <>
    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="bank_acc_no">Bank Account No.</label>
        <input
          type="text"
          className="form-control"
          id="bank_acc_no"
          name="bank_acc_no"
          value={formData.bank_acc_no}
          onChange={handleChange}
        />
      </div>
    </div>

    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="bank_name">Bank Name</label>
        <input
          type="text"
          className="form-control"
          id="bank_name"
          name="bank_name"
          value={formData.bank_name}
          onChange={handleChange}
        />
      </div>
    </div>

    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="branch_name">Branch Name</label>
        <input
          type="text"
          className="form-control"
          id="branch_name"
          name="branch_name"
          value={formData.branch_name}
          onChange={handleChange}
        />
      </div>
    </div>

    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="ifsc_code">IFSC Code</label>
        <input
          type="text"
          className="form-control"
          id="ifsc_code"
          name="ifsc_code"
          value={formData.ifsc_code}
          onChange={handleChange}
        />
      </div>
    </div>
  </>
)}


<div className="row clearfix" style={{ marginLeft: "10px", marginRight: "10px"}}>
  <div className="col-md-12 table-responsive-md mt-3">
    <table className="table table-bordered table-hover mt-3" id="salesOrderItemsTable">
      <thead>
        <tr>
          <th className="text-center">#</th>
          <th className="text-center">PRODUCT / SERVICE</th>
          <th className="text-center">HSN / SAC</th>
          <th className="text-center">QTY</th>
          <th className="text-center">PRICE</th>
          <th className="text-center">TAX (%)</th>
          <th className="text-center">DISCOUNT</th>
          <th className="text-center">TOTAL</th>
          <th className="text-center">ACTIONS</th>
        </tr>
      </thead>
      <tbody id="items-table-body">
        {salesOrderItems.map((row, index) => {
          const selectedOptionI = items.find((item) => item.label === row.item|| item.label === row.name);

          return (
            <tr key={row.id} id={`tab_row${row.id}`}>
              <td className="nnum" style={{ textAlign: "center" }}>{index + 1}</td>

              {/* Product / Service */}
              <td style={{ width: "20%" }}>
                <div className="d-flex align-items-center">
                  <Select
                    options={items}
                    name="item"
                    className="w-100"
                    id={`item${row.id}`}
                    required
                    value={selectedOptionI}
                    onChange={(selectedOption) => handleItemChange(selectedOption, row.id)}
                    isClearable
                    isSearchable
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary ml-1"
                    data-target="#newItem"
                    data-toggle="modal"
                    style={{ width: "fit-content", height: "fit-content" }}
                  >
                    +
                  </button>
                </div>
              </td>

              {/* HSN / SAC */}
              <td>
  <input
    type="text"
    name={`hsnSac_${row.id}`}
    value={row.hsnSac}
    id={`hsn${row.id}`}
    placeholder="HSN/SAC Code"
    className="form-control HSNCODE"
    readOnly
  />
</td>


              {/* Quantity */}
              <td>
                <input
                  type="number"
                  name={`qty_${row.id}`}
                  id={`qty${row.id}`}
                  value={row.quantity}
                 
                  className="form-control qty"
                  step="0"
                  min="1"
                  
                  onChange={(e) => handleSalesOrderItemsInputChange(row.id, "quantity", e.target.value)}
                  required
                />
                  <small className="text-muted">Available: {row.current_stock}</small>
              </td>

              {/* Price */}
              <td>
                <input
                  type="number"
                  name={`price_${row.id}`}
                  id={`price${row.id}`}
                  className="form-control price"
                  step="0.00"
                  min="0"
                  value={row.price}
                  readOnly
                />
              </td>

             {/* Tax (%) */}
             {showIGST && (
              <td style={{ width: "13%" }}>
                <select
                  name={`taxGST_${row.id}`}
                  id={`taxGST${row.id}`}
                  className="form-control tax_ref tax_ref_gst"
                  value={row.taxGst}
                  onChange={(e) => handleSalesOrderItemsInputChange(row.id, "taxGst", e.target.value)}
                >
                  <option value="">Select IGST</option>
                  <option value="28">28.0% IGST</option>
                  <option value="18">18.0% IGST</option>
                  <option value="12">12.0% IGST</option>
                  <option value="5">5.0% IGST</option>
                  <option value="3">3.0% IGST</option>
                  <option value="0">0.0% IGST</option>
                </select>
              </td>
              )}
                {/* Tax (%) */}
                {showCGSTSGST && (
                <td style={{ width: "13%" }}>
                <select
                  name={`taxGST_${row.id}`}
                  id={`taxGST${row.id}`}
                  className="form-control tax_ref tax_ref_gst"
                  value={row.taxGst}
                  onChange={(e) => handleSalesOrderItemsInputChange(row.id, "taxGst", e.target.value)}
                >
                  <option value="">Select GST</option>
                  <option value="28">28.0% GST</option>
                  <option value="18">18.0% GST</option>
                  <option value="12">12.0% GST</option>
                  <option value="5">5.0% GST</option>
                  <option value="3">3.0% GST</option>
                  <option value="0">0.0% GST</option>
                </select>
              </td>
                )}


              {/* Discount */}
              <td>
                <input
                  type="number"
                  name={`discount_${row.id}`}
                  placeholder="Enter Discount"
                  id={`disc${row.id}`}
                  value={row.discount}
                  onChange={(e) => handleSalesOrderItemsInputChange(row.id, "discount", e.target.value)}
                  className="form-control disc"
                  step="0"
                  min="0"
                />
              </td>

              {/* Total */}
              <td>
                <input
                  type="number"
                  name={`total_${row.id}`}
                  id={`total${row.id}`}
                  className="form-control total"
                  value={row.total}
                  readOnly
                />
                <input
                  type="hidden"
                  id={`taxamount${row.id}`}
                  className="form-control itemTaxAmount"
                  value={row.taxAmount}
                />
              </td>

              {/* Remove Row */}
              <td>
                <button
                  type="button"
                  id={`${row.id}`}
                  onClick={() => removeRow(row.id)}
                  className="btn btn-danger remove_row px-2 py-1 mx-1 fa fa-close"
                  title="Remove Row"
                  style={{ width: "fit-content", height: "fit-content" }}
                ></button>
              </td>
            </tr>
          );
        })}
      </tbody>

      {/* Add New Row Button */}
      <tr>
        <td style={{ border: "none" }}>
          <a
            className="btn btn-secondary ml-1"
            role="button"
            id="add"
            onClick={addNewRow}
            style={{ width: "fit-content", height: "fit-content" }}
          >
            +
          </a>
        </td>
      </tr>
    </table>
  </div>
</div>
<div className="modal fade" id="newCustomer">
        <div className="modal-dialog modal-xl">
        <div className="modal-content" style={{ backgroundColor: "white" }}>
            <div className="modal-header">
              <h5 className="m-3">New Customer</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form method="post" id="newCustomerForm" className="px-1">
                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="title">Title</label>
                      <select
                        name="title"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="form-control"
                      >
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Miss">Miss</option>
                        <option value="Ms">Ms</option>
                      </select>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-4">
                      <label for="firstName">First Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="first_name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        onBlur={checkFirstName}
                        required
                       
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-4">
                      <label for="lastName">Last Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="last_name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        onBlur={checkLastName}
                        required
                      
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>

                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="companyName">Company</label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyName"
                        name="company_name"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-4">
                      <label for="location">Location</label>
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        name="location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-4">
                      <label for="custPlaceOfSupply">Place of Supply*</label>
                      <select
                        className="custom-select form-control"
                        id="custPlaceOfSupply"
                        name="place_of_supply"
                        value={customerPlaceOfSupply}
                        onChange={(e) =>
                          setCustomerPlaceOfSupply(e.target.value)
                        }
                        
                        required
                      >
                        <option selected value="">
                          Select Place of Supply
                        </option>
                        <option value="Andaman and Nicobar Islads">
                          Andaman and Nicobar Islands
                        </option>
                        <option value="Andhra Predhesh">Andhra Predhesh</option>
                        <option value="Arunachal Predesh">
                          Arunachal Predesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadra and Nagar Haveli">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Damn anad Diu">Damn anad Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Predesh">
                          Himachal Predesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Ladakh">Ladakh</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Madhya Predesh">Madhya Predesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Predesh">Uttar Predesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                        <option value="Other Territory">Other Territory</option>
                      </select>
                      <div className="invalid-feedback">
                        Please select a valid registration type.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="gstType">GST Type*</label>
                      <select
                        className="form-control"
                        id="custGstType"
                        name="gst_type"
                        value={customerGstType}
                        onChange={(e) => handleGstType(e.target.value)}
                        
                        required
                      >
                        <option selected value="">
                          Select GST Type
                        </option>
                        <option value="Registered Business - Regular">
                          Registered Business - Regular{" "}
                          <span>
                            <i>(Business that is registered under gst)</i>
                          </span>
                        </option>
                        <option value="Registered Business - Composition">
                          Registered Business - Composition (Business that is
                          registered under composition scheme in gst)
                        </option>
                        <option value="Unregistered Business">
                          Unregistered Business (Business that has not been
                          registered under gst)
                        </option>
                        <option value="Overseas">
                          Overseas (Import/Export of supply outside india)
                        </option>
                        <option value="Consumer">Consumer</option>
                        <option value="Special Economic Zone (SEZ)">
                          Special Economic Zone (SEZ) (Business that is located
                          in a special economic zone of india or a SEZ
                          developer)
                        </option>
                        <option value="Demed Exports">
                          Demed Exports (Supply of woods to an exports oriented
                          unit or againsed advanced authorization or export
                          promotion capital woods)
                        </option>
                        <option value="Tax Deductor">
                          Tax Deductor (State of central gov,government agencies
                          or local authority)
                        </option>
                        <option value="SEZ Developer">
                          SEZ Developer (A person or organization who owns
                          atleast 26% equality in creating business units in
                          special economic zone)
                        </option>
                      </select>
                      <div className="invalid-feedback">
                        Please select a valid registration type.
                      </div>
                    </div>

                    <div className="col-md-4 gstrow d-block" id="gstInValue">
                      <div>
                        <label for="custGstIN">GSTIN*</label>
                        <input
                          type="text"
                          className="form-control"
                          value={customerGstIn}
                          onChange={(e) => setCustomerGstIn(e.target.value)}
                          onBlur={(e) => checkgst(e.target.value)}
                          id="gstIN"
                          name="gstin"
                          
                          placeholder="29APPCK7465F1Z1"
                        />
                        <a
                          data-toggle="modal"
                          href="#exampleModal"
                          style={{ color: "#3dd5f3" }}
                        >
                          Get Taxpayer Details
                        </a>
                        <div className="text-danger m-2" id="warngst"></div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <label for="panNo">PAN No.*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="panNo"
                        name="pan_no"
                       
                        required
                        value={panNo}
                        onChange={(e) => setPanNo(e.target.value)}
                        onBlur={(e) => checkpan(e.target.value)}
                        placeholder="APPCK7465F"
                      />
                      <div className="text-danger m-2" id="warnpan"></div>
                    </div>
                  </div>

                  <div className="row w-100">
                    <div className="col-md-4 mt-3">
                      <label for="validationCustom05">Opening Balance</label>
                      <div className="d-flex">
                        <select
                          name="balance_type"
                          id="bal"
                          className="form-select text-white mr-1 px-1"
                          value={oBalType}
                          onChange={(e) => handleOpenBalType(e.target.value)}
                          style={{
                            
                            width: "25%",
                            borderRadius: "5px",
                          }}
                        >
                          <option value="debit">Debit</option>
                          <option value="credit">Credit</option>
                        </select>
                        <input
                          type="text"
                          className="form-control"
                          name="open_balance"
                          id="openbalance"
                          value={oBal}
                          onChange={(e) => setOBal(e.target.value)}
                          onBlur={(e) => setOpeningBalanceValue(e.target.value)}
                          step="any"
                         
                        />
                        <div className="text-danger m-2"></div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-3">
                      <label for="creditLimit">Credit Limit</label>
                      <input
                        type="text"
                        className="form-control"
                        name="credit_limit"
                     
                        step="any"
                        value={creditLimit}
                        onChange={(e) => setCreditLimit(e.target.value)}
                        id="creditLimit"
                      />
                      <div className="text-danger m-2"></div>
                    </div>

                    <div className="col-md-4 mt-3">
                      <label for="custPaymentTerms">Payment Terms</label>
                      <div className="d-flex align-items-center">
                      <select
        className="form-control"
        id="payment_terms"
        name="payment_terms"
        value={formData.payment_terms}
        onChange={handlePaymentTermChange}
      >
        <option value="">Select Payment Term</option>
        {terms.map((term) => (
          <option key={term.id} value={term.id} data-days={term.days}>
            {term.name}
          </option>
        ))}
      </select>
                        <a
                          href="#newCustomerPaymentTerm"
                          data-dismiss="modal"
                          data-toggle="modal"
                         
                          className="btn btn-outline-secondary ml-1"
                        >
                          +
                        </a>
                      </div>
                    </div>

                    <div className="col-md-4 mt-3">
                      <label for="priceList">Price List</label>
                      <select
      id="price_list"
      name="price_list"
      value={formData.price_list}
      onChange={(e) => setFormData({ ...formData, price_list: e.target.value })}
      className="form-control"
    >
      <option value="">Select a Price List</option>
      {priceLists.map((list) => (
        <option key={list.id} value={list.id}>
          {list.name} ({list.currency})
        </option>
      ))}
    </select>
                    </div>
                  </div>

                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="custEmail">Email*</label>
                      <input
                        type="email"
                        className="form-control"
                        required
                        id="custEmail"
                        name="email"
                        value={customerEmail}
                        onChange={(e) => setCustomerEmail(e.target.value)}
                        onBlur={(e) => checkemail(e.target.value)}
                      
                        placeholder="accuhub@gmail.com"
                      />
                      <div id="warnemail" className="text-danger"></div>
                    </div>
                    <div className="col-md-4">
                      <label for="custWebsite">Website</label>
                      <input
                        type="text"
                        className="form-control"
                        id="custWebsite"
                        required
                        placeholder="www.accuhub.com"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        onBlur={(e) => checkweb(e.target.value)}
                        name="website"
                      
                      />
                      <div id="warnweb" className="text-danger"></div>
                    </div>
                    <div className="col-md-4">
                      <label for="custMobile">Mobile*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="custMobile"
                        required
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        onBlur={(e) => checkphone(e.target.value)}
                        name="mobile"
                      
                      />
                      <div className="text-danger m-2" id="warnphone"></div>
                    </div>
                  </div>
                  <hr />
                  <div className="row mt-5 w-100">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 card-title">
                          <h5 className="mb-0">Billing Address</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="form-row">
                            <label for="street">Street*</label>
                            <textarea
                              className="form-control street"
                              required
                              id="street"
                              value={bStreet}
                              onChange={(e) => setBStreet(e.target.value)}
                              name="street"
                            
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Street
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="city">City*</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="city"
                              name="city"
                              value={bCity}
                              onChange={(e) => setBCity(e.target.value)}
                             
                              placeholder="City"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid City
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="state">State*</label>
                            <select
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                              required
                              value={bState}
                              onChange={(e) => setBState(e.target.value)}
                            
                            >
                              <option value="" selected hidden>
                                Choose
                              </option>
                              <option value="Andaman and Nicobar Islads">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Andhra Predhesh">
                                Andhra Predhesh
                              </option>
                              <option value="Arunachal Predesh">
                                Arunachal Predesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadra and Nagar Haveli">
                                Dadra and Nagar Haveli
                              </option>
                              <option value="Damn anad Diu">
                                Damn anad Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Predesh">
                                Himachal Predesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Ladakh">Ladakh</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Madhya Predesh">
                                Madhya Predesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Predesh">
                                Uttar Predesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                              <option value="Other Territory">
                                Other Territory
                              </option>
                            </select>
                            <div className="invalid-feedback">
                              Please provide a valid State
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="pinco">Pin Code*</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="pinco"
                              value={bPincode}
                              onChange={(e) => setBPincode(e.target.value)}
                              name="pincode"
                             
                              placeholder="PIN code"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Pin Code
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="country">Country*</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="country"
                              name="country"
                              value={bCountry}
                              onChange={(e) => setBCountry(e.target.value)}
                             
                              placeholder="Country"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Country
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 d-flex">
                          <h5>Shipping Address</h5>
                          <input
                            className="ml-4 ml-5"
                            type="checkbox"
                            onClick={placeShipAddress}
                            id="shipAddress"
                            name="ship_address"
                          />
                          <label className="ml-2 mt-1 ml-2" for="shipAddress">
                            Same As Billing Address
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="form-row">
                            <label for="shipstreet">Street</label>
                            <textarea
                              className="form-control"
                              id="shipstreet"
                              name="shipstreet"
                              value={sStreet}
                              onChange={(e) => setSStreet(e.target.value)}
                            
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Street
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shipcity">City</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shipcity"
                              value={sCity}
                              onChange={(e) => setSCity(e.target.value)}
                              name="shipcity"
                            
                              placeholder="City"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid City
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shipstate">State</label>
                            <select
                              type="text"
                              className="form-control"
                              id="shipState"
                              value={sState}
                              onChange={(e) => setSState(e.target.value)}
                              name="shipstate"
                             
                            >
                              <option value="" selected>
                                Choose
                              </option>
                              <option value="Andaman and Nicobar Islads">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Andhra Predhesh">
                                Andhra Predhesh
                              </option>
                              <option value="Arunachal Predesh">
                                Arunachal Predesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadra and Nagar Haveli">
                                Dadra and Nagar Haveli
                              </option>
                              <option value="Damn anad Diu">
                                Damn anad Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Predesh">
                                Himachal Predesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Ladakh">Ladakh</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Madhya Predesh">
                                Madhya Predesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Predesh">
                                Uttar Predesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                              <option value="Other Territory">
                                Other Territory
                              </option>
                            </select>
                            <div className="invalid-feedback">
                              Please provide a valid State
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shippinco">Pin Code</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shippinco"
                              value={sPincode}
                              onChange={(e) => setSPincode(e.target.value)}
                              name="shippincode"
                             
                              placeholder="PIN code"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Pin Code
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shipcountry">Country</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shipcountry"
                              name="shipcountry"
                              value={sCountry}
                              onChange={(e) => setSCountry(e.target.value)}
                             
                              placeholder="Country"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Country
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 w-100">
                    <div className="col-4"></div>
                    <div className="col-4 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-secondary text-grey w-75"
                        onClick={handleNewCustomerModalSubmit}
                        data-dismiss="modal"
                        type="button"
                        id="newCustomerSave"
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-4"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

 {/* New Ietm */}
 <div className="modal fade" id="newItem">
        <div className="modal-dialog modal-xl">
        <div className="modal-content">
            <div className="modal-header">
              <h5 className="m-3">New Item</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form
                  className="needs-validation px-1"
                  onSubmit={handleSubmit}
                  validate
                >
                  <div className="row w-100">
                    <div className="col-md-12 mx-0">
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label for="itemName" >
                            Name
                          </label>
                          <input
                            type="text"
                            id="itemName"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                           
                            autocomplete="off"
                            required
                          />
                        </div>
                        <div className="col-md-6 mt-3">
                          <label for="itemType">
                            Type
                          </label>
                          <select
                            name="type"
                            className="form-control"
                            id="itemType"
                            value={type}
                            onChange={(e) => {
                              setType(e.target.value);
                              itemTypeChange();
                            }}
                           
                            required
                          >
                            <option selected disabled value="">
                              Choose...
                            </option>
                            <option value="Goods">Goods</option>
                            <option value="Services">Services</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label for="itemUnit">
                            Unit
                          </label>
                          <div className="d-flex align-items-center">
                            <select
                              className="custom-select"
                              name="unit"
                              id="itemUnit"
                              value={unit}
                              onChange={(e) => setUnit(e.target.value)}
                              required
                              
                            >
                              <option selected disabled value="">
                                Choose...
                              </option>
                              {units &&
                                units.map((i) => (
                                  <option
                                    value={i.name}
                                    className="text-uppercase"
                                  >
                                    {i.name}
                                  </option>
                                ))}
                            </select>
                              <button
                                type="button"
                                className="btn btn-outline-secondary ml-1"
                                data-toggle="modal"
                                data-dismiss="modal"
                                data-target="#createNewUnit"
                               
                              >
                                +
                              </button>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3" id="hsnDiv">
                          <label for="hsnField" >
                            HSN Code
                          </label>
                          <input
                            type="number"
                            name="hsn"
                            className="form-control"
                            
                            placeholder="Enter a valid HSN code"
                            required
                            value={hsn}
                            onChange={(e) => setHsn(e.target.value)}
                            id="hsnField"
                            onInput={validateHSN}
                          />
                          <div id="hsnError" style={{ color: "red" }}></div>
                        </div>
                        <div
                          className="col-md-6 mt-3"
                          id="sacDiv"
                          style={{ display: "none" }}
                        >
                          <label for="sacField" >
                            SAC Code
                          </label>
                          <input
                            type="number"
                            name="sac"
                            className="form-control"
                           
                            placeholder="Enter a valid SAC code"
                            required
                            value={sac}
                            onChange={(e) => setSac(e.target.value)}
                            id="sacField"
                            onInput={validateSAC}
                          />
                          <div id="sacError" style={{ color: "red" }}></div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-3 mt-3">
                          <label >
                            Tax Reference
                          </label>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check mt-1">
                            <input
                              className="form-check-input"
                              name="taxref"
                              type="radio"
                              id="inclusive"
                              value="taxable"
                              onChange={(e) => setTaxRef(e.target.value)}
                              onClick={showdiv}
                              required
                            />
                            <label  for="inclusive">
                              taxable
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check mt-1">
                            <input
                              className="form-check-input"
                              name="taxref"
                              type="radio"
                              value="non taxable"
                              onChange={(e) => setTaxRef(e.target.value)}
                              id="check"
                              onClick={hidediv}
                            />
                            <label  for="check">
                              non taxable
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        id="taxableDiv"
                       
                      >
                        <div className="col-md-6 mt-3">
                          <label for="intraStateTax">
                            Intra State Tax Rate
                          </label>
                          <select
                            name="intra_st"
                            className="form-control"
                           
                            id="intraStateTax"
                            value={intraStateTax}
                            onChange={(e) => setIntraStateTax(e.target.value)}
                          >
                            <option value="0">GST 0 (0%)</option>
                            <option value="3">GST 3 (3%)</option>
                            <option value="5">GST 5 (5%)</option>
                            <option value="12">GST 12 (12%)</option>
                            <option value="18">GST 18 (18%)</option>
                            <option value="28">GST 28 (28%)</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label for="interStateTax" >
                            Inter State Tax Rate
                          </label>
                          <select
                            name="inter_st"
                            className="form-control"
                           
                            id="interStateTax"
                            value={interStateTax}
                            onChange={(e) => setInterStateTax(e.target.value)}
                          >
                            <option value="0">IGST 0 (0%)</option>
                            <option value="3">IGST 3 (3%)</option>
                            <option value="5">IGST 5 (5%)</option>
                            <option value="12">IGST 12 (12%)</option>
                            <option value="18">IGST 18 (18%)</option>
                            <option value="28">IGST 28 (28%)</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label >
                            Purchase Price
                          </label>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <input
                                type="text"
                                className="form-control mr-1"
                                value="INR"
                               
                              />
                              <input
                                type="number"
                                name="pcost"
                                className="form-control"
                                id="purprice"
                               
                                value={purchasePrice}
                                onChange={(e) =>
                                  setPurchasePrice(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label >Sales Price</label>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <input
                                type="text"
                                className="form-control mr-1"
                                value="INR"
                                
                              />
                              <input
                                type="text"
                                name="salesprice"
                                className="form-control"
                                id="saleprice"
                               
                                value={salesPrice}
                                onChange={(e) => setSalesPrice(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label
                            for="purchaseAccount"
                            
                          >
                            Account
                          </label>
                          <div className="d-flex align-items-center">
                            <select
                              name="pur_account"
                              className="form-control"
                              
                              id="purchaseAccount"
                              value={purchaseAccount}
                              onChange={(e) =>
                                setPurchaseAccount(e.target.value)
                              }
                            >
                              <option value="" selected disabled>
                                --Choose--
                              </option>
                              {accounts &&
                                accounts.map((i) => (
                                  <option
                                    value={i.account_name}
                                    className="text-uppercase"
                                  >
                                    {i.account_name}
                                  </option>
                                ))}
                            </select>
                              <button
                                type="button"
                                className="btn btn-outline-secondary ml-1"
                                data-toggle="modal"
                                data-dismiss="modal"
                                data-target="#createNewAccount"
                               
                              >
                                +
                              </button>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label for="salesAccount">
                            Account
                          </label>
                          <select
                            name="sale_account"
                            className="form-control"
                           
                            id="salesAccount"
                            value={salesAccount}
                            onChange={(e) => setSalesAccount(e.target.value)}
                          >
                            <option value="" selected disabled>
                              --Choose--
                            </option>
                            <option value="General Income">
                              General Income
                            </option>
                            <option value="Interest Income">
                              Interest Income
                            </option>
                            <option value="Late Fee Income">
                              Late Fee Income
                            </option>
                            <option value="Discount Income">
                              Discount Income
                            </option>
                            <option value="Shipping Charges">
                              Shipping Charges
                            </option>
                            <option value="Other Charges">Other Charges</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label
                            for="purchaseDescription"
                          
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            name="pur_desc"
                            id="purchaseDescription"
                           
                            value={purchaseDescription}
                            onChange={(e) =>
                              setPurchaseDescription(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-6 mt-3">
                          <label
                            for="salesDescription"
                           
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            name="sale_desc"
                            id="salesDescription"
                           
                            value={salesDescription}
                            onChange={(e) =>
                              setSalesDescription(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="row" id="inventorytrack">
                        <div className="col-md-6 mt-3">
                          <label >
                            Inventory Account
                          </label>
                          <select
                            name="invacc"
                            className="form-control"
                            
                            required
                            value={inventoryAccount}
                            onChange={(e) =>
                              setInventoryAccount(e.target.value)
                            }
                          >
                            <option selected disabled value="">
                              Choose...
                            </option>
                            <option value="Inventory Assets">
                              Inventory Assets
                            </option>
                          </select>
                        </div>
                        <div className="col-md-3 mt-3">
                          <label >
                            Stock on hand
                          </label>
                          <input
                            type="number"
                            name="stock"
                            className="form-control"
                          
                            value={stock}
                            onChange={(e) => setStock(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label >
                            Stock Rate per Unit
                          </label>
                          <input
                            type="number"
                            name="stock_rate"
                            className="form-control"
                           
                            value={stockUnitRate}
                            onChange={(e) => setStockUnitRate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <label >
                            Minimum Stock to maintain
                          </label>
                          <input
                            type="number"
                            name="min_stock"
                            className="form-control"
                           
                            value={minStock}
                            onChange={(e) => setMinStock(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row mt-4 w-100">
                        <div className="col-4"></div>
                        <div className="col-4 d-flex justify-content-center">
                          <button
                            className="btn btn-outline-secondary text-grey w-75"
                            onClick={handleItemModalSubmit}
                            data-dismiss="modal"
                            type="button"
                            id="newItemSave"
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-4"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* customer payment term*/}

    <div className="modal fade" id="newPaymentTermcustomer">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="m-3">New Payment Term</h5>
            <button
              type="button"
              className="close"
              id="termModalDismiss"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body w-100">
            <div className="card p-3">
              <form id="newTermForm" onClick={handleTermModalSubmit}>
                <div className="row mt-2 w-100">
                  <div className="col-6">
                    <label htmlFor="termName">Term Name</label>
                    <input
                      type="text"
                      name="term_name"
                      id="termName"
                      value={newTermName}
                      onChange={(e) => setNewTermName(e.target.value)}
                      className="form-control w-100"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="termDays">Days</label>
                    <input
                      type="number"
                      name="days"
                      id="termDays"
                      value={newTermDays}
                      onChange={(e) => setNewTermDays(e.target.value)}
                      className="form-control w-100"
                      min="0"
                      step="1"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-4 w-100">
                  <div className="col-4"></div>
                  <div className="col-4 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-secondary text-grey w-75"
                      type="button"
                      id="savePaymentTerm"
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-4"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

     {/* <!-- Item Unit Create Modal --> */}

     <div className="modal fade" id="createNewUnit">
        <div className="modal-dialog">
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="m-3">New Item Unit</h5>
              <button
                type="button"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                data-target="#newItem"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form
                  onSubmit={handleUnitModalSubmit}
                  id="newUnitForm"
                  className="px-1"
                >
                  <div className="row mt-2 w-100">
                    <div className="col-12">
                      <label for="name">Unit Name</label>
                      <input
                        name="name"
                        id="unit_name"
                        value={newUnit}
                        onChange={(e) => setNewUnit(e.target.value)}
                        className="form-control text-uppercase w-100"
                      />
                    </div>
                  </div>
                  <div className="row mt-4 w-100">
                    <div className="col-12 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-info text-grey"
                        type="submit"
                        data-toggle="modal"
                        data-target="#newItem"
                        onClick={handleUnitModalSubmit}
                        id="saveItemUnit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
        {/* customer payment term*/}

    <div className="modal fade" id="newPaymentTermcustomer">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="m-3">New Payment Term</h5>
            <button
              type="button"
              className="close"
              id="termModalDismiss"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body w-100">
            <div className="card p-3">
              <form id="newTermForm" onClick={handleTermModalSubmit}>
                <div className="row mt-2 w-100">
                  <div className="col-6">
                    <label htmlFor="termName">Term Name</label>
                    <input
                      type="text"
                      name="term_name"
                      id="termName"
                      value={newTermName}
                      onChange={(e) => setNewTermName(e.target.value)}
                      className="form-control w-100"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="termDays">Days</label>
                    <input
                      type="number"
                      name="days"
                      id="termDays"
                      value={newTermDays}
                      onChange={(e) => setNewTermDays(e.target.value)}
                      className="form-control w-100"
                      min="0"
                      step="1"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-4 w-100">
                  <div className="col-4"></div>
                  <div className="col-4 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-secondary text-grey w-75"
                      type="button"
                      id="savePaymentTerm"
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-4"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


                      
                       <div className="container-fluid">
  <div className="row">
    {/* Left Side - Note Section */}
    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="note">Note</label>
        <textarea
          className="form-control"
          id="note"
          name="note"
          value={formData.note}
          onChange={handleChange}
          rows="5"
          style={{
            backgroundColor: "#f7f7f7",
            border: "1px solid #ced4da",
            padding: "10px",
          }}
        />
      </div>
    </div>

    {/* Right Side - Paid and Balance Details */}
<div className="col-md-8">
  <div
    className="table-responsive-md mt-3"
    id="salesOrderItemsTablePaid"
    style={{
      backgroundColor: "#A9A9A9", // Dark gray color
      border: "1px solid rgba(128, 128, 128, 0.6)",
      padding: "20px",
    }}
  >
    {/* Sub Total */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Sub Total</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          step="any"
          name="subtotal"
          value={subTotal}
          readOnly
          className="form-control"
          style={{ backgroundColor: "#f1f1f1" }}
          id="sub_total"
        />
      </div>
    </div>

    {/* IGST */}
    {showIGST && (
      <div className="row container-fluid p-2 m-0">
        <div className="col-sm-4 mt-2 text-right">
          <label>IGST</label>
        </div>
        <div className="col-sm-1 mt-2 text-center">:</div>
        <div className="col-sm-7 mt-2">
          <input
            type="number"
            name="igst"
            step="any"
            id="igstAmount"
            value={Number(taxAmount).toFixed(2)} // Calculate IGST
            readOnly
            className="form-control"
            style={{ backgroundColor: 'white' }}
          />
        </div>
      </div>
    )}

    {/* CGST */}
    {showCGSTSGST && (
      <div className="row container-fluid p-2 m-0">
        <div className="col-sm-4 mt-2 text-right">
          <label>CGST</label>
        </div>
        <div className="col-sm-1 mt-2 text-center">:</div>
        <div className="col-sm-7 mt-2">
          <input
            type="number"
            name="cgst"
            step="any"
            id="cgstAmount"
            value={(Number(taxAmount) / 2).toFixed(2)} // Calculate CGST
            readOnly
            className="form-control"
            style={{ backgroundColor: 'white' }}
          />
        </div>
      </div>
    )}

    {/* SGST */}
    {showCGSTSGST && (
      <div className="row container-fluid p-2 m-0">
        <div className="col-sm-4 mt-2 text-right">
          <label>SGST</label>
        </div>
        <div className="col-sm-1 mt-2 text-center">:</div>
        <div className="col-sm-7 mt-2">
          <input
            type="number"
            name="sgst"
            step="any"
            id="sgstAmount"
            value={(Number(taxAmount) / 2).toFixed(2)} // Calculate SGST
            readOnly
            className="form-control"
            style={{ backgroundColor: 'white' }}
          />
        </div>
      </div>
    )}

    {/* Tax Amount */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Tax Amount</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          step="any"
          name="taxamount"
          id="tax_amount"
          value={Number(taxAmount).toFixed(2)} // Ensure taxAmount is a number
          readOnly
          className="form-control"
          style={{ backgroundColor: "#f1f1f1" }}
        />
      </div>
    </div>

    {/* Shipping Charge */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Shipping Charge</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="text"
          step="any"
          name="ship"
          id="ship"
          value={formData.shipping_charge}
          onChange={(e) => handleShippingCharge(e.target.value)}
          className="form-control"
        />
      </div>
    </div>

    {/* Adjustment */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Adjustment</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          step="any"
          name="adj"
          id="adj"
          value={adjustment}
          onChange={(e) => handleAdjustment(e.target.value)}
          className="form-control"
        />
      </div>
    </div>

    {/* Grand Total */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Grand Total</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          name="grandtotal"
          id="grandtotal"
          value={grandTotal}
          readOnly
          className="form-control"
          style={{ backgroundColor: "#f1f1f1" }}
        />
      </div>
    </div>
  </div>

  {/* New Box for Paid Off and Balance */}
  <div
    className="table-responsive-md mt-3"
    id="salesOrderPaidBalance"
    style={{
      backgroundColor: "#A9A9A9", // Dark gray color
      border: "1px solid rgba(128, 128, 128, 0.6)",
      padding: "20px",
    }}
  >
    {/* Paid Off */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Paid Off</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          step="any"
          name="advance"
          id="advance"
          value={paid}
          onChange={(e) => handlePaid(e.target.value)}
          className="form-control"
        />
      </div>
    </div>

    {/* Balance */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Balance</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          name="balance"
          id="balance"
          value={balance}
          readOnly
          className="form-control"
          style={{ backgroundColor: "#f1f1f1" }}
        />
      </div>
    </div>
  </div>
</div>
 




    {/* Note and File Upload - Aligned Below the Balance Section */}
    <div className="row mt-3">
    
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="file">Upload File</label>
          <input
            type="file"
            className="form-control"
            id="file"
            name="file"
            onChange={handleFileChange}
          />
        </div>
      </div>
    </div>
  </div>
</div>


                        {/* Status */}
                      
                      </div>
                      <div className="col-md-12 text-right" >
                      <input
        type="button"
        className="btn btn-outline-secondary mr-2"
        value="Cancel"
        onClick={() => navigate("/Salesorder/")}
        style={{ height: "fit-content", width: "150px" }}
      />
  <input
    type="submit"
    className="btn btn-outline-secondary"
    onClick={() => setStatus("Saved")}
    value="convert"
    style={{ height: "fit-content", width: "150px" }} 
  />
</div>
                    </form>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    

    



  );
}

export default SALESTORECURRING;
