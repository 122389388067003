import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import * as XLSX from "xlsx";

function Add_salarydetails(){
    const ID = Cookies.get("user_id");
    const user = Cookies.get("role");
    const [employees, setEmployees] = useState([]);
    const [attendence, setAttendence] = useState([]);
    
   
    const navigate = useNavigate();

    const fetchsalarydetailsData = () => {
      axios
        .get(`${config.base_url}/fetch_salarydetails_data/${ID}/`)
        .then((res) => {
          console.log("SAL Data==", res);
          if (res.data.status) {
            let emp = res.data.emp;
            let att=res.data.att;
            
          
            att.map((v) => {
              setAttendence((prevState) => [...prevState, v]);
            });
            setEmployees([]);
            
          const newEmpOptions = emp.map((item) => ({
            label: item.first_name + " " + item.last_name,
            value: item.id,
          }));
          setEmployees(newEmpOptions);
            
           
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      fetchsalarydetailsData();
    }, []);

    var currentDate = new Date();
      var formattedDate = currentDate.toISOString().slice(0, 10);
    
      const [employee, setEmployee] = useState("");
      const [employeeName, setEmployeeName] = useState("");
      const [date, setDate] = useState(formattedDate);
      const [employeeEmail, setEmployeeEmail] = useState("");
      const [description, setDescription] = useState("");        
      const [status, setStatus] = useState("");
      const [leave, setLeave] = useState("");        
      const [holiday, setHoliday] = useState("");    
      const [workingDays, setWorkingDays] = useState(""); 
      const [casualleave, setCasualleave] = useState(0);
      const [HRA, setHra] = useState(0);
      const [otherallowance, setOtherallowance] = useState(0);
      const [othercuttings, setOthercuttings] = useState(0); 
      const [addbonous, setAddbonous] = useState(0);
      const [conveyanceallowance, setConveyanceallowance] = useState(0); 
      const [basicsalary, setBasicsalary] = useState(0);  
      const [month, setMonth] = useState("");  
      const [salary, setSalary] = useState(0);
      const currentYear = new Date().getFullYear();
      const [year, setYear] = useState(currentYear);
      const monthOptions = [
        'January', 'February', 'March', 'April', 'May', 'June', 
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
      useEffect(() => {
        const today = new Date();
        setMonth(monthOptions[today.getMonth()]);
      }, []); 

      const generateYearOptions = () => {
        let years = [];
        for (let i = currentYear - 10; i <= currentYear + 10; i++) {
          years.push(i);
        }
        return years;
      };
    
      const yearOptions = generateYearOptions();
    
      const handleYearChange = (e) => {
        setYear(e.target.value);
        getemployeeData(employee, e.target.value, monthOptions.indexOf(month) + 1);

      };
      const handleMonthChange = (e) => {
        setMonth(e.target.value);
        getemployeeData(employee, year, monthOptions.indexOf(e.target.value) + 1);
      };
   
      const handleemployeechange = (value) => {
        setEmployee(value);
        getemployeeData(value, year, monthOptions.indexOf(month) + 1);
      };
      
      const getemployeeData = (employee, year, month) => {
        if (employee === "") {
          resetFields();
          return;
        }
        var cst = {
          Id: ID,
          v_id: employee,
          month: month,
          year: year
        };
      
        if (employee != "") {
          axios
            .get(`${config.base_url}/altos_get_employee_data/`, { params: cst })
            .then((res) => {
              if (res.data.status) {
                setFirstName("");
                setLastName("");
                setEmail("");
                setEmployee_Number("");
                setDesignation("");
                setSalaryAmount("");
                setJoiningDate("");
                setLeave(0);
                setHoliday(0);
                setWorkingDays(0);
                
                
                var sal = res.data.employeeDetails;
                setFirstName(sal.first_name);
                setLastName(sal.last_name);
                setEmail(sal.email);
                setEmployee_Number(sal.employeeid);
                setDesignation(sal.designation)
                setSalaryAmount(sal.salary)
                setJoiningDate(sal.joindate)
                setLeave(sal.leave);         
                setHoliday(sal.holiday);      
                setWorkingDays(sal.working_days);
      
                
              }
            })
            .catch((err) => {
              console.log("ERROR", err);
            });
        } else {
          setFirstName("");
          setLastName("");
          setEmail("");
          setEmployee_Number("");
          setDesignation("");
          setSalaryAmount("");
          setJoiningDate("");
          setLeave(0);
          setHoliday(0);
          setWorkingDays(0);
      
      
        }
      } 
      
      const resetFields = () => {
        setLeave(0);
        setHoliday(0);
        setWorkingDays(0);
      };

      // const calculateSalary = () => {
      //   const totalDaysInMonth = parseFloat(workingDays) + parseFloat(leave) ;

      //   if (totalDaysInMonth === 0 || workingDays > totalDaysInMonth) {
      //     alert("Please enter a valid number of working days, leave, and holidays.");
      //     return;
      //   }
      //   const totalSalary =
      //     (parseFloat(basicsalary) +
      //     parseFloat(HRA) +
      //     parseFloat(otherallowance) +
      //     parseFloat(conveyanceallowance) +
      //     parseFloat(addbonous) -
      //     parseFloat(othercuttings))* (parseFloat(workingDays) / totalDaysInMonth);
          
      //   setSalary(totalSalary.toFixed(2));
      // };
     


      const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
 
        const monthIndex = monthOptions.indexOf(month) + 1;
        const formattedDate = new Date(date).toISOString().split('T')[0];

        if (monthIndex === 0) {
            console.error("Invalid month name:", month);
            return;
        }
        formData.append("date", formattedDate); 
        formData.append("status", status);
        formData.append("Id", ID);
        formData.append("date", date); 
        formData.append("v_id", employee); 
        formData.append("first_name", employeeName);
        formData.append("email", employeeEmail);
        formData.append("working_days", workingDays);
        formData.append("leave", leave);
        formData.append("holiday", holiday);
        formData.append("HRA", HRA);
        formData.append("casualleave", casualleave);
        formData.append("otherallowance", otherallowance);
        formData.append("othercuttings", othercuttings);
        formData.append("conveyanceallowance", conveyanceallowance);
        formData.append("basicsalary", basicsalary);
        formData.append("month", monthIndex);
        formData.append("salary", salary);
        formData.append("addbonous", addbonous);
        formData.append("year", year);



        axios
        .post(`${config.base_url}/create_new_salarydetails/`, formData)
        .then((res) => {
          console.log("NEW RES=", res);
          if (res.data.status) {
            setEmployees(res.data.status);
          
            Toast.fire({
              icon: "success",
              title: "Salary details Created",
            });
            navigate("/salarydetails");
          }
          if (!res.data.status && res.data.message != "") {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    };
    
    const calculateSalary = () => {
      // Get the total days in the selected month using date-fns or any other date library
      const parsedBasicSalary = parseFloat(basicsalary) || 0;
      const parsedHra = parseFloat(HRA) || 0;
      const parsedAllowance = parseFloat(otherallowance) || 0;
      const parsedConveyanceAllowance = parseFloat(conveyanceallowance) || 0;
      const parsedCutting = parseFloat(othercuttings) || 0;
      const parsedBonus = parseFloat(addbonous) || 0;
      const parsedLeave = parseFloat(leave) || 0;
      const parsedCasualLeave = parseFloat(casualleave) || 0;
      const parsedHoliday = parseFloat(holiday) || 0;

  
      // Get the total days in the selected month using date-fns or any other date library
      const numDays = parseFloat(workingDays); // For current year and month
  
      // Calculate daily wage (avoid dividing by zero)
      const dailyWage = numDays > 0 ? parsedBasicSalary / numDays : 0;
  
      // Calculate leave deductions (paid leave is added back later)
      const leaveDeduction = dailyWage * parsedLeave;
      const total_deduction= (parsedCutting+leaveDeduction);
  
      // Calculate the monthly salary
      let monthlySalary =
    (parsedBasicSalary - leaveDeduction) +
    (parsedHra + parsedConveyanceAllowance + parsedAllowance + parsedBonus - parsedCutting);

  setSalary(Math.round(monthlySalary)); // Set final salary after rounding
};
  
    

      // NEW employee

    const [bloodGroups, setBloodGroups] = useState([]);
    const [newUnit, setNewUnit] = useState("");

    const fetchBloodGroups = () => {
      axios
        .get(`${config.base_url}/altos_createbloodgroupdata/${ID}/`)
        .then((res) => {
          if (res.data.status) {
            setBloodGroups(res.data.bloodgp);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
  
    useEffect(() => {
      fetchBloodGroups();
    }, []);
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
  
    function handleUnitModalSubmit(e) {
      e.preventDefault();
      var name = newUnit;
      console.log(name)
      if (name != "") {
        var u = {
          Id: ID,
          blood_group: newUnit,
        };
        console.log(u)
        axios
          .post(`${config.base_url}/altos_create_new_bloodgroup/`, u)
          .then((res) => {
            console.log("UNIT RES=", res);
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "bloodgroup Created",
              });
              fetchBloodGroups();
              setNewUnit("");
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      } else {
        alert("Invalid");
      }
    }
    const [sameAddress, setSameAddress] = useState(false);
    const [tdsApplicable, setTdsApplicable] = useState('');
    const [tdsType, setTdsType] = useState('');
    const [bankDetails, setBankDetails] = useState('');
    const [transactionType, setTransactionType] = useState('');
    const [age, setAge] = useState('');
    const [joiningDate, setJoiningDate] = useState('');
    const [isBankInfoVisible, setIsBankInfoVisible] = useState(false);
    const [isTdsInfoVisible, setIsTdsInfoVisible] = useState(false);
    const [isTdsPercentageVisible, setIsTdsPercentageVisible] = useState(false);
    const [isTdsAmountVisible, setIsTdsAmountVisible] = useState(false);
    const [salaryType, setSalaryType] = useState('');
    const [amountPerHour, setAmountPerHour] = useState(0);
    const [workingHours, setWorkingHours] = useState(0);
    const [salaryAmount, setSalaryAmount] = useState('');
    const [presentAddress, setPresentAddress] = useState({
      address: '',
      city: '',
      state: '',
      pincode: '',
      country:'',
    });
    const [permanentAddress, setPermanentAddress] = useState({
      address: '',
      city: '',
      state: '',
      pincode: '',
      country:'',
    
    });
    const [Title, setTitle] = useState('');
    const [First_Name, setFirstName] = useState('');
    const [Last_Name, setLastName] = useState('');
    const [Alias, setAlias] = useState('');

    const [Email, setEmail] = useState("");
    const [Employee_Number, setEmployee_Number] = useState("");
    const [Designation, setDesignation] = useState("");
    const [CurrentLocation, setCurrentLocation] = useState("");
    const [Gender, setGender] = useState("");
    const [DOB, setDOB] = useState("");
    const [Blood, setBlood] = useState("");
    const [parent, setparent] = useState("");
    const [Spouse, setSpouse] = useState("");
    const [Number2, setNumber2] = useState("");
    const [Account_Number, setAccountNumber] = useState("");
    const [IFSC, setIFSC] = useState("");
    const [BankName, setBankName] = useState("");
    const [branch_name, setBranchName] = useState("");
    const [PAN, setPAN] = useState("");
    const [PR, setPR] = useState(0);
    const [UAN, setUAN] = useState("");
    const [PF, setPF] = useState("");
    const [Income_Tax, setIncome_Tax] = useState("");
    const [Aadhar, setAadhar] = useState(0);
    const [ESI, setESI] = useState("");
    const [salary_details, setSalaryDetails] = useState({});
    const [TDS_Amount, setTdsAmount] = useState('0');
    const [TDS_Percentage, setTdsPercentage] = useState('0');
    const [formData, setFormData] = useState({});
    const [Salary_Date, setSalaryDate] = useState(new Date());
    const [contact, setContact] = useState('');
    const [image, setimage] = useState('');

    const [file, setFile] = useState(null);
    
    const handleImageChange= (e) => {
      const image = e.target.files[0];
      setimage(image);
    };
    
  
    
    const handleSubmitemployee = (e) => {
      e.preventDefault();  

      var dt = {
        Id: ID,
        Title: Title,
        First_Name:First_Name,
        
      Last_Name:Last_Name,
      Joining_Date:joiningDate,
      Salary_Date: Salary_Date,
      Salary_Type: salaryType,
      Salary_Amount: salaryAmount,
      Amount_Per_Hour: amountPerHour,
      Working_Hours: workingHours,
      Alias: Alias,
      Employee_Number: Employee_Number,
      Designation: Designation,
      Location: CurrentLocation,
      Gender: Gender,
      DOB: DOB,
      Age: age,
      Blood_Group: Blood,
      Contact_Number: contact,
      Emergency_Contact_Number: Number2,
      Personal_Email: Email,
      Parent_Name: parent,
      Spouse_Name: Spouse,
      file: file,
      image: image,
      Bank_Details: bankDetails,

      TDS_Applicable: tdsApplicable,
      Account_Number: Account_Number,
      IFSC: IFSC,
      Bank_Name: BankName,
      Branch_Name: branch_name,
      Transaction_Type: transactionType,
      TDS_Type: tdsType,
      TDS_Amount: TDS_Amount,
      TDS_Percentage: TDS_Percentage,

      Present_Address: JSON.stringify(presentAddress),
      Permanent_Address: JSON.stringify(permanentAddress),
      PAN: PAN,
      Income_Tax: Income_Tax,
      Aadhar:Aadhar,
      UAN: UAN,
      PF:PF,
      PR: PR,

      };
        
      axios.post(`${config.base_url}/altos_create_new_employee/`, dt)
      
      .then((res) => {
        console.log("ITM RES=", res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Employee Created",
          });
          navigate("/add_salarydetails");
        }
        if (!res.data.status && res.data.message != "") {
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }; 
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'Title':
        setTitle(value);
        break;
      case 'First_Name':
        setFirstName(value);
        break;
      case 'Last_Name':
        setLastName(value);
        break;
      case 'Alias':
        setAlias(value);
        break;
      case 'Salary_Date':
        setSalaryDate(value);
        break;
      case 'Joining_Date':
        setJoiningDate(value);
        break;
      case 'Employee_Number':
        setEmployee_Number(value);
        break;
      case 'Designation':
        setDesignation(value);
        break;
      case 'Location':
        setCurrentLocation(value);
        break;
      case 'Gender':
        setGender(value);
        break;
      case 'Blood':
        setBlood(value);
        break;
      case 'Contact_Number':
        setContact(value);
        break;
      case 'Emergency_Contact_Number':
        setNumber2(value);
        break;
      case 'Personal_Email':
        setEmail(value);
        break;
      case 'Parent':
        setparent(value);
        break;
      case 'Spouse':
        setSpouse(value);
        break;
      case 'Bank_Details':
        setBankDetails(value);
        break;
      case 'tds_applicable':
        setTdsApplicable(value);
        break;
      case 'Income_Tax':
        setIncome_Tax(value);
        break;
      case 'Aadhar':
        setAadhar(value);
        break;
      case 'UAN':
        setUAN(value);
        break;
      case 'PF':
        setPF(value);
        break;
      case 'PAN':
        setPAN(value);
        break;
      case 'PR':
        setPR(value);
        break;
      case 'ESI':
        setESI(value);
        break;
      case 'Account_Number':
        setAccountNumber(value);
        break;
      case 'IFSC':
        setIFSC(value);
        break;
      case 'BankName':
        setBankName(value);
        break;
      case 'BranchName':
        setBranchName(value);
        break;
      case 'Transaction_Type':
        setTransactionType(value);
        break;
      case 'TDS_Type':
        setTdsType(value);
        break;
      case 'TDS_Percentage':
        setTdsPercentage(value);
        break;
      case 'TDS_Amount':
        setTdsAmount(value);
        break;
    
      default:
        break;
  }
};



useEffect(() => {
  setJoiningDate(new Date().toISOString().split('T')[0]);
}, []);

useEffect(() => {
  if (sameAddress) {
    setPermanentAddress({ ...presentAddress });
  }
}, [sameAddress, presentAddress]);

useEffect(() => {
  if (bankDetails === 'Yes') {
    setIsBankInfoVisible(true);
  } else {
    setIsBankInfoVisible(false);
  }
}, [bankDetails]);

useEffect(() => {
  if (tdsApplicable === 'Yes') {
    setIsTdsInfoVisible(true);
  } else {
    setIsTdsInfoVisible(false);
  }
}, [tdsApplicable]);

useEffect(() => {
  if (tdsType === 'Percentage') {
    setIsTdsPercentageVisible(true);
    setIsTdsAmountVisible(false);
  } else if (tdsType === 'Amount') {
    setIsTdsPercentageVisible(false);
    setIsTdsAmountVisible(true);
  } else {
    setIsTdsPercentageVisible(false);
    setIsTdsAmountVisible(false);
  }
}, [tdsType]);



const handleFileChange = (e) => {
  const file = e.target.files[0];
  setFile(file);
};

const handlePresentAddressChange = (e) => {
  const { name, value } = e.target;
  setPresentAddress((prev) => ({
    ...prev,
    [name]: value,
  }));
};

const handlePermanentAddressChange = (e) => {
  const { name, value } = e.target;
  setPermanentAddress((prev) => ({
    ...prev,
    [name]: value,
  }));
};

const handleSameAddressChange = () => {
  setSameAddress((prev) => !prev);
};

const handleSalaryTypeChange = (e) => {
  setSalaryType(e.target.value);
  // Reset salaryAmount if switching from Time Based to Fixed or Temporary
  if (e.target.value !== 'Time Based') {
    setSalaryAmount('');
  }
};

const handleSalaryAmountChange = (e) => {
  setSalaryAmount(e.target.value);
};

const handleAmountPerHourChange = (e) => {
  setAmountPerHour(e.target.value);
};

const handleWorkingHoursChange = (e) => {
  setWorkingHours(e.target.value);
};

useEffect(() => {
  if (salaryType === 'Time Based') {
    if (amountPerHour > 0 && workingHours > 0) {
      setSalaryAmount(amountPerHour * workingHours);
    } else {
      setSalaryAmount('0');
    }
  }
}, [salaryType, amountPerHour, workingHours]);

const handleDOBChange = (e) => {
  const dob = e.target.value;
  setDOB(dob);
  setAge(calculateAge(dob));
};

const calculateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};



  










    return(
        <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper d-flex">
          <Sidebar />
          <div
        className="main-panel px-3 py-2"
        style={{ background: "#a9a9a961", maxWidth: "100vw" }}
      >
        <Link
          className="d-flex justify-content-end p-2"
          style={{ cursor: "pointer" }}
          to="/salarydetails"
        >
          <i
            className="fa fa-times-circle text-white"
            style={{ fontSize: "1.2rem" }}
          ></i>
        </Link>
                  <div className="card radius-15 h-20 mt-3">
                    <div className="row w-100">
                      <div className="col-md-12">
                      <center>
                <h2 className="mt-3">ADD SALARY DETAILS</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        <form
          className="needs-validation px-1"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <div className="card radius-15 mt-3" style={{ minWidth: "100%" }}>
            <div className="card-body">
              <div id="salesOrder">
             
                <hr />

                <div className="row">
                  

                  <div className="col-md-6 mt-3">
                    <label className="">Employee*</label>
                    <div className="d-flex align-items-center">
                    <Select
                        options={employees}
                      
                        name="employee"
                        className="w-100"
                        id="employee"
                        required
                        onChange={(selectedOption) =>
                          handleemployeechange(
                            selectedOption ? selectedOption.value : ""
                          )
                        }
                        isClearable
                        isSearchable
                      />
                      <a
                        className="btn btn-outline-secondary ml-1"
                        role="button"
                        data-target="#add_employee"
                        data-toggle="modal"
                        style={{ width: "fit-content", height: "fit-content" }}
                        id="termsadd"
                      >
                        +
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Employee Id</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="employee_number"

                       id="amount"
                       step="any"
                       value={Employee_Number}
                     
                       placeholder="Employee Id"
                     
                       readOnly
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Email</label>
                    <div className="d-flex">
                     
                     <input
                       type="email"
                       className="form-control ml-1"
                       name="email"
                       id="email"
                       step="any"
                       value={Email}
                    
                       placeholder="Email"
                       readOnly
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Join Date</label>
                    <div className="d-flex">
                     
                     <input
                       type="date"
                       className="form-control ml-1"
                       name="joiningDate"
                       id="joiningDate"
                       step="any"
                       value={joiningDate}
                      
                       required
                     />
                   </div>
                  </div>
                  

                  <div className="col-md-6 mt-3">
                    <div className="d-flex">
                      <label className="">Designation</label>
                      <span className="text-danger ml-3" id="EXPNoErr"></span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name="Designation"
                      id="Designation"
                      value={Designation}
                   
                      readOnly
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Salary</label>
                    <input
                      type="text"
                      className="form-control"
                      name="salaryAmount"
                      value={salaryAmount}
                    style={{backgroundColor:"white"}}
                      readOnly
                    />
                  </div>

                  <div className="col-md-6 mt-3">
                    <label>Salary Date</label>
                    <div className="d-flex">
                     
                      <input
                        type="date"
                        className="form-control ml-1"
                        name="salary"
                        id="salary"
                      
                       value={date}
                       onChange={(e) => setDate(e.target.value)}
                      
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Month</label>
                    <div className="d-flex">
                     
                    <select value={month} 
                    className="form-control"

                    onChange={handleMonthChange}>
                   {monthOptions.map((monthOption, index) => (
              <option key={index} value={monthOption}>
                {monthOption}
              </option>
                ))}
              </select>
                   </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <label className="">Year</label>
                    <div className="d-flex">
                    <select id="year" 
                    className="form-control"
                    value={year} onChange={handleYearChange}>
                  {yearOptions.map((yearOption) => (
                    <option key={yearOption} value={yearOption}>
                      {yearOption}
                    </option>
                  ))}
                  
                </select>
        
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Leave</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="leave"
                       id="leave"
                       step="any"
                       value={leave} 
                     
                       
                       readOnly
                     />
                   </div>
                  </div>
                  
                  <div className="col-md-6 mt-3">
                    <label className="">Casual Leave</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="casualleave"
                       id="casualleave"
                       step="any"
                       value={casualleave}
                       onChange={(e) => setCasualleave(e.target.value)}
                 
                     
                       required
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Holiday</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="holiday"
                       id="holiday"
                       step="any"
                       value={holiday}
                    
                     
                       readOnly
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Working Day</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="working_days"
                       id="working_days"
                       step="any"
                       value={workingDays}
                       onChange={(e) => setWorkingDays(e.target.value)}
                  
                     
                       readOnly
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Basic Salary</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="basicsalary"
                       id="basicsalary"
                       step="any"
                       value={basicsalary}
                       onChange={(e) => setBasicsalary(e.target.value)}
                    
                     
                       required
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">HRA</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="HRA"
                       id="HRA"
                       step="any"
                       value={HRA}
                       onChange={(e) => setHra(e.target.value)}
                    
                     
                       required
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Conveyance Allowance</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="coveyanaceallowance"
                       id="coveyanaceallowance"
                       step="any"
                       value={conveyanceallowance}
                       onChange={(e) => setConveyanceallowance(e.target.value)}
                 
                     
                       required
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Other Allowance</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="otherallowance"
                       id="otherallowance"
                       step="any"
                       value={otherallowance}
                       onChange={(e) => setOtherallowance(e.target.value)}
                      
                     
                       required
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Other Cuttings</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="othercuttings"
                       id="othercuttings"
                       step="any"
                       value={othercuttings}
                       onChange={(e) => setOthercuttings(e.target.value)}
                       
                     
                       required
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Add Bonous</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="addbonous"
                       id="addbonous"
                       step="any"
                       value={addbonous}
                       onChange={(e) => setAddbonous(e.target.value)}
                       
                     
                       required
                     />
                   </div>
                  </div>
                  <div className="col-md-6 mt-3">
                    <label className="">Salary</label>
                    <div className="d-flex">
                     
                     <input
                       type="number"
                       className="form-control ml-1"
                       name="salary"
                       id="salary"
                       step="any"
                       value={salary}
           
                       readOnly
                     />
                   </div>
                   <button
                      type="button" 
                   className="btn btn-outline-secondary w-50 mt-3"
                   onClick={calculateSalary}>Calculate Salary</button>
                  </div>
                </div>
                
                

                
                <div className="row clearfix" style={{ marginTop: "20px" }}>
                  <div className="col-md-6">
                    <label>Description</label>
                    <textarea
                      className="form-control mt-3"
                      id=""
                      name="note"
                      placeholder="Note"
                      style={{ height: "190px" }}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-5 mt-3"></div>
                </div>

                <div className="row">
                  <div className="col-md-7 mt-3">
                    {/* <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="agreeTerms"
                        required
                        
                      />
                      <label for="agreeTerms">
                        Agree to terms and conditions
                      </label>
                      <div className="invalid-feedback">
                        You must agree before submitting.
                      </div>
                    </div> */}
                  </div>
                  <div className="col-md-5 mt-3"></div>
                </div>
                <div className="row">
                  <div className="col-md-3 mt-3"></div>
                  <div className="col-md-6 mt-3 d-flex">
                  <input
                      type="submit"
                      className="btn btn-outline-secondary w-50 "
                      onClick={() => setStatus("Draft")}
                      value="Draft"
                      style={{ height: "fit-content" }}
                    />
                    <input
                      type="submit"
                      className="btn btn-outline-secondary w-50 ml-1 "
                      onClick={() => setStatus("Saved")}
                      value="Save"
                      style={{ height: "fit-content" }}
                    />
                  </div>
                  <div className="col-md-3 mt-3"></div>
                </div>
                </div>
            </div>
          </div>
        </form>
      </div>

      {/* Add employee */}
      <div className="modal fade" id="add_employee">
        <div className="modal-dialog modal-xl">
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="m-3">New Employee</h5>
              <button
                type="button"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                // data-target="#addemployee"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form
                  method="post"
                  id="newVendorPaymentTermForm"
                  onSubmit={handleSubmitemployee}
                >
                 <div className="row w-100">
                <div className="col-md-12 mx-0">
                    
                <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label>Title</label>
                    <select
                    id="Title"
                    name="Title"
                    className="form-control"
                    onChange={handleChange}
                    value={Title}
                  >
                    <option value="">Choose...</option>
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                    <option value="Mrs">Mrs</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    placeholder="First Name"
                    type="text"
                    id="First_Name"
                    name="First_Name"
                    className="form-control"
                    onChange={handleChange}
                    value={First_Name}
                  />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    placeholder="Last Name"
                    type="text"
                    id="Last_Name"
                    name="Last_Name"
                    className="form-control"
                    onChange={handleChange}
                    value={Last_Name}
                  />
                  </div>
                  </div>
                  <div className="col-md-4">
                      <label 
                        htmlFor="Image" 
                        className="ml-5 mt-5" 
                        style={{ 
                          cursor: 'pointer', 
                          padding: '20% 35%', 
                          backgroundImage: "url('static/assets/images/upload.png')", 
                          backgroundRepeat: 'no-repeat', 
                          backgroundSize: 'contain' 
                        }}
                      ></label>
                      <br />
                      <span className="ml-5">Upload Image</span>
                      <input 
                        type="file" 
                        name="Image" 
                        id="Image" 
                        accept="image/*" 
                        style={{ display: 'none' }}
                        onChange={handleImageChange}

                      />
                    </div>
                    
                  </div> 
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Alias (optional)</label>
                        <input placeholder="Alias" type="text"
                        id="Alias"
                        name="Alias"
                        className="form-control"
                        onChange={handleChange}
                        value={Alias}
                      />
                      </div>
                      <div className="form-group">
                        <label>Date of Joining</label>
                        <input placeholder="Joining Date" required  type="date"
                        id="Joining_Date"
                        name="Joining_Date"
                        className="form-control"
                        onChange={handleChange}
                        value={joiningDate}
                      />
                      </div>
                    </div>
                    <div className="col">
                        
                      <div className="form-group">
                        <label>Salary Date</label>
                        <select 
                        id="Salary_Date"
                        name="Salary_Date"
                        className="form-control"
                        onChange={handleChange}
                        >
                          <option value="">--select--</option>
                          <option value="1-10">1-10</option>
                          <option value="11-15">11-15</option>
                          <option value="16-31">16-31</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Define salary details</label>
                        <select
                        id="Salary_Type"
                        name="Salary_Type"
                        className="form-control"
                        onChange={handleSalaryTypeChange}
                        value={salaryType}
                        
                        >
                          <option value="">--select--</option>
                          <option value="Fixed">Fixed</option>
                          <option value="Temporary">Temporary</option>
                          <option value="Time Based">Time Based</option>
                        </select>

                        {(salaryType === 'Fixed' || salaryType === 'Temporary' || salaryType === 'Time Based') && (
                          <div className="form-group" id="salary_amount">
                            <label>Salary Amount</label>
                            <input
                              placeholder="Salary Amount"
                              name="Salary_Amount"
                              id="salary_amount2"
                              type="text"
                              className="form-control"
                              value={salaryAmount}
                              onChange={handleSalaryAmountChange}
                              readOnly={salaryType === 'Time Based'}
                            />
                            
                          </div>
                        )}

                        {salaryType === 'Time Based' && (
                          <div id="salary_timebase">
                            <div className="form-group">
                              <label>Amount Per Hour</label>
                              <input
                                placeholder="Amount Per Hour"
                                name="perhour"
                              
                                id="amount_perhour"
                                type="number"
                                className="form-control"
                                value={amountPerHour}
                              onChange={handleAmountPerHourChange}
                              /> 
                            </div>
                            <div className="form-group">
                              <label>Total Working Hour(s)</label>
                              <input
                                placeholder="Total Working Hour(s)"
                                name="workhour"
                                id="working_hours"
                                type="number"
                                className="form-control"
                                value={workingHours}
                              onChange={handleWorkingHoursChange}
                              /> 
                              
                            </div>
                          </div>
                        )}
                      </div>
  
                      </div>
                      </div>
                      <br />
                  <center>
                    <p><b>General Information</b></p>
                  </center>
                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-group col">
                      <label>Employee Number</label>
                      <input placeholder="Employee Number" required  
                        type="text"
                        id="Employee_Number"
                        name="Employee_Number"
                        className="form-control"
                        onChange={handleChange}
                        value={Employee_Number}
                      />
                    </div>
                    </div>
                    <div className="form-group col">
                      <label>Designation</label>
                      <input placeholder="Designation"
                      required
                       type="text"
                        id="Designation"
                        name="Designation"
                        className="form-control"
                        onChange={handleChange}
                        value={Designation}
                        />
                        
                    </div>
                   
                    <div className="form-group col">
                      <label>Location</label>
                      <input placeholder="Current Location" name="Location" type="text" className="form-control" 
                      onChange={handleChange}
                        value={CurrentLocation}
                        />
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                    <div className="form-group col">
                      <label>Gender</label>
                      <select required
                        id="Gender"
                        name="Gender"
                        className="form-control"
                        onChange={handleChange}
                        value={Gender}
                      >
                        <option value="">--select--</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group col">
                      <label>Date Of Birth</label>

                        <label style={{ float: 'right', marginRight: '30px' }}>Age</label>
                      <div className="row">
                        <div className="col-9">
                          <input
                            placeholder="Date Of Birth"
                            type="date"
                            id="DOB"
                            name="DOB"
                            className="form-control"
                            onChange={handleDOBChange}
                            value={DOB}
                            required
                          />
                           </div>
                       
                        <div className="col-3">
                          <input id="age" disabled className="form-control" type="text" readOnly 
                          value={age}
                          />
                        </div>
                        </div>
                        
                       
                        
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group col">
                    <label>Blood Group</label>
                    <div  style={{ display: 'flex' }}>
                          <select  id="Blood"
                            name="Blood"
                            style={{ width: '80%' }}
                            required
                            className="form-control col-11"
                            onChange={handleChange}
                            value={Blood}
                          >
                            <option value="">--select--</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                            {bloodGroups.map((bloodGroup) => (
                              <option key={bloodGroup.id} value={bloodGroup.blood_group}>{bloodGroup.blood_group}</option>
                            ))}

                       
                            </select>
                            <button
                                type="button"
                                className="btn btn-outline-secondary text-grey mt-0 mb-2 ml-1"
                                data-toggle="modal"
                                data-target="#createNewUnit"
                                style={{
                                  width: "fit-content",
                                  height: "fit-content",
                                }}
                              >
                                +
                              </button>
                            </div>
                            
                           
                    </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                  <div className="form-group col">
                      <label>Contact Number</label>
                      <input placeholder="Contact Number" required name="Contact_Number" type="text"   pattern="^\d{10}$"
                      className="form-control" 
                      onChange={handleChange}
                        value={contact} 
                        />
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group col">
                    
                      <label>Emergency Contact Number</label>
                      <input placeholder="Emergency Contact Number" required name="Emergency_Contact_Number"   pattern="^\d{10}$"
                     type="text" className="form-control" 
                     onChange={handleChange}
                        value={Number2} 
                        />
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group col">
                    
                      <label>Email</label>
                      <input placeholder=" Email"  required name="Personal_Email"   pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                     type="email" className="form-control" 
                     onChange={handleChange}
                        value={Email} 
                        />
                    </div>
                    </div>
                    
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                  <div className="form-group col">
                      <label>Father's Name / Mother's Name</label>
                      <input placeholder="Father's Name / Mother's Name" type="text"
                        id="Parent"
                        name="Parent"
                        className="form-control"
                        onChange={handleChange}
                        value={parent} 
                        />
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group col">
                    
                      <label>Spouse's Name</label>
                      <input placeholder="Spouse's Name" name="Spouse" type="text" className="form-control" 
                      onChange={handleChange}
                       value={Spouse} 
                       /> 
                 
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group col">
                    
                    <label>File</label>
                    <input  type="file" class="form-control" name="file" 
                    onChange={handleFileChange} 
                    /> 
                    </div>
                    </div>
                    
                    </div>
                    <br />
                    <br />
                    <div className="row mt-5">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 card-title">
                          <h5 className="mb-0">Permanent Address</h5>
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label htmlFor="city">Street</label>
                            <input
                            placeholder="street"
                            type="text"
                            name="address"
                            className="form-control"
                            id="perStreet"
                            value={presentAddress.address}
                            onChange={handlePresentAddressChange}
                            
                            />
                           
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="vendstate">City</label>
                            <input
                            placeholder="city"
                            type="text"
                            name="city"
                            className="form-control"
                            id="perCity"
                            value={presentAddress.city}
                            onChange={handlePresentAddressChange}
                        
                            />
                            
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="vpinco">State</label>
                            <input
                            placeholder="state"
                            type="text"
                            name="state"
                            className="form-control"
                            id="perState"
                            value={presentAddress.state}
                            onChange={handlePresentAddressChange}
             
                        />
                           
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="vcountry">Pin code</label>
                            <input
                            placeholder="pincode"
                            type="text"
                            name="pincode"
                            className="form-control"
                            id="perPincode"
                            value={presentAddress.pincode}
                            onChange={handlePresentAddressChange}
                            
                            />
                           
                          </div>
                        </div>
                       
                        <div className="col-md-12 mt-3">
                          <div className="form-row">
                            <label htmlFor="street">Country</label>
                            <input
                            placeholder="country"
                            type="text"
                            name="country"
                            className="form-control"
                            id="perCountry"
                              value={presentAddress.country}
                              onChange={handlePresentAddressChange}
                            
                            
                            />
                           
                          </div>
                        </div>
                      </div>
                      
                      </div>
                    
                    
                    
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 d-flex">
                          <h5>Temporary Address</h5>
                          <input
                            className="ml-4 ml-5"
                            type="checkbox"
                            id="sameAddress"
                            checked={sameAddress}
                            onChange={handleSameAddressChange}
                          />
                          <label
                            className="ml-2 mt-1 ml-2"
                            for="sameAddress"
                          >
                            Same As Permanent Address
                          </label>
                        </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label htmlFor="shipcity">Street</label>
                            <input
                            placeholder="street"
                            type="text"
                            name="address"
                            className="form-control"
                            id="temStreet"
                            value={permanentAddress.address}
                            onChange={handlePermanentAddressChange}
                            disabled={sameAddress}
            
            />
                            
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="vendshipstate">City</label>
                            <input
                            placeholder="city"
                            type="text"
                            name="city"
                            className="form-control"
                            id="temCity"
                            value={permanentAddress.city}
                            onChange={handlePermanentAddressChange}
                            disabled={sameAddress}
             
            />
                           
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="vshippinco">State</label>
                            <input
                            placeholder="state"
                            type="text"
                            name="state"
                            className="form-control"
                            id="temState"
                            value={permanentAddress.state}
                            onChange={handlePermanentAddressChange}
                            disabled={sameAddress}
          
                          />
                          
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="vshipcountry">Pin code</label>
                            <input
                            placeholder="pincode"
                            type="text"
                            name="pincode"
                            className="form-control"
                            id="temPincode"
                            value={permanentAddress.pincode}
                            onChange={handlePermanentAddressChange}
                            disabled={sameAddress}
                            
                            />
                           
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="form-row">
                            <label htmlFor="street">Country</label>
                            <input
                            placeholder="country"
                            type="text"
                            name="country"
                            className="form-control"
                            id="perCountry"
                              value={presentAddress.country}
                              onChange={handlePresentAddressChange}
                            
                            
                            />
                           
                          </div>
                          
                        </div>
                      
                 
                        
                      </div>
                      
                    </div>
                    
                  </div>
                  <div className="row">
                  <div className="col-md-6 mt-3">
                  <div className="form-row">
                    <label>Provide bank Details</label>
                    <select className="form-control" name="Bank_Details" 
                    value={bankDetails} onChange={(e) => setBankDetails(e.target.value)}
                    >
                        <option value="">--select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                    </div>
                    </div>
                    <div className="col-md-6 mt-3">
                  <div className="form-row">
                  <label>TDS Applicable</label>
                    <select className="form-control" name="tds_applicable" 
                    value={tdsApplicable} onChange={(e) => setTdsApplicable(e.target.value)}
                      >
                        <option value="">--select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                    </div>
                    </div>
                    
                    </div>
                    {isBankInfoVisible && (
        <div className="row">
          <div className="form-group col" id="BankInfo">
            <center>
              <p><b>Banking Information</b></p>
            </center>
            <div>
              <label>Account Number</label>
              <input placeholder="Account Number" name="Account_Number" type="text" className="form-control" 
              onChange={handleChange}
                        value={Account_Number} 
                        />
            </div>
            <div>
              <label>IFSC</label>
              <input placeholder="SBIN0071242" pattern="^[A-Za-z]{4}0[A-Za-z0-9]{6}$" name="IFSC" type="text" className="form-control" 
              onChange={handleChange}
                        value={IFSC} 
                        />
            </div>
            <div>
              <label>Name of Bank</label>
              <input placeholder="Name Of Bank" name="BankName" type="text" className="form-control"
              onChange={handleChange}
                        value={BankName} 
                        />
            </div>
            <div>
              <label>Branch Name</label>
              <input placeholder="Branch Name" name="BranchName" type="text" className="form-control" 
              onChange={handleChange}
                        value={branch_name} 
                        />
            </div>
            <center>
              <p><b>For Banking</b></p>
            </center>
            <div>
              <label>Transaction Type</label>
              <select className="form-control" name="Transaction_Type" 
              value={transactionType} onChange={(e) => setTransactionType(e.target.value)}
              >
                <option value="">--select--</option>
                <option value="ATM">ATM</option>
                <option value="Cash">Cash</option>
                <option value="Cheque">Cheque</option>
              </select>
            </div>
          </div>
        </div>
      )}

{isTdsInfoVisible && (
        <div className="row">
          <div className="form-group col" id="TDSinfo">
            <center>
              <p><b>TDS Application</b></p>
            </center>
            <div>
              <label>Percentage/Amount</label>
              <select className="form-control" id="TDStype" name="TDS_Type" 
              value={tdsType} onChange={(e) => setTdsType(e.target.value)}
              >
                <option value="">--select--</option>
                <option value="Percentage">Percentage</option>
                <option value="Amount">Amount</option>
              </select>
            </div>

            {isTdsPercentageVisible && (
              <div id="TDSpercentage">
                <label>Enter TDS Percentage</label>
                <input placeholder="TDS Percentage" name="TDS_Percentage" type="text" className="form-control"  
                onChange={handleChange}
                        value={TDS_Percentage} 
                        />
              </div>
            )}

            {isTdsAmountVisible && (
              <div id="TDSamount">
                <label>Enter TDS Amount</label>
                <input placeholder="TDS Amount" name="TDS_Amount" type="text" className="form-control"  
                onChange={handleChange}
                        value={TDS_Amount} 
                        />
              </div>
            )}
          </div>
        </div>
      )}
      <br />
                  <center>
        <p><b>Statutory Information</b></p>
      </center>
      <div className="row">
        <div className="col">
          <div>
            <label>Income Tax Number</label>
            <input placeholder="Income Tax Number" name="Income_Tax" type="text" className="form-control" 
            onChange={handleChange}
                        value={Income_Tax} 
                        />
          </div>
          <div>
            <label>Aadhar Number</label>
            <input name="Aadhar" placeholder="12 Digit Unique Number" pattern="\d{4} \d{4} \d{4}"  maxLength="14"  type="text" className="form-control" 
            onChange={handleChange}
                        value={Aadhar} 
                        />
          </div>
          <div>
            <label>Universal Account Number (UAN)</label>
            <input placeholder="12 digit number" pattern="\d{12}"  maxLength="12" name="UAN" type="text" className="form-control" 
            onChange={handleChange}
                        value={UAN} 
                        />
          </div>
        </div>
        <div className="col">
          <div>
            <label>PF Account Number</label>
            <input placeholder="MH/PUN/1234567/12" pattern="[A-Z]{2}/[A-Z0-9]{3}/[0-9]{7}/[0-9]{0,2}" name="PF"  type="text" className="form-control" 
            onChange={handleChange}
                        value={PF} 
                        />
          </div>
          <div>
            <label>PAN Number</label>
            <input placeholder="ABCDE1234F" name="PAN"  pattern="[A-Z]{5}[0-9]{4}[A-Z]" maxlength="10" type="text" className="form-control"
            onChange={handleChange}
                        value={PAN} 
                        />
          </div>
          <div>
            <label>PR Account Number</label>
            <input placeholder="12 digit number"  pattern="\d{12}"  name="PR" type="text" className="form-control" 
            onChange={handleChange}
                        value={PR} 
                        />
          </div>
       
        </div>
      </div>
                    
                  
                    
                    

                  
                      
            
              
                
                </div>
                </div>
                   
                  <div className="row mt-4 w-100">
                    <div className="col-4"></div>
                    <div className="col-4 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-secondary text-grey w-75"
                       
                        data-toggle="modal"
                        data-target="#add_employee"
                        type="button"
                        onClick={handleSubmitemployee}
                        id="saveVendorPaymentTerm"
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-4"></div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* 
      blood group */}
      <div className="modal fade" id="createNewUnit">
  <div className="modal-dialog">
    <div className="modal-content" >
      <div className="modal-header">
        <h5 className="m-3">New Blood Group</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body w-100">
        <div className="card p-3">
          <form
            onSubmit={handleUnitModalSubmit}
            id="newUnitForm"
            className="px-1"
          >
            <div className="row mt-2 w-100">
              <div className="col-12">
                <label for="name">Blood Group</label>
                <input
                  name="name"
                  id="unit_name"
                  value={newUnit}
                  onChange={(e) => setNewUnit(e.target.value)}
                  className="form-control text-uppercase w-100"
                />
              </div>
            </div>
            <div className="row mt-4 w-100">
              <div className="col-12 d-flex justify-content-center">
                <button
                  className="btn btn-outline-secondary text-grey"
                  data-dismiss="modal"
                  type="submit"
                  onClick={handleUnitModalSubmit}
                  id="saveItemUnit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


      

    
      
      

        </div>
        </div>
        

    );
}
export default Add_salarydetails;