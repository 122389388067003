import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import * as XLSX from "xlsx";

function CashInHand() {
    const ID = Cookies.get("user_id");
    const navigate = useNavigate();
    const [reportData, setReportData] = useState([]);
    const [otherDetails, setOtherDetails] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dropdownIndex, setDropdownIndex] = useState(null);
    const [dropdownPosition, setDropdownPosition] = useState({});
    const dropdownRefs = useRef([]);
  
    const handleButtonClick = () => {
      navigate('/cashinhandstatement');
    };
  
    const handleDateFilter = () => {
      fetchCustomizedData(startDate, endDate);
    };
  
    const searchTable = () => {
      const input = document.getElementById('search').value.toLowerCase().trim();
      const rows = document.querySelectorAll('#itemsTable tbody tr');
    
      rows.forEach(row => {
        const cells = Array.from(row.getElementsByTagName('td'));
        const match = cells.some(cell => 
          cell.textContent.toLowerCase().includes(input)
        );
        row.style.display = match ? '' : 'none';
      });
    };
    
    const fetchHolder = (id) => {
      if (!id) {
        console.error("Invalid ID:", id);
        return;
      }
    
      axios.get(`${config.base_url}/cash_in_hand/${id}/`)
        .then((res) => {
          if (res.data.status) {
            // Debug: Log the unsorted data
            console.log("Unsorted Data:", res.data.reportData);
    
            // Prioritize Add Cash and Reduce Cash data at the top
            const prioritizedData = res.data.reportData.sort((a, b) => {
              if (a.type === 'Add cash' || a.type === 'Reduce cash') return -1;
              if (b.type === 'Add cash' || b.type === 'Reduce cash') return 1;
              return 0;
            });
    
            // Debug: Log the sorted data
            console.log("Sorted Data:", prioritizedData);
    
            setReportData(prioritizedData);
            setOtherDetails(res.data.otherDetails);
            setStartDate(res.data.startDate);
            setEndDate(res.data.endDate);
          } else {
            Swal.fire({
              icon: "error",
              title: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          Swal.fire({
            icon: "error",
            title: "An error occurred while fetching the data.",
          });
        });
    };
  
    useEffect(() => {
      if (ID) {
        fetchHolder(ID);
      } else {
        console.error("No Login ID found in cookies");
        // Handle the case where ID is not found, e.g., redirect to login
      }
    }, [ID]);
  
    const fetchCustomizedData = () => {
      const params = {
        Id: ID,
        start_date: startDate,
        end_date: endDate,
      };
    
      axios.get(`${config.base_url}/cash_in_hand_customized/${ID}/`, { params })
        .then((res) => {
          if (res.data.status) {
            // Debug: Log the unsorted data
            console.log("Unsorted Data (Customized):", res.data.reportData);
    
            // Prioritize Add Cash and Reduce Cash data at the top
            const prioritizedData = res.data.reportData.sort((a, b) => {
              if (a.type === 'Add cash' || a.type === 'Reduce cash') return -1;
              if (b.type === 'Add cash' || b.type === 'Reduce cash') return 1;
              return 0;
            });
    
            // Debug: Log the sorted data
            console.log("Sorted Data (Customized):", prioritizedData);
    
            setReportData(prioritizedData);
            setOtherDetails(res.data.otherDetails);
            setStartDate(res.data.startDate);
            setEndDate(res.data.endDate);
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    };
  
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
  
    const toggleContent = () => {
      var contentDiv = document.getElementById("contentDiv");
      contentDiv.style.display = contentDiv.style.display === "block" ? "none" : "block";
    };
  
    const refreshAll = () => {
      setReportData([]);
      fetchHolder(ID);
    };
  
    const handleIconClick = (index) => {
      if (dropdownIndex === index) {
        setDropdownIndex(null);
        setDropdownPosition({});
      } else {
        setDropdownIndex(index);
        // const rect = dropdownRefs.current[index].getBoundingClientRect();
        // setDropdownPosition({
        //   top: rect.top + window.scrollY - 300, // Adjust 50 as per your requirement
        //   left: rect.left + window.scrollX - 10,
        // });
      }
    };
    const handleHistory = (id) => {
      navigate(`/cashinhand_history/${id}`);
  };
    
    const handleEdit = (id) => {
      console.log("edit id", id)
      navigate(`/edit_cashinhand/${id}`);
    };
  
    const handleDelete = (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`${config.base_url}/cash_in_hand_delete/${id}/`)
            .then((res) => {
              if (res.status === 204) { // HTTP 204 No Content
                setReportData(reportData.filter(item => item.id !== id));
                Swal.fire(
                  'Deleted!',
                  'Your record has been deleted.',
                  'success'
                );
              } else {
                Swal.fire({
                  icon: "error",
                  title: "An error occurred.",
                });
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              Swal.fire({
                icon: "error",
                title: "An error occurred while deleting the data.",
              });
            });
        }
      });
    };

  return (
    <div className="container-scroller">
  <Navbar />
  <div className="container-fluid page-body-wrapper d-flex">
    <Sidebar />
    <div
      className="main-panel"
      style={{ background: "#a9a9a961", maxWidth: "100vw" }}
    >
      <div className="content-wrapper">
        <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
          <div className="page-content">
            <div className="card radius-15 h-20">
              <div className="row">
                <div className="col-md-12">
                  <center>
                    <h2 className="mt-3">CASH IN HANDS</h2>
                  </center>
                  <hr />
                </div>
              </div>
            </div>

            <div className="card radius-15 mt-3">
              <div className="card-body">
                <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    id="search"
                    style={{ width: '250px' }}
                    className="form-control"
                    placeholder="Search.."
                    autoComplete="off"
                    onKeyUp={searchTable}
                  />
                  
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="date"
                    className="form-control mr-2"
                    value={startDate}
                    style={{ width: '150px' }}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <input
                    type="date"
                    className="form-control mr-2"
                    value={endDate}
                    style={{ width: '150px' }}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary text-dark"
                    onClick={handleDateFilter}
                    style={{ width: '150px', height: '38px' }}
                  >
                    Customize
                  </button>
                </div>
                <div className="d-flex justify-content-end">
                  <Link to="/cashinhand_graph" className="ml-1">
                    <button
                      type="button"
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="btn btn-outline-secondary text-grey"
                      id="exportBtn"
                    >
                      <i className="fa fa-line-chart"></i> Graph
                    </button>
                  </Link>
                  <div className="dropdown ml-1">
                    <button
                      type="button"
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="btn btn-outline-secondary text-grey"
                      onClick={handleButtonClick}
                    >
                      <i className="fas fa-file-invoice"></i> Statement
                    </button>
                  </div>
                  <Link to="/addcash" className="ml-1">
                    <button
                      type="button"
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="btn btn-outline-secondary text-grey"
                    >
                      <i className="fa fa-plus font-weight-light"></i> Add Cash
                    </button>
                  </Link>
                </div>
              </div>
              <h2 style={{ fontFamily: "Arial", textAlign: "end" }}>
                Total Balance: {otherDetails.totalBalance}
              </h2>
              </div>
            </div>

              <div className="table-responsive">
                <table
                  className="table table-responsive-md table-hover mt-4"
                  id="itemsTable"
                  style={{ textAlign: "center" }}
                >
                  <thead>
                    <tr>
                    <th style={{ textAlign: "center" }}>DATE</th>
                    <th>TYPE</th>
                    <th>NAME</th>
                    <th>Amount</th>
                    <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                  {reportData.map((item, index) => (
                  <tr key={index} className="clickable-row" style={{ cursor: "pointer" }}>
                    <td style={{ textAlign: "center" }}>{item.date}</td>
                    <td>{item.type }</td>
                    <td>{item.name}</td>
                    <td>{item.total}</td>
                    <td>
                    <div className="dropdown p-0 nav-item" id={`dropdown-${index}`}>
                      {item.showOptions && (
                        <li
                          className="ml-2  btn  text-grey fa fa-ellipsis-v"
                          data-toggle="dropdown"
                          onClick={() => handleIconClick(index)}
                          style={{
                            height: "38px",
                            width: "40px",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          &nbsp;
                        </li>
                      )}

                      {dropdownIndex === index && (
                        <ul
                          className="dropdown-menu"
                          style={{
                            position: 'absolute',
                            top: dropdownPosition.top,
                            left: dropdownPosition.left,
                            zIndex: 1000,
                            backgroundColor: 'white',
                            color: 'black',
                          }}
                        >
                          <li
                            className="dropdown-item"
                            style={{
                              textAlign: "center",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => handleHistory(item.id)}
                          >
                            History
                          </li>
                          <li
                            className="dropdown-item"
                            style={{
                              textAlign: "center",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => handleEdit(item.id)}
                          >
                            Edit
                          </li>
                          <li
                            className="dropdown-item"
                            style={{
                              textAlign: "center",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(item.id)}
                          >
                            Delete
                          </li>
                        </ul>
                      )}
                    </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              <style jsx>{`
                .options-icon {
                  font-size: 24px; /* Adjust the size as needed */
                  cursor: pointer;
                }
                .dropdown-menu {
                  display: none;
                }
                .dropdown-menu.show {
                  display: block;
                }
              `}</style>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  );
}

export default CashInHand;
