import React, { useEffect, useState } from 'react'
import Navbar from "../company/Navbar";
import Sidebar from "../company/Sidebar";
import axios from 'axios';
import Cookies from "js-cookie";
import config from "../../functions/config";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import Select from 'react-select';

import "../styles/BankStatement.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';



function AccountTypeSummary() {

  const ID = Cookies.get("user_id");
  
  const [accounts,setAccounts] = useState([]);
  const [company,setCompany] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
 
  const [companyName, setCompanyName] = useState("");
  


  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  



  const [creditNoteDetails, setCreditNoteDetails] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState("");
  const [totalBalance, setTotalBalance] = useState("");

  const [buttonClicked, setButtonClicked] = useState(false);


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgb(255 255 255 / 14%)",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      // width: '200px', // Adjust the width of the dropdown menu
      // maxHeight: '150px' // Adjust the height of the dropdown menu
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999 // Ensure the dropdown appears above other elements

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "lightgray"
        : state.isFocused
        ? "lightgray"
        : "white",
      color: state.isSelected ? "black" : "black",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
  };



  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
      // Position the div just below the button
      // var buttonRect = event.target.getBoundingClientRect();
      // contentDiv.style.top = (buttonRect.bottom + window.scrollY) + "px";
      // contentDiv.style.left = buttonRect.left + "px";
    }
  }


  const fetch_accounts = () =>{
    axios.get(`${config.base_url}/Fetch_Account_Type/${ID}/`).then((res)=>{
        if(res.data.status){
            setAccounts(res.data.acc);
            setCompany(res.data.com);
        }
      }).catch((err)=>{
        console.log('ERR',err)
      })
};
useEffect(()=>{
    fetch_accounts();
  },[])






  const fetchCreditNoteDetailsCustomised = (e) => {
    e.preventDefault();

    toggleContent();

    console.log("fetching credit note details")
    

    var params  = {
      id: ID,
      start_date: startDate,
      end_date: endDate,
      status: status,

    };

    // axios
    //   .get(`${config.base_url}/fetch_credit_note_details_customised/`, { params })
    //   .then((res) => {
    //     console.log("REPRT DATA=", res);
    //     setCreditNoteDetails(res.data.reportData);
    //     setTotalCustomers(res.data.otherDetails.totCustomers);
    //     setTotalBalance(res.data.otherDetails.totBalance);
    //   })
    //   .catch((err) => {
    //     console.log("ERROR=", err);
    //     if (!err.response.data.status) {
    //       Swal.fire({
    //         icon: "error",
    //         title: `${err.response.data.message}`,
    //       });
    //     }
    //   });
  }

  const [status,setStatus] = useState("");
  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      status:status
    };

    axios
      .post(`${config.base_url}/Account_Type_Customized/`, det)
      .then((res) => {
        console.log(res.data)
        if (res.data.status) {
            setAccounts(res.data.acc);
            setCompany(res.data.com);

          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };
  


  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Account_Type_Summary" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  } 



  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Account_Type_summary" + date + ".xlsx");
  }


  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }



  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;







  async function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var emailsString = emailIds.trim();
    var emails = emailsString.split(",").map((email) => email.trim());
    var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    var invalidEmails = [];

    if (emailsString === "") {
        alert("Enter valid email addresses.");
    } else {
        for (var i = 0; i < emails.length; i++) {
            var currentEmail = emails[i];
            if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
                invalidEmails.push(currentEmail);
            }
        }

        if (invalidEmails.length > 0) {
            alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
            try {
                // Generate the PDF using html2pdf
                const element = document.getElementById("printReport");
                const opt = {
                    margin: [0.5, 0.3, 0.3, 0.5],
                    filename: "Account_Type_summary.pdf",
                    image: { type: "jpeg", quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
                };

                // Convert the HTML element to PDF Blob using async/await
                const pdfBlob = await html2pdf().set(opt).from(element).toPdf().outputPdf('blob');

                // Create FormData object to send the PDF and other data
                const formData = new FormData();
                formData.append('pdf', pdfBlob, 'Account_Type_Summary.pdf');
                formData.append('Id', ID);
                formData.append('start', st);
                formData.append('end', end);
                formData.append('email_ids', emailIds);
                formData.append('email_message', emailMessage);

                // Send the form data with PDF to the backend
                const res = await axios.post(`${config.base_url}/Share_Account_Type_Summary/`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                if (res.data.status) {
                    Toast.fire({
                        icon: "success",
                        title: "Shared via mail.",
                    });
                    setEmailIds("");
                    setEmailMessage("");
                }
            } catch (err) {
                console.log("ERROR=", err);
                if (err.response && err.response.data && !err.response.data.status) {
                    Swal.fire({
                        icon: "error",
                        title: `${err.response.data.message}`,
                    });
                }
            }
        }
    }
  }


  

  



  

  return (
    <>
    <style>
      {`
        .container-fluid {
          padding: 20px;
        }

        .card {
          margin: 20px 0;
        }

        .bar {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 20px;
        }

        .btn {
          width: fit-content;
          margin: 5px 0;
        }

        @media (max-width: 768px) {
          .main-panel {
            padding: 10px;
          }

          h2 {
            font-size: 1.5rem;
          }

          .btn {
            width: 100%;
          }
        }

        .printReportTemplate {
          display: block;
        }
      `}
    </style>

    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel px-3 py-2" style={{ background: "#a9a9a961", maxWidth: "100%" }}>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
                <div className="card radius-15 h-20">
                  <div className="card-body" style={{ width: "100%" }}>
                    <div className="card-title">
                      <center>
                        <h2 className="text-uppercase" id="headline">ACCOUNT TYPE SUMMARY</h2>
                      </center>
                      <hr />
                    </div>
                    <div className="bar">
                      <div className="left d-flex justify-content-start">
                        <div className="position-relative">
                          <button className="btn btn-secondary" onClick={toggleContent}>
                            <i className="fa fa-solid fa-gear"></i> Customize Report
                          </button>
                          <div id="contentDiv" className="salescontent" style={{  display: "none",border: "2px solid black"}}>
  <form onSubmit={handleCustomize} className="form reportCustomizeForm px-1">
    <div className="px-2 w-100 mt-4" style={{height:"55px"}}>
      <label htmlFor="status" style={{ textAlign: "left" }}>Status</label>
      <select 
        name="status" 
        id="status" 
        className="form-control" 
        value={status} 
        onChange={(e) => setStatus(e.target.value)}
      >
        <option value="all">All</option>
        <option value="Asset">Asset</option>
        <option value="Liability">Liability</option>
        <option value="Equity">Equity</option>
        <option value="Income">Income</option>
        <option value="Expense">Expense</option>
      </select>
    </div>

    <div className="d-flex flex-column flex-sm-row px-2 mt-5 mb-0 w-100">
      <button 
        type="submit" 
        className="btn btn-outline-secondary text-grey w-100 w-sm-50"
      >
        Run Report
      </button>
      <button 
        type="button" 
        onClick={toggleContent} 
        className="btn btn-outline-secondary text-grey  w-100 w-sm-50"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
   </div>
                      </div>
                      <div className="right d-flex flex-wrap">
                      <Link className="btn btn-outline-secondary text-grey fa fa-file ml-2" onClick={reportPDF}>
    PDF
  </Link>
                        <Link className="btn btn-outline-secondary text-grey fa fa-file ml-2" onClick={printSection}> Print</Link>
                        <Link className="btn btn-outline-secondary text-grey fa fa-file ml-2" onClick={ExportToExcel}> Export</Link>
                        <div className="dropdown p-0 nav-item ml-2"  id="shareBtn">
                                                    <li className="dropdown-toggle btn btn-outline-secondary text-gray fa fa-share-alt" data-toggle="dropdown"> Share </li>
                                                    <ul className="dropdown-menu" id="listdiv">
                                                        <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                                                            <li style={{ textAlign: "center", color: "#e5e9ec", cursor: "pointer" }}>WhatsApp</li>
                                                        </a>
                                                        <li style={{ textAlign: "center",  color: "#e5e9ec",cursor: "pointer" }} data-toggle="modal" data-target="#shareToEmail">Email</li>
                                                    </ul>
                                                </div> 
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card card-registration card-registration-2 card radius-15 mt-3">
                  <div className="card-body p-0">
                    <div className="container-fluid">
                      <div id="printReport" className="printReportTemplate">
                        <div className="my-5 page" size="A4">
                          <div id="printdiv2">
                            <div className="py-4 bg-secondary">
                              <div className="col-12">
                                <center className="h5 text-white"><b>{company}</b></center>
                                <center className="h3 text-white"><b>Account Type Summary</b></center>
                                {startDate && endDate ? (
                                  <center className="h6 text-white">{startDate} {"TO"} {endDate}</center>
                                ) : null}
                              </div>
                            </div>
                            <div className="row px-1 py-1">
                              <div className="col-12">
                                <section className="product-area mt-2 py-1">
                                  <table className="table table-responsive-md mt-4 table-hover" id="reportTable">
                                    <thead>
                                      <tr>
                                        <th className="text-center">ACCOUNT NAME</th>
                                        <th className="text-center">ACCOUNT CODE</th>
                                        <th className="text-center">ACCOUNT TYPE</th>
                                        <th className="text-center">DEBIT AMOUNT</th>
                                        <th className="text-center">CREDIT AMOUNT</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {accounts.length > 0 ? accounts.map((p, index) => (
                                        <tr key={index}>
                                          <td className="text-center">{p.account_name}</td>
                                          <td className="text-center">{p.account_code}</td>
                                          <td className="text-center">{p.account_type}</td>
                                          <td className="text-center">0.0</td>
                                          <td className="text-center">0.0</td>
                                        </tr>
                                      )) : (
                                        <tr>
                                          <td colSpan="5" className="text-center"><h4 className="text-dark">No data available!</h4></td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </section>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Share To Email Modal */}
                <div className="modal fade" id="shareToEmail">
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="m-3">Account Type Summary</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleShareEmail} className="needs-validation px-1">
                          <div className="card p-3 w-100">
                            <div className="form-group">
                              <label htmlFor="emailIds">Email IDs</label>
                              <textarea className="form-control" name="email_ids" id="emailIds" rows="3" placeholder="Multiple emails can be added by separating with a comma(,)." value={emailIds} onChange={(e) => setEmailIds(e.target.value)} required />
                            </div>
                            <div className="form-group mt-2">
                              <label htmlFor="email_message">Message (optional)</label>
                              <textarea name="email_message" id="email_message" className="form-control" rows="4" value={emailMessage} onChange={(e) => setEmailMessage(e.target.value)} placeholder="This message will be sent along with Payment Made details." />
                            </div>
                          </div>
                          <div className="modal-footer d-flex justify-content-center w-100" style={{ borderTop: "1px solid #ffffff" }}>
                            <button type="submit" id="share_with_email" className="submitShareEmailBtn w-50 text-uppercase">SEND MAIL</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default AccountTypeSummary