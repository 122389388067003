import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import * as XLSX from "xlsx";

function Salarydetails(){
    const navigate = useNavigate();
    function exportToExcel() {
      const Table = document.getElementById("expenseTable");
      const ws = XLSX.utils.table_to_sheet(Table);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Salary Details.xlsx");
    }
  
    function sortTable(columnIndex) {
      var table, rows, switching, i, x, y, shouldSwitch;
      table = document.getElementById("expenseTable");
      switching = true;
  
      while (switching) {
        switching = false;
        rows = table.rows;
  
        for (i = 1; i < rows.length - 1; i++) {
          shouldSwitch = false;
          x = rows[i]
            .getElementsByTagName("td")
            [columnIndex].textContent.toLowerCase();
          y = rows[i + 1]
            .getElementsByTagName("td")
            [columnIndex].textContent.toLowerCase();
  
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        }
  
        if (shouldSwitch) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
        }
      }
    }
  
    function sortAmountAsc() {
      var table = document.getElementById("expenseTable");
      var rows = Array.from(table.rows).slice(1);
  
      rows.sort(function (a, b) {
        var amt1 = parseFloat(a.cells[4].textContent);
        var amt2 = parseFloat(b.cells[4].textContent);
        return amt1 - amt2;
      });
  
      // Remove existing rows from the table
      for (var i = table.rows.length - 1; i > 0; i--) {
        table.deleteRow(i);
      }
  
      // Append the sorted rows back to the table
      rows.forEach(function (row) {
        table.tBodies[0].appendChild(row);
      });
    }
  
    function filterTable(row,filterValue) {
      var table1 = document.getElementById("expenseTable");
      var rows1 = table1.getElementsByTagName("tr");
  
      for (var i = 1; i < rows1.length; i++) {
        var statusCell = rows1[i].getElementsByTagName("td")[row];
  
        if (filterValue == "all" || statusCell.textContent.toLowerCase() == filterValue) {
          rows1[i].style.display = "";
        } else {
          rows1[i].style.display = "none";
        }
      }
    }
  
    function searchTable(){
      var rows = document.querySelectorAll('#expenseTable tbody tr');
      var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
      rows.forEach(function(row) {
        var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
        row.style.display = text.includes(val) ? '' : 'none';
      });
    }
  
    const ID = Cookies.get('user_id');
    const [salarydetails, setSalarydetails] = useState([]);
  
    const fetchSalarydetails = () =>{
      axios.get(`${config.base_url}/fetch_salary/${ID}/`).then((res)=>{
        console.log("RINV RES=",res)
        if(res.data.status){
          var inv = res.data.salarydetails;
          setSalarydetails([])
          inv.map((i)=>{
            setSalarydetails((prevState)=>[
              ...prevState, i
            ])
          })
        }
      }).catch((err)=>{
        console.log('ERR',err)
      })
    }
  
    useEffect(()=>{
        fetchSalarydetails();
    },[])
    
    function refreshAll(){
        setSalarydetails([])
      fetchSalarydetails();
    }
  
    return (
      <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper d-flex">
      <Sidebar />
      <div
        className="main-panel"
        style={{ background: "#a9a9a961", maxWidth: "100vw" }}
      >
        <div className="content-wrapper">
          <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
            <div className="page-content">
              <div className="card radius-15 h-20">
                <div className="row">
                  <div className="col-md-12">
                    <center>
                      <h2 className="mt-3">Salary Details</h2>
                    </center>
                    <hr />
                  </div>
                </div>
              </div>
  
              <div className="card radius-15 mt-3">
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-4 col-12 mb-2">
                        <div className="d-flex flex-column flex-md-row">
                          <input
                            type="text"
                            id="search"
                            className="form-control mb-2 mb-md-0"
                            placeholder="Search..."
                            autoComplete="off"
                            onKeyUp={searchTable}
                          />
                          <div className="dropdown ml-md-1">
                            <button
                              type="button"
                              className="btn btn-outline-secondary dropdown-toggle text-grey w-100"
                              data-toggle="dropdown"
                            >
                              <i className="fa fa-sort"></i> Sort by
                            </button>
                            <div className="dropdown-menu">
                            <a
                                className="dropdown-item"
                                onClick={() => refreshAll}
                              >
                               All
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={() => sortTable(3)}
                              >
                                Employee Name
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={() => sortTable(2)}
                              >
                               Month
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 d-none d-md-block"></div>
                      <div className="col-md-5 col-12 mb-2 d-flex flex-column flex-md-row justify-content-end">
                        <button
                          type="button"
                          className="btn btn-outline-secondary text-grey w-100 mb-2 mb-md-0"
                          id="exportBtn"
                          onClick={exportToExcel}
                        >
                          <i className="fa fa-table"></i> Export To Excel
                        </button>
                        <div className="dropdown ml-md-1">
                          <button
                            type="button"
                            className="btn btn-outline-secondary dropdown-toggle text-grey w-100"
                            data-toggle="dropdown"
                          >
                            <i className="fa fa-filter"></i> Filter by
                          </button>
                          <div className="dropdown-menu">
                            <a
                              className="dropdown-item"
                              onClick={() => filterTable(5, 'all')}
                            >
                              All
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() => filterTable(5, 'draft')}
                            >
                              Draft
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() => filterTable(5, 'saved')}
                            >
                              Saved
                            </a>
                          </div>
                        </div>
                        <Link to="/add_salarydetails" className="ml-1">
                          <button
                            type="button"
                            className="btn btn-outline-secondary text-grey w-100"
                          >
                            <i className="fa fa-plus font-weight-light"></i> Salary Details
                          </button>
                          </Link>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className="table-responsive">
                  <table
                    className="table table-responsive-md table-hover mt-4"
                    id="expenseTable"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                      
                        <th>EMPLOYEE NAME</th>
                        <th>EMPLOYEE ID</th>
                        <th>MONTH-YEAR</th>
                        <th>AMOUNT</th>
                        <th>STATUS</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {salarydetails &&
                        salarydetails.map((i, index) => (
                          <tr
                            className="clickable-row"
                            onClick={() => navigate(`/view_salarydetails/${i.id}/`)}
                            style={{ cursor: "pointer" }}
                          >
                            <td>{index + 1}</td>
                           
                            <td>{i.employee_name}</td>
                            <td>{i.employee_id}</td>
                            <td>{i.month}-{i.year}</td>
                            <td>{i.total_salary}</td>
                            <td>{i.status}</td>
                           
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
    );
}
export default Salarydetails;