import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import html2pdf from "html2pdf.js";
import axios from "axios";
import * as XLSX from "xlsx";
import config from "../../../functions/config";
import Swal from "sweetalert2";
// import "../../styles/salesbyCustomerreport.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

function Sales_by_hsn() {
  const ID = Cookies.get("user_id");

  const [reportData, setReportData] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [trans, settrans] = useState("");
  const [report, setReport] = useState("");

  const fetchAlltransactionsReport = () => {
    axios
      .get(`${config.base_url}/sales_by_hsn/${ID}/`)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          console.log('rept',reportData)
          setOtherDetails(res.data.otherDetails);
          setStartDate("");
          setEndDate("");
          settrans("");
          setReport("");
          
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchAlltransactionsReport();
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  const navigate = useNavigate();

  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      report: report,
      transaction: trans,
    };

    axios
      .post(`${config.base_url}/sales_by_hsnCustomized/`, det)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setOtherDetails(res.data.otherDetails);
          setStartDate(res.data.startDate);
          setEndDate(res.data.endDate);
          settrans(res.data.filtertrans);
          setReport(res.data.report);

          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Report_sales_summary_by_hsn" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Report_sales_summary_by_hsn" + date + ".xlsx");
  }

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var tra = trans;
    var rpt = report;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          // document.getElementById("share_to_email_form").submit();
          var em = {
            Id: ID,
            start: st,
            end: end,
            transaction: tra,
            report: rpt,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/share_sales_by_hsnReports_email/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
      // Position the div just below the button
      // var buttonRect = event.target.getBoundingClientRect();
      // contentDiv.style.top = (buttonRect.bottom + window.scrollY) + "px";
      // contentDiv.style.left = buttonRect.left + "px";
    }
  }

  function searchTable() {
    var rows = document.querySelectorAll("#reportTable tbody tr");
    var val = document
      .getElementById("search")
      .value.trim()
      .replace(/ +/g, " ")
      .toLowerCase();
    rows.forEach(function (row) {
      var text = row.textContent.replace(/\s+/g, " ").toLowerCase();
      row.style.display = text.includes(val) ? "" : "none";
    });
  }

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961", maxWidth:"100vw" }}
        >
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
              <div className="card radius-15 h-20">
              <div className="card-body" style={{ width: "100%" }}>
                <div className="card-title">
                  <center>
                    <h2 className="text-uppercase" id="headline">
                      SALES SUMMARY BY HSN
                    </h2>
                  </center>
                  <hr />
                </div>
                <div className="d-flex justify-content-between">
  {/* Left side: Customize Report */}
  <div className="left d-flex justify-content-start">
    <div className="position-relative mr-2">
      <button
        className="btn btn-secondary btn btn-outline-dark  bg-light text-dark"
        onClick={toggleContent}
        style={{ width: "fit-content", height: "fit-content",backgroundColor:"white", color:"black" }}
      >
        <i className="fas fa-solid fa-gear  bg-light text-dark"></i> Customize Report
      </button>
      <div id="contentDiv" className="salescontent  bg-light text-dark">
        <h6>Customize Report</h6>
        <form
          onSubmit={handleCustomize}
          className="form reportCustomizeForm px-1  bg-light text-dark"
          method="get"
        >
          <div className="px-2 w-100 bg-light text-dark">
            <label style={{ textAlign: "left" }}>From</label>
            <br />
            <input
              className="inputdate form-control"
              type="date"
              name="start_date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required={endDate != "" ? true : false}
            />
          </div>
          <div className="px-2 w-100 bg-light text-dark">
            <label style={{ textAlign: "left" }}>To</label>
            <br />
            <input
              type="date"
              className="inputdate form-control"
              name="end_date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required={startDate != "" ? true : false}
            />
          </div>

          <div className="px-2 w-100 bg-light text-dark">
            <label style={{ textAlign: "left" }}>Transaction</label>
            <br />
            <select
              name="transaction"
              id="salesStatus"
              className="form-control"
              value={trans}
              onChange={(e) => settrans(e.target.value)}
            >
              <option value="all">All Types</option>
              <option value="Invoice">Invoice</option>
              <option value="Recurring Invoices">Recurring Invoice</option> 

            </select>
          </div>
          <div className="d-flex px-2 mt-3 mb-4 w-100 bg-light text-dark">
            <button
              type="submit"
              className="btn btn-outline-dark w-50"
             
              style={{
                padding: "5px 25px", 
                whiteSpace: "nowrap",  
                height: "fit-content",
                width:"fit-content",
                display: "flex",            // Flexbox for centering
                alignItems: "center",       // Vertically centers text
                justifyContent: "center", 
                backgroundColor:"white",
                color:"black"
              }}
            >
              Run Report
            </button>
            <button
              type="reset"
              onClick={toggleContent}
              className="btn btn-outline-dark ml-1 w-50 bg-light text-dark"
              style={{
                width: "fit-content",
                height: "fit-content",
                backgroundColor:"white",
                color:"black"
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  


      {/* Right side: PDF, Print, Export, Share */}
      <div className="right d-flex align-items-center">
        <a
          className="btn btn-outline-secondary text-grey fa fa-file"
          role="button"
          id="pdfBtn"
          onClick={reportPDF}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          {" "}
          &nbsp;PDF
        </a>
        <a
          className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
          role="button"
          id="printBtn"
          onClick={printSection}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          &nbsp;Print
        </a>
        <a
          className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
          role="button"
          id="exportBtn"
          onClick={ExportToExcel}
          style={{ width: "fit-content", height: "fit-content" }}
        >
          &nbsp;Export
        </a>
        <div className="dropdown p-0 nav-item" id="shareBtn">
          <li
            className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
            data-toggle="dropdown"
            style={{
              height: "fit-content",
              width: "fit-content",
            }}
          >
            &nbsp;Share
          </li>
          <ul
            className="dropdown-menu"
            style={{ backgroundColor: "black" }}
            id="listdiv"
          >
            <a href={shareUrl} target="_blank" rel="noopener noreferrer">
              <li
                style={{
                  textAlign: "center",
                  color: "#e5e9ec",
                  cursor: "pointer",
                }}
              >
                WhatsApp
              </li>
            </a>
            <li
              style={{
                textAlign: "center",
                color: "#e5e9ec",
                cursor: "pointer",
              }}
              data-toggle="modal"
              data-target="#shareToEmail"
            >
              Email
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<br></br>

                 
              

   
<div className="card radius-15 mt-3 print-only" id="pdf-card">
          <div className="card-body">
            <div className="container-fluid">
              <div
                id="printReport"
                className="printReportTemplate"
                style={{ display: "block " }}
              >
                <div className="my-5 page" size="A4">
                  <div id="printdiv2">
                    <div className="py-4 bg-secondary">
                      <div className="col-12">
                        <center className="h5 text-white">
                          <b>{otherDetails.cmpName}</b>
                        </center>
                        <center className="h3 text-white">
                          <b> SALES SUMMARY BY HSN</b>
                        </center>
                        {startDate != "" && endDate != "" ? (
                          <center className="h6 text-white">
                            {startDate} {"TO"} {endDate}
                          </center>
                        ) : null}
                      </div>
                    </div>
                    <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table
                            className="table table-responsive-md mt-4 table-hover"
                            id="reportTable"
                          >

                            <thead>
                              <tr>
                                <th className="text-center">HSN</th>
                                <th className="text-center">TOTAL VALUE</th>
                                <th className="text-center">TAXABLE VALUE</th>
                                <th className="text-center">IGST AMOUNT</th>
                                <th className="text-center">CGST AMOUNT</th>
                                <th className="text-center">SGST AMOUNT</th>
                                <th className="text-center">ADD CESS</th>
                            </tr>
                            </thead>
                            <tbody>
                              {reportData.length > 0 &&
                                reportData.map((i, index) => (
                                  <tr key={index}> 
                                    <td className="text-center">{i.type}</td>
                                    <td className="text-center">{i.total}</td>
                                    <td className="text-center">{i.subtotal}</td>
                                    <td className="text-center">{i.igst}</td>
                                    <td className="text-center">Rs.{i.cgst}</td>
                                    <td className="text-center">Rs.{i.sgst}</td>
                                    <td className="text-center">Rs.0</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>

                          {reportData.length == 0 ? (
                            <center>
                              <h4 className="text-dark">No data available.!</h4>
                            </center>
                          ) : null}
                        </section>

                        
                        <section className="balance-info text-dark">
                        <div className="row p-4">
                          <div className="col-10">
                            <div className="row mb-2">
                              <div className="col-4">
                                <p style={{fontSize: 19}} />
                              </div>
                              <br />
                              <br />
                            </div>
                            <div className="row">
                              <div className="col-4" />
                              <div className="col-4" />
                              
                            </div>
                          </div>
                          <div className="col-2 text-center">
                            <div style={{paddingTop: 70, color: '#000', fontWeight: 'bold', fontSize: '1.25rem'}}>Total value:</div>
                            <div className="text-dark" style={{fontWeight: 600, fontSize: '1.5rem'}} id="totvalue">
                              ₹<span id="superTotal">{parseFloat(otherDetails.tototal).toFixed(2)}</span>
                            </div>
                          </div>
                        </div>
                      </section>


                                

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  );
}

export default Sales_by_hsn;
