// import React, { useEffect, useState } from "react";
// import Navbar from "../Navbar";
// import Sidebar from "../Sidebar";
// import * as XLSX from "xlsx";
// import { Link, useNavigate } from "react-router-dom";
// import Cookies from 'js-cookie';
// import axios from "axios";
// import config from "../../../functions/config";


// function Attendence() {
//     const ID = Cookies.get('user_id');
//     const [attendences,setAttendences] = useState([]);
//     const [name,setName] = useState({});
//     const Fetch_Attendence = () =>{
//       axios.get(`${config.base_url}/All_Attendences/${ID}/`).then((res)=>{
//         if(res.data.status){
//           // var emps = res.data.attd;
//           var emp = res.data.attd;
//           console.log(res.data.attd);
//           setName(emp);
//           setAttendences([]);
//           emp.map((i)=>{
//             setAttendences((prevState)=>[
//               ...prevState, i
//             ])
//           })
//         }
        
//       }).catch((err)=>{
//         console.log('ERR',err)
//       })
//     };
//     useEffect(()=>{
//       Fetch_Attendence();
//     },[]);
//     const navigate = useNavigate();
  
//     function searchTable(){
//       var rows = document.querySelectorAll('#itemsTable tbody tr');
//       var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
//       rows.forEach(function(row) {
//         var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
//         row.style.display = text.includes(val) ? '' : 'none';
//       });
//     }
//     function refreshAll(){
//       setAttendences([])
//       Fetch_Attendence();
//     }
//     function sortTable(columnIndex) {
//       var table, rows, switching, i, x, y, shouldSwitch;
//       table = document.getElementById("itemsTable");
//       switching = true;
  
//       while (switching) {
//         switching = false;
//         rows = table.rows;
  
//         for (i = 1; i < rows.length - 1; i++) {
//           shouldSwitch = false;
//           x = rows[i]
//             .getElementsByTagName("td")
//             [columnIndex].textContent.toLowerCase();
//           y = rows[i + 1]
//             .getElementsByTagName("td")
//             [columnIndex].textContent.toLowerCase();
  
//           if (x > y) {
//             shouldSwitch = true;
//             break;
//           }
//         }
  
//         if (shouldSwitch) {
//           rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
//           switching = true;
//         }
//       }
//     }
//     function exportToExcel() {
//       const Table = document.getElementById("TableExport");
//       const ws = XLSX.utils.table_to_sheet(Table);
//       const wb = XLSX.utils.book_new();
//       XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
//       XLSX.writeFile(wb, "Attendence.xlsx");
//     }
//     return(
//         <>
//        <div className="container-scroller">
//   <Navbar />
//   <div className="container-fluid page-body-wrapper d-flex">
//     <Sidebar />
//     <div className="page-content" style={{backgroundColor: 'white', minHeight: "100vh", width: "100%" }}>
//       <div className="card radius-15 h-20">
//         <div className="row">
//           <div className="col-md-12">
//             <center>
//               <h2 className="mt-3">ALL ATTENDANCE</h2>
//             </center>
//             <hr />
//           </div>
//         </div>
//       </div>
      
//       <div className="card radius-15">
//         <div className="card-body">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-4">
//                 <div className="d-flex">
//                   <input
//                     type="text"
//                     id="search"
//                     className="form-control"
//                     placeholder="Search..."
//                     autoComplete="off"
//                     onKeyUp={searchTable}
//                   />
//                   <div className="dropdown ml-1" style={{ justifyContent: "left" }}>
//                     <button
//                       type="button"
//                       className="btn btn-outline-secondary dropdown-toggle text-grey"
//                       data-toggle="dropdown"
//                       style={{ height: "40px", position: "relative", bottom: "10px" }}
//                     >
//                       <i className="fa fa-sort"></i> Sort by
//                     </button>
//                     <div className="dropdown-menu" style={{ backgroundColor: "white" }}>
//                       <a
//                         className="dropdown-item"
//                         onClick={refreshAll}
//                         style={{ height: "40px", fontSize: "15px", color: "black" }}
//                       >
//                         All
//                       </a>
//                       <a
//                         className="dropdown-item"
//                         style={{ height: "40px", fontSize: "15px", color: "black", cursor: "pointer" }}
//                         onClick={() => sortTable(1)}
//                       >
//                         Employee Name
//                       </a>
//                       <a
//                         className="dropdown-item"
//                         style={{ height: "40px", fontSize: "15px", color: "black", cursor: "pointer" }}
//                         onClick={() => sortTable(2)}
//                       >
//                         Month
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-3"></div>
//               <div className="col-md-5 d-flex justify-content-end">
//                 <button
//                   type="button"
//                   className="btn btn-outline-secondary text-dark"
//                   id="exportBtn"
//                   style={{ height: "fit-content", width: "fit-content" }}
//                   onClick={exportToExcel}
//                 >
//                   <i className="fa fa-table"></i> Export To Excel
//                 </button>
//                 <Link to="/add_attendence" className="ml-1">
//                   <button
//                     type="button"
//                     style={{ width: "fit-content", height: "fit-content" }}
//                     className="btn btn-outline-secondary text-grey"
//                   >
//                     <i className="fa fa-plus font-weight-light"></i> Attendance
//                   </button>
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="table-responsive">
//           <table
//             className="table table-responsive-md table-hover mt-4"
//             id="itemsTable"
//             style={{ textAlign: "center" }}
//           >
//             <thead>
//               <tr>
//                 <th>SL.NO.</th>
//                 <th>EMPLOYEE NAME</th>
//                 <th>MONTH</th>
//                 <th>YEAR</th>
//                 <th>HOLIDAYS</th>
//                 <th>WORKING DAYS</th>
//                 <th>TOTAL LEAVE</th>
//               </tr>
//             </thead>
//             <tbody>
//               {attendences.map((a, index) => (
//                 <tr
//                   className="clickable-row"
//                   style={{ cursor: "pointer" }}
//                   onClick={() => navigate(`/view_attendence/${a.e_id}/${a.month}/${a.year}/`)}
//                 >
//                   <td>{index + 1}</td>
//                   <td>{a.employee}</td>
//                   <td>{a.month}</td>
//                   <td>{a.year}</td>
//                   <td>{a.holidays}</td>
//                   <td>{a.working_days}</td>
//                   <td>{a.absent_days}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

//         </>
//     )
    
// }
// export default Attendence;


















import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../../functions/config";


function Attendence() {
    const ID = Cookies.get('user_id');
    const [attendences,setAttendences] = useState([]);
    const [name,setName] = useState({});
    const Fetch_Attendence = () =>{
      axios.get(`${config.base_url}/All_Attendences/${ID}/`).then((res)=>{
        if(res.data.status){
          // var emps = res.data.attd;
          var emp = res.data.attd;
          console.log(res.data.attd);
          setName(emp);
          setAttendences([]);
          emp.map((i)=>{
            setAttendences((prevState)=>[
              ...prevState, i
            ])
          })
        }
        
      }).catch((err)=>{
        console.log('ERR',err)
      })
    };
    useEffect(()=>{
      Fetch_Attendence();
    },[]);
    const navigate = useNavigate();
  
    function searchTable(){
      var rows = document.querySelectorAll('#itemsTable tbody tr');
      var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
      rows.forEach(function(row) {
        var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
        row.style.display = text.includes(val) ? '' : 'none';
      });
    }
    function refreshAll(){
      setAttendences([])
      Fetch_Attendence();
    }
    function sortTable(columnIndex) {
      var table, rows, switching, i, x, y, shouldSwitch;
      table = document.getElementById("itemsTable");
      switching = true;
  
      while (switching) {
        switching = false;
        rows = table.rows;
  
        for (i = 1; i < rows.length - 1; i++) {
          shouldSwitch = false;
          x = rows[i]
            .getElementsByTagName("td")
            [columnIndex].textContent.toLowerCase();
          y = rows[i + 1]
            .getElementsByTagName("td")
            [columnIndex].textContent.toLowerCase();
  
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        }
  
        if (shouldSwitch) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
        }
      }
    }
    function exportToExcel() {
      const Table = document.getElementById("TableExport");
      const ws = XLSX.utils.table_to_sheet(Table);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Attendence.xlsx");
    }
    return (
      <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper d-flex">
      <Sidebar />
          <div className="page-content" style={{ backgroundColor: "#a9a9a961", minHeight: "100vh", width: '100%', padding:'20px' }}>
              <div className="card radius-15 h-20">
                  <div className="row">
                      <div className="col-md-12">
                          <center>
                              <h2 className="mt-3">ATTENDENCE</h2>
                          </center>
                          <hr />
                      </div>
                  </div>
              </div>

              <div className="card radius-15" style={{marginTop:"20px"}}>
                  <div className="card-body">
                      <div className="container-fluid">
                          <div className="row">
                              <div className="col-md-4">
                                  <div className="d-flex">
                                      <input
                                          type="text"
                                          id="search"
                                          className="form-control"
                                          placeholder="Search.."
                                          autoComplete="off"
                                          onInput={searchTable}
                                      />
                                      <div className="dropdown ml-1" style={{ justifyContent: 'left' }}>
                                          <button
                                              type="button"
                                              className="btn btn-outline-secondary dropdown-toggle text-grey"
                                              data-toggle="dropdown"
                                              style={{ height: '38px', position: 'relative' }}
                                          >
                                              <i className="fa fa-sort"></i> Sort by
                                          </button>
                                          <div className="dropdown-menu" style={{ backgroundColor: 'black' }}>
                                              <a
                                                  className="dropdown-item"
                                                  onClick={refreshAll}
                                                  style={{ height: '40px', fontSize: '15px', color: 'white', cursor: 'pointer' }}
                                              >
                                                  All
                                              </a>
                                              <a
                                                  className="dropdown-item"
                                                  onClick={() => sortTable(1)}
                                                  style={{ height: '40px', fontSize: '15px', color: 'white', cursor: 'pointer' }}
                                              >
                                                   Employee Name
                                              </a>
                                              <a
                                                  className="dropdown-item"
                                                  onClick={() => sortTable(2)}
                                                  style={{ height: '40px', fontSize: '15px', color: 'white', cursor: 'pointer' }}
                                              >
                                                  Month
                                              </a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-3"></div>
                              <div className="col-md-5 d-flex justify-content-end">
                                  <button
                                      type="button"
                                      className="btn btn-outline-secondary text-grey"
                                      id="exportBtn"
                                      style={{ height: 'fit-content', width: 'fit-content' }}
                                      onClick={exportToExcel}
                                  >
                                      <i className="fa fa-table"></i> Export To Excel
                                  </button>
                                  <Link to="/add_attendence" className="ml-1">
                                      <button
                                          type="button"
                                          style={{ width: "fit-content", height: "fit-content" }}
                                          className="btn btn-outline-secondary text-grey"
                                      >
                                          <i className="fa fa-plus font-weight-light"></i> Attendance
                                      </button>
                                  </Link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="table-responsive">                        
                      <table className="table table-responsive-md table-hover mt-4" id="itemsTable" style={{ textAlign: 'center' }}>
                          <thead>
           
              <tr>
                <th>SL.NO.</th>
                <th>EMPLOYEE NAME</th>
                <th>MONTH</th>
                <th>YEAR</th>
                <th>HOLIDAYS</th>
                <th>WORKING DAYS</th>
                <th>TOTAL LEAVE</th>
              </tr>
            </thead>
            <tbody>
              {attendences.map((a, index) => (
                <tr
                  className="clickable-row"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/view_attendence/${a.e_id}/${a.month}/${a.year}/`)}
                >
                  <td>{index + 1}</td>
                  <td>{a.employee}</td>
                  <td>{a.month}</td>
                  <td>{a.year}</td>
                  <td>{a.holidays}</td>
                  <td>{a.working_days}</td>
                  <td>{a.absent_days}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

       
    );
    
}
export default Attendence;