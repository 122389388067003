import React, { useEffect, useState } from "react";
import { Link, useNavigate,useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";


function Salarydetails_history() {
    const ID = Cookies.get("user_id");
    const { salaryId } = useParams();
    const [history, setHistory] = useState([]);
    const [salarydetails, setSalarydetails] = useState({});
    const [employeeName, setEmployeeName] = useState(""); // Add state for employee name
  
    const fetchSalarydetailsHistory = () => {
      axios
        .get(`${config.base_url}/fetch_salarydetails_history/${salaryId}/`)
        .then((res) => {
          console.log("EXP HIST=", res);
          if (res.data.status) {
            var sls = res.data.salary;
            var hist = res.data.history;
            
            setSalarydetails(sls);
            setEmployeeName(res.data.employee_name); // Set employee name from response
  
            setHistory([]); // Clear previous history
            hist.map((i) => {
              setHistory((prevState) => [...prevState, i]);
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    };
  
    useEffect(() => {
      fetchSalarydetailsHistory();
    }, []);
  
    return (
      <div className="container-scroller1">
        <Navbar />
        <div className="container-fluid page-body-wrapper d-flex flex-column flex-md-row">
          <Sidebar />
          <div
            className="main-panel px-3 py-2"
            style={{ background: "#a9a9a961", maxWidth: "100vw" }}
          >
            <Link
              className="d-flex justify-content-end p-2"
              style={{ cursor: "pointer" }}
              to={`/view_salarydetails/${salaryId}/`}
            >
              <i
                className="fa fa-times-circle text-white"
                style={{ fontSize: "1.2rem" }}
              ></i>
            </Link>
            <div className="card radius-15 mt-3">
              <div className="card-body" style={{ width: "100%" }}>
                <div className="card-title">
                  <center>
                    <h3 className="card-title" style={{ textTransform: "Uppercase" }}>
                      SALARY DETAILS HISTORY
                    </h3>
                    {salarydetails.status === "draft" ? (
                      <h6
                        className="blinking-text"
                        style={{ color: "red", width: "140px", fontWeight: "bold" }}
                      >
                        Draft
                      </h6>
                    ) : (
                      <h6
                        style={{
                          width: "140px",
                          color: "green",
                          fontWeight: "bold",
                        }}
                      >
                        Saved
                      </h6>
                    )}
                  </center>
                </div>
              </div>
            </div>
  
            <div
              className="card card-registration card-registration-2 mt-3"
              style={{ borderRadius: "15px" }}
            >
              <div className="card-body p-0">
                <div id="history">
                  <center>
                    <h3 className="mt-3 text-uppercase">
                      {employeeName} - HISTORY 
                    </h3>
                  </center>
                  <div className="table-responsive px-2">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="text-center">SL NO.</th>
                          <th className="text-center">DATE</th>
                          <th className="text-center">ACTION</th>
                          <th className="text-center">DONE BY</th>
                        </tr>
                      </thead>
                      <tbody>
                        {history &&
                          history.map((h, index) => (
                            <tr key={index}>
                              <td style={{ textAlign: "center" }}>{index + 1}</td>
                              <td style={{ textAlign: "center" }}>{h.date}</td>
                              {h.action === "Created" ? (
                                <td className="text-success text-center">{h.action}</td>
                              ) : (
                                <td className="text-warning text-center">{h.action}</td>
                              )}
                              <td style={{ textAlign: "center" }}>{h.name}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    
  
    
    );
}
export default Salarydetails_history;

