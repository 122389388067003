import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "./styles/Staff.css";
import * as XLSX from 'xlsx';

function Salesorder() {
  const navigate = useNavigate();
  const [salesOrders, setSalesOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const ID = Cookies.get("user_id");

  useEffect(() => {
    fetchSalesOrders();
  }, []);

  const fetchSalesOrders = async () => {
    try {
      const res = await axios.get(`${config.base_url}/sales-order-list/${ID}/`);
      if (res.data.status) {
        setSalesOrders(res.data.data);
        setFilteredOrders(res.data.data);
      } else {
        Swal.fire({
          icon: "error",
          title: "No data found",
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: `${err.response?.data?.message || "Error fetching sales orders"}`,
      });
    }
  };
  
  const searchTable = (e) => {
    const query = e.target.value.toLowerCase();
    const filtered = salesOrders.filter(order =>
      Object.values(order).some(value =>
        value && value.toString().toLowerCase().includes(query)
      )
    );
    setFilteredOrders(filtered);
  };
  
  
  const sortTable = (columnIndex) => {
    const sorted = [...filteredOrders].sort((a, b) => {
      const aValue = Object.values(a)[columnIndex];
      const bValue = Object.values(b)[columnIndex];
      return aValue > bValue ? 1 : -1;
    });
    setFilteredOrders(sorted);
  };
  

  const refreshAll = () => {
    setFilteredOrders(salesOrders);
  };

  const filterTable = (columnIndex, filter) => {
    const filtered = salesOrders.filter(order => order.status === filter);
    setFilteredOrders(filter === "all" ? salesOrders : filtered);
  };

  const exportToExcel = () => {
    // Select the table element
    const table = document.getElementById("salesOrderTable");
    
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    
    // Convert the table to a worksheet
    const worksheet = XLSX.utils.table_to_sheet(table);
    
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Orders");
    
    // Generate a file and trigger download
    XLSX.writeFile(workbook, "SalesOrders.xlsx");
  };

  // Handle converting to invoice
  const handleConvertToInvoice = (orderId) => {
    // Logic to convert the sales order to an invoice
    console.log("Convert to Invoice clicked for order ID:", orderId);
    // You can navigate to another route or call an API
    // Example: navigate(`/invoice/${orderId}`); 
  };

  // Handle recurring invoice
  const handleRecurringInvoice = (orderId) => {
    // Logic to create a recurring invoice from the sales order
    console.log("Recurring Invoice clicked for order ID:", orderId);
    // Example: navigate(`/recurring-invoice/${orderId}`); 
  };
  
  

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel" style={{ background: "#a9a9a961", maxWidth: "100vw" }}>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
                <div className="card radius-15 h-20 mt-3">
                  <div className="row w-100">
                    <div className="col-md-12">
                      <center>
                        <h2 className="mt-3">SALES ORDERS</h2>
                      </center>
                      <hr />
                    </div>
                  </div>
                </div>
                <div className="card radius-15">
                  <div className="card-body">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              id="search"
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                              onChange={searchTable}
                            />
                            <div className="dropdown ml-1" style={{ justifyContent: "left" }}>
                              <button
                                type="button"
                                style={{ width: "fit-content", height: "fit-content" }}
                                className="btn btn-outline-secondary dropdown-toggle text-grey"
                                data-toggle="dropdown"
                              >
                                <i className="fa fa-sort"></i> Sort by
                              </button>
                              <div className="dropdown-menu" style={{ backgroundColor: "black" }}>
                                <a
                                  className="dropdown-item"
                                  onClick={refreshAll}
                                  style={{ height: "40px", fontSize: "15px", color: "white" }}
                                >
                                  All
                                </a>
                                <a
                                  className="dropdown-item"
                                  style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                                  onClick={() => sortTable(2)}
                                >
                                  Customer Name
                                </a>
                                <a
                                  className="dropdown-item"
                                  style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                                  onClick={() => sortTable(1)}
                                >
                                  Sales Order No.
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-6 d-flex justify-content-end">
                          <button
                            type="button"
                            style={{ width: "fit-content", height: "fit-content" }}
                            className="btn btn-outline-secondary text-grey"
                            id="exportBtn"
                            onClick={exportToExcel}
                          >
                            <i className="fa fa-table"></i> Export To Excel
                          </button>
                          <div className="dropdown ml-1">
                            <button
                              type="button"
                              style={{ width: "fit-content", height: "fit-content" }}
                              className="btn btn-outline-secondary dropdown-toggle text-grey"
                              data-toggle="dropdown"
                            >
                              <i className="fa fa-filter"></i> Filter by
                            </button>
                            <div className="dropdown-menu" style={{ backgroundColor: "black" }}>
                              <a
                                className="dropdown-item"
                                style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                                onClick={() => filterTable(5, "all")}
                              >
                                All
                              </a>
                              <a
                                className="dropdown-item"
                                style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                                onClick={() => filterTable(5, "Save")}
                              >
                                Saved
                              </a>
                              <a
                                className="dropdown-item"
                                style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                                onClick={() => filterTable(5, "Draft")}
                              >
                                Draft
                              </a>
                            </div>
                          </div>
                          <Link to="/Addsalesorder" className="ml-1">
                            <button
                              type="button"
                              style={{ width: "fit-content", height: "fit-content" }}
                              className="btn btn-outline-secondary text-grey"
                            >
                              <i className="fa fa-plus font-weight-light"></i> Sales Order
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                <table
  className="table table-responsive-md table-hover mt-4"
  id="salesOrderTable"
  style={{ textAlign: "center", backgroundColor: "white" }}
>
  <thead>
    <tr>
      <th>#</th>
      <th>SALES ORDER NO.</th>
      <th>CUSTOMER NAME</th>
      <th>MAIL ID</th>
      <th>AMOUNT</th>
      <th>STATUS</th>
      <th>BALANCE</th>
      <th>ACTION</th>
    </tr>
  </thead>
  <tbody>
  {filteredOrders.length === 0 ? (
    <tr>
      <td colSpan="8">No sales orders found.</td>
    </tr>
  ) : (
    filteredOrders.map((order, index) => (
<tr
  key={order.id}
  className="clickable-row"
  onDoubleClick={() => navigate(`/SalesOrderDetail/${order.id}`)}
  style={{ cursor: "pointer" }}
>

        <td>{index + 1}</td>
        <td>{order.sales_order_no}</td>
        <td>{order.customer_name}</td>
        <td>{order.customer_email}</td>
        <td>{order.grandtotal}</td>
        <td>{order.status}</td>
        <td>{order.balance}</td>
        <td>
          {order.converted ? (
            <span
              className="text-info font-weight-bolder text-center"
              onClick={() => navigate(order.link)}
            >
              Converted to <br />
              {order.type} - #{order.number}
            </span>
          ) : (
            <div className="dropdown ml-1" style={{ justifyContent: "left" }}>
              <button
                type="button"
                style={{ width: "fit-content", height: "fit-content" }}
                className="btn btn-outline-secondary dropdown-toggle text-grey"
                data-toggle="dropdown"
              >
                <i className=""></i> convert
              </button>
              <div className="dropdown-menu" style={{ backgroundColor: "black" }}>
                <a
                  className="dropdown-item"
                  onClick={() => navigate(`/SalesToInvoice/${order.id}`)}
                  style={{ height: "40px", fontSize: "15px", color: "white" }}
                >
                  to invoice
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => navigate(`/SalesToRecurring/${order.id}`)}
                  style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                >
                  to recurring invoice
                </a>
              </div>
            </div>
          )}
        </td>
      </tr>
    ))
  )}
</tbody>

</table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Salesorder;
