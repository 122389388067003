import React, { useEffect, useState } from "react";
import { Link, useNavigate,useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { Bar } from "react-chartjs-2";
import 'chart.js/auto';
import moment from "moment";


function Cashinhand_Graph(){
  const ID = Cookies.get("user_id");
  const [reportData, setReportData] = useState({ addCashData: [], reduceCashData: [] });
  const [groupBy, setGroupBy] = useState("month");

  useEffect(() => {
    fetchHolder(ID);
  }, [ID]);

  const fetchHolder = (id) => {
    if (!id) {
      console.error("Invalid ID:", id);
      return;
    }

    axios.get(`${config.base_url}/cash_in_hand/${id}/`)
      .then((res) => {
        if (res.data.status) {
          const addCashData = res.data.reportData.filter(item => item.process === 'Add cash');
          const reduceCashData = res.data.reportData.filter(item => item.process === 'Reduce cash');
          setReportData({ addCashData, reduceCashData });
        } else {
          Swal.fire({
            icon: "error",
            title: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        Swal.fire({
          icon: "error",
          title: "An error occurred while fetching the data.",
        });
      });
  };

  const groupData = (data) => {
    return data.reduce((acc, item) => {
      const key = groupBy === "month" 
        ? moment(item.date).format('YYYY-MM') 
        : moment(item.date).format('YYYY');
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] += parseFloat(item.total);
      return acc;
    }, {});
  };

  const generateChartData = () => {
    return groupBy === "month" ? generateMonthlyChartData() : generateYearlyChartData();
  };
  
  const generateMonthlyChartData = () => {
    const addCashGrouped = groupData(reportData.addCashData);
    const reduceCashGrouped = groupData(reportData.reduceCashData);
  
    const now = moment();
    const monthsToShow = 8;
    const extendedMonths = [];
    
    for (let i = 0; i < monthsToShow; i++) {
      const month = now.clone().subtract(i, 'months').format('YYYY-MM');
      extendedMonths.unshift(month);
    }
    
    const addCashAmounts = extendedMonths.map(month => addCashGrouped[month] || 0);
    const reduceCashAmounts = extendedMonths.map(month => reduceCashGrouped[month] || 0);
  
    return {
      labels: extendedMonths.map(month => moment(month).format('MMM YYYY')),
      datasets: [
        {
          label: 'Add Cash',
          data: addCashAmounts,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          barThickness: 20, // Adjust thickness
          categoryPercentage: 0.4,
          barPercentage: 0.6, // Adjust to control spacing between bars
        },
        {
          label: 'Reduce Cash',
          data: reduceCashAmounts,
          backgroundColor: 'rgba(255,99,132,0.6)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          barThickness: 20, // Adjust thickness
          categoryPercentage: 0.4,
          barPercentage: 0.6, // Adjust to control spacing between bars
        }
      ]
    };
  };
  
  const generateYearlyChartData = () => {
    const addCashGrouped = groupData(reportData.addCashData);
    const reduceCashGrouped = groupData(reportData.reduceCashData);

    const now = moment();
    const yearsToShow = 8;
    const extendedYears = [];
    
    for (let i = 0; i < yearsToShow; i++) {
      const year = now.clone().subtract(i, 'years').format('YYYY');
      extendedYears.unshift(year);
    }
    
    const addCashAmounts = extendedYears.map(year => addCashGrouped[year] || 0);
    const reduceCashAmounts = extendedYears.map(year => reduceCashGrouped[year] || 0);

    return {
      labels: extendedYears,
      datasets: [
        {
          label: 'Add Cash',
          data: addCashAmounts,
          backgroundColor: 'rgba(75,192,192,0.6)',
          borderColor: 'rgba(75,192,192,1)',
          borderWidth: 1,
          barThickness: 20,
          categoryPercentage: 0.4,
          barPercentage: 0.8,
        },
        {
          label: 'Reduce Cash',
          data: reduceCashAmounts,
          backgroundColor: 'rgba(255,99,132,0.6)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          barThickness: 20,
          categoryPercentage: 0.4,
          barPercentage: 0.8,
        }
      ]
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'black',
        },
      },
      title: {
        display: true,
        text: groupBy === 'month' 
          ? `Cash In Flow and Out Flow - ${moment().format('YYYY')}` 
          : 'Cash Out Flow and In Flow',
        color: 'black',
        font: {
          size: 16
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat().format(context.parsed.y);
            }
            return label;
          }
        },
        titleColor: 'white',
        bodyColor: 'white',
      }
    },
    scales: {
      x: {
        stacked: false,
        barPercentage: 0.8, // Controls the width of the bars
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          color: 'black',
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.2)',
        }
      },
      y: {
        stacked: false,
        ticks: {
          beginAtZero: true,
          callback: function(value) {
            return new Intl.NumberFormat().format(value);
          },
          color: 'black',
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.2)',
          borderDash: [8, 4],
        }
      }
    }
  };

  const handleGroupChange = (event) => {
    setGroupBy(event.target.value);
  };
  

    return(
        
        <div className="container-scroller1">
        <Navbar />
        <div className="container-fluid page-body-wrapper d-flex flex-column flex-md-row">
          <Sidebar />
          <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961", maxWidth: "100vw" }}
        >
          
        <div className="card radius-15 h-20">
            <div className="row">
            <div className="col-md-12">
                <center>
                <h2 className="mt-3">CASH IN HAND GRAPH</h2>
                </center>
                <hr />
            </div>
            </div>
        </div>

        <div className="card radius-15 mt-5" >
          <div className="card-body">
            <div className="container-fluid">
              <div className="row w-100">
                <div className="col-md-12">
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                    <select 
                      value={groupBy} 
                      onChange={handleGroupChange} 
                      className="form-select "
                      style={{ backgroundColor: "white", color: "black", borderColor: "black", width: '200px' }}
                    >
                      <option value="month">Month wise</option>
                      <option value="year">Year wise</option>
                    </select>
                  </div>
                  <div style={{ height: '400px', width: '100%' }}>
                    <Bar 
                      data={generateChartData()} 
                      options={chartOptions}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-5 mb-5 w-100">
                <div className="col-md-4"></div>
                <div className="col-md-4 d-flex justify-content-center">
                  <button
                    onClick={() => window.history.back()}
                    className="btn btn-secondary btn-sm"
                    style={{ color: "black", borderColor: "black",  borderRadius: "5px", padding: "10px 20px" }}
                  >
                    Go Back
                  </button>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
       </div>
      </div>
    </div>
 );
}
export default Cashinhand_Graph;

