import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
// import config from "../../functions/config";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "../styles/Staff.css"
import html2pdf from "html2pdf.js";
import * as XLSX from 'xlsx';
import config from '../../../functions/config';








function Employeeloanstatement() {
  const [loanData, setLoanData] = useState([]);
  const [totloan, setTotLoan] = useState(0);
  const [totbal, setTotBal] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [startDate, setStartDate] = useState(""); // New state for start date
  const [endDate, setEndDate] = useState(""); // New state for end date
  const [selectedEmployee, setSelectedEmployee] = useState(""); // New state for selected employee
  const [employees, setEmployees] = useState([]); // State to hold employee options
  const userID = Cookies.get("user_id");
  const [employee,setEmployee] = useState("");
  const ID = Cookies.get("user_id");
    function toggleContent() {
        var contentDiv = document.getElementById("contentDiv");
        if (contentDiv.style.display === "block") {
            contentDiv.style.display = "none";
        } else {
            contentDiv.style.display = "block";
        }
    }

    
      function reportPDF(){
        var element = document.getElementById('printReport');
        var opt =
        {
            margin: [0.5, 0.3, 0.3, 0.5],
            filename: 'Employee_Loan_Statement_Report',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().set(opt).from(element).save();
    }
    function ExportToExcel(){
        const Table = document.getElementById("reportTable");
        const ws = XLSX.utils.table_to_sheet(Table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Employee_Loan_Statement_Report.xlsx");
    }
    function printSheet() {
        var divToPrint = document.getElementById("printReport");
        var printWindow = window.open("", "", "height=700,width=1000");
    
        printWindow.document.write("<html><head><title></title>");
        printWindow.document.write(`
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
        `);
        printWindow.document.write("</head>");
        printWindow.document.write("<body>");
        printWindow.document.write(divToPrint.outerHTML);
        printWindow.document.write("</body>");
        printWindow.document.write("</html>");
        printWindow.document.close();
        printWindow.print();
        printWindow.addEventListener('afterprint', function() {
          printWindow.close();
        });
    
      }
      const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  useEffect(() => {
    // Fetch employees from Employee_Loan model
    axios.get(`${config.base_url}/Employee_Loan_Statement_Customized/${userID}/`) // Adjust this endpoint to fetch employees from Employee_Loan
      .then((response) => {
        if (response.data.status) {
          setEmployees(response.data.employees);
        } else {
          Swal.fire("Error", response.data.message, "error");
        }
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      });
  }, [userID]); // Add userID as dependency to refetch if it changes

  useEffect(() => {
    fetchLoanData(); // Fetch loan data initially
  }, [userID]);

  const fetchLoanData = () => {
    axios.get(`${config.base_url}/Employee_Loan_Statement/${userID}/`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        employee_id: selectedEmployee,
      },
    })
      .then((response) => {
        if (response.data.status) {
          setLoanData(response.data.ldata);
          setTotLoan(response.data.totloan);
          setTotBal(response.data.totbal);
          setCompanyName(response.data.cmp);
        } else {
          Swal.fire("Error", response.data.message, "error");
        }
      })
      .catch((error) => {
        Swal.fire("Error", "Failed to load data.", "error");
        console.error("Error fetching data:", error);
      });
  };
  
  const handleSearch = () => {
    fetchLoanData(); // Fetch loan data based on filters
  };
    
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
    function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var empl = employee;
    console.log("employee",empl)
    console.log("startdate",st)
    console.log("enddate",end)
    

    


    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          var em = {
            Id: ID,
            start: st,
            end: end,
            emp:empl,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          console.log(
            "print",em
          )
          axios
            .post(
              `${config.base_url}/Share_Employee_Loan_Statement/`,
              em
            )
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel px-3 py-2" style={{ background: "#a9a9a961", maxWidth: "100vw" }}>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
                <div className="card radius-15 h-20 mt-3">
                  <div className="row w-100">
                    <div className="col-md-12">
                      <center>
                        <h2 className="mt-3">{companyName}- Employee Loan Report</h2>
                      </center>
                      <hr />
                      {/* Custom Filter Form */}
                      {/* <div className="mb-3">
                        <label>Start Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                        <label>End Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                        <label>Employee:</label>
                        <select
                          className="form-control"
                          value={selectedEmployee}
                          onChange={(e) => setSelectedEmployee(e.target.value)}
                        >
                          <option value="all">All</option>
                         
                          {employees.map((employee) => (
                            <option key={employee.id} value={employee.id}>
                              {employee.first_name} {employee.last_name}
                            </option>
                          ))}
                        </select>
                        <button className="btn btn-primary mt-3" onClick={handleSearch}>
                          Runreport
                        </button>
                      </div> */}
                                            <div
                        className="bar"
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="left d-flex justify-content-start"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                          }}
                        >
                          <div className="position-relative mr-2">
                            <button
                              className="btn btn-secondary"
                              onClick={toggleContent}
                              style={{ width: "fit-content", height: "fit-content" }}
                            >
                              <i className="fa-solid fa fa-gear"></i> Customize Report
                            </button>
                            <div
                              id="contentDiv"
                              className="salescontent"
                              style={{
                                position: "absolute",
                                backgroundColor: "#f4f5f7",
                                minWidth: "350px",
                                minHeight: "250px",
                                padding: "10px",
                                border: "1px solid #ccc",
                                height: "fit-content",
                                zIndex: 1,
                              }}
                            >
                              <h6>Customize Report</h6>
                              
                                <div className="px-2 mb-2 w-100">
                                  <label style={{ textAlign: "left" }}>From:</label>
                                  <br />
                                  <input
                                    name="from_date"
                                    className="inputdate form-control"
                                    type="date"
                                    id="startDate"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                  />
                                </div>
                                <div className="px-2 mb-2 w-100">
                                  <label style={{ textAlign: "left" }}>To:</label>
                                  <br />
                                  <input
                                    name="to_date"
                                    type="date"
                                    className="inputdate form-control"
                                    id="endDate"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                  />
                                </div>
                                <div className="px-2 mb-2 w-100">
                                <label style={{ textAlign: "left" }}>Employee:</label>
                                <br />
                                <select
                                  name="employee"
                                  id="employeeValue"
                                  className="form-control"
                                  value={employee}
                                  onChange={(e) => setEmployee(e.target.value)}
                                >
                                  <option value="All">All</option>
                                  {employees.map((employee) => (
                            <option key={employee.id} value={employee.id}>
                              {employee.first_name} {employee.last_name}
                            </option>
                          ))}
                               
                                 
                                  
                                  
                                                        
                                  

                                  
                                </select>
                              </div>





                                {/* <div className="px-2 mb-2 w-100">
                                  <label style={{ textAlign: "left" }}>Employee:</label>
                                  <br />
                                  <select
                                    name="employee"
                                    id="employeeValue"
                                    className="form-control"
                                    value={employee}
                                    onChange={(e) => setEmployee(e.target.value)}
                                  >
                                    <option value="All">All</option>
                                    

                                  </select>
                                </div> */}
                                <div
                                  className="d-flex px-2 mt-3 mb-4 w-100"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <button
                                     onClick={handleSearch}
                                    className="btn btn-outline-dark"
                                    style={{ width: "fit-content", height: "fit-content" }}
                                  >
                                    Run Report
                                  </button>
                                  <button
                                    type="reset"
                                    onClick={toggleContent}
                                    className="btn btn-outline-dark ml-1 w-50"
                                    style={{ width: "fit-content", height: "fit-content" }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              
                            </div>
                          </div>
                        </div>
                        <div className="right d-flex">
                          <a
                            className="btn btn-outline-secondary text-grey fa fa-file"
                            role="button"
                            id="pdfBtn"
                            onClick={reportPDF}
                            style={{ width: "fit-content", height: "fit-content" }}
                          >
                            &nbsp;PDF
                          </a>
                          <a
                            className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                            role="button"
                            id="printBtn"
                            onClick={printSheet}
                            style={{ width: "fit-content", height: "fit-content" }}
                          >
                            &nbsp;Print
                          </a>
                          <a
                            className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
                            role="button"
                            id="exportBtn"
                            onClick={ExportToExcel}
                            style={{ width: "fit-content", height: "fit-content" }}
                          >
                            &nbsp;Export
                          </a>
                          <div className="dropdown p-0 nav-item" id="shareBtn">
                            <li
                              className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                              data-toggle="dropdown"
                              style={{ width: "fit-content", height: "fit-content" }}
                            >
                              &nbsp;Share
                            </li>
                            <ul
                              className="dropdown-menu mt-1"
                              style={{ backgroundColor: "black" }}
                              id="listdiv"
                            >
                              <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                                <li style={{ textAlign: "center" }}>Whatsapp</li>
                              </a>
                              <li
                                className="mb-2 ml-4"
                                id="toggleEmailModal"
                                style={{
                                  cursor: "pointer",
                                  color: "#e5e9ec",
                                  textAlign: "center",
                                }}
                                data-toggle="modal"
                                data-target="#shareToEmail"
                              >
                                Email
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>








                      
                    </div>
                  </div>
                </div>
                <div className="card radius-15 print-only mt-3" id="pdf-card">
                  <div className="card-body">
                      <div className="container-fluid">

                          <div  id="printReport"  className="printReportTemplate" style={{display:'block', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: '5px'}}>
                              <div className="my-5 page" size="A4" >
                                  <div id="printdiv2">
                                      <div className="py-4 bg-secondary" id="tableHead">
                                          <div className="col-12" >
                                                  <center className="h5 text-white" ><b style={{color:'white'}}>{companyName}</b></center>
                                                  <center className="h3 text-white"><b style={{color:'white'}}>Employee Loan Report</b></center>
                                                  <center><b id="datefilter2" style={{color:'white'}}></b></center>
                                                  
                                          </div>  
                                          
                                      </div>
                                      <div className="row px-1 py-1">
                                          <div className="col-12">
                                              <section className="product-area mt-2">
                                                  <table className="table table-responsive-md mt-4 table-hover  table-bordered" id="reportTable">
                                                      <thead>
                                                          <tr>
                                                              <th className="text-center">Sl.No.</th>
                                                              <th className="text-center">EMPLOYEE NAME</th>
                                                              <th className="text-center">EMPLOYEE NO.</th>
                                                              <th className="text-center">LOAN DATE</th>
                                                              <th className="text-center">DUE DATE</th>
                                                              <th className="text-center">LOAN AMOUNT</th>
                                                              <th className="text-center">BALANCE AMOUNT</th>
                                      
                   
                   
                                                         </tr>
                                                      </thead>
                                                      <tbody>
                                                          { loanData.map((item,index) =>(
                                                              <tr>
                                                              <td style={{width:'5%'}} className="text-center">{ index+1 }</td>
                                                              <td style={{width:'16%'}} className="text-center">{item.empname}</td>
                                                              <td style={{width:'16%'}} className="text-center">{item.empno}</td>
                                                              <td style={{width:'16%'}} className="text-center">{item.loandate}</td>
                                                              <td style={{width:'16%'}} className="text-center">{item.duedate}</td>
                                                              <td style={{width:'16%'}} className="text-center">{item.loan}</td>
                                                              <td style={{width:'16%'}} className="text-center">{item.balance}</td>
                                                          </tr>
                                                          ))}
                                                          {/* <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className="text-center" style={{fontWeight:'bold',fontSize:'larger'}} id="loanTotal">Total Loan Amount:{totloan}</td>
                                                            <td className="text-center" style={{fontWeight:'bold',fontSize:'larger'}} id="BalTotal">Total Balance:{totbal}</td>
                                                          </tr> */}

                                                      </tbody>
                                                  </table>
                                                  {/* {% if not loan %}
                                                  <center><h4 className="text-dark">No data available.!</h4></center>
                                                  {% endif %} */}
                                              </section>
                                  
                                              <section className="balance-info text-dark">
                                                  <div className="row p-4">
                                                      <div className="col-12">
                                                          <div className="row mb-2 d-flex justify-content-end">
                                                              <div className="mr-5 font-weight-bold">Total Loan Amount:{totloan} <p id="loanTotal" style={{fontSize:'larger'}}></p></div>
                                                              <div className="mr-5 font-weight-bold">Total Balance:{totbal} <p id="BalTotal" style={{fontSize:'larger'}}></p></div>
                                                              
                                                          </div>
                                                      </div>
                                                  </div>
                                              </section>
                                          </div>
                                              
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

                






              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="shareToEmail">
         <div className="modal-dialog modal-lg">
           <div className="modal-content" style={{ backgroundColor: "#f4f5f7" }}>
             <div className="modal-header">
               <h5 className="m-3">Share Report</h5>
               <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>












    </div>
  );
}

export default Employeeloanstatement;
