import React, { useEffect, useState } from 'react'
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import axios from 'axios';
import Cookies from "js-cookie";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import Select from 'react-select';

import "../../styles/BankStatement.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';



function LoanAccountStatement() {

  const ID = Cookies.get("user_id");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
 
  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
const [stockDetail, setStockDetail] = useState({
    stock_detail: [],
    count_items: 0,
    total_closing_quantity: 0,
    company_name: ''
});
  const [bankstatement, setBankStatement] = useState({ transactions: [], total_deposit: 0, total_withdrawal: 0, total_balance: 0,company_name:'' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [loanStatements, setLoanStatements] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalLoanAmount, setTotalLoanAmount] = useState(0);
  const [company,setCompany]=useState('')


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgb(255 255 255 / 14%)",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      // width: '200px', // Adjust the width of the dropdown menu
      // maxHeight: '150px' // Adjust the height of the dropdown menu
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999 // Ensure the dropdown appears above other elements

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "lightgray"
        : state.isFocused
        ? "lightgray"
        : "white",
      color: state.isSelected ? "black" : "black",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
  };



  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
      // Position the div just below the button
      // var buttonRect = event.target.getBoundingClientRect();
      // contentDiv.style.top = (buttonRect.bottom + window.scrollY) + "px";
      // contentDiv.style.left = buttonRect.left + "px";
    }
  }


  useEffect(() => {
    const fetchLoanStatements = async () => {
      if (!ID) {
        setError('User ID is not available in cookies.');
        setLoading(false);
        return;
      }
  
      setLoading(true); // Start loading
      try {
        const response = await axios.get(`${config.base_url}/loan_account_statement`, {
          params: { user_id: ID }
        });
        console.log(response.data)
        const { loan_statements, total_balance, total_loan_amount,company} = response.data;
        setLoanStatements(loan_statements);
        setTotalBalance(total_balance);
        setTotalLoanAmount(total_loan_amount);
        setCompany(company)
        
        setError(''); // Clear any previous errors
      } catch (err) {
        console.error("Error fetching loan statements:", err); // Log the error
        setError(err.response ? err.response.data.error : 'An error occurred');
      } finally {
        setLoading(false); // End loading
      }
    };
  
    fetchLoanStatements();
  
    // Optional: Cleanup function to avoid setting state on unmounted component
    return () => {
      setLoading(false);
      setError(null); // Reset error if needed
    };
  }, [ID]);
  


  const fetchLoanStatementCustomized = async (e) => {
    e.preventDefault();

    toggleContent();
    console.log("fetching credit note details");

    const params = {
        id: ID,
        start_date: startDate,
        end_date: endDate,
    };

    try {
        const response = await axios.get(`${config.base_url}/fetch_loan_statement_customized/`, { params });
        const { loan_statements, total_balance, total_loan_amount,company} = response.data;
        setLoanStatements(loan_statements);
        setTotalBalance(total_balance);
        setTotalLoanAmount(total_loan_amount);
        setCompany(company)
    } catch (err) {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
            Swal.fire({
                icon: "error",


                title: `${err.response.data.message}`,
            });
        }
    }
};
  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Loan Account Statement" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  } 



  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st && en) {
        date = `_${startDate}` + "_" + `${endDate}`;
    }
    
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    
    // Set column widths
    const colWidth = [{ wch: 20 }, { wch: 20 }, { wch: 30 }]; // Adjust widths as needed
    ws['!cols'] = colWidth;

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Loan Account Statement" + date + ".xlsx");
}


  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
         <style>
        body {
          display: flex;
          justify-content: center;

          height: 100vh;
          margin: 0;
        }
        .centered-content {
          text-align: center;
          width: 100%;
        }
      </style>
        `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }



  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;







  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
   
  

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          // document.getElementById("share_to_email_form").submit();
          var em = {
            Id: ID,
            start: st,
            end: end,
           
           
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/share_loan_statement_To_Email/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
            }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
   
  }
  

  



  

  return (
    <>
     <style>
                {`
                    @media (max-width: 768px) {
                        .total-balance {
                            font-size: 14px; 
                             white-space: nowrap;/* Reduce font size on mobile */
                        }
                    }
                `}
            </style>
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper d-flex">
                <Sidebar />

                <div className="main-panel" style={{ background: "#a9a9a961", maxWidth: "100%" }}>
                    <div className="content-wrapper">
                        <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
                            <div className="container-fluid">

                                <div className="card radius-15 h-20">
                                    <div className="card-body">
                                        <div className="card-title text-center">
                                            <h2 className="text-uppercase" id="headline">LOAN ACCOUNT STATEMENT</h2>
                                           
                                            <hr />
                                        </div>
                                        <div className="bar d-flex justify-content-between flex-wrap">

                                            <div className="left d-flex">
                                                <button className="btn btn-secondary" onClick={toggleContent}>
                                                    <i className="fa fa-solid fa-gear"></i> Customize Report
                                                </button>

                                                <div id="contentDiv" style={{ display: "none",width: "300px" ,position: "absolute",zIndex:1,backgroundColor:"white",border:"1.5px solid gray" }}>
                                                    <h6>Customize Report</h6>
                                                    <form onSubmit={fetchLoanStatementCustomized} className="form reportCustomizeForm px-1" method="get">
                                                        <div className="px-2 w-100">
                                                            <label style={{ textAlign: "left" }}>From</label>
                                                            <input
                                                                className="inputdate form-control"
                                                                type="date"
                                                                name="start_date"
                                                                id="startDate"
                                                                value={startDate}
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="px-2 w-100">
                                                            <label style={{ textAlign: "left" }}>To</label>
                                                            <input
                                                                type="date"
                                                                className="inputdate form-control"
                                                                name="end_date"
                                                                id="endDate"
                                                                value={endDate}
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                                required
                                                            />
                                                        </div>

                                                        <div className="d-flex px-2 mt-3 mb-4 w-100">
                                <button
                                // type="submit"
                                type="submit"
                                // onClick={fetchItemReport}
                                className="btn btn-outline-secondary text-grey w-50"
                                style={{
                                    width: "fit-content",
                                    height: "fit-content",
                                }}
                                >
                                Run Report
                                </button>
                                <button
                                type="reset"
                                // type="button"
                                onClick={toggleContent}
                                className="btn btn-outline-secondary text-grey ml-1 w-50"
                                style={{
                                    width: "fit-content",
                                    height: "fit-content",
                                }}
                                >
                                Cancel
                                </button>
                            </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="right d-flex flex-wrap">
                                                <Link className="btn btn-outline-secondary text-grey fa fa-file ml-2" role="button" onClick={reportPDF}> PDF </Link>
                                                <Link className="btn btn-outline-secondary text-grey fa fa-print ml-2" role="button" onClick={printSection}> Print </Link>
                                                <Link className="btn btn-outline-secondary text-grey fa fa-table ml-2" role="button" onClick={ExportToExcel}> Export </Link>
                                                <div className="dropdown p-0 nav-item ml-2"  id="shareBtn">
                                                    <li className="dropdown-toggle btn btn-outline-secondary text-gray fa fa-share-alt" data-toggle="dropdown"> Share </li>
                                                    <ul className="dropdown-menu" id="listdiv">
                                                        <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                                                            <li style={{ textAlign: "center", color: "#e5e9ec", cursor: "pointer" }}>WhatsApp</li>
                                                        </a>
                                                        <li style={{ textAlign: "center",  color: "#e5e9ec",cursor: "pointer" }} data-toggle="modal" data-target="#shareToEmail">Email</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-registration card-registration-2 card radius-15 mt-3">
                                    <div className="card-body p-0">
                                        <div className="container-fluid">
                                            <div id="printReport" className="printReportTemplate" style={{ display: "block" }}>
                                                <div className="my-5 page" size="A4">
                                                    <div id="printdiv2">
                                                        <div className="py-4 bg-secondary">
                                                            <div className="col-12 text-center">
                                                                <h5 className="text-white">{company}</h5>
                                                                <h3 className="text-white">LOAN ACCOUNT STATEMENT</h3>
                                                                {startDate && endDate && (
                                                                    <h6 className="text-white">{startDate} {"TO"} {endDate}</h6>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="row px-1 py-1">
                                                            <div className="col-12">
                                                                <section className="product-area mt-2 py-1">
                                                                    <table className="table table-responsive-md mt-4 table-hover" id="reportTable">
                                                                        <thead>
                                                                            <tr>
                                                                                <th><center>SI</center></th>
                                                                                <th><center>ACCOUNT HOLDER NAME</center></th>
                                                                                <th><center>LOAN DATE</center></th>
                                                                               
                                                                                <th><center>LOAN AMOUNT</center></th>
                                                                                <th><center>BALANCE AMOUNT</center></th>
                                                                               

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                       
                                                                        {loanStatements.map((loan, index) => (
                <tr key={index}>
                  <td style={{ textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ textAlign: 'center' }}>{loan.account_holder_name}</td>
                  <td style={{ textAlign: 'center' }}>{new Date(loan.loan_date).toLocaleDateString()}</td>
                 
                  <td style={{ textAlign: 'center' }}>{loan.loan_amount}</td>
                  <td style={{ textAlign: 'center' }}>{loan.balance_amount}</td>
                </tr>
              ))}
                                                                             {/* <tr>
                                                                                <td><div  style={{ fontSize: 19, fontWeight: 'bold' }}>
                                                                                      <center>  Total Items: {stockDetail.count_items}</center>
                                                                                    </div></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                <td> </td>
                                                                                <td></td>
                                                                                <td><div  style={{ fontSize: 19, fontWeight: 'bold' }}>
                                                                                      <center>  Total Closing Quantity: {stockDetail.total_closing_quantity}</center>
                                                                                    </div></td>
                                                                                
                                                                            </tr>  */}
                                                                        </tbody>
                                                                    </table>
                                                                    <div style={{ display: 'flex',gap: '80px',justifyContent:"space-between", margin: '20px 0',marginTop:'60px' }}>
                                                                 <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                                                                  Total Loan Amount: {totalLoanAmount} 
                                                                </div>
                                                    <div style={{ fontSize: 16, fontWeight: 'bold'}}>
                                                      Total Balance Amount: {totalBalance}

                                                </div>
                                                </div>


                                                                    {/* {loan.stock_detail.length === 0 && (
                                                                        <center><h4 className="text-dark">No data available!</h4></center>
                                                                    )} */}

                                                                </section>

                                                               

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Share To Email Modal */}
                                <div className="modal fade" id="shareToEmail">
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="m-3">Share Stock Details</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <form onSubmit={handleShareEmail} className="needs-validation px-1" id="share_to_email_form">
                                                    <div className="card p-3 w-100">
                                                        <div className="form-group">
                                                            <label htmlFor="emailIds">Email IDs</label>
                                                            <textarea className="form-control" name="email_ids" id="emailIds" rows="3"
                                                                placeholder="Multiple emails can be added by separating with a comma(,)."
                                                                value={emailIds} onChange={(e) => setEmailIds(e.target.value)} required />
                                                        </div>
                                                        <div className="form-group mt-2">
                                                            <label htmlFor="item_unitname">Message (optional)</label>
                                                            <textarea name="email_message" id="email_message" className="form-control" cols="" rows="4"
                                                                value={emailMessage} onChange={(e) => setEmailMessage(e.target.value)}
                                                                placeholder="This message will be sent along with Payment Made details." />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer d-flex justify-content-center w-100">
                                                        <button type="submit" id="share_with_email" className="submitShareEmailBtn w-50 text-uppercase">SEND MAIL</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
     </div>


    </>
  )
}

export default LoanAccountStatement