import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate,useParams } from "react-router-dom";
import Select from "react-select";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import * as XLSX from "xlsx";

function Edit_cashinhand(){
    const ID = Cookies.get("user_id");
    const { id } = useParams(); // Get the id from URL params
    const navigate = useNavigate();
    const [transactionType, setTransactionType] = useState("");
    const [amount, setAmount] = useState(0);
    const [adjustDate, setAdjustDate] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (id) {
        axios
            .get(`${config.base_url}/cash_in_hand_details/${id}/`)
            .then((res) => {
            if (res.data.status) {
                const data = res.data.data;
                setTransactionType(data.adjustment);
                setAmount(data.amount);
                setAdjustDate(data.adjust_date);
                setDescription(data.description || ""); // Handle empty description
            } else {
                Swal.fire({
                icon: "error",
                title: "Failed to fetch data",
                });
            }
            })
            .catch((err) => {
            console.error("ERROR=", err);
            Swal.fire({
                icon: "error",
                title: "An error occurred while fetching data.",
            });
            });
        }
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const dt = {
        Id: ID,
        adjustment: transactionType,
        amount,
        adjust_date: adjustDate,
        description: description || null, // Send null if description is empty
        };

        axios
        .put(`${config.base_url}/cash_in_hand_details/${id}/`, dt)
        .then((res) => {
            if (res.data.status) {
            Toast.fire({
                icon: "success",
                title: "Cash Adjustment Updated",
            });
            navigate("/cashinhand");
            } else if (!res.data.status && res.data.message !== "") {
            Swal.fire({
                icon: "error",
                title: `${res.data.message}`,
            });
            }
        })
        .catch((err) => {
            console.log("ERROR=", err);
            if (err.response && !err.response.data.status) {
            Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
            });
            }
        });
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
        },
    });
    
return(
    <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
    className="main-panel px-3 py-2"
    style={{ background: "#a9a9a961", maxWidth: "100vw" }}
    >
    <Link
        className="d-flex justify-content-end p-2"
        style={{ cursor: "pointer" }}
        to="/cashinhand"
    >
        <i
        className="fa fa-times-circle text-white"
        style={{ fontSize: "1.2rem" }}
        ></i>
    </Link>
    <div className="card radius-15 h-20 mt-3">
        <div className="row w-100">
        <div className="col-md-12">
            <center>
            <h2 className="mt-3">EDIT CASH IN HAND</h2>
            </center>
            <hr />
        </div>
        </div>
    </div>

    <div className="card radius-15 mt-3" style={{ minWidth: "100%" }}>
        <div className="card-body">
        <div className="container-fluid">
        <form id="cashAdjustForm" className="px-1" onSubmit={handleSubmit}>
        <div className="row w-100">
            <div className="col-md-5 p-2">
            <label htmlFor="transactionType">ADJUSTMENT</label>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5 p-2">
            <label htmlFor="amount">AMOUNT</label>
            </div>
        </div>

        <div className="row w-100">
            <div className="col-md-5 p-2">
            <select
                className="form-control"
                name="transactionType"
                value={transactionType}
                onChange={(e) => setTransactionType(e.target.value)}
                id="transactionType"
                required
            >
                <option value="" disabled>Select Transaction Type</option>
                <option value="add">Add Cash</option>
                <option value="reduce">Reduce Cash</option>
            </select>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5 p-2">
            <input
                className="form-control"
                type="number"
                name="amount"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
            />
            </div>
        </div>

        <div className="row w-100">
            <div className="col-md-5 p-2">
            <label htmlFor="adjustDate">ADJUST DATE</label>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5 p-2">
            <label htmlFor="description">DESCRIPTION</label>
            </div>
        </div>

        <div className="row w-100">
            <div className="col-md-5 p-2">
            <input
                className="form-control"
                type="date"
                value={adjustDate}
                onChange={(e) => setAdjustDate(e.target.value)}
                name="adjustDate"
                required
            />
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5 p-2">
            <input
                className="form-control"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                name="description"
            />
            </div>
        </div>

        <div className="row mt-5 mb-5 w-100">
            <div className="col-md-4"></div>
            <div className="col-md-4 d-flex justify-content-center">
            <button
                className="btn btn-outline-secondary text-dark"
                type="submit"
                style={{ width: "50%", height: "fit-content" }}
            >
                SAVE
            </button>
            <Link
                to="/cashinhand"
                className="btn btn-outline-secondary ml-1 text-dark"
                style={{ width: "fit-content", height: "fit-content" }}
            >
                CANCEL
            </Link>
            </div>
            <div className="col-md-4"></div>
        </div>
        </form>
        </div>
      </div>
      </div>
     </div>
    </div>
  </div>
        
 );
}
export default Edit_cashinhand;