import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "./styles/Staff.css"
import * as XLSX from "xlsx";

function LoanHolderlist() {
  const [requests, setRequests] = useState([]);
  const user = Cookies.get("role");

  const navigate = useNavigate();
  function exportToExcel() {
    const Table = document.getElementById("itemsTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "loan.xlsx");
  }

  function sortTable(columnIndex) {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("itemsTable");
    switching = true;

    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();
        y = rows[i + 1]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();

        if (x > y) {
          shouldSwitch = true;
          break;
        }
      }

      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }

  function filterTable(row, filterValue) {
    var table = document.getElementById("itemsTable");
    var rows = table.getElementsByTagName("tr");

    for (var i = 1; i < rows.length; i++) {
      var statusCell = rows[i].getElementsByTagName("td")[row];

      if (
        filterValue == "all" ||
        statusCell.textContent.toLowerCase() == filterValue
      ) {
        rows[i].style.display = "";
      } else {
        rows[i].style.display = "none";
      }
    }
  }

  function sortLoanAmountAscending() {
    var table = document.getElementById("itemsTable");
    var rows = Array.from(table.rows).slice(1);  
    rows.sort(function (a, b) {
      
      var loanAmountA = parseFloat(a.cells[4].textContent.trim().replace(/[^0-9.-]/g, ''));
      var loanAmountB = parseFloat(b.cells[4].textContent.trim().replace(/[^0-9.-]/g, ''));
  
      return loanAmountA - loanAmountB;  
    });
  
    
    for (var i = table.rows.length - 1; i > 0; i--) {
      table.deleteRow(i);
    }
  
    
    rows.forEach(function (row) {
      table.tBodies[0].appendChild(row);
    });
  }
  function searchTable(){
    var rows = document.querySelectorAll('#itemsTable tbody tr');
    var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }

  const ID = Cookies.get('user_id');
  const [loan, setloan] = useState([]);
  
  const fetchLoan = () =>{
    axios.get(`${config.base_url}/get_loan_account/${ID}/`).then((res)=>{
      console.log("holder RES=",res)
      if(res.data.status){
        var itms = res.data.loan;
        setloan([])
        itms.map((i)=>{
          var obj = {
            id: i.id,
            date: i.date,
            employee_f: i.account_name ,
            acc_no: i.account_number,
            loan_amount:i.loan_amount,
            balance :i.balance,
            
            
            status: i.status
          }
          setloan((prevState)=>[
            ...prevState, obj
          ])
        })
      }
    }).catch((err)=>{
      console.log('ERR',err)
    })
  }

  useEffect(()=>{
    fetchLoan();
  },[])
  
  function refreshAll(){
    setloan([])
    fetchLoan();
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961", width:"100%" }}
        >
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
                <div className="card radius-15 h-20 mt-3">
                  <div className="row w-100">
                    <div className="col-md-12">
                      <center>
                        <h2 className="mt-3">ALL LOAN</h2>
                      </center>
                      <hr />
                    </div>
                  </div>
                </div>
                <div className="card radius-15 mt-3">
    <div className="card-body">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-md-4"> {/* Full width on mobile */}
                    <div className="d-flex align-items-center">
                        <input
                            type="text"
                            id="search"
                            className="form-control mr-1" // Added margin-right for spacing
                            placeholder="Search.."
                            autoComplete="off"
                            onKeyUp={searchTable}
                            style={{  width: "100%", maxWidth: "200px"}} // Ensure it grows to take available space
                        />
                        <div className="dropdown">
                            <button
                                type="button"
                                className="btn btn-outline-secondary dropdown-toggle text-grey"
                                data-toggle="dropdown"
                                style={{ width: "100%", maxWidth: "150px"}}
                            >
                                <i className="fa fa-sort"></i> Sort by
                            </button>
                            <div className="dropdown-menu" >
                                <a className="dropdown-item" onClick={refreshAll}>All</a>
                                <a className="dropdown-item" onClick={() => sortTable(2)}>Employee Name</a>
                                <a className="dropdown-item" onClick={sortLoanAmountAscending}>Loan Amount</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-md-end">
                    <button
                        type="button"
                        className="btn btn-outline-secondary text-grey mb-2"
                        id="exportBtn"
                        onClick={exportToExcel}
                    >
                        <i className="fa fa-table"></i> Export To Excel
                    </button>
                    <div className="dropdown mb-2">
                        <button
                            type="button"
                            className="btn btn-outline-secondary dropdown-toggle text-grey"
                            data-toggle="dropdown"
                        >
                            <i className="fa fa-filter"></i> Filter by
                        </button>
                        <div className="dropdown-menu" >
                            <a className="dropdown-item" onClick={() => filterTable(5, 'all')}>All</a>
                            <a className="dropdown-item" onClick={() => filterTable(5, 'active')}>Active</a>
                            <a className="dropdown-item" onClick={() => filterTable(5, 'inactive')}>Inactive</a>
                        </div>
                    </div>
                    <Link to="/addholderloan" className="ml-1">
                        <button type="button" className="btn btn-outline-secondary text-grey mb-2">
                            <i className="fa fa-plus font-weight-light"></i> Account Loan
                        </button>
                    </Link>
                </div>
            </div>
        </div>
        <div className="table-responsive">
            <table className="table table-responsive-md table-hover mt-4" id="itemsTable" style={{ textAlign: "center" }}>
                <thead>
                    <tr>
                        <th>SL.NO.</th>
                        <th>DATE</th>
                        <th>HOLDER NAME</th>
                        <th>ACCOUNT NO</th>
                        <th>LOAN AMOUNT</th>
                        <th>STATUS</th>
                    </tr>
                </thead>
                <tbody>
                    {loan && loan.map((i, index) => (
                        <tr
                            className="clickable-row"
                            onClick={() => navigate(`/viewloanholder/${i.id}/`)}
                            style={{ cursor: "pointer" }}
                            key={i.id}
                        >
                            <td>{index + 1}</td>
                            <td>{i.date}</td>
                            <td>{i.employee_f}</td>
                            <td>{i.acc_no}</td>
                            <td>{i.loan_amount}</td>
                            <td>{i.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
</div>







                

                {/* <div className="card radius-15 mt-3">
                    <div className="card-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="d-flex align-items-center">
                                        <input
                                        type="text"
                                        id="search"
                                        className="form-control"
                                        placeholder="Search.."
                                        autoComplete="off"
                                        onKeyUp={searchTable}
                                        />
                                        <div
                                        className="dropdown ml-1"
                                        style={{ justifyContent: "left" }}
                                        >
                                            <button
                                                type="button"
                                                style={{ width: "fit-content", height: "fit-content" }}
                                                className="btn btn-outline-secondary dropdown-toggle text-grey"
                                                data-toggle="dropdown"
                                            >
                                                <i className="fa fa-sort"></i> Sort by
                                            </button>
                                            <div
                                                className="dropdown-menu"
                                                style={{ backgroundColor: "black" }}
                                            >
                                                <a
                                                className="dropdown-item"
                                                onClick={refreshAll}
                                                style={{
                                                    height: "40px",
                                                    fontSize: "15px",
                                                    color: "white",
                                                }}
                                                >
                                                All
                                                </a>
                                                <a
                                                className="dropdown-item"
                                                style={{
                                                    height: "40px",
                                                    fontSize: "15px",
                                                    color: "white",
                                                    cursor: "pointer",
                                                }}
                                                onClick={()=>sortTable(2)}
                                                >
                                                Employee Name
                                                </a>
                                                <a
                                                className="dropdown-item"
                                                style={{
                                                    height: "40px",
                                                    fontSize: "15px",
                                                    color: "white",
                                                    cursor: "pointer",
                                                }}
                                                onClick={sortLoanAmountAscending}
                                                >
                                                Loan Amount
                                                </a>                                                                     
                                            </div>
                                        </div>                               
                                    </div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button
                                        type="button"
                                        style={{ width: "fit-content", height: "fit-content" }}
                                        className="btn btn-outline-secondary text-grey"
                                        id="exportBtn"
                                        onClick={exportToExcel}
                                    >
                                        <i className="fa fa-table"></i> Export To Excel
                                    </button>
                                    <div className="dropdown ml-1">
                                        <button
                                        type="button"
                                        style={{ width: "fit-content", height: "fit-content" }}
                                        className="btn btn-outline-secondary dropdown-toggle text-grey"
                                        data-toggle="dropdown"
                                        >
                                        <i className="fa fa-filter"></i> filter by
                                        </button>
                                        <div
                                        className="dropdown-menu"
                                        style={{ backgroundColor: "black" }}
                                        >
                                            <a
                                                className="dropdown-item"
                                                style={{
                                                height: "40px",
                                                fontSize: "15px",
                                                color: "white",
                                                cursor: "pointer",
                                                }}
                                                onClick={()=>filterTable(5,'all')}
                                            >
                                                All
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                style={{
                                                height: "40px",
                                                fontSize: "15px",
                                                color: "white",
                                                cursor: "pointer",
                                                }}
                                                onClick={()=>filterTable(5,'active')}
                                            >
                                                Active
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                style={{
                                                height: "40px",
                                                fontSize: "15px",
                                                color: "white",
                                                cursor: "pointer",
                                                }}
                                                onClick={()=>filterTable(5,'inactive')}
                                            >
                                                Inactive
                                            </a>
                                        </div>
                                    </div>
                                    <Link to="/addholderloan" className="ml-1">
                                        <button
                                        type="button"
                                        style={{ width: "fit-content", height: "fit-content" }}
                                        className="btn btn-outline-secondary text-grey"
                                        >
                                        <i className="fa fa-plus font-weight-light"></i> Account Loan
                                        </button>
                                    </Link>
                                </div>
                                                                                                                    
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table
                        className="table table-responsive-md table-hover mt-4"
                        id="itemsTable"
                        style={{ textAlign: "center" }}
                        >
                        <thead>

                        
                            <tr>
                                <th>SL.NO.</th>
                                <th>DATE</th>
                                <th>HOLDER NAME</th>
                                <th>ACCOUNT NO</th>
                                
                                <th>LOAN AMOUNT</th>
                                
                                
                                
                                <th>STATUS</th>
                            
                            </tr>
                        </thead>
                        <tbody>
                          {loan && loan.map((i, index) => {
                            console.log(i.id); 
                            return (
                              <tr
                                className="clickable-row"
                                onClick={() => navigate(`/viewloanholder/${i.id}/`)}
                                style={{ cursor: "pointer" }}
                                key={i.id} 
                              >
                                <td>{index + 1}</td>
                                <td>{i.date}</td>
                                <td>{i.employee_f}</td>
                                <td>{i.acc_no}</td>
                                <td>{i.loan_amount}</td>
                                <td>{i.status}</td>
                              </tr>
                            );
                          })}
                        </tbody>

                                                    

                        </table>
                                


                    </div>
                        
                    
                   

                    
            
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanHolderlist;

