import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";

function PaymentMadeHistory() {

  const user = Cookies.get("role");
  const ID = Cookies.get("user_id");
  const { paymentId } = useParams();
  const [history, setHistory] = useState([]);
  const [payment, setPayment] = useState({});

  const fetchInvoiceHistory = () => {
    axios
      .get(`${config.base_url}/fetch_payment_made_history/${paymentId}/`)
      .then((res) => {
        console.log("PAY HIST=", res);
        if (res.data.status) {
          var pay = res.data.payment;
          var hist = res.data.history;
          setPayment(pay);
          setHistory([]);
          hist.map((i) => {
            setHistory((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchInvoiceHistory();
  }, []);

  return (
    <>
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />

        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961", maxWidth:"100vw" }}
        >
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
                <div className="container-fluid">

                <Link
                  className="d-flex justify-content-end p-1"
                  style={{ cursor: "pointer" }}
                  to={`/view_payment_made/${paymentId}/`}
                >
                  <i
                    className="fa fa-times-circle"
                    style={{ fontSize: "1.2rem" , color: "black" }}
                  ></i>
                </Link>


                <div className="card radius-15">
                              <div className="card-body" style={{ width: "100%" }}>
                                <div className="card-title">
                                  <center>
                                    <h3
                                      className="card-title"
                                      style={{ textTransform: "Uppercase" }}
                                    >
                                      BILL TRANSACTIONS
                                    </h3>
                                    {payment.status == "Draft" ? (
                                    <h6
                                        className="blinking-text"
                                        style={{ color: "red", width: "140px", fontWeight: "bold" }}
                                    >
                                        Draft
                                    </h6>
                                    ) : (
                                    <h6
                                        style={{
                                        width: "140px",
                                        color: "green",
                                        fontWeight: "bold",
                                        }}
                                    >
                                        Saved
                                    </h6>
                                    )}
                                  </center>
                                  <hr/>
                                </div>
                              </div>
                            </div>



                  

                    <div className="card radius-15 mt-3">
                        
                        <div className="card-body">

                        <div
                            className="page-content"
                            style={{ minHeight: "100vh" }}
                          >
                            

                            <div
                              className="card card-registration card-registration-2"
                              style={{ borderColor: "white" }}
                            >


                              <div className="card-body p-0">
                              <div id="history">
                                <center>
                                    <h3 className="mt-3 text-uppercase">
                                    #{payment.payment_no} - TRANSACTIONS
                                    </h3>
                                </center>
                                <div className="table-responsive px-2">
                                    <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                        <th className="text-center">SL NO.</th>
                                        <th className="text-center">DATE</th>
                                        <th className="text-center">ACTION</th>
                                        <th className="text-center">DONE BY</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {history &&
                                        history.map((h, index) => (
                                            <tr>
                                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                                            <td style={{ textAlign: "center" }}>{h.date}</td>
                                            {h.action == "Created" ? (
                                                <td className="text-success text-center">
                                                {h.action}
                                                </td>
                                            ) : (
                                                <td className="text-warning text-center">
                                                {h.action}
                                                </td>
                                            )}
                                            <td style={{ textAlign: "center" }}>{h.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                              </div>
                            </div>
                          </div>







                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>



      
    </>
  )
}

export default PaymentMadeHistory