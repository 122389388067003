import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../functions/config";
import {  useParams } from 'react-router-dom'; // Import useParams here
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import html2pdf from 'html2pdf.js';
import Cookies from "js-cookie";
import Sidebar from "./Sidebar";
import "./SalesOrderDetails.css"; // Assuming external CSS file

function SalesOrderDetails() {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const { orderId } = useParams();
  const [salesDetails, setSalesDetails] = useState({});
  const [file, setFile] = useState(null);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  
  const [otherDetails, setOtherDetails] = React.useState({
    Company_name: '',
    Email: '',
    Mobile: '',
    Address: '',
    City: '',
    State: '',
    Pincode: '',
    customerName: '',
    customerEmail: ''
  });
  
  const [salesItems, setSalesItems] = useState([]);
  const [history, setHistory] = useState({});
  const [companyName, setCompanyName] = useState('COMPANY NAME');

  

  useEffect(() => {
    // Fetch sales order details only if orderId exists
    if (orderId) {
      fetchSalesOrderDetails(orderId);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Order ID is missing',
      });
    }
  }, [orderId]);
  
  // Fetch Sales Order Details
  const fetchSalesOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(`${config.base_url}/sales-order-overview/${orderId}/`);
      if (response.status === 200) {
        const { sales_order, other_details, items} = response.data;

        // Set fetched data to the state
        setSalesDetails(sales_order);
        setItems(items);
        setOtherDetails(other_details);
       
      } else {
        Swal.fire({
          icon: 'error',
          title: 'No data found',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: error.response?.data?.detail || 'Error fetching sales order details',
      });
    }
  };

  // Fetch details when component mounts
  useEffect(() => {
    if (orderId) {
      fetchSalesOrderDetails(orderId);
    }
  }, [orderId]);
  

  const [paymentDetails, setPaymentDetails] = useState({
    PaymentRequest: false,
    daysLeft: 0,
    paymentTerm: false,
    endDate: "",
    alertMessage: false,
  });

  const ID = Cookies.get("user_id");
  const user = Cookies.get("role");

  

 

  const handleConvertSalesOrder = () => {
    console.log("Convert Sales Order clicked");
  };

  // Function to generate the PDF
const salesOrderPdf = () => {
  console.log("Sales Order PDF clicked");

  // Select the element to print
  const element = document.getElementById("whatToPrint");

  if (element) {
    // Use html2pdf to generate the PDF
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // Margins (top, right, bottom, left) in inches
      filename: 'sales_order.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, width: 794, height: 1123 }, // A4 size in pixels at 96 DPI
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }, // A4 size and portrait mode
    };

    html2pdf().set(opt).from(element).save();
  }
};

 // Function to generate the PDF
const salesOrderslipPdf = () => {
  console.log("Sales Order PDF clicked");

  // Select the element to print
  const element = document.getElementById("slip");

  if (element) {
    // Use html2pdf to generate the PDF
    const opt = {
      margin: [0.2, 0.2, 0.2, 0.2], // Margins (top, right, bottom, left) in inches
      filename: 'sales_order.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2, 
        dpi: 300, // Higher DPI for better quality
        letterRendering: true, 
        useCORS: true 
      },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }, // A4 size and portrait mode
    };

    html2pdf().set(opt).from(element).save();
  }
};

function printSheet() {
  var divToPrint = document.getElementById("whatToPrint");
  var printWindow = window.open("", "", "height=700,width=1000");

  printWindow.document.write("<html><head><title></title>");
  printWindow.document.write(`
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
  `);
  printWindow.document.write("</head>");
  printWindow.document.write("<body>");
  printWindow.document.write(divToPrint.outerHTML);
  printWindow.document.write("</body>");
  printWindow.document.write("</html>");
  printWindow.document.close();
  printWindow.print();
  printWindow.addEventListener("afterprint", function () {
    printWindow.close();
  });
}



const print2 = () => {
  const divToPrint = document.getElementById("slip_container");
  const printWindow = window.open("", "", "height=700,width=1000");

  // Define print-specific CSS styles
  const styles = `
    .slip h5 {
      font-family: serif;
    }
    p {
      font-size: 1.2em;
    }
    .address {
      display: flex;
      flex-direction: column;
    }
    .address p,
    .slip-footer p {
      font-size: 1rem;
      margin: 0;
    }
    .slip-container {
      width: 105mm;
      margin: 2rem auto;
      padding: 2rem;
      border: 1px dotted black;
      box-shadow: rgba(60, 64, 67, 0.5) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.35) 0px 2px 6px 2px;
    }
    .divider {
      margin: 1rem 0;
      border-bottom: 3px dotted black;
    }
    .trns-id p,
    .datetime p,
    .createdby p {
      font-size: 0.85rem;
      margin: 0;
    }
    .equal-length-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 2vh;
      margin-right: 2vh;
    }
    .equal-length-item {
      flex: 1;
      text-align: center;
    }
  `;

  // Open a new window for printing
  printWindow.document.write("<html><head><title>Print Document</title>");
  printWindow.document.write(`
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
  `);
  printWindow.document.write("</head><body>");
  printWindow.document.write("<style>");
  printWindow.document.write(styles); // Include custom print styles
  printWindow.document.write("</style>");
  printWindow.document.write(divToPrint.outerHTML); // Add the content to print
  printWindow.document.write("</body></html>");
  
  printWindow.document.close(); // Close the document for writing
  printWindow.focus(); // Focus on the new window
  printWindow.print(); // Trigger the print dialog

  // Close the new window after printing
  printWindow.addEventListener("afterprint", () => {
    printWindow.close();
  });
};




  const handleDeleteSalesOrder = async (orderId) => {
    try {
      const response = await axios.delete(`${config.base_url}/sales_orderdelete/${orderId}/`);
      if (response.status === 200) {
        Swal.fire("Deleted!", "Sales order has been deleted.", "success").then(() => {
          navigate('/Salesorder'); // Redirect after confirmation
        });
      }
    } catch (error) {
      Swal.fire("Error!", "Could not delete sales order.", "error");
    }
  };
// Define the attachFile function to handle the file attachment logic
const attachFile = () => {
  // Implement your file attachment logic here
  console.log("Attach button clicked");
  // For example, you could trigger a file input or perform any desired action
};



// Function to set the active tab and store it in localStorage
function setActiveTab(tab) {
  localStorage.setItem("activeTab", tab); // Save the active tab to localStorage
  updateButtonVisibility(tab); // Update the visibility of sections and buttons
}

// Function to update the visibility of buttons and sections based on the active tab
function updateButtonVisibility(tab) {
  const sections = ["overview", "template", "slip"];
  const buttonsToShowHide = {
    overview: {
      printBtn: "none", pdfBtn: "none", printBtnslip: "none", pdfBtnslip: "none",
      editBtn: "block", deleteBtn: "block", attachBtn: "block", historyBtn: "block", 
      commentBtn: "block", shareDropdown: "none"
    },
    template: {
      printBtn: "block", pdfBtn: "block", printBtnslip: "none", pdfBtnslip: "none",
      editBtn: "none", deleteBtn: "none", attachBtn: "none", historyBtn: "none",
      commentBtn: "none", shareDropdown: "block"
    },
    slip: {
      printBtn: "none", pdfBtn: "none", printBtnslip: "block", pdfBtnslip: "block",
      editBtn: "none", deleteBtn: "none", attachBtn: "none", historyBtn: "none", 
      commentBtn: "none", shareDropdown: "none"
    }
  };

  // Show or hide sections based on the active tab
  sections.forEach(section => {
    const sectionElement = document.getElementById(section);
    if (sectionElement) {
      sectionElement.style.display = section === tab ? "block" : "none";
    }
  });

  // Show or hide buttons based on the active tab configuration
  const buttonsConfig = buttonsToShowHide[tab];
  Object.keys(buttonsConfig).forEach(buttonId => {
    toggleElementDisplay(buttonId, buttonsConfig[buttonId]);
  });

  // Update the active state of tab buttons
  toggleTabActiveState("overviewBtn", tab === "overview");
  toggleTabActiveState("templateBtn", tab === "template");
  toggleTabActiveState("slipBtn", tab === "slip");
}

// Helper function to toggle element display
function toggleElementDisplay(elementId, displayValue) {
  const element = document.getElementById(elementId);
  if (element) {
    element.style.display = displayValue;
  }
}

// Helper function to toggle tab active state
function toggleTabActiveState(buttonId, isActive) {
  const button = document.getElementById(buttonId);
  if (button) {
    button.classList.toggle("active", isActive); // Add or remove the 'active' class
  }
}

// Functions to switch between tabs
function overview() {
  setActiveTab("overview");
}

function template() {
  setActiveTab("template");
}

function slip() {
  setActiveTab("slip");
}





// Handle page reloads or direct loads

const storedTab = localStorage.getItem('activeTab') || 'overview';
setActiveTab(storedTab);




  
function toggleTemplate(templateId, buttonId) {
  // Hide all print templates
  document.querySelectorAll(".printTemplates").forEach(function (ele) {
      ele.style.display = "none";
  });

  // Show the selected template
  const templateElement = document.getElementById(templateId);
  if (templateElement) {
      templateElement.style.display = "block";
  } else {
      console.error(`Template with ID ${templateId} not found.`);
  }

  // Remove 'active' class from all buttons
  document.querySelectorAll(".templateToggleButtons").forEach(function (ele) {
      ele.classList.remove("active");
  });

  // Add 'active' class to the clicked button
  const buttonElement = document.getElementById(buttonId);
  if (buttonElement) {
      buttonElement.classList.add("active");
  } else {
      console.error(`Button with ID ${buttonId} not found.`);
  }

  // Scroll to the page content if it exists
  const pageContentElement = document.getElementById("page-content");
  if (pageContentElement) {
      pageContentElement.scrollIntoView();
  } else {
      console.error(`Element with ID "page-content" not found.`);
  }
}

   // Function to handle toggling the modal visibility
   const toggleComments = () => {
    const modal = new window.bootstrap.Modal(document.getElementById("commentModal"));
    modal.show();
  };

  const saveSalesOrderComment = async (e) => {
    e.preventDefault();
    const cmt = {
      Id: ID,  // User ID
      SalesOrder: orderId,  // Sales Order ID
      comments: comment,
    };
    
    try {
      const res = await axios.post(`${config.base_url}/add_sales_order_comment/`, cmt);
      if (res.data.status) {
        Toast.fire({
          icon: "success",
          title: "Comment Added",
        });
        setComment(""); // Clear the comment field
        await  fetchComments(); // Fetch sales order details after comment is added
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to add comment",
        });
      }
    } catch (err) {
      console.log("ERROR=", err);
      Swal.fire({
        icon: "error",
        title: err.response?.data?.message || "Error adding comment",
      });
    }
  };

  
   // Function to fetch comments
   const fetchComments = async () => {
    try {
      const response = await axios.get(`${config.base_url}/sales_order_comments/${orderId}/`);
      setComments(response.data);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  // Fetch comments when the modal opens
  useEffect(() => {
    fetchComments();
  }, [orderId]);

  const deleteComment = async (id) => {
    try {
      await axios.delete(`${config.base_url}/sales_order_comments/delete/${id}/`);
      fetchComments(); // Refresh comments after deletion
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  
   // Define the function to handle file modal submission
   const handleFileModalSubmit = (e) => {
    e.preventDefault();
    // Add your file submission logic here
    console.log("File submitted:", file);
  };

  // Handle file input change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
// Define the handleViewHistory function


useEffect(() => {
  // Fetch sales order history data
  const fetchHistory = async () => {
    try {
      const response = await axios.get(`${config.base_url}/history/${orderId}/`);
      setHistory(response.data);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to fetch history data');
      setIsLoading(false);
    }
  };

  if (orderId) {
    fetchHistory();
  }
}, [orderId]);

const handleViewHistory = () => {
  console.log('Viewing history...');
};

const handleFileDownload = async () => {
  try {
    const response = await axios.get(`${config.base_url}/sales_orderdown/${orderId}/download/`, {
      responseType: 'blob',
    });

    // Create a new Blob object using the response data
    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);

    // Create a temporary anchor element for downloading
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = `SalesOrder_${salesDetails.sales_order_no}.pdf`; // Set the file name dynamically

    // Append the anchor to the body, click it, and then remove it
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke the Object URL to free up memory
    URL.revokeObjectURL(fileURL);
  } catch (error) {
    console.error('File download failed:', error);
    alert('File download failed.');
  }
};

// File selection handler
const handleFileUpload = (event) => {
  setSelectedFile(event.target.files[0]);
};

// File submission handler
const submitFile = async () => {
  if (!selectedFile) {
    alert('Please select a file first.');
    return;
  }

  const formData = new FormData();
  formData.append('file', selectedFile);
  formData.append('salesorder_id', orderId);  // Add salesorder ID to the form data

  try {
    const response = await axios.post(`${config.base_url}/upload_file/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    alert('File uploaded successfully!');
  } catch (error) {
    console.error('File upload failed:', error);
    alert('File upload failed.');
  }
};


const currentUrl = window.location.href;
const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
  currentUrl
)}`;
const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var emailsString = emailIds.trim();

    var emails = emailsString.split(",").map(function (email) {
      return email.trim();
    });

    var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    var invalidEmails = [];
    if (emailsString === "") {
      alert("Enter valid email addresses.");
    } else {
      for (var i = 0; i < emails.length; i++) {
        var currentEmail = emails[i];

        if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
          invalidEmails.push(currentEmail);
        }
      }

      if (invalidEmails.length > 0) {
        alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
      } else {
        // document.getElementById("share_to_email_form").submit();
        var em = {
          sales_id: orderId, // assuming orderId is the salesId
          Id: ID,
          email_ids: emailIds,
          email_message: emailMessage,
        };
        
        axios
          .post(`${config.base_url}/share_sales_order_email/`, em)
          .then((res) => {
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Shared via mail.",
              });
              setEmailIds("");
              setEmailMessage("");
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (
              err.response &&
              err.response.data &&
              !err.response.data.status
            ) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  }
  const [currentDateTime, setCurrentDateTime] = useState('');

  const updateDateTime = () => {
    const now = new Date();
    setCurrentDateTime(now.toLocaleString());
  };

  useEffect(() => {
    // Initial update
    updateDateTime();
    
    // Set interval to update time every second
    const intervalId = setInterval(updateDateTime, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handlePrint = () => {
    document.getElementById('slip').style.display = 'block';
    window.print();
    document.getElementById('slip').style.display = 'none'; // Hide the slip after printing
  };
    




 
  

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel px-3 py-2">
  <div className="content-wrapper">
    <div className="body-wrapper p-3">
      <div className="card radius-15">
      <button
  className="btn btn-dark btn-sm ml-auto"
  onClick={() => navigate('/Salesorder')}
>
  <i className="fa fa-close"></i> {/* Icon for Close Button */}
</button>

        <div className="card-body">
          <div className="card-title text-center">
            <h3 className="uppercase">Sales Order View</h3>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            {/* Top Left Group Buttons */}
            <div className="btn-group">
              <button onClick={overview} className="btn btn-outline-secondary btn-sm">Overview</button>
              <button onClick={template} className="btn btn-outline-secondary btn-sm">Templates</button>
              <button onClick={slip} className="btn btn-outline-secondary btn-sm">Slip</button>
            </div>

            {/* Top Right Group Buttons */}
            <div className="d-flex align-items-center mt-3">
              <button
                id="editBtn"
                onClick={() => navigate(`/Editsalesorder/${orderId}`)}
                className="btn btn-outline-secondary btn-sm me-2"
              >
                <i className="fa fa-pencil"></i> Edit
              </button>

              <button
                id="pdfBtn"
                onClick={salesOrderPdf}
                className="btn btn-outline-secondary btn-sm me-2"
                style={{ display: "block" }} // Set display to block to make the button visible
              >
                <i className="fa fa-file"></i> PDF
              </button>

              <button
                id="pdfBtnslip"
                onClick={salesOrderslipPdf}
                className="btn btn-outline-secondary btn-sm me-2"
                style={{ display: "block" }} // Set display to block to make the button visible
              >
                <i className="fa fa-file"></i> PDF
              </button>

              <button
                id="printBtn"
                onClick={printSheet}
                className="btn btn-outline-secondary btn-sm me-2"
                style={{ display: "none" }}
              >
                <i className="fa fa-print"></i> Print
              </button>

              <button
                id="printBtnslip"
                onClick={print2}
                className="btn btn-outline-secondary btn-sm me-2"
                style={{ display: "none" }}
              >
                <i className="fa fa-print"></i> Print
              </button>

              {/* Share Button with Dropdown */}
              <div className="dropdown p-0">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm dropdown-toggle me-2"
                  id="shareDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-share-alt"></i> Share
                </button>
                <div className="dropdown-menu" aria-labelledby="shareDropdown" style={{ backgroundColor: "black" }}>
                  <a
                    className="dropdown-item"
                    href={shareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#e5e9ec", textAlign: "center" }}
                  >
                    WhatsApp
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#shareToEmail"
                    style={{ color: "#e5e9ec", textAlign: "center" }}
                  >
                    Email
                  </a>
                </div>
              </div>

              <button
                id="commentBtn"
                onClick={toggleComments}
                className="btn btn-outline-secondary btn-sm me-2"
              >
                <i className="fa fa-comments"></i> Comments
              </button>

              <button
                id="deleteBtn"
                onClick={() => handleDeleteSalesOrder(orderId)}
                className="btn btn-outline-secondary btn-sm me-2"
              >
                <i className="fa fa-trash"></i> Delete
              </button>

              {/* Attach Button with Dropdown */}
              <div className="dropdown me-2">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm dropdown-toggle"
                  id="attachBtn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-paperclip"></i> Attach
                </button>
                <div className="dropdown-menu" aria-labelledby="attachDropdown">
                  <a
                    className="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#attachFile"
                  >
                    Attach File
                  </a>
                  <a className="dropdown-item" onClick={handleFileDownload} href="#">Download File</a>
                </div>
              </div>

              <button
                id="historyBtn"
                onClick={handleViewHistory}
                className="btn btn-outline-secondary btn-sm"
                data-toggle="modal"
                data-target="#historyModal"
              >
                <i className="fa fa-history"></i> History
              </button>
            </div>
          </div>
        </div>
        </div>
        
        <br />
      
 

      

                <div className="card card-registration card-registration-2" style={{ borderRadius: "19px" }}>
  <div className="card-body p-0">
  <div id="overview" >
    
    <div
      className="row g-0"
      
      style={{ marginLeft: "1px", marginRight: "1px"  ,backgroundColor: "offwhite",}}
    >
      <div className="col-lg-8">
      <div className="history_highlight px-4 pt-4 d-flex">
  {history && history.length > 0 ? (
    <>
      <div className="col-8 d-flex justify-content-start">
      
        <span
          className="ml-2"
          style={{ fontSize: "1.15rem", fontWeight: "500" }}
        >
        
        </span>
      </div>
      <div className="col-4 d-flex justify-content-end">
      
      </div>
    </>
  ) : (
    <span>No history available.</span>
  )}
</div>

        <div className="pb-3 px-2">
          <div className="card-body">
            <div className="card-title">
              <div className="row">
                <div className="col mt-3">
                  <h2 className="mb-0">
                    # {salesDetails.sales_order_no}
                  </h2>
                </div>
              </div>
            </div>
            <hr />

            <div className="row mb-4 d-flex justify-content-between align-items-center">
              <div className="col-md-4 mt-3">
                <h5
                  style={{
                    borderBottom:
                      "1px solid rgba(128, 128, 128, 0.6)",
                    width: "fit-content",
                  }}
                >
                  Company Details
                </h5>
              </div>
              <div className="col-md-4 mt-3"></div>
              <div className="col-md-4 mt-3"></div>

              <div className="col-md-2 mt-3">
                <h6 className="mb-0">Company</h6>
              </div>
              <div className="col-md-4 mt-3">
                <p className="mb-0 text-right">
                  {otherDetails.Company_name}
                </p>
              </div>
              <div className="col-md-2 mt-3 vl">
                <h6 className="mb-0">Email</h6>
              </div>
              <div className="col-md-4 mt-3">
                <p className="mb-0 text-right">
                  {otherDetails.Email}
                </p>
              </div>
            </div>
            <div className="row mb-4 d-flex justify-content-between align-items-center">
              <div className="col-md-2 mt-3">
                <h6 className="mb-0">Mobile</h6>
              </div>
              <div className="col-md-4 mt-3">
                <p className="mb-0 text-right">
                  {otherDetails.Mobile}
                </p>
              </div>
              <div className="col-md-2 mt-3 vl">
                <h6 className="mb-0">Address</h6>
              </div>
              <div className="col-md-4 mt-3">
                <p className="mb-0 text-right">
                  {otherDetails.Address} <br />
                  {otherDetails.City},{otherDetails.State} -{" "}
                  {otherDetails.Pincode}
                </p>
              </div>
            </div>

            <hr className="my-4" />

            <div className="row mb-4 d-flex justify-content-between align-items-center">
              <div className="col-md-4 mt-3">
                <h5
                  style={{
                    borderBottom:
                      "1px solid rgba(128, 128, 128, 0.6)",
                    width: "fit-content",
                  }}
                >
                  Sales Order Details
                </h5>
              </div>
              <div className="col-md-4 mt-3"></div>
              <div className="col-md-4 mt-3"></div>

              <div className="col-md-3 mt-3">
                <h6 className="mb-0">Sales Order No,</h6>
              </div>
              <div className="col-md-3 mt-3">
                <p className="mb-0 text-right">
                  {salesDetails.sales_order_no}
                </p>
              </div>

              <div className="col-md-3 mt-3 vl">
                <h6 className="mb-0">Customer Name</h6>
              </div>
              <div className="col-md-3 mt-3">
                <p className="mb-0 text-right">
                  {salesDetails.customer_name}
                </p>
              </div>
            </div>
            <div className="row mb-4 d-flex justify-content-between align-items-center">
              <div className="col-md-3 mt-3">
                <h6 className="mb-0">Sales Order Date</h6>
              </div>
              <div className="col-md-3 mt-3">
                <p className="mb-0 text-right">
                  {salesDetails.sales_order_date}
                </p>
              </div>

              <div className="col-md-3 mt-3 vl">
                <h6 className="mb-0">Shipment Date</h6>
              </div>
              <div className="col-md-3 mt-3">
                <p className="mb-0 text-right">
                  {salesDetails.exp_ship_date}
                </p>
              </div>
            </div>
            <div className="row mb-4 d-flex justify-content-start align-items-center">
              <div className="col-md-3 mt-3">
                <h6 className="mb-0">Address</h6>
              </div>
              <div className="col-md-3 mt-3 vr">
                <p className="mb-0">{salesDetails.billing_address}</p>
              </div>
              <div className="col-md-3 mt-3 vl">
                <h6 className="mb-0">Payment Method</h6>
              </div>
              <div className="col-md-3 mt-3">
                <p className="mb-0 text-right">
                  {salesDetails.payment_method}
                </p>
              </div>
            </div>

            <hr />

            <div className="row mb-4 d-flex justify-content-between align-items-center">
              <div className="col-md-4 mt-3">
                <h5
                  style={{
                    borderBottom:
                      "1px solid rgba(128, 128, 128, 0.6)",
                    width: "fit-content",
                  }}
                >
                  Customer Details
                </h5>
              </div>
              <div className="col-md-4 mt-3"></div>
              <div className="col-md-4 mt-3"></div>

              <div className="col-md-3 mt-3 ">
                <h6 className="mb-0">Customer Name</h6>
              </div>
              <div className="col-md-3 mt-3">
                <p className="mb-0 text-right">
                  {salesDetails.customer_name}
                </p>
              </div>

              <div className="col-md-3 mt-3 vl">
                <h6 className="mb-0">Customer Email</h6>
              </div>
              <div className="col-md-3 mt-3">
                <p className="mb-0 text-right">
                  {salesDetails.customer_email}
                </p>
              </div>

              <div className="col-md-3 mt-3">
                <h6 className="mb-0">GST Type</h6>
              </div>
              <div className="col-md-3 mt-3">
                <p className="mb-0" style={{ textAlign: "right" }}>
                  {salesDetails.gst_type}
                </p>
              </div>

              {salesDetails.gstin != "None" && (
                <>
                  <div className="col-md-3 mt-3 vl">
                    <h6 className="mb-0">GSTIN No</h6>
                  </div>
                  <div className="col-md-3 mt-3">
                    <p className="mb-0 text-right">
                      {salesDetails.gstin}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="row mb-4 d-flex justify-content-between align-items-center">
              <div className="col-md-3 mt-3">
                <h6 className="mb-0">Address</h6>
              </div>
              <div className="col-md-3 mt-3 ">
                <p className="mb-0 text-right">
                  {salesDetails.billing_address}
                </p>
              </div>
            </div>
            <hr />

            <div className="row mb-4 d-flex justify-content-between align-items-center">
              <div className="col-md-12 mt-3">
                <h5
                  style={{
                    borderBottom:
                      "1px solid rgba(128, 128, 128, 0.6)",
                    width: "fit-content",
                  }}
                >
                  Items Details
                </h5>
                {items &&
                  items.map((itm) => (
                    <>
                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-3 mt-3">
                          <h6 className="mb-0">Name</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {itm.name}
                          </p>
                        </div>

                        <div className="col-md-3 mt-3 vl">
                          <h6 className="mb-0">
                            {itm.item_type == "Goods" ? "HSN" : "SAC"}
                          </h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {itm.item_type == "Goods"
                              ? itm.hsn
                              : itm.sac}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-3 mt-3">
                          <h6 className="mb-0">Price</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {itm.price}
                          </p>
                        </div>
                        <div className="col-md-3 mt-3 vl">
                          <h6 className="mb-0">Quantity</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {itm.quantity}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-4 d-flex justify-content-start align-items-center">
                        <div className="col-md-3 mt-3">
                          <h6 className="mb-0">Tax Amount</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {itm.tax} %
                          </p>
                        </div>
                        <div className="col-md-3 mt-3 vl">
                          <h6 className="mb-0">Discount</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {itm.discount}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-4 d-flex justify-content-start align-items-center">
                        <div className="col-md-3 mt-3">
                          <h6 className="mb-0">Total</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {itm.total}
                          </p>
                        </div>
                        <div className="col-md-6 mt-3 vl">
                          <h6 className="mb-0">&nbsp;</h6>
                        </div>
                      </div>
                      <hr
                        className="my-3 mx-auto"
                        style={{ width: "50%" }}
                      />
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="col-md"
        style={{
          backgroundColor: "rgba(211, 211, 211, 1)",  // Ash color (light grey)
          color: "black",  // Text color remains black
          borderTopRightRadius: "2vh",
          borderBottomRightRadius: "2vh",
          marginRight: "-1vh", 
         
        
        }}
        
        
      >
        <div className="px-3 py-4">
          <h4 className="fw-bold mb-2 mt-4 pt-1">
            Sales Order Tax Details
          </h4>
          <hr className="my-4" />
          <div className="d-flex justify-content-between mb-4">
            <h6 className="">Status</h6>
            {salesDetails.status == "Draft" ? (
              <span className="text-info h5 font-weight-bold">
                DRAFT
              </span>
            ) : (
              <span className="text-success h5 font-weight-bold">
                SAVED
              </span>
            )}
          </div>
          <div className="d-flex justify-content-between mb-4">
            <h6 className="">Sub Total</h6>
            {salesDetails.subtotal}
          </div>
          <div className="d-flex justify-content-between mb-4">
            <h6 className="">Tax Amount</h6>
            {salesDetails.tax_amount}
          </div>

          
          {salesDetails.igst ? (
  // Show IGST if it has a value, hide CGST and SGST
  <div className="d-flex justify-content-between mb-4">
    <h6 className="">IGST</h6>
    {salesDetails.igst}
  </div>
) : (
  <>
    {/* If IGST is not available (null or 0), show CGST and SGST */}
    {salesDetails.cgst ? (
      <div className="d-flex justify-content-between mb-4">
        <h6 className="">CGST</h6>
        {salesDetails.cgst}
      </div>
    ) : null}
    {salesDetails.sgst ? (
      <div className="d-flex justify-content-between mb-4">
        <h6 className="">SGST</h6>
        {salesDetails.sgst}
      </div>
    ) : null}
  </>
)}

          {salesDetails.shipping_charge != 0 ? (
            <div className="d-flex justify-content-between mb-4">
              <h6 className="">Shipping Charge</h6>
              {salesDetails.shipping_charge}
            </div>
          ) : null}

          {salesDetails.adjustment != 0 ? (
            <div className="d-flex justify-content-between mb-4">
              <h6 className="">Adjustment</h6>
              {salesDetails.adjustment}
            </div>
          ) : null}
          <hr className="my-4" />
          <div className="d-flex justify-content-between mb-4">
            <h6 className="">Grand Total</h6>
            <span className="font-weight-bold">
              {salesDetails.grandtotal}
            </span>
          </div>
        </div>
      </div>
    </div>
  
</div>
</div>
{/* Template Section */}
<div className="" id="template" style={{ display: "none" }}>
  <div id="whatToPrint" className="print-only">
    {/* Template1 */}
    <div id="whatToPrint1" className="printTemplates template1">
      <div className="my-5 page pagesizea4" size="A4">
        <div className="p-4" id="printdiv1">
          <div id="ember2512" className="tooltip-container ember-view ribbon text-ellipsis">
            <div className="ribbon-inner ribbon-open">{salesDetails.status}</div>
          </div>
          <section className="top-content bb d-flex justify-content-between">
            <div className="logo">
              {/* Optional logo */}
            </div>
            <div className="top-left">
              <div className="graphic-path">
                <p>Sales Order</p>
              </div>
              <div className="position-relative">
                <p>Sales Order No. <span>{salesDetails.sales_order_no}</span></p>
              </div>
            </div>
          </section>
                      <section className="store-user mt-5">
                        <div className="col-12">
                          <div className="row bb pb-3">
                            <div className="col-7">
                              <p>FROM,</p>
                              <h5 style={{ color: 'orange' }}>{otherDetails.Company_name}</h5>

                              <p className="address ">
                                {" "}
                                {otherDetails.Address}
                                <br />
                                {otherDetails.City},{otherDetails.State}
                                <br />
                                {otherDetails.Pincode}
                                <br />
                              </p>
                            </div>
                            <div className="col-5">
                              <p>TO,</p>
                              <h5 style={{ color: 'orange' }}>{salesDetails.customer_name}</h5>
                              <p
                                className="address col-9"
                                style={{ marginLeft: "-14px" }}
                              >
                                {" "}
                                {salesDetails.billing_address}{" "}
                              </p>
                            </div>
                          </div>
                          <div className="row extra-info pt-3">
                            <div className="col-6">
                              <p>
                                Sales oder Date:{" "}
                                <span>{salesDetails.sales_order_date}</span>
                              </p>
                              <p>
                                Payment Method:{" "}
                                <span>{salesDetails.payment_method}</span>
                              </p>
                            </div>
                            <div className="col-6">
                              <p>
                                Expected Shipment Date :{" "}
                                <span>{salesDetails.exp_ship_date}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="product-area mt-4">
                        <table
                          className=" table table-hover table-bordered "
                          id="table1"
                        >
                          <thead>
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "black",
                                  backgroundColor:"orange"
                                }}
                              >
                                Items
                              </td>
                              <td
                                style={{ fontWeight: "bold", color: "black", backgroundColor:"orange" }}
                              >
                                HSN/SAC
                              </td>
                              <td
                                style={{ fontWeight: "bold", color: "black", backgroundColor:"orange" }}
                              >
                                Price
                              </td>
                              <td
                                style={{ fontWeight: "bold", color: "black", backgroundColor:"orange" }}
                              >
                                Quantity
                              </td>
                              <td
                                style={{ fontWeight: "bold", color: "black", backgroundColor:"orange" }}
                              >
                                Tax
                              </td>
                              <td
                                style={{ fontWeight: "bold", color: "black", backgroundColor:"orange" }}
                              >
                                Discount
                              </td>
                              <td
                                style={{ fontWeight: "bold", color: "black", backgroundColor:"orange" }}
                              >
                                Total
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((j) => (
                              <tr>
                                <td
                                  style={{
                                    color: "black",
                                    textAlign: "center",
                                  }}
                                >
                                  {j.name}
                                </td>
                                <td style={{ color: "black" }}>
                                  {j.item_type == "Goods" ? j.hsn : j.sac}
                                </td>
                                <td style={{ color: "black" }}>{j.price}</td>
                                <td style={{ color: "black" }}>{j.quantity}</td>
                                <td style={{ color: "black" }}>{j.tax} %</td>
                                <td style={{ color: "black" }}>
                                  {j.discount}{" "}
                                </td>
                                <td style={{ color: "black" }}>{j.total}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <br />
                        <br />
                      </section>

                      <section className="balance-info">
                        <div className="row">
                          <div className="col-md-8"></div>
                          <div className="col-md-4">
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <td style={{ color: "#000" }}>Sub Total</td>
                                  <td style={{ color: "#000" }}>:</td>
                                  <td
                                    className="text-right"
                                    style={{ color: "#000" }}
                                  >
                                    <span>&#8377; </span>
                                    {salesDetails.subtotal}
                                  </td>
                                </tr>

                                {salesDetails.igst ? (
  // Show IGST if it has a value, hide CGST and SGST
  <tr>
    <td style={{ color: "#000" }}>IGST</td>
    <td style={{ color: "#000" }}>:</td>
    <td className="text-right" style={{ color: "#000" }}>
      <span>&#8377; </span>
      {salesDetails.igst}
    </td>
  </tr>
) : (
  <>
    {/* If IGST is not available (null or 0), show CGST and SGST */}
    {salesDetails.cgst ? (
      <tr>
        <td style={{ color: "#000" }}>CGST</td>
        <td style={{ color: "#000" }}>:</td>
        <td className="text-right" style={{ color: "#000" }}>
          <span>&#8377; </span>
          {salesDetails.cgst}
        </td>
      </tr>
    ) : null}
    {salesDetails.sgst ? (
      <tr>
        <td style={{ color: "#000" }}>SGST</td>
        <td style={{ color: "#000" }}>:</td>
        <td className="text-right" style={{ color: "#000" }}>
          <span>&#8377; </span>
          {salesDetails.sgst}
        </td>
      </tr>
    ) : null}
  </>
)}

                                <tr>
                                  <td style={{ color: "#000" }}>Tax Amount</td>
                                  <td style={{ color: "#000" }}>:</td>
                                  <td
                                    className="text-right"
                                    style={{ color: "#000" }}
                                  >
                                    <span>&#8377; </span>
                                    {salesDetails.tax_amount}
                                  </td>
                                </tr>
                                {salesDetails.shipping_charge != 0 ? (
                                  <tr>
                                    <td style={{ color: "#000" }}>
                                      Shipping Charge
                                    </td>
                                    <td style={{ color: "#000" }}>:</td>
                                    <td
                                      className="text-right"
                                      style={{ color: "#000" }}
                                    >
                                      <span>&#8377; </span>
                                      {salesDetails.shipping_charge}
                                    </td>
                                  </tr>
                                ) : null}
                                {salesDetails.adjustment != 0 ? (
                                  <tr>
                                    <td style={{ color: "#000" }}>
                                      Adjustment
                                    </td>
                                    <td style={{ color: "#000" }}>:</td>
                                    <td
                                      className="text-right"
                                      style={{ color: "#000" }}
                                    >
                                      <span>&#8377; </span>
                                      {salesDetails.adjustment}
                                    </td>
                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                            <hr style={{ backgroundColor: "#000000" }} />
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <th style={{ color: "#000" }}>Grand Total</th>
                                  <th style={{ color: "#000" }}>:</th>
                                  <th
                                    className="text-right"
                                    style={{ color: "#000" }}
                                  >
                                    <span>&#8377; </span>
                                    {salesDetails.grandtotal}
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>

                {/* <!-- template2 --> */}
                <div
                  id="whatToPrint2"
                  className="printTemplates template2"
                  style={{ display: "none" }}
                >
                  <div className="my-5 page pagesizea4" size="A4">
                    <div id="printdiv2">
                      <div
                        className="row px-5 py-4"
                        style={{ backgroundColor: "orange" }}
                      >
                        <div
                          id="ember2512"
                          className="col-md-4 d-flex justify-content-start tooltip-container ember-view ribbon text-ellipsis"
                        >
                          <div
                            className="text-white d-flex align-items-center px-5"
                            style={{
                              borderRadius: "1vh",
                              backgroundColor: "#999999",
                            }}
                          >
                            {salesDetails.status}
                          </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-center">
                          <center className="h3 text-white">
                            <b>Sales Order</b>
                          </center>
                        </div>
                        <div className="col-md-4 d-flex justify-content-end">
                          <div className="text-white">
                            <p className="mb-0 mt-2">
                              Sales Order No:{" "}
                              <b>{salesDetails.sales_order_no}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="px-5 py-1">
                        <section className="store-user">
                          <br />
                          <br />
                          <div className="col-12">
                            <div className="row bb pb-2 mt-3">
                              <div className="col-4 pl-0">
                                <label
                                  className="text-white w-100 p-1"
                                  style={{
                                    backgroundColor: "orange",
                                    borderTopRightRadius: "4vh",
                                    borderBottomRightRadius: "4vh",
                                  }}
                                >
                                  <b>COMPANY ADDRESS</b>
                                </label>
                                <h5
                                  className="text-secondary"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {otherDetails.Company_name}
                                </h5>
                                <p
                                  className="address"
                                  style={{ fontWeight: "bold", color: "#000" }}
                                >
                                  {otherDetails.Address}
                                  <br />
                                  {otherDetails.City}
                                  <br />
                                  {otherDetails.State} - {otherDetails.Pincode}
                                  <br />
                                  <span>Mob: </span>
                                  <b>{otherDetails.Contact}</b>
                                </p>
                              </div>
                              <div className="col-4"></div>
                              <div className="col-4 pr-0">
                                <label
                                  className="text-white w-100 p-1"
                                  style={{
                                    backgroundColor: "orange",
                                    borderTopRightRadius: "4vh",
                                    borderBottomRightRadius: "4vh",
                                  }}
                                >
                                  <b>SHIPPING ADDRESS</b>
                                </label>
                                <h5
                                  className="text-secondary"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {otherDetails.customerName}
                                </h5>
                                <p
                                  className="address"
                                  style={{ fontWeight: "bold", color: "#000" }}
                                >
                                  {salesDetails.billing_address}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="row my-3">
                              <div className="col-12">
                                <p>
                                  Order Date:{" "}
                                  <span>{salesDetails.sales_order_date}</span>
                                </p>
                                <p>
                                  Payment Method:{" "}
                                  <span>{salesDetails.payment_method}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </section>

                        <section className="product-area mt-2">
                          <table
                            className="table table-hover table-bordered  template2table"
                            id="table2"
                          >
                            <thead>
                              <tr className="template3tablehead">
                                <th
                                  className="text-center "
                                  style={{ color: "black", backgroundColor:"orange" }}
                                >
                                  Items
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  HSN/SAC
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Quantity
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Rate
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Tax
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Discount
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody style={{ backgroundColor: "white" }}>
                              {items.map((j) => (
                                <tr>
                                  <td
                                    className="text-center"
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                    }}
                                  >
                                    {j.name}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.item_type == "Goods" ? j.hsn : j.sac}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.price}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.quantity}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.tax} %
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.discount}{" "}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.total}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </section>

                        <section className="balance-info">
                          <div className="row mt-3">
                            <div className="col-4">
                              <table className="table table-borderless">
                                <tbody>
                                  {salesDetails.note ? (
                                    <tr>
                                      <td
                                        style={{
                                          color: "#000",
                                          textAlign: "left",
                                        }}
                                      >
                                        Note
                                      </td>
                                      <td
                                        style={{
                                          color: "#000",
                                          textAlign: "left",
                                        }}
                                      >
                                        :
                                      </td>
                                      <th
                                        style={{
                                          color: "#000",
                                          textAlign: "center",
                                        }}
                                      >
                                        {salesDetails.note}
                                      </th>
                                    </tr>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                            <div className="col-4"></div>
                            <div className="col-4">
                              <br />
                              <br />
                              <table className="table table-borderless">
                                <tbody>
                                  <tr>
                                    <td style={{ color: "#000" }}>Sub Total</td>
                                    <td style={{ color: "#000" }}>:</td>
                                    <td
                                      className="text-right"
                                      style={{ color: "#000" }}
                                    >
                                      <span>&#8377; </span>
                                      {salesDetails.subtotal}
                                    </td>
                                  </tr>

                                
                                  {salesDetails.igst ? (
  // Show IGST if it has a value, hide CGST and SGST
  <tr>
    <td style={{ color: "#000" }}>IGST</td>
    <td style={{ color: "#000" }}>:</td>
    <td className="text-right" style={{ color: "#000" }}>
      <span>&#8377; </span>
      {salesDetails.igst}
    </td>
  </tr>
) : (
  <>
    {/* If IGST is not available (null or 0), show CGST and SGST */}
    {salesDetails.cgst ? (
      <tr>
        <td style={{ color: "#000" }}>CGST</td>
        <td style={{ color: "#000" }}>:</td>
        <td className="text-right" style={{ color: "#000" }}>
          <span>&#8377; </span>
          {salesDetails.cgst}
        </td>
      </tr>
    ) : null}
    {salesDetails.sgst ? (
      <tr>
        <td style={{ color: "#000" }}>SGST</td>
        <td style={{ color: "#000" }}>:</td>
        <td className="text-right" style={{ color: "#000" }}>
          <span>&#8377; </span>
          {salesDetails.sgst}
        </td>
      </tr>
    ) : null}
  </>
)}

                                  <tr>
                                    <td style={{ color: "#000" }}>
                                      Tax Amount
                                    </td>
                                    <td style={{ color: "#000" }}>:</td>
                                    <td
                                      className="text-right"
                                      style={{ color: "#000" }}
                                    >
                                      <span>&#8377; </span>
                                      {salesDetails.tax_amount}
                                    </td>
                                  </tr>
                                  {salesDetails.shipping_charge != 0 ? (
                                    <tr>
                                      <td style={{ color: "#000" }}>
                                        Shipping Charge
                                      </td>
                                      <td style={{ color: "#000" }}>:</td>
                                      <td
                                        className="text-right"
                                        style={{ color: "#000" }}
                                      >
                                        <span>&#8377; </span>
                                        {salesDetails.shipping_charge}
                                      </td>
                                    </tr>
                                  ) : null}
                                  {salesDetails.adjustment != 0 ? (
                                    <tr>
                                      <td style={{ color: "#000" }}>
                                        Adjustment
                                      </td>
                                      <td style={{ color: "#000" }}>:</td>
                                      <td
                                        className="text-right"
                                        style={{ color: "#000" }}
                                      >
                                        <span>&#8377; </span>
                                        {salesDetails.adjustment}
                                      </td>
                                    </tr>
                                  ) : null}
                                </tbody>
                              </table>
                              <hr style={{ backgroundColor: "#000000" }} />
                              <table className="table table-borderless">
                                <tbody>
                                  <tr>
                                    <th style={{ color: "#000" }}>
                                      Grand Total
                                    </th>
                                    <th style={{ color: "#000" }}>:</th>
                                    <th
                                      className="text-right"
                                      style={{ color: "#000" }}
                                    >
                                      <span>&#8377; </span>
                                      {salesDetails.grandtotal}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- template3 --> */}
                <div
                  id="whatToPrint3"
                  className="printTemplates template3"
                  style={{ display: "none" }}
                >
                  <div className="my-5 page pagesizea4" size="A4">
                    <div className="" id="printdiv3">
                      <div className="row">
                        <div
                          className="col-sm-12"
                          style={{
                            backgroundImage: "linear-gradient(orange, black)",
                            color: "white",
                          }}
                          
                        >
                          <p style={{ fontSize: "4vh", textAlign: "center", color:"white" }}>
                            SALES ORDER
                          </p>
                          <p style={{ textAlign: "center", color:"white" }}>
                            {" "}
                            {otherDetails.Company_name} <br />
                            {otherDetails.Address} <br />
                            {otherDetails.City},{otherDetails.State}
                            <br />
                            {otherDetails.Email}
                            <br />
                          </p>
                        </div>

                        <div className="row col-12">
                          <div className="col-md-1"></div>
                          <div className="col-5">
                            <br />
                            <br />
                            <br />
                            <p style={{ color: "black" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>To: </span>
                              <br />
                              {otherDetails.customerName}
                              <br />
                              {salesDetails.billing_address}
                            </p>
                          </div>
                          <div className="col-md-1"></div>
                          <div className="col-5">
                            <br />
                            <br />
                            <br />
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      color: "#000",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Sales Order No.
                                  </td>
                                  <td style={{ color: "#000" }}>:</td>
                                  <td
                                    className="text-right"
                                    style={{ color: "#000" }}
                                  >
                                    {salesDetails.sales_order_no}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      color: "#000",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Sales Order Date
                                  </td>
                                  <td style={{ color: "#000" }}>:</td>
                                  <td
                                    className="text-right"
                                    style={{ color: "#000" }}
                                  >
                                    {salesDetails.sales_order_date}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      color: "#000",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Shipment Date
                                  </td>
                                  <td style={{ color: "#000" }}>:</td>
                                  <td
                                    className="text-right"
                                    style={{ color: "#000" }}
                                  >
                                    {salesDetails.exp_ship_date}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      color: "#000",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Payment Method
                                  </td>
                                  <td style={{ color: "#000" }}>:</td>
                                  <td
                                    className="text-right"
                                    style={{ color: "#000" }}
                                  >
                                    {salesDetails.payment_method}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                          <br />
                          <table
                            className="table table-hover table-bordered  template3table"
                            id="table3"
                          >
                            <thead>
                              <tr className="template3tablehead">
                                <th
                                  className="text-center "
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Items
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  HSN/SAC
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Quantity
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Rate
                                </th>
                                <th
                                  className="text-center "
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Tax
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Discount
                                </th>
                                <th
                                  className="text-center"
                                  style={{ color: "black",backgroundColor:"orange" }}
                                >
                                  Total
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {items.map((j) => (
                                <tr>
                                  <td
                                    className="text-center"
                                    style={{
                                      color: "black",
                                      textAlign: "center",
                                    }}
                                  >
                                    {j.name}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.item_type == "Goods" ? j.hsn : j.sac}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.price}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.quantity}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.tax} %
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.discount}{" "}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {j.total}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <section className="balance-info">
                        <div className="row">
                          <div className="col-md-7"></div>
                          <div className="col-md-4">
                            <br />
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <td style={{ color: "#000" }}>Sub Total</td>
                                  <td style={{ color: "#000" }}>:</td>
                                  <td
                                    className="text-right"
                                    style={{ color: "#000" }}
                                  >
                                    <span>&#8377; </span>
                                    {salesDetails.subtotal}
                                  </td>
                                </tr>

                              
                                 
                                {salesDetails.igst ? (
  // Show IGST if it has a value, hide CGST and SGST
  <tr>
    <td style={{ color: "#000" }}>IGST</td>
    <td style={{ color: "#000" }}>:</td>
    <td className="text-right" style={{ color: "#000" }}>
      <span>&#8377; </span>
      {salesDetails.igst}
    </td>
  </tr>
) : (
  <>
    {/* If IGST is not available (null or 0), show CGST and SGST */}
    {salesDetails.cgst ? (
      <tr>
        <td style={{ color: "#000" }}>CGST</td>
        <td style={{ color: "#000" }}>:</td>
        <td className="text-right" style={{ color: "#000" }}>
          <span>&#8377; </span>
          {salesDetails.cgst}
        </td>
      </tr>
    ) : null}
    {salesDetails.sgst ? (
      <tr>
        <td style={{ color: "#000" }}>SGST</td>
        <td style={{ color: "#000" }}>:</td>
        <td className="text-right" style={{ color: "#000" }}>
          <span>&#8377; </span>
          {salesDetails.sgst}
        </td>
      </tr>
    ) : null}
  </>
)}

                                <tr>
                                  <td style={{ color: "#000" }}>Tax Amount</td>
                                  <td style={{ color: "#000" }}>:</td>
                                  <td
                                    className="text-right"
                                    style={{ color: "#000" }}
                                  >
                                    <span>&#8377; </span>
                                    {salesDetails.tax_amount}
                                  </td>
                                </tr>
                                {salesDetails.shipping_charge != 0 ? (
                                  <tr>
                                    <td style={{ color: "#000" }}>
                                      Shipping Charge
                                    </td>
                                    <td style={{ color: "#000" }}>:</td>
                                    <td
                                      className="text-right"
                                      style={{ color: "#000" }}
                                    >
                                      <span>&#8377; </span>
                                      {salesDetails.shipping_charge}
                                    </td>
                                  </tr>
                                ) : null}
                                {salesDetails.adjustment != 0 ? (
                                  <tr>
                                    <td style={{ color: "#000" }}>
                                      Adjustment
                                    </td>
                                    <td style={{ color: "#000" }}>:</td>
                                    <td
                                      className="text-right"
                                      style={{ color: "#000" }}
                                    >
                                      <span>&#8377; </span>
                                      {salesDetails.adjustment}
                                    </td>
                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                            <hr style={{ backgroundColor: "#000000" }} />
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <th style={{ color: "#000" }}>Grand Total</th>
                                  <th style={{ color: "#000" }}>:</th>
                                  <th
                                    className="text-right"
                                    style={{ color: "#000" }}
                                  >
                                    <span>&#8377; </span>
                                    {salesDetails.grandtotal}
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <div
  id="templateToggle"
  className="templateToggleSegment mt-1 mb-2 w-100 d-flex justify-content-center"
>
  <button
    id="toggleTemplate1"
    onClick={() => toggleTemplate("whatToPrint1", "toggleTemplate1")}
    style={{
      width: "fit-content",
      height: "fit-content",
      border: "1px solid white", // Persistent border
    }}
    className="btn btn-outline-light btn-sm m-2 active templateToggleButtons"
  >
    1
  </button>
  <button
    id="toggleTemplate2"
    onClick={() => toggleTemplate("whatToPrint2", "toggleTemplate2")}
    style={{
      width: "fit-content",
      height: "fit-content",
      border: "1px solid white", // Persistent border
    }}
    className="btn btn-outline-light btn-sm m-2 templateToggleButtons"
  >
    2
  </button>
  <button
    id="toggleTemplate3"
    onClick={() => toggleTemplate("whatToPrint3", "toggleTemplate3")}
    style={{
      width: "fit-content",
      height: "fit-content",
      border: "1px solid white", // Persistent border
    }}
    className="btn btn-outline-light btn-sm m-2 templateToggleButtons"
  >
    3
  </button>
</div>

            </div>
            <div className="" id="slip" style={{ display: "none" }}>
              <div className="slip-container bg-white" id="slip_container">
                <div className="slip text-dark">
                  <h5 className="font-weight-bold text-center text-dark">
                    {otherDetails.Company_name}
                  </h5>
                  <div className="address text-center">
                    <p>{otherDetails.Address}</p>
                    <p>
                      {otherDetails.State}, {otherDetails.Country}
                    </p>
                    <p>{otherDetails.Contact}</p>
                  </div>

                  <div className="divider"></div>

                  <div className="ml-4 mt-2" style={{ color: "black" }}>
                    <div className="row">
                      <div className="col-md-6 text-left">Sales Order No:</div>
                      <div className="col-md-5 text-right">
                        {salesDetails.sales_order_no}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 text-left">Customer Name:</div>
                      <div className="col-md-5 text-right">
                      {salesDetails?.customer_name}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 text-left">
                        Sales Order Date:
                      </div>
                      <div className="col-md-5 text-right">
                        {salesDetails.sales_order_date}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 text-left">Shipment Date:</div>
                      <div className="col-md-5 text-right">
                        {salesDetails.exp_ship_date}
                      </div>
                    </div>
                  </div>

                  <div className="divider"></div>

                  <div
                    className="equal-length-container"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    <div
                      className="equal-length-item"
                      style={{ textAlign: "center" }}
                    >
                      Item
                      <hr
                        style={{
                          borderBottom: "1px solid black",
                          marginTop: "1vh",
                          width: "95%",
                        }}
                      />
                    </div>
                    <div
                      className="equal-length-item ml-2"
                      style={{ textAlign: "center" }}
                    >
                      HSN/SAC
                      <hr
                        style={{
                          borderBottom: "1px solid black",
                          marginTop: "1vh",
                          width: "63%",
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                    <div
                      className="equal-length-item"
                      style={{ textAlign: "center" }}
                    >
                      Qty
                      <hr
                        style={{
                          borderBottom: "1px solid black",
                          marginTop: "1vh",
                          width: "60%",
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                    <div
                      className="equal-length-item"
                      style={{ textAlign: "center" }}
                    >
                      Rate
                      <hr
                        style={{
                          borderBottom: "1px solid black",
                          marginTop: "1vh",
                          width: "65%",
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                    <div
                      className="equal-length-item"
                      style={{ textAlign: "center" }}
                    >
                      Tax
                      <hr
                        style={{
                          borderBottom: "1px solid black",
                          marginTop: "1vh",
                          width: "60%",
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                    <div
                      className="equal-length-item"
                      style={{ textAlign: "center" }}
                    >
                      Total
                      <hr
                        style={{
                          borderBottom: "1px solid black",
                          marginTop: "1vh",
                          width: "65%",
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                  </div>
                  {items.map((i) => (
                    <div
                      className="equal-length-container"
                      style={{
                        color: "black",
                        fontSize: "small",
                        wordWrap: "break-word",
                        marginBottom: "1vh",
                      }}
                    >
                      <div
                        className="equal-length-item"
                        style={{ textAlign: "center", marginLeft: "2px" }}
                      >
                        {i.name}
                      </div>
                      <div
                        className="equal-length-item"
                        style={{ textAlign: "right" }}
                      >
                        {i.item_type == "Goods" ? i.hsn : i.sac}
                      </div>
                      <div
                        className="equal-length-item"
                        style={{ textAlign: "center" }}
                      >
                        {i.quantity}
                      </div>
                      <div
                        className="equal-length-item"
                        style={{ textAlign: "center" }}
                      >
                        {i.price}
                      </div>
                      <div
                        className="equal-length-item"
                        style={{ textAlign: "center" }}
                      >
                        {i.tax}%
                      </div>
                      <div
                        className="equal-length-item"
                        style={{ textAlign: "center" }}
                      >
                        {i.total}
                      </div>
                    </div>
                  ))}

                  <div className="subtot mt-5">
                    <div className="subtot-item d-flex justify-content-between">
                      <span>Subtotal</span>
                      <span>
                        <span>&#8377; </span>
                        {salesDetails.subtotal}
                      </span>
                    </div>
                    <div className="subtot-item d-flex justify-content-between">
                      <span>Tax Amount</span>
                      <span>
                        <span>&#8377; </span>
                        {salesDetails.tax_amount}
                      </span>
                    </div>

                    {salesDetails.igst == 0 ? (
                      <>
                        <div className="subtot-item d-flex justify-content-between">
                          <span>CGST</span>
                          <span>
                            <span>&#8377; </span>
                            {salesDetails.cgst}
                          </span>
                        </div>
                        <div className="subtot-item d-flex justify-content-between">
                          <span>SGST</span>
                          <span>
                            <span>&#8377; </span>
                            {salesDetails.sgst}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="subtot-item d-flex justify-content-between">
                       
                      </div>
                    )}

                    {salesDetails.adjustment != 0 ? (
                      <div className="subtot-item d-flex justify-content-between">
                        <span>Adjustment</span>
                        <span>
                          <span>&#8377; </span>
                          {salesDetails.adjustment}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="divider"></div>
                  <div className="grandtot fw-bold d-flex justify-content-between">
                    <span>
                      <strong>TOTAL</strong>
                    </span>
                    <span>
                      <strong>
                        <span>&#8377; </span>
                        {salesDetails.grandtotal}
                      </strong>
                    </span>
                  </div>
                  <div className="divider"></div>
                  <div className="paid-by mb-4 d-flex justify-content-between">
                    <span>Paid By:</span>
                    <span>{salesDetails.payment_method}</span>
                  </div>
                  <div className="createdby d-flex justify-content-between">
                    <p className="">Created By:</p>
                    <span>{otherDetails.Company_name}</span>
                  </div>
                  <div className="datetime d-flex justify-content-between">
                    <p className="">Printed On:</p>
                    <span>{currentDateTime}</span>
                  </div>
                  <div className="trns-id d-flex justify-content-between">
                    <span>Transaction ID:</span>
                    <span>XXXXXXXXX</span>
                  </div>
                  <div className="slip-footer mt-4 text-center">
                    <p>Thank you for supporting Local business!</p>
                  </div>
                </div>
              </div>
            </div>
         
        
      



            {/* <!-- Add Comments Modal --> */}
            <div
      className="modal fade"
      id="commentModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">
              Add Comments
            </h3>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={saveSalesOrderComment} className="px-1">
            <div className="modal-body w-100">
              <textarea
                type="text"
                className="form-control"
                name="comment"
                value={comment}
                required
                onChange={(e) => setComment(e.target.value)}
              />
              {comments.length > 0 ? (
                <div className="container-fluid">
                  <table className="table mt-4">
                    <thead>
                      <tr>
                        <th className="text-center">Sl No.</th>
                        <th className="text-center">Comment</th>
                        <th className="text-center">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {comments.map((c, index) => (
                        <tr key={c.id}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{c.comments}</td>
                          <td className="text-center">
                            <a
                              className="text-danger"
                              onClick={() => deleteComment(c.id)}
                            >
                              <i
                                className="fa fa-trash"
                                style={{
                                  fontSize: '1.1rem',
                                  cursor: 'pointer',
                                }}
                              ></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <span className="my-2 font-weight-bold d-flex justify-content-center">
                  No Comments.!
                </span>
              )}
            </div>
            <div className="modal-footer w-100">
              <button
                type="button"
                style={{ width: 'fit-content', height: 'fit-content' }}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                style={{ width: 'fit-content', height: 'fit-content' }}
                className="btn"
                id="commentSaveBtn"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
      {/* Modal for file upload */}
<div className="modal fade" id="attachFile" tabIndex="-1" role="dialog" aria-labelledby="attachFileLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="attachFileLabel">Upload File</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <input type="file" onChange={handleFileUpload} />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={submitFile}>Upload</button>
      </div>
    </div>
  </div>
</div>
  {/* <!-- Share To Email Modal --> */}
  <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="m-3">Share Item Transactions</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Bill details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>




     {/* History Modal */}
<div
  className="modal fade"
  id="historyModal"
  tabIndex="-1"
  role="dialog"
  aria-labelledby="historyModalLabel"
  aria-hidden="true"
>
  <div className="modal-dialog modal-lg" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h3 className="modal-title" id="historyModalLabel">
          Transaction History
        </h3>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body w-100">
        {history && history.length > 0 ? (
          <div className="container-fluid">
            <table className="table mt-4">
              <thead>
                <tr>
                  <th className="text-center">SL NO.</th>
                  <th className="text-center">DATE</th>
                  <th className="text-center">ACTION</th>
                  <th className="text-center">DONE BY</th>
                </tr>
              </thead>
              <tbody>
                {history.map((h, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{h.date}</td>
                    <td className={`text-center ${h.action === "Created" ? 'text-success' : 'text-warning'}`}>{h.action}</td>
                    <td className="text-center">{h.username}</td> {/* Using 'username' directly */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <span className="my-2 font-weight-bold d-flex justify-content-center">
            No History Available.
          </span>
        )}
      </div>
      <div className="modal-footer w-100">
        <button
          type="button"
          style={{ width: 'fit-content', height: 'fit-content' }}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
</div>
  </div>



  
  </div>
              </div>
            </div>
          </div>
       
     
    

    
    
  );
}

export default SalesOrderDetails;
