import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from "sweetalert2";
import config from '../../../functions/config';
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

function Edit_Holiday() {
    const { holidayId } = useParams();
    
    const [holidayDetails, setHolidayDetails] = useState({
        title: '',
        startDate: '',
        endDate: ''
    });

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    
    const query = useQuery();
    const ID = query.get("ID");
    console.log('id', ID);

    useEffect(() => {
        axios.get(`${config.base_url}/get_holidayedit/${holidayId}/`)
            .then(response => {
                if (response.data.status) {
                    const data = response.data.data;
                    setHolidayDetails({
                        title: data.Holiday_Name,
                        startDate: data.Start_Date,
                        endDate: data.End_Date
                    });
                } else {
                    console.error("Error fetching holiday details:", response.data.message);
                }
            })
            .catch(error => {
                console.error("Error fetching holiday details:", error);
            });
    }, [holidayId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setHolidayDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!holidayDetails.title || !holidayDetails.startDate || !holidayDetails.endDate) {
            Swal.fire({
                icon: "error",
                title: "All fields are required.",
                text: "Please fill out the Title, Start Date, and End Date fields."
            });
            return;
        }
        const updatedDetails = { ...holidayDetails, ID }; // Add ID to holidayDetails
        axios.post(`${config.base_url}/update_holiday/${holidayId}/`, updatedDetails)
            .then(response => {
                if (response.data.status) {
                    window.history.back(); // Redirect to holidays list page
                } else {
                    console.error("Error updating holiday details:", response.data.message);
                }
            })
            .catch(err => {
                console.log("ERROR=", err);
                if (err.response && err.response.data.message) {
                    Swal.fire({
                        icon: "error",
                        title: err.response.data.message,
                    });
                }
            });
    };

    return (
        <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper d-flex">
          <Sidebar />
            
            <div className="page-content" style={{ backgroundColor: "#a9a9a961", minHeight: "100vh", width: '100%', padding:'20px' }}>
                <div className="card radius-15 h-20">
                    <div className="row">
                        <div className="col-md-12">
                            <center><h2 className="mt-3">EDIT HOLIDAY</h2></center>
                            <hr />
                        </div>
                    </div>
                </div>

                <div className="card radius-15"style={{marginTop:"20px"}}>
                    <div className="card-body">
                        <form method="post" className="needs-validation" noValidate onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-12 mx-0">
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <label htmlFor="title" style={{ color: 'black' }}>Title</label>
                                            <input
                                                type="text"
                                                name="title"
                                                className="form-control"
                                                style={{ backgroundColor: 'white', color: 'black' }}
                                                required
                                                id="title"
                                                value={holidayDetails.title}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <label htmlFor="startDate" style={{ color: 'black' }}>Start Date</label>
                                            <input
                                                type="date"
                                                name="startDate"
                                                className="form-control"
                                                style={{ backgroundColor: 'white', color: 'black' }}
                                                required
                                                id="startDate"
                                                value={holidayDetails.startDate}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <label htmlFor="endDate" style={{ color: 'black' }}>End Date</label>
                                            <input
                                                type="date"
                                                name="endDate"
                                                className="form-control"
                                                style={{ backgroundColor: 'white', color: 'black' }}
                                                required
                                                id="endDate"
                                                value={holidayDetails.endDate}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6 d-flex justify-content-center">
                                            <button className="btn btn-outline-secondary w-50 text-dark" type="submit" style={{ width: 'fit-content', height: 'fit-content' }}>UPDATE</button>
                                            <button type="button" onClick={() => window.history.back()} className="btn btn-outline-secondary w-50 ml-1 text-dark" style={{ width: 'fit-content', height: 'fit-content' }}>CANCEL</button>
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Edit_Holiday;
