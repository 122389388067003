import React, { useEffect, useState,useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import { Link, useNavigate,useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "../styles/Staff.css"
import * as XLSX from "xlsx";


 



function Loanedit() {
    const user = Cookies.get("role");
    const ID = Cookies.get("user_id");
  const navigate = useNavigate();

  const {loanId} = useParams()


  const [employees, setEmployees] = useState([]); // Initialize employees state as an empty array
  
  const [filePath, setFilePath] = useState('');
  const [empDetail, setEmpDetail] = useState({ empid: '',empemail: '',empsalary: '',empdata:''});
  const [banks,setbanks]=useState([])
  const [selectedBank,setSelectedBank]=useState('')
  const [bankDetail, setBankDetail] = useState({ accountNumber: ''});
  const percent = useRef();
  const percentvalue = useRef();

  
  
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [empemail, setEmpEmail] = useState('');
  const [salary, setSalary] = useState('');
  const [employeeDate, setEmployeeDate] = useState('');
  //const [date,setDate] = useState('')
  const [loanAmount, setLoanAmount] = useState(''); 
  const [loanDate, setLoanDate] = useState(''); 
  const [loanDuration, setLoanDuration] = useState([]); 
  const [loanDurations, setLoanDurations] = useState(''); 
  const [expiryDate, setExpiryDate] = useState('');
  const [paymentType, setPaymentType] = useState(''); 
  const [chequeNumber, setChequeNumber] = useState(''); 
  const [upiId, setUpiId] = useState(''); 
  const [accno,setAccno] = useState('')
  console.log('accc nnoo=',bankDetail.accountNumber)
  const [monthlyCuttingType, setMonthlyCuttingType] = useState(''); 
  const [monthlyCuttingAmount, setMonthlyCuttingAmount] = useState(''); 
  const [monthlyCuttingPercentage, setMonthlyCuttingPercentage] = useState(''); 
  const [percentageAmount , setPercentageAmount]= useState('')
  const [note, setNote] = useState('');
  const [status, setStatus] = useState(''); 
  const [empfile, setEmpFile] = useState(null); 


  const [modalduration , setModalDuration]= useState('')
  const [modalterm , setModalTerm]= useState('')

  
  


  function handleTermModalSubmit(e){
    e.preventDefault();
    
    if (modalduration != "" && modalterm != "") {
      var u = {
        Id: ID,
        num: modalduration,
        value:modalterm,
        
        
      };
      axios
        .post(`${config.base_url}/loan_create_new_term/`, u)
        .then((res) => {
          console.log("LOAN RES=", res);
          if (res.data.status) {
            Toast.fire({
              icon: "success",
              title: "Term Created",
            });
            fetchLoanDurations();
            
            setModalDuration("");
            setModalTerm("");
            
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    } else {
      alert("Invalid");
    }
  }


  

    
    
    const fetchLoanDurations = () => {
      axios
        .get(`${config.base_url}/get_loan_duration/${ID}/`)
        .then((res) => {
          console.log("duraions==", res);
          if (res.data.status) {
            
            setLoanDuration(res.data.term);
           
              
           
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  

    const fetchEmployee = () => {
      axios
        .get(`${config.base_url}/get_loan_employee/${ID}/`)
        .then((res) => {
          console.log("employee==", res);
          if (res.data.status) {
            var emp = res.data.employee;
            setEmployeeId(emp.employeeId);
            setEmpEmail(emp.employee_mail);
            setSalary(emp.salary_amount);
            setEmployeeDate(emp.date_of_joining);

            setEmployees(res.data.employee);
           
              
           
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const fetchbanks = () => {
      axios
        .get(`${config.base_url}/get_banks/${ID}/`)
        .then((res) => {
          console.log("banks==", res);
          if (res.data.status) {
            
            setbanks(res.data.bank);
           
              
           
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  

    const fetchBankDetails = (bankId) => {
      axios
        .get(`${config.base_url}/get_bank_details/${bankId}/${ID}/`)
        .then((res) => {
          console.log("bank details==", res);
          if (res.data.status) {
            const { account_number } = res.data.bank[0];
            setBankDetail({ 
              accountNumber: account_number, 
              
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    


    const fetchLoanData = () => {
      axios
        .get(`${config.base_url}/get_loan_data/${loanId}/${ID}/`)
        .then((res) => {
          console.log("loan details==", res);
          if (res.data.status) {
            var loan = res.data.loan;
            var emp = res.data.loan.employee;
            var term = res.data.term;
            var payment = res.data.bank_id;
            setSelectedEmployee(emp.id);
            //fetchEmployeeDetails(selectedEmployee);
            setEmployeeId(emp.employee_number);
            setEmpEmail(emp.employee_mail);
            setSalary(emp.salary_amount);
            setEmployeeDate(emp.date_of_joining);
            setLoanAmount(loan.loan_amount);
            setLoanDate(loan.loan_date);
            setExpiryDate(loan.expiry_date);
            setLoanDurations(loan.loan_duration);
            if(payment){
              setPaymentType(payment)
            }else{
              setPaymentType(loan.payment_method)
            }
            setChequeNumber(loan.cheque_number);
            setUpiId(loan.upi);
            if (loan.monthly_cutting_percentage) {
              setMonthlyCuttingType('percentage');
              setPercentageAmount(loan.monthly_cutting_amount)
          } else {
              setMonthlyCuttingType('amount');
              setPercentageAmount('')
          }
            //setPercentageAmount(loan.monthly_cutting_amount)
            
            setMonthlyCuttingAmount(loan.monthly_cutting_amount);
            setMonthlyCuttingPercentage(loan.monthly_cutting_percentage);
            setNote(loan.note);
            setEmpFile(loan.file.name);
            if (empfile) {
              setFilePath(empfile.name);  
            } else {
              setFilePath('');  
            }
            setStatus(loan.status);
            






          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    

    
  
  
   
    useEffect(() => {
      fetchLoanDurations();
      fetchEmployee();
      fetchbanks();
      fetchBankDetails();
      fetchLoanData();
      
        }, []);
        useEffect(() => {
    
          const getCurrentDate = () => {
            const date = new Date();
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
      
            
            if (month < 10) {
              month = `0${month}`;
            }
            if (day < 10) {
              day = `0${day}`;
            }
      
            return `${year}-${month}-${day}`;
          };
      
      
      
          
          setLoanDate(getCurrentDate());
          
        }, []); 
        const handleBankChange = (id) => {
          
          setSelectedBank(id);
          fetchBankDetails(id);
          //setBank(e.target.value)
        };
        const maskAccountNumber = (accountNumber) => {
          
          return '*'.repeat(accountNumber.length - 4) + accountNumber.slice(-4);
        };


        const fetchEmployeeDetails = (selectedEmployee) => {
          axios
            .get(`${config.base_url}/get_employee_details/${selectedEmployee}/${ID}/`)
            .then((res) => {

             
              
              if (res.data.status)  {
                
            var emp = res.data.employee[0];
            console.log('emppplooyeeee ddaaaattta=',emp)
            setEmployeeId(emp.employee_number);
            console.log('emmmplloyeee iiiidddd=',employeeId)
            setEmpEmail(emp.employee_mail);
            setSalary(emp.salary_amount);
            setEmployeeDate(emp.date_of_joining);
              } else {
                console.log("No employee details found or invalid response structure.");
                setEmpDetail({
                  empid: '',
                  empemail: '',
                  empsalary: '',
                  empdata: ''
                });
              }
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: "Error fetching employee details",
                text: err.message,
              });
            });
        };


        
        const handleLoanDate = (event) => {
          const du = event.target.value;
          console.log('du=',du)
          
          setLoanDate(du);
          calculateExpiryDate();
        };
        const handleduration = (e) => {
          const du = e.target.value;
          
          setLoanDurations(du);
          calculateExpiryDate();
        };

        const formatDateToYYYYMMDD = (date) => {
          const [day, month, year] = date.split('-');
          return `${year}-${month}-${day}`;
        };
      
        // Convert a date from YYYY-MM-DD to DD-MM-YYYY format
        const formatDateToDDMMYYYY = (date) => {
          const [year, month, day] = date.split('-');
          return `${day}-${month}-${year}`;
        };
      
       
        const calculateExpiryDate = () => {
          console.log('loandurationsss=',loanDurations)
          if (loanDate && loanDurations) {
            const [durationValue, durationUnit] = loanDurations.split('-');
            const formattedLoanDate = formatDateToYYYYMMDD(loanDate);
            const [day, month, year] = formattedLoanDate.split('-');
            const date = new Date(`${year}-${month}-${day}`);
      
            // Add duration to the loan date
            if (durationUnit === 'MONTH') {
              date.setMonth(date.getMonth() + parseInt(durationValue));
            } else if (durationUnit === 'YEAR') {
              date.setFullYear(date.getFullYear() + parseInt(durationValue));
            }
      
            // Set the expiry date in DD-MM-YYYY format
            const yearExp = date.getFullYear();
            const monthExp = ('0' + (date.getMonth() + 1)).slice(-2);
            const dayExp = ('0' + date.getDate()).slice(-2);
            const expiryDate = `${dayExp}-${monthExp}-${yearExp}`;
            var expd = formatDateToYYYYMMDD(expiryDate)
            setExpiryDate(expd);

          }
        };

        useEffect(() => {
          calculateExpiryDate();
        }, [loanDate, loanDurations]);

        useEffect(() => {
          if (empDetail.empsalary && monthlyCuttingPercentage) {
            calculatePercentageAmount(empDetail.empsalary, monthlyCuttingPercentage);
          }
        }, [empDetail.empsalary, monthlyCuttingPercentage]);
 
    const handleEmployeeChange = (event) => {
      const employeeId = event.target.value;
      setSelectedEmployee(employeeId);
      fetchEmployeeDetails(employeeId)
    };
  

  const handleLoanAmount = (event) => {
    setLoanAmount(event.target.value);
  };

  const handleSalary = (event) => {
    setSalary(event.target.value);
  };

  const calculatePercentageAmount = (salary, percentage) => {
    const amount = (salary * percentage) / 100;
    setPercentageAmount(amount.toFixed(2)); 
  };
  const handlePercentage = (event) => {
    setMonthlyCuttingPercentage(event.target.value);
    if (salary) {
      calculatePercentageAmount(salary, event.target.value);
    }
  };

 

  const handleLoanDuration = (event) => {
    setLoanDuration(event.target.value);
  };

  const handlePaymentType = (event) => {
    
      const value = event.target.value;
      console.log('baaank=',value)
      setPaymentType(value);
      //setSelectedBank(value); 
  
      if (value !== 'Cash' || value !== 'Cheque' || value !== 'UPI') {
        setSelectedBank(value);
        handleBankChange(value)  
      } else {
        setSelectedBank('');  // Clear the selected bank if the value is not valid
      }
    };
  

  const handleChequeNumber = (event) => {
    setChequeNumber(event.target.value);
  };

  const handleUpiId = (event) => {
    setUpiId(event.target.value);
  };

  const handleMonthlyCuttingType = (event) => {
    setMonthlyCuttingType(event.target.value);
    if(event.target.value == 'amount'){
      setMonthlyCuttingPercentage('')
      setPercentageAmount('')
    }else{
      setMonthlyCuttingAmount('')
    }
  };

  const handleMonthlyCuttingAmount = (event) => {
    setMonthlyCuttingAmount(event.target.value);
    setMonthlyCuttingPercentage('');
    setPercentageAmount('');
  };

  const handleMonthlyCuttingPercentage = (event) => {
    setMonthlyCuttingPercentage(event.target.value);
  };
  const handlePercentageAmount = (event) => {
    setPercentageAmount(event.target.value);
  };

  const handleNote = (event) => {
    setNote(event.target.value);
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setEmpFile(uploadedFile);
  };

  

  
 
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('monthly amount amount=',monthlyCuttingAmount);
    console.log('percentage amount amount=',percentageAmount);
    console.log('percentage =',monthlyCuttingPercentage)
    console.log('status=',status)
  
    const formData = new FormData();
      formData.append('Id', ID);
      formData.append('employee', selectedEmployee);
      formData.append('empid',employeeId);
      formData.append('empemail', empemail);
      formData.append('empdate', employeeDate);
      formData.append('empsalary', salary);
      formData.append('loan_date', loanDate);
      formData.append('loan_amount', loanAmount);
      formData.append('expiry_date', expiryDate);
      formData.append('loan_duration', loanDurations);
      formData.append('payment_method', paymentType);
      formData.append('cheque_number', chequeNumber);
      formData.append('upi', upiId);
      formData.append('account_number', bankDetail.accountNumber);
      formData.append('monthly_cutting_amount', monthlyCuttingAmount);
      formData.append('monthly_cutting_percentage', monthlyCuttingPercentage);
      formData.append('percentage_amount', percentageAmount);
      formData.append('note', note);
     // formData.append('file', file);
      if (empfile) { 
        formData.append('file', empfile);
    }
      formData.append('status',status)

      axios.put(`${config.base_url}/edit_loan/${loanId}/`, formData)
      
      .then((res) => {
        console.log("ITM RES=", res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Loan Edited",
          });
          navigate("/loanlist");
        }
        if (!res.data.status && res.data.message != "") {
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }; 


  



  
    
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });





  // EMPLOYEEE AREA---------------------------------





  const [bloodGroups, setBloodGroups] = useState([]);
  const [newUnit, setNewUnit] = useState("");


  const fetchBloodGroups = () => {
    axios
      .get(`${config.base_url}/create_new_employee/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          setBloodGroups(res.data.bloodgp);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchBloodGroups();
  }, []);
  

  function handleUnitModalSubmit(e) {
    e.preventDefault();
    var name = newUnit;
    console.log(name)
    if (name != "") {
      var u = {
        Id: ID,
        blood_group: newUnit,
      };
      console.log(u)
      axios
        .post(`${config.base_url}/loan_create_new_bloodgroup/`, u)
        .then((res) => {
          console.log("UNIT RES=", res);
          if (res.data.status) {
            Toast.fire({
              icon: "success",
              title: "bloodgroup Created",
            });
            fetchBloodGroups();
            setNewUnit("");
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    } else {
      alert("Invalid");
    }
  }
  const [sameAddress, setSameAddress] = useState(false);
  const [tdsApplicable, setTdsApplicable] = useState('');
  const [tdsType, setTdsType] = useState('');
  const [bankDetails, setBankDetails] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const [age, setAge] = useState('');
  const [joiningDate, setJoiningDate] = useState('');
  const [isBankInfoVisible, setIsBankInfoVisible] = useState(false);
  const [isTdsInfoVisible, setIsTdsInfoVisible] = useState(false);
  const [isTdsPercentageVisible, setIsTdsPercentageVisible] = useState(false);
  const [isTdsAmountVisible, setIsTdsAmountVisible] = useState(false);
  const [salaryType, setSalaryType] = useState('');
  const [amountPerHour, setAmountPerHour] = useState(0);
  const [workingHours, setWorkingHours] = useState(0);
  const [salaryAmount, setSalaryAmount] = useState('');
  const [presentAddress, setPresentAddress] = useState({
    address: '',
    city: '',
    state: '',
    pincode: '',
    country:'',
  });
  const [permanentAddress, setPermanentAddress] = useState({
    address: '',
    city: '',
    state: '',
    pincode: '',
    country:'',
  
  });
  const [Title, setTitle] = useState('');
  const [First_Name, setFirstName] = useState('');
  const [Last_Name, setLastName] = useState('');
  const [Alias, setAlias] = useState('');

  const [Email, setEmail] = useState("");
  const [Employee_Number, setEmployee_Number] = useState("");
  const [Designation, setDesignation] = useState("");
  const [CurrentLocation, setCurrentLocation] = useState("");
  const [Gender, setGender] = useState("");
  const [DOB, setDOB] = useState("");
  const [Blood, setBlood] = useState("");
  const [parent, setparent] = useState("");
  const [Spouse, setSpouse] = useState("");
  const [Number2, setNumber2] = useState("");
  const [Account_Number, setAccountNumber] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [BankName, setBankName] = useState("");
  const [branch_name, setBranchName] = useState("");
  const [PAN, setPAN] = useState("");
  const [PR, setPR] = useState(0);
  const [UAN, setUAN] = useState("");
  const [PF, setPF] = useState("");
  const [Income_Tax, setIncome_Tax] = useState("");
  const [Aadhar, setAadhar] = useState(0);
  const [ESI, setESI] = useState("");
  const [salary_details, setSalaryDetails] = useState({});
  const [TDS_Amount, setTdsAmount] = useState('0');
  const [TDS_Percentage, setTdsPercentage] = useState('0');
  const [formData, setFormData] = useState({});
  const [Salary_Date, setSalaryDate] = useState(new Date());
  const [contact, setContact] = useState('');
  const [image, setimage] = useState('');
  
  const handleImageChange= (e) => {
    const image = e.target.files[0];
    setimage(image);
  };

  // const handleSalaryTypeChange = (event) => {
  //   setSalaryType(event.target.value);
  //   // Reset the salary amount and other inputs when the salary type changes
  //   setSalaryAmount('');
  //   setAmountPerHour('');
  //   setWorkingHours('');
  // };
  const handlesubmit = (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('Id', ID);
    formData.append('Title', Title);
    formData.append('First_Name', First_Name);
    formData.append('Last_Name', Last_Name);
    formData.append('Joining_Date', joiningDate);
    formData.append('Salary_Date', Salary_Date);
    formData.append('Salary_Type', salaryType);
    formData.append('Salary_Amount', salaryAmount);
    formData.append('Amount_Per_Hour', amountPerHour);
    formData.append('Working_Hours', workingHours);
    formData.append('Alias', Alias);
    formData.append('Employee_Number', Employee_Number);
    formData.append('Designation', Designation);
    formData.append('Location', CurrentLocation);
    formData.append('Gender', Gender);
    formData.append('DOB', DOB);
    formData.append('Age', age);
    formData.append('Blood_Group', Blood);
    formData.append('Contact_Number', contact);
    formData.append('Emergency_Contact_Number', Number2);
    formData.append('Personal_Email', Email);
    formData.append('Parent_Name', parent);
    formData.append('Spouse_Name', Spouse);
    formData.append('file', file);
    formData.append('image', image);
    formData.append('Bank_Details', bankDetails);
    formData.append('TDS_Applicable', tdsApplicable);
    formData.append('Account_Number', Account_Number);
    formData.append('IFSC', IFSC);
    formData.append('Bank_Name', BankName);
    formData.append('Branch_Name', branch_name);
    formData.append('Transaction_Type', transactionType);
    formData.append('TDS_Type', tdsType);
    formData.append('TDS_Amount', TDS_Amount);
    formData.append('TDS_Percentage', TDS_Percentage);

    formData.append('Present_Address', JSON.stringify(presentAddress));
    formData.append('Permanent_Address', JSON.stringify(permanentAddress));
    formData.append('PAN', PAN);
    formData.append('Income_Tax', Income_Tax);
    formData.append('Aadhar', Aadhar);
    formData.append('UAN', UAN);
    formData.append('PF', PF);
    formData.append('PR', PR);
      
    axios.post(`${config.base_url}/loan_employee_save/`, formData)
    
    .then((res) => {
      console.log("ITM RES=", res);
      if (res.data.status) {
        Toast.fire({
          icon: "success",
          title: "Employee Created",
        });
        fetchEmployee();
        
      }
      if (!res.data.status && res.data.message != "") {
        Swal.fire({
          icon: "error",
          title: `${res.data.message}`,
        });
      }
    })
    .catch((err) => {
      console.log("ERROR=", err);
      if (!err.response.data.status) {
        Swal.fire({
          icon: "error",
          title: `${err.response.data.message}`,
        });
      }
    });
}; 

const handleChange = (e) => {
  const { name, value } = e.target;
  switch (name) {
    case 'Title':
      setTitle(value);
      break;
    case 'First_Name':
      setFirstName(value);
      break;
    case 'Last_Name':
      setLastName(value);
      break;
    case 'Alias':
      setAlias(value);
      break;
    case 'Salary_Date':
      setSalaryDate(value);
      break;
    case 'Joining_Date':
      setJoiningDate(value);
      break;
    case 'Employee_Number':
      setEmployee_Number(value);
      break;
    case 'Designation':
      setDesignation(value);
      break;
    case 'Location':
      setCurrentLocation(value);
      break;
    case 'Gender':
      setGender(value);
      break;
    case 'Blood':
      setBlood(value);
      break;
    case 'Contact_Number':
      setContact(value);
      break;
    case 'Emergency_Contact_Number':
      setNumber2(value);
      break;
    case 'Personal_Email':
      setEmail(value);
      break;
    case 'Parent':
      setparent(value);
      break;
    case 'Spouse':
      setSpouse(value);
      break;
    case 'Bank_Details':
      setBankDetails(value);
      break;
    case 'tds_applicable':
      setTdsApplicable(value);
      break;
    case 'Income_Tax':
      setIncome_Tax(value);
      break;
    case 'Aadhar':
      setAadhar(value);
      break;
    case 'UAN':
      setUAN(value);
      break;
    case 'PF':
      setPF(value);
      break;
    case 'PAN':
      setPAN(value);
      break;
    case 'PR':
      setPR(value);
      break;
    case 'ESI':
      setESI(value);
      break;
    case 'Account_Number':
      setAccountNumber(value);
      break;
    case 'IFSC':
      setIFSC(value);
      break;
    case 'BankName':
      setBankName(value);
      break;
    case 'BranchName':
      setBranchName(value);
      break;
    case 'Transaction_Type':
      setTransactionType(value);
      break;
    case 'TDS_Type':
      setTdsType(value);
      break;
    case 'TDS_Percentage':
      setTdsPercentage(value);
      break;
    case 'TDS_Amount':
      setTdsAmount(value);
      break;
  
    default:
      break;
}
};
const [file, setFile] = useState(null);

const handleFileChange = (e) => {
const file = e.target.files[0];
setFile(file);
};

const handlePresentAddressChange = (e) => {
const { name, value } = e.target;
setPresentAddress((prev) => ({
  ...prev,
  [name]: value,
}));
};

const handlePermanentAddressChange = (e) => {
const { name, value } = e.target;
setPermanentAddress((prev) => ({
  ...prev,
  [name]: value,
}));
};

const handleSameAddressChange = () => {
setSameAddress((prev) => !prev);
};

const handleDOBChange = (e) => {
const dob = e.target.value;
setDOB(dob);
setAge(calculateAge(dob));
};

const calculateAge = (dob) => {
const today = new Date();
const birthDate = new Date(dob);
let age = today.getFullYear() - birthDate.getFullYear();
const monthDiff = today.getMonth() - birthDate.getMonth();

if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
  age--;
}
return age;
};

// const handleAmountPerHourChange = (event) => {
//   const value = event.target.value;
//   setAmountPerHour(value);
//   calculateAndSetSalary(value, workingHours);
// };

// const handleWorkingHoursChange = (event) => {
//   const value = event.target.value;
//   setWorkingHours(value);
//   calculateAndSetSalary(amountPerHour, value);
// };

// const calculateAndSetSalary = (perHour, hours) => {
//   const totalSalary = (parseFloat(perHour) || 0) * (parseFloat(hours) || 0);
//   setSalaryAmount(totalSalary.toFixed(2));
// };

// const handleSalaryAmountChange = (event) => {
//   if (salaryType !== 'Time Based') {
//     setSalaryAmount(event.target.value);
//   }
// };

const handleBankDetailsChange = (e) => {
setBankDetails(e.target.value);
};

const handleTdsApplicableChange = (e) => {
setTdsApplicable(e.target.value);
};

const handleTdsTypeChange = (e) => {
setTdsType(e.target.value);
};

useEffect(() => {
setJoiningDate(new Date().toISOString().split('T')[0]);
}, []);

useEffect(() => {
if (sameAddress) {
  setPermanentAddress({ ...presentAddress });
}
}, [sameAddress, presentAddress]);

useEffect(() => {
if (bankDetails === 'Yes') {
  setIsBankInfoVisible(true);
} else {
  setIsBankInfoVisible(false);
}
}, [bankDetails]);

useEffect(() => {
if (tdsApplicable === 'Yes') {
  setIsTdsInfoVisible(true);
} else {
  setIsTdsInfoVisible(false);
}
}, [tdsApplicable]);

useEffect(() => {
if (tdsType === 'Percentage') {
  setIsTdsPercentageVisible(true);
  setIsTdsAmountVisible(false);
} else if (tdsType === 'Amount') {
  setIsTdsPercentageVisible(false);
  setIsTdsAmountVisible(true);
} else {
  setIsTdsPercentageVisible(false);
  setIsTdsAmountVisible(false);
}
}, [tdsType]);

// useEffect(() => {
//   if (salaryType === 'Temporary' || salaryType === 'Fixed') {
//     setSalaryAmount('');
//   } else if (salaryType === 'Time Based') {
//     calculateSalary();
//   }
// }, [salaryType, amountPerHour, workingHours]);

// const calculateSalary = () => {
//   if (amountPerHour > 0 && workingHours > 0) {
//     setSalaryAmount(amountPerHour * workingHours);
//   } else {
//     setSalaryAmount('');
//   }
// };



const handleSalaryTypeChange = (e) => {
setSalaryType(e.target.value);
// Reset salaryAmount if switching from Time Based to Fixed or Temporary
if (e.target.value !== 'Time Based') {
  setSalaryAmount('');
}
};

const handleSalaryAmountChange = (e) => {
setSalaryAmount(e.target.value);
};

const handleAmountPerHourChange = (e) => {
setAmountPerHour(e.target.value);
};

const handleWorkingHoursChange = (e) => {
setWorkingHours(e.target.value);
};

useEffect(() => {
if (salaryType === 'Time Based') {
  if (amountPerHour > 0 && workingHours > 0) {
    setSalaryAmount(amountPerHour * workingHours);
  } else {
    setSalaryAmount('0');
  }
}
}, [salaryType, amountPerHour, workingHours]);




  

  
  
  

  
  
  
  return (
    <>
      <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961", maxWidth:"100vw" }}
        >
        <div className="d-flex justify-content-end mb-1">
          <Link to={`/viewloan/${loanId}/`}>
            <i
              className="fa fa-times-circle text-white mx-4 p-1"
              style={{ fontSize: "1.2rem", marginRight: "0rem !important" }}
            ></i>
          </Link>
        </div>
        <div className="card radius-15 h-20 mb-5">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="mt-3">EDIT LOAN</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        <div className="card radius-15">
  <div className="card-body">
  
  <form
              className="needs-validation px-1 "
              onSubmit={handleSubmit}
              validate
            >
      <div className="row w-100">
      <div className="col-md-12 mx-0">
        <div className="row">
        <div className="col-md-6">
          {/* Column 1: Employee selection, loan details */}
          <div className="form-group">
            <label htmlFor="employee">Employee</label>
            <div className="d-flex align-items-center">
                
            <select
              id="employee"
              className="form-control"
              value={selectedEmployee}
              onChange={handleEmployeeChange}
            >
              <option value="">Select Employee</option>
            {employees.map(emp => (
              <option key={emp.id} value={emp.id}>
                {emp.first_name} {emp.last_name}
              </option>
              ))}
            </select>
           

           
            <button
                            type="button"
                            className="btn btn-outline-secondary ml-1"
                            data-toggle="modal"
                            data-target="#createNewEmployee"
                            style={{
                              width: "fit-content",
                              height: "fit-content",
                            }}
                          >
                            +
                          </button>
                       
            </div>
          </div>
         
          
          
          <div className="form-group">
            <label htmlFor="loanAmount">Employee ID</label>
            <input
              type="text"
              id="loanAmount"
              className="form-control"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="loanAmount">Employee Email</label>
            <input
              type="text"
              id="loanAmount"
              className="form-control"
              value={empemail}
              onChange={(e) => setEmpEmail(e.target.value)}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="loanAmount">Employee Salary</label>
            <input
              type="text"
              id="loanAmount"
              className="form-control"
              value={salary}
              onChange={handleSalary}
              readOnly
              
            />
          </div>
          
         
          <div className="form-group">
            <label htmlFor="loanDate">Joining Date</label>
            <input
              type="date"
              id="loanDate"
              className="form-control"
              value={employeeDate}
              onChange={(e) => setEmployeeDate(e.target.value)}
              readonly
            />
          </div>
          
        </div>
        <div className="col-md-6">
          {/* Column 2: Payment details, monthly cutting, file upload */}
          <div className="form-group">
            <label htmlFor="loanAmount">Loan Amount</label>
            <input
              type="text"
              id="loanAmount"
              className="form-control"
              value={loanAmount}
              onChange={handleLoanAmount}
            />
          </div>
          <div className="form-group">
            <label htmlFor="loanDate">Loan Date</label>
            <input
              type="date"
              id="loanDate"
              className="form-control"
              value={loanDate}
              onChange={handleLoanDate}
            />
          </div>
          <div className="form-group">
            <label htmlFor="loanDate">Expiry Date</label>
            <input
              type="date"
              id="loanDate"
              className="form-control"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="loanDuration">Loan Duration</label>
            <div className="d-flex align-items-center">
            <select
              id="loanDuration"
              className="form-control"
              value={loanDurations}
              onChange={handleduration}
            >
              <option value="">Select Duration</option>
               
              {loanDuration.map(duration => (
            <option key={duration.id} value={`${duration.duration}-${duration.term}`}>
              {duration.duration} {duration.term}
            </option>
              ))}
            </select>
            <button
                            type="button"
                            className="btn btn-outline-secondary ml-1"
                            data-toggle="modal"
                            data-target="#createNewTerm"
                            style={{
                              width: "fit-content",
                              height: "fit-content",
                            }}
                          >
                            +
                          </button>
                       
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="paymentType">Payment Type</label>
            <select
              id="paymentType"
              className="form-control"
              value={paymentType}
              onChange={handlePaymentType}
            >
              <option value="">Select Payment Type</option>
              <option value="Cash">Cash</option>
              <option value="Cheque">Cheque</option>
              <option value="UPI">UPI</option>
             
              {banks.map((bank) => (
          <option key={bank.id} value={bank.id} className="text-uppercase">
           {bank.bank_name} - {maskAccountNumber(bank.account_number)}
          </option>
        ))}
            </select>
          </div>
          {paymentType === 'Cheque' && (
            <div className="form-group">
              <label htmlFor="chequeNumber">Cheque Number</label>
              <input
                type="text"
                id="chequeNumber"
                className="form-control"
                value={chequeNumber}
                onChange={handleChequeNumber}
              />
            </div>
          )}
          {paymentType === 'UPI' && (
            <div className="form-group">
              <label htmlFor="upiId">UPI ID</label>
              <input
                type="text"
                id="upiId"
                className="form-control"
                value={upiId}
                onChange={handleUpiId}
              />
            </div>
          )}
          

          {selectedBank && !['Cash', 'Cheque', 'UPI'].includes(paymentType) && (
  <div className="form-group">
    <label htmlFor="accountNumber">Account Number</label>
    <input
      type="text"
      id="accountNumber"
      className="form-control"
      value={bankDetail.accountNumber}
      onChange={(e) => setAccno(e.target.value)}
      readOnly
    />
  </div>
)}
          <div className="form-group">
            <label htmlFor="monthlyCuttingType">Monthly Cutting Type</label>
            <select
              id="monthlyCuttingType"
              className="form-control"
              value={monthlyCuttingType}
              onChange={handleMonthlyCuttingType}
            >
              <option value="">Select Cutting Type</option>
              <option value="amount">Amount</option>
              <option value="percentage">Percentage</option>
            </select>
          </div>
          {monthlyCuttingType === 'amount' && (
            <div className="form-group">
              <label htmlFor="monthlyCuttingAmount">Monthly Cutting Amount</label>
              <input
                type="text"
                id="monthlyCuttingAmount"
                className="form-control"
                value={monthlyCuttingAmount}
                onChange={handleMonthlyCuttingAmount}
              />
            </div>
          )}
          {monthlyCuttingType === 'percentage' && (
            <div className="form-group">
              <label htmlFor="monthlyCuttingPercentage">Monthly Cutting Percentage</label>
              <input
                type="text"
                id="monthlyCuttingPercentage"
                className="form-control"
                value={monthlyCuttingPercentage}
                onChange={handlePercentage}
                ref={percent}
              />
            </div>
          )}
          { monthlyCuttingType == 'percentage' && (
          <div className="form-group">
              <label htmlFor="monthlyCuttingPercentage">Amount</label>
              <input
                type="text"
                id="monthlyCuttingPercentage"
                className="form-control"
                value={percentageAmount}
                onChange={(e) => setPercentageAmount(e.target.value)}
                ref={percentvalue}
                readonly
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="note">Note</label>
            <textarea
              id="note"
              className="form-control"
              value={note}
              onChange={handleNote}
            />
          </div>
          <div className="form-group">
            <label >Upload File </label>
            <input
              type="file"
              id="file"
              className="form-control"
              onChange={handleFileUpload}
              
            
              
            />
            {filePath && (
        <p className="file-path">Selected file: {filePath}</p>  
      )}
          </div>
        </div>
      </div>
      <div className="row mt-5 mb-5">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 d-flex justify-content-center">
                    <button
                        className="btn btn-outline-secondary "
                        type="submit"
                       
                        style={{ width: "50%", height: "fit-content" }}
                      >
                        EDIT
                      </button>
                      <Link
                        to={`/viewloan/${loanId}/`}
                        className="btn btn-outline-secondary ml-1 "
                        style={{ width: "fit-content", height: "fit-content" }}
                      >
                        CANCEL
                      </Link>
                    </div>
      </div>
      </div>
      </div>
    </form>
    </div>
    </div>
    </div>
    </div></div>








{/* <!-- Payment Term Create Modal --> */}
<div className="modal fade" id="createNewEmployee">
        <div className="modal-dialog " style={{ maxWidth: '80%' }}>
          <div className="modal-content">
            <div className="modal-header ">
              <h5 className="m-3"></h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">

           
              <center>
                <h2 className="mt-3">CREATE EMPLOYEE</h2>
              </center>
              <hr />
            
        <div className="card radius-15">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-lg-12 col-xl-12"></div>
            </div>
            <form  onSubmit={handlesubmit} className="needs-validation px-1" validate>
              <br />
              <div className="row w-100">
                <div className="col-md-12 mx-0">
                    
                <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label>Title</label>
                    <select
                    id="Title"
                    name="Title"
                    className="form-control"
                    onChange={handleChange}
                    value={Title}
                  >
                    <option value="">Choose...</option>
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                    <option value="Mrs">Mrs</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    placeholder="First Name"
                    type="text"
                    id="First_Name"
                    name="First_Name"
                    className="form-control"
                    onChange={handleChange}
                    value={First_Name}
                  />
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    placeholder="Last Name"
                    type="text"
                    id="Last_Name"
                    name="Last_Name"
                    className="form-control"
                    onChange={handleChange}
                    value={Last_Name}
                  />
                </div>
              </div>
              <div className="col-md-4">
                      <label 
                        htmlFor="Image" 
                        className="ml-5 mt-5" 
                        style={{ 
                          cursor: 'pointer', 
                          padding: '20% 35%', 
                          backgroundImage: "url('static/assets/images/upload.png')", 
                          backgroundRepeat: 'no-repeat', 
                          backgroundSize: 'contain' 
                        }}
                      ></label>
                      <br />
                      <span className="ml-5">Upload Image</span>
                      <input 
                        type="file" 
                        name="Image" 
                        id="Image" 
                        accept="image/*" 
                        style={{ display: 'none' }}
                        onChange={handleImageChange}

                      />
                    </div>
                  </div>                  
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Alias (optional)</label>
                        <input placeholder="Alias" type="text"
                        id="Alias"
                        name="Alias"
                        className="form-control"
                        onChange={handleChange}
                        value={Alias}
                      />
                      </div>
                      <div className="form-group">
                        <label>Date of Joining</label>
                        <input placeholder="Joining Date" required  type="date"
                        id="Joining_Date"
                        name="Joining_Date"
                        className="form-control"
                        onChange={handleChange}
                        value={joiningDate}
                      />
                      </div>
                    </div>
                    <div className="col">
                        
                      <div className="form-group">
                        <label>Salary Date</label>
                        <select type="date"
                        id="Salary_Date"
                        name="Salary_Date"
                        className="form-control"
                        onChange={handleChange}
                        value={Salary_Date}>
                          <option value="">--select--</option>
                          <option value="1-10">1-10</option>
                          <option value="11-15">11-15</option>
                          <option value="16-31">16-31</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Define salary details</label>
                        <select
                        id="Salary_Type"
                        name="Salary_Type"
                        className="form-control"
                        onChange={handleSalaryTypeChange}
                        value={salaryType}
                        
                        >
                          <option value="">--select--</option>
                          <option value="Fixed">Fixed</option>
                          <option value="Temporary">Temporary</option>
                          <option value="Time Based">Time Based</option>
                        </select>

                        {(salaryType === 'Fixed' || salaryType === 'Temporary' || salaryType === 'Time Based') && (
                          <div className="form-group" id="salary_amount">
                            <label>Salary Amount</label>
                            <input
                              placeholder="Salary Amount"
                              name="Salary_Amount"
                              id="salary_amount2"
                              type="text"
                              className="form-control"
                              value={salaryAmount}
                              onChange={handleSalaryAmountChange}
                              readOnly={salaryType === 'Time Based'}
                            />
                            
                          </div>
                        )}

                        {salaryType === 'Time Based' && (
                          <div id="salary_timebase">
                            <div className="form-group">
                              <label>Amount Per Hour</label>
                              <input
                                placeholder="Amount Per Hour"
                                name="perhour"
                              
                                id="amount_perhour"
                                type="number"
                                className="form-control"
                                value={amountPerHour}
                              onChange={handleAmountPerHourChange}
                              /> 
                            </div>
                            <div className="form-group">
                              <label>Total Working Hour(s)</label>
                              <input
                                placeholder="Total Working Hour(s)"
                                name="workhour"
                                id="working_hours"
                                type="number"
                                className="form-control"
                                value={workingHours}
                              onChange={handleWorkingHoursChange}
                              /> 
                              
                            </div>
                          </div>
                        )}
                      </div>
  
                     
                     
                         
                    </div>
                  </div>
                  <br />
                  <center>
                    <p><b>General Information</b></p>
                  </center>
                  
                  <div className="row">
                    <div className="form-group col">
                      <label>Employee Number</label>
                      <input placeholder="Employee Number" required  
                        type="text"
                        id="Employee_Number"
                        name="Employee_Number"
                        className="form-control"
                        onChange={handleChange}
                        value={Employee_Number}
                      />
                    </div>
                    <div className="form-group col">
                      <label>Designation</label>
                      <input placeholder="Designation"
                      required
                       type="text"
                        id="Designation"
                        name="Designation"
                        className="form-control"
                        onChange={handleChange}
                        value={Designation}/>
                        
                    </div>
                    <div className="form-group col">
                      <label>Location</label>
                      <input placeholder="Current Location" name="Location" type="text" className="form-control" onChange={handleChange}
                        value={CurrentLocation}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <label>Gender</label>
                      <select required
                        id="Gender"
                        name="Gender"
                        className="form-control"
                        onChange={handleChange}
                        value={Gender}
                      >
                        <option value="">--select--</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    
                    
                    <div className="form-group col">
                      <label>
                        Date Of Birth                        </label>

                        <label style={{ float: 'right', marginRight: '50px' }}>Age</label>
                      <div className="row">
                        <div className="col-9">
                          <input
                            placeholder="Date Of Birth"
                            type="date"
                            id="DOB"
                            name="DOB"
                            className="form-control"
                            onChange={handleDOBChange}
                            value={DOB}
                            required
                          />
                        </div>
                        <div className="col-3">
                          <input id="age" disabled className="form-control" type="text" readOnly value={age} />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col">
                      <label>Blood Group</label>
                      
                      <div  style={{ display: 'flex' }}>
                          <select  id="Blood"
                            name="Blood"
                            style={{ width: '80%' }}
                            required
                            className="form-control col-11"
                            onChange={handleChange}
                            value={Blood}
                          >
                            <option value="">--select--</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                            {bloodGroups.map((bloodGroup) => (
                              <option key={bloodGroup.id} value={bloodGroup.blood_group}>{bloodGroup.blood_group}</option>
                            ))}

                       
                            </select>


                              <button
                                type="button"
                                className="btn btn-outline-secondary text-grey mt-0 mb-2 ml-1"
                                data-toggle="modal"
                                data-target="#createNewUnit"
                                style={{
                                  width: "fit-content",
                                  height: "fit-content",
                                }}
                              >
                                +
                              </button>

                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="form-group col">
                      <label>Contact Number</label>
                      <input placeholder="Contact Number" required name="Contact_Number" type="text"   pattern="^\d{10}$"
 className="form-control" onChange={handleChange}
                        value={contact} />
                    </div>
                    <div className="form-group col">
                      <label>Emergency Contact Number</label>
                      <input placeholder="Emergency Contact Number" required name="Emergency_Contact_Number"   pattern="^\d{10}$"
 type="text" className="form-control" onChange={handleChange}
                        value={Number2} />
                    </div>
                    <div className="form-group col">
                      <label> Email</label>
                      <input placeholder=" Email"  required name="Personal_Email"   pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
 type="email" className="form-control" onChange={handleChange}
                        value={Email} />
                    </div>
                   
                    
                  </div>
                  <div className="row">
                   
                   
                    <div className="form-group col">
                      <label>Father's Name / Mother's Name</label>
                      <input placeholder="Father's Name / Mother's Name" type="text"
                        id="Parent"
                        name="Parent"
                        className="form-control"
                        onChange={handleChange}
                        value={parent} />
                    </div>
                    <div className="form-group col">
                      <label>Spouse's Name</label>
                      <input placeholder="Spouse's Name" name="Spouse" type="text" className="form-control" onChange={handleChange}
                        value={Spouse} />
                    </div>
                    <div className="form-group col">
                      <label>File</label>
                      <input  type="file" class="form-control" name="file" onChange={handleFileChange} />            
                      </div>
                  </div>
                  <br />
                  <br />

    
<div className="row">
    <div className="col">
      <div className="form-group col" id="perAddress">
        <br />
        <label style={{ fontSize: 'large' }}>Permanent Address</label>
        <br /><br />
        <div className="row">
          <div className="col">
            <label>Street</label>
            <input
              placeholder="street"
              type="text"
              name="address"
              className="form-control"
              id="perStreet"
              value={presentAddress.address}
              onChange={handlePresentAddressChange}
             
            />
          </div>
          <div className="col">
            <label>City</label>
            <input
              placeholder="city"
              type="text"
              name="city"
              className="form-control"
              id="perCity"
              value={presentAddress.city}
            onChange={handlePresentAddressChange}
           
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>State</label>
            <input
              placeholder="state"
              type="text"
              name="state"
              className="form-control"
              id="perState"
              value={presentAddress.state}
            onChange={handlePresentAddressChange}
             
            />
          </div>
          <div className="col">
            <label>Pincode</label>
            <input
              placeholder="pincode"
              type="text"
              name="pincode"
              className="form-control"
              id="perPincode"
              value={presentAddress.pincode}
            onChange={handlePresentAddressChange}
             
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>Country</label>
            <input
              placeholder="country"
              type="text"
              name="country"
              className="form-control"
              id="perCountry"
              value={presentAddress.country}
              onChange={handlePresentAddressChange}
              
            
            />
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <div className="form-group col" id="temAddress">
        <br />
        <label style={{ fontSize: 'large' }}>Temporary Address</label>
        <label style={{ float: 'right' }}>
          <input
             type="checkbox"
             id="sameAddress"
             checked={sameAddress}
             onChange={handleSameAddressChange}
            
          />
          Same as permanent address
        </label>
        <br /><br />
        <div className="row">
          <div className="col">
            <label>Street</label>
            <input
              placeholder="street"
              type="text"
              name="address"
              className="form-control"
              id="temStreet"
              value={permanentAddress.address}
            onChange={handlePermanentAddressChange}
            disabled={sameAddress}
            
            />
          </div>
          <div className="col">
            <label>City</label>
            <input
              placeholder="city"
              type="text"
              name="city"
              className="form-control"
              id="temCity"
              value={permanentAddress.city}
            onChange={handlePermanentAddressChange}
            disabled={sameAddress}
             
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>State</label>
            <input
              placeholder="state"
              type="text"
              name="state"
              className="form-control"
              id="temState"
              value={permanentAddress.state}
            onChange={handlePermanentAddressChange}
            disabled={sameAddress}
          
            />
          </div>
          <div className="col">
            <label>Pincode</label>
            <input
              placeholder="pincode"
              type="text"
              name="pincode"
              className="form-control"
              id="temPincode"
              value={permanentAddress.pincode}
            onChange={handlePermanentAddressChange}
            disabled={sameAddress}
              
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label>Country</label>
            <input
              placeholder="country"
              type="text"
              name="country"
              className="form-control"
              id="temCountry"
              value={permanentAddress.country}
            onChange={handlePermanentAddressChange}
            disabled={sameAddress}
             
            />
          </div>
        </div>
      </div>
    </div>
  </div>
                  <div className="row">
                    <div className="form-group col">
                    <label>Provide bank Details</label>
                    <select className="form-control" name="Bank_Details" value={bankDetails} onChange={(e) => setBankDetails(e.target.value)}>
                        <option value="">--select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                    </div>

                    <div className="form-group col">
                    <label>TDS Applicable</label>
                    <select className="form-control" name="tds_applicable" value={tdsApplicable} onChange={(e) => setTdsApplicable(e.target.value)}>
                        <option value="">--select--</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                    </div>
                </div>
                {isBankInfoVisible && (
        <div className="row">
          <div className="form-group col" id="BankInfo">
            <center>
              <p><b>Banking Information</b></p>
            </center>
            <div>
              <label>Account Number</label>
              <input placeholder="Account Number" name="Account_Number" type="text" className="form-control" onChange={handleChange}
                        value={Account_Number} />
            </div>
            <div>
              <label>IFSC</label>
              <input placeholder="SBIN0071242" pattern="^[A-Za-z]{4}0[A-Za-z0-9]{6}$" name="IFSC" type="text" className="form-control" onChange={handleChange}
                        value={IFSC} />
            </div>
            <div>
              <label>Name of Bank</label>
              <input placeholder="Name Of Bank" name="BankName" type="text" className="form-control"onChange={handleChange}
                        value={BankName} />
            </div>
            <div>
              <label>Branch Name</label>
              <input placeholder="Branch Name" name="BranchName" type="text" className="form-control" onChange={handleChange}
                        value={branch_name} />
            </div>
            <center>
              <p><b>For Banking</b></p>
            </center>
            <div>
              <label>Transaction Type</label>
              <select className="form-control" name="Transaction_Type" value={transactionType} onChange={(e) => setTransactionType(e.target.value)}>
                <option value="">--select--</option>
                <option value="ATM">ATM</option>
                <option value="Cash">Cash</option>
                <option value="Cheque">Cheque</option>
              </select>
            </div>
          </div>
        </div>
      )}

      {isTdsInfoVisible && (
        <div className="row">
          <div className="form-group col" id="TDSinfo">
            <center>
              <p><b>TDS Application</b></p>
            </center>
            <div>
              <label>Percentage/Amount</label>
              <select className="form-control" id="TDStype" name="TDS_Type" value={tdsType} onChange={(e) => setTdsType(e.target.value)}>
                <option value="">--select--</option>
                <option value="Percentage">Percentage</option>
                <option value="Amount">Amount</option>
              </select>
            </div>

            {isTdsPercentageVisible && (
              <div id="TDSpercentage">
                <label>Enter TDS Percentage</label>
                <input placeholder="TDS Percentage" name="TDS_Percentage" type="text" className="form-control"  onChange={handleChange}
                        value={TDS_Percentage} />
              </div>
            )}

            {isTdsAmountVisible && (
              <div id="TDSamount">
                <label>Enter TDS Amount</label>
                <input placeholder="TDS Amount" name="TDS_Amount" type="text" className="form-control"  onChange={handleChange}
                        value={TDS_Amount} />
              </div>
            )}
          </div>
        </div>
      )}

 

                  <br />
                  <center>
        <p><b>Statutory Information</b></p>
      </center>
      <div className="row">
        <div className="col">
          <div>
            <label>Income Tax Number</label>
            <input placeholder="Income Tax Number" name="Income_Tax" type="text" className="form-control" onChange={handleChange}
                        value={Income_Tax} />
          </div>
          <div>
            <label>Aadhar Number</label>
            <input name="Aadhar" placeholder="12 Digit Unique Number" pattern="\d{4} \d{4} \d{4}"  maxLength="14"  type="text" className="form-control" onChange={handleChange}
                        value={Aadhar} />
          </div>
          <div>
            <label>Universal Account Number (UAN)</label>
            <input placeholder="12 digit number" pattern="\d{12}"  maxLength="12" name="UAN" type="text" className="form-control" onChange={handleChange}
                        value={UAN} />
          </div>
        </div>
        <div className="col">
          <div>
            <label>PF Account Number</label>
            <input placeholder="MH/PUN/1234567/12" pattern="[A-Z]{2}/[A-Z0-9]{3}/[0-9]{7}/[0-9]{0,2}" name="PF"  type="text" className="form-control" onChange={handleChange}
                        value={PF} />
          </div>
          <div>
            <label>PAN Number</label>
            <input placeholder="ABCDE1234F" name="PAN"  pattern="[A-Z]{5}[0-9]{4}[A-Z]" maxlength="10" type="text" className="form-control"onChange={handleChange}
                        value={PAN} />
          </div>
          <div>
            <label>PR Account Number</label>
            <input placeholder="12 digit number"  pattern="\d{12}"  name="PR" type="text" className="form-control" onChange={handleChange}
                        value={PR} />
          </div>
       
        </div>
      </div>
      <center>

      <div className="row mt-5 mb-5">
        <div className="col-md-4"></div>
        <div className="col-md-4 d-flex justify-content-center">
        <button
                        className="btn btn-outline-secondary "
                        type="submit"
                        style={{ width: "50%", height: "fit-content" }}
                      >
                        SAVE
                      </button>
          
        </div>
        <div className="col-md-4"></div>
      </div>
                  </center>
                </div>
              </div>
            </form>
          </div>
        </div>
    
      <div className="modal fade" id="createNewUnit">
  <div className="modal-dialog">
    <div className="modal-content" >
      <div className="modal-header">
        <h5 className="m-3">New Blood Group</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body w-100">
        <div className="card p-3">
          <form
            onSubmit={handleUnitModalSubmit}
            id="newUnitForm"
            className="px-1"
          >
            <div className="row mt-2 w-100">
              <div className="col-12">
                <label for="name">Blood Group</label>
                <input
                  name="name"
                  id="unit_name"
                  value={newUnit}
                  onChange={(e) => setNewUnit(e.target.value)}
                  className="form-control text-uppercase w-100"
                />
              </div>
            </div>
            <div className="row mt-4 w-100">
              <div className="col-12 d-flex justify-content-center">
                <button
                  className="btn btn-outline-info text-grey"
                  data-dismiss="modal"
                  type="submit"
                  onClick={handleUnitModalSubmit}
                  id="saveItemUnit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>











              </div>
              </div>
              
       
    
    



        </div>
       </div>
              
        </div>
        </div>
    
    




    
    

    {/* <!-- Payment Term Create Modal --> */}
    <div className="modal fade" id="createNewTerm">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="m-3">New Payment Term</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form
                 // onSubmit={handleUnitModalSubmit}
                  id="newUnitForm"
                  className="px-1"
                >
                  <div className="row mt-2 w-100">
                    <div className="col-12">
                      <label for="name">Duration</label>
                      <input
                        name="name"
                        id="unit_name"
                        value={modalduration}
                        onChange={(e) => setModalDuration(e.target.value)}
                        className="form-control text-uppercase w-100"
                      />
                    </div>
                  </div>
                  <div className="row mt-2 w-100">
                    <div className="col-12">
                      <label for="name">Term</label>
                      <select
                          className="custom-select"
                          name="unit"
                          id="itemUnit"
                          value={modalterm}
                          onChange={(e) => setModalTerm(e.target.value)}
                          required
                         // style={{  color: "white" }}
                        >
                          <option selected disabled value="">
                            Choose...
                          </option>
                          <option value="MONTH">MONTHS</option>
                          <option value="YEAR">YEAR</option>
                         
                        </select>
                    </div>
                  </div>

                  <div className="row mt-4 w-100">
                    <div className="col-12 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-info text-grey"
                        data-dismiss="modal"
                        type="submit"
                        onClick={handleTermModalSubmit}
                        id="saveItemUnit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    
   
    
    


</>
  );
}


 

  


export default Loanedit;