

import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../../functions/config";

function Rec_bill() {
  const navigate = useNavigate();
  function exportToExcel() {
    const Table = document.getElementById("recBillTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Recurring_Bills.xlsx");
  }

  function sortTable(columnIndex) {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("recBillTable");
    switching = true;

    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();
        y = rows[i + 1]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();

        if (x > y) {
          shouldSwitch = true;
          break;
        }
      }

      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }

  function filterTable(row,filterValue) {
    var table1 = document.getElementById("recBillTable");
    var rows1 = table1.getElementsByTagName("tr");

    for (var i = 1; i < rows1.length; i++) {
      var statusCell = rows1[i].getElementsByTagName("td")[row];

      if (filterValue == "all" || statusCell.textContent.toLowerCase() == filterValue) {
        rows1[i].style.display = "";
      } else {
        rows1[i].style.display = "none";
      }
    }
  }

  function searchTable(){
    var rows = document.querySelectorAll('#recBillTable tbody tr');
    var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }

  const ID = Cookies.get('user_id');
  const [recBills, setRecBills] = useState([]);

  const fetchBills = () =>{
    axios.get(`${config.base_url}/Rec_fetch_rec_bills/${ID}/`).then((res)=>{
      if(res.data.status){
        var bill = res.data.recBill;
        setRecBills([])
        bill.map((i)=>{
          setRecBills((prevState)=>[
            ...prevState, i
          ])
        })
      }
    }).catch((err)=>{
      console.log('ERR',err)
    })
  }

  useEffect(()=>{
    fetchBills();
  },[])
  
  function refreshAll(){
    setRecBills([])
    fetchBills();
  }
  return (
    <>
      <div className="container-scroller">
  <Navbar />
  <div className="container-fluid page-body-wrapper d-flex">
    <Sidebar />
    <div
      className="main-panel px-3 py-2"
      style={{ background: "#a9a9a961", maxWidth: "100vw" }}
    >
      <div className="content-wrapper">
        <div className="body-wrapper p-3" style={{ minHeight:"100vh" }}>
          <div className="container-fluid">
            <div className="card radius-15 h-20 mt-3">
              <div className="row w-100">
                <div className="col-md-12">
                  <center>
                    <h2 className="mt-3">RECURRING BILLS</h2>
                  </center>
                  <hr />
                </div>
              </div>
            </div>
            <br />
            <div className="card radius-15">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          id="search"
                          className="form-control"
                          placeholder="Search.."
                          autoComplete="off"
                          onKeyUp={searchTable}
                        />
                        <div className="dropdown ml-1" style={{ justifyContent: "left" }}>
                          <button
                            type="button"
                            style={{ width: "fit-content", height: "fit-content" }}
                            className="btn btn-outline-secondary dropdown-toggle text-grey"
                            data-toggle="dropdown"
                          >
                            <i className="fa fa-sort"></i> Sort by
                          </button>
                          <div className="dropdown-menu">
                            <a
                              className="dropdown-item"
                              onClick={refreshAll}
                              style={{
                                height: "40px",
                                fontSize: "15px",
                                color: "black",
                              }}
                            >
                              All
                            </a>
                            <a
                              className="dropdown-item"
                              style={{
                                height: "40px",
                                fontSize: "15px",
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(3)}
                            >
                              Vendor Name
                            </a>
                            <a
                              className="dropdown-item"
                              style={{
                                height: "40px",
                                fontSize: "15px",
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(2)}
                            >
                              Rec. Bill No.
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-6 d-flex justify-content-end">
                      <button
                        type="button"
                        style={{ width: "fit-content", height: "fit-content" }}
                        className="btn btn-outline-secondary text-grey"
                        id="exportBtn"
                        onClick={exportToExcel}
                      >
                        <i className="fa fa-table"></i> Export To Excel
                      </button>
                      <div className="dropdown ml-1">
                        <button
                          type="button"
                          style={{ width: "fit-content", height: "fit-content" }}
                          className="btn btn-outline-secondary dropdown-toggle text-grey"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-filter"></i> Filter by
                        </button>
                        <div className="dropdown-menu">
                          <a
                            className="dropdown-item"
                            style={{
                              height: "40px",
                              fontSize: "15px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => filterTable(6, "all")}
                          >
                            All
                          </a>
                          <a
                            className="dropdown-item"
                            style={{
                              height: "40px",
                              fontSize: "15px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => filterTable(6, "saved")}
                          >
                            Saved
                          </a>
                          <a
                            className="dropdown-item"
                            style={{
                              height: "40px",
                              fontSize: "15px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => filterTable(6, "draft")}
                          >
                            Draft
                          </a>
                        </div>
                      </div>
                      <Link to="/Add_rec_bill" className="ml-1">
                        <button
                          type="button"
                          style={{ width: "fit-content", height: "fit-content" }}
                          className="btn btn-outline-secondary text-grey"
                        >
                          <i className="fa fa-plus font-weight-light"></i> Rec. Bill
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="table-responsive mt-4">
                  <table
                    className="table table-hover"
                    id="recBillTable"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>DATE</th>
                        <th>REC. BILL NO.</th>
                        <th>VENDOR NAME</th>
                        <th>VENDOR MAIL</th>
                        <th>AMOUNT</th>
                        <th>STATUS</th>
                        <th>BALANCE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recBills && recBills.map((i, index) => (
                        <tr
                          className="clickable-row"
                          onClick={() => navigate(`/View_rec_bill/${i.id}/`)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{index + 1}</td>
                          <td>{i.rec_bill_date}</td>
                          <td>{i.rec_bill_no}</td>
                          <td>{i.vendor_name}</td>
                          <td>{i.vendor_email}</td>
                          <td>{i.grandtotal}</td>
                          <td>{i.status}</td>
                          <td>{i.balance}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
}

export default Rec_bill;
