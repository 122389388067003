import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import Cookies from "js-cookie";
import html2pdf from "html2pdf.js";
import axios from "axios";
import * as XLSX from "xlsx";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../styles/Payment_Report.css";


function All_parties_report() {
  const ID = Cookies.get("user_id");

  const [reportData, setReportData] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [parties, setParties] = useState("all");

  const fetchpartyReportDetails = () => {
    axios
      .get(`${config.base_url}/fetch_all_parties_report/${ID}/`)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setOtherDetails(res.data.otherDetails);
          setStartDate("");
          setEndDate("");
          setParties("all");
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchpartyReportDetails();
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  const handleCustomize = (e) => {
    e.preventDefault();
    
    var det = {
      Id: ID,
      start: startDate,
      end: endDate,
      party : parties
    };

    axios
      .post(`${config.base_url}/fetch_all_parties_report_customized/`, det)
      .then((res) => {
       
        if (res.data.status) {
          setReportData(res.data.reportData);
          setOtherDetails(res.data.otherDetails);
          if (res.data.startDate){
            setStartDate(res.data.startDate);
          }
          if(res.data.endDate){
            setEndDate(res.data.endDate);

          }

          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "All_Parties_Reports" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "All_Parties_Details" + date + ".xlsx");
  }

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");


  async function handleShareEmail(e) {
    e.preventDefault();

    if ((startDate && !endDate) || (!startDate && endDate)) {
      alert("Please select both date inputs or select none");
      return;
    }

    const emails = emailIds.trim().split(",").map(email => email.trim());
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const invalidEmails = emails.filter(email => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
      return;
    }

    const element = document.getElementById("printReport");
    const opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "All_Parties_Reports.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };

    const pdfBlob = await html2pdf().set(opt).from(element).outputPdf('blob');

    const formData = new FormData();
    formData.append('Id', ID);
    formData.append("pdf", pdfBlob, "All_Parties_Reports.pdf");
    formData.append("email_ids", emailIds);
    formData.append("email_message", emailMessage);
    console.log("Emails: ", emailIds);
    console.log("Email message: ", emailMessage);
    console.log("PDF Blob: ", pdfBlob);

    axios
      .post(`${config.base_url}/share_all_parties_report_email/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Shared via mail.",
          });
          setEmailIds("");
          setEmailMessage("");
        }
      })
      .catch((err) => {
        console.error("Error sharing report via email:", err);
        if (err.response && err.response.data && !err.response.data.status) {
          Toast.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Failed to send email. Please try again.",
          });
        }
      });
      
  }


  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
    }
  }

  return (
    <>
     <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper d-flex">
                    <Sidebar />
                    <div
                         className="main-panel px-3 py-2"
                       style={{ background: "#a9a9a961", width:"100%" }}
                          >
                        <div className="content-wrapper">
                            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
                                <div className="container-fluid">
                                    <div className="card radius-15 h-20 mt-3">
                                        <div className="row w-100">
                                            <div className="col-md-12">
                                                <center>
                                                    <h2 className="mt-3"> ALL PARTIES REPORT</h2>
                                                </center>
                                                <hr className="w-100 ml-3"  />
                                                
                                            </div>
                                        
           
 <div
    className="bar p-3"
    style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap', 
    }}
>
    <div
        className="left"
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginLeft: '30px',
            flexWrap: 'wrap',
        }}
    >
        <div className="position-relative">
            <button
                className="btn btn-secondary"
                onClick={toggleContent}
                style={{ height: 'fit-content', width: 'fit-content' }}
            >
                <i className="fa-solid fa fa-gear"></i> Customize Report
            </button>
            <div id="contentDiv" className="salescontent">
                <h6>Customize Report</h6>
                <form
                    onSubmit={handleCustomize}
                    className="form reportCustomizeForm px-1"
                    method="get"
                >
                    <div className="px-2 w-100">
                        <label style={{ textAlign: 'left' }}>From</label><br />
                        <input
                            className="inputdate form-control"
                            type="date"
                            name="start_date"
                            id="startDate"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            required={endDate !== ""}
                        />
                    </div>
                    <div className="px-2 w-100">
                        <label style={{ textAlign: 'left' }}>To</label><br />
                        <input
                            type="date"
                            className="inputdate form-control"
                            name="end_date"
                            id="endDate"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            required={startDate !== ""}
                        />
                    </div>
                    <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>Party</label>
                        <br />
                        <select
                          name="parties"
                          id="Parties"
                          value={parties}
                          onChange={(e) => setParties(e.target.value)}
                          className="form-control"
                        >
                          <option value="all">All Party</option>
                          <option
                            value="payable"
                            style={{ textTransform: "capitalize" }}
                          >
                            Accounts Payable
                          </option>
                          <option
                            value="receivable"
                            style={{ textTransform: "capitalize" }}
                          >
                            Accounts Receivable
                          </option>
                        </select>
                      </div>
                    <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                            type="submit"
                            className="btn btn-outline-dark w-50"
                            style={{ width: 'fit-content', height: 'fit-content' }}
                        >
                            Run Report
                        </button>
                        <button
                            type="reset"
                            onClick={toggleContent}
                            className="btn btn-outline-dark ml-1 w-50"
                            style={{ width: 'fit-content', height: 'fit-content' }}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div
        className="d-flex justify-content-end"
        style={{
            display: 'flex',
            flexWrap: 'wrap', 
            gap: '10px', 
            marginTop: '10px', 
        }}
    >
        <a
            className="btn btn-outline-secondary text-grey fa fa-file"
            role="button"
            id="pdfBtn"
            onClick={reportPDF}
            style={{ height: 'fit-content', width: 'fit-content' }}
        >
            &nbsp;PDF
        </a>
        <a
            className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
            role="button"
            id="printBtn"
            onClick={printSection}
            style={{ height: 'fit-content', width: 'fit-content' }}
        >
            &nbsp;Print
        </a>
        <a
            className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
            role="button"
            id="exportBtn"
            onClick={ExportToExcel}
            style={{ height: 'fit-content', width: 'fit-content' }}
        >
            &nbsp;Export
        </a>
        <div
            className="dropdown p-0 nav-item"
            id="shareBtn"
            style={{
                height: 'fit-content',
                width: 'fit-content',
            }}
        >
            <li
                className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                data-toggle="dropdown"
                style={{ height: 'fit-content', width: 'fit-content' }}
            >
                &nbsp;Share
            </li>
            <ul className="dropdown-menu"  id="listdiv">
                <a href={shareUrl} target="_blank" rel="noopener noreferrer">
                    <li style={{ textAlign: 'center' }}>Whatsapp</li>
                </a>
                <li
                    style={{
                        textAlign: 'center',
                        color: '#e5e9ec',
                        cursor: 'pointer',
                    }}
                    id="toggleEmailModal"
                    data-toggle="modal"
                    data-target="#shareToEmail"
                >
                    Email
                </li>
            </ul>
        </div>
    </div>
</div>
</div>
</div>

        <div className="card radius-15 print-only mt-3" id="pdf-card">
          <div className="card-body">
            <div className="container-fluid">
              <div
                id="printReport"
                className="printReportTemplate"
                style={{ display: "block " }}
              >
                <div className="my-5 page" size="A4">
                  <div id="printdiv2">
                    <div className="py-4 bg-secondary">
                      <div className="col-12">
                        <center className="h5 text-white">
                          <b>{otherDetails.cmpName}</b>
                        </center>
                        <center className="h3 text-white">
                          <b> ALL PARTIES REPORT </b>
                        </center>
                        {startDate != "" && endDate != ""
                          ? (() => {
                              const formatDate = (dateStr) => {
                                const date = new Date(dateStr);
                                const day = String(date.getDate()).padStart(
                                  2,
                                  "0"
                                );
                                const monthNames = [
                                  "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec",
                                ];
                                const month = monthNames[date.getMonth()];
                                const year = date.getFullYear();
                                return `${day}-${month}-${year}`;
                              };

                              const formattedStartDate = formatDate(startDate);
                              const formattedEndDate = formatDate(endDate);

                              return (
                                <center className="h6 text-white">
                                  {formattedStartDate} {" TO "}{" "}
                                  {formattedEndDate}
                                </center>
                              );
                            })()
                          : null}
                      </div>
                    </div>
                    <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                          <table
                            className="table table-responsive-md mt-4 table-hover"
                            id="reportTable"
                          >
                            <thead>
                              <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">PARTY NAME</th>
                                <th className="text-center">EMAIL</th>
                                <th className="text-center">PHONE NUMBER</th>
                                <th className="text-center">RECEIVABLE BALANCE</th>
                                <th className="text-center">PAYABLE BALANCE</th>
                                <th className="text-center">CREDIT LIMIT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportData.length > 0 &&
                                reportData.map((i, index) => {
                                  
                                  return (
                                    <tr key={i.billno}>
                                      <td className="text-center text-bold">
                                        {index+1}
                                      </td>
                                      <td className="text-center">
                                        {i.name}
                                      </td>
                                      <td className="text-center">
                                        {i.email}
                                      </td>
                                      <td className="text-center">
                                        +91 {i.mobile}
                                      </td>
                                      <td className="text-center">

                                        ₹ {i.rec !== undefined && i.rec !== null ? i.rec.toFixed(2) : '0.00'}
                                      </td>

                                      <td className="text-center">
                                        ₹ {i.pay !== undefined && i.pay !== null ? i.pay.toFixed(2) : '0.00'}
                                      </td>
                                      <td className="text-center">
                                      ₹ {i.credit !== undefined && i.credit !== null ? i.credit.toFixed(2) : '0.00'}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>

                          {reportData.length == 0 ? (
                            <center>
                              <h4 className="text-dark">No data available.!</h4>
                            </center>
                          ) : null}
                        </section>

                        <section className="balance-info text-dark pt-1 pb-1">
  <div className="row p-4">
    <div className="col-12 col-md-8"></div>
    
    {/* Total Receivables */}
    <div className="col-12 col-md-2 text-center mb-3 mb-md-0">
      <h5
        style={{
          color: "#000",
          fontSize: "15px",
          fontWeight: "700",
        }}
      >
        Total Receivables:
      </h5>
      <p
        className="text-dark"
        id="expense"
        style={{ fontWeight: "700", fontSize: "19px" }}
      >
        ₹ {" "}
        <span id="totalCreditnote">
          {otherDetails.totalReceivable !== undefined && otherDetails.totalReceivable !== null 
            ? otherDetails.totalReceivable.toFixed(2) 
            : '0.00'}
        </span>
      </p>
    </div>
    
    {/* Total Payable */}
    <div className="col-12 col-md-2 text-center">
      <h5
        style={{
          color: "#000",
          fontSize: "15px",
          fontWeight: "700",
        }}
      >
        Total Payable:
      </h5>
      <p
        className="text-dark"
        style={{ fontWeight: "600", fontSize: '19px' }}
        id="totvalue"
      >
        ₹ {" "}
        <span id="superTotal">
          {otherDetails.totalPayable !== undefined && otherDetails.totalPayable !== null 
            ? otherDetails.totalPayable.toFixed(2) 
            : '0.00'}
        </span>
      </p>
    </div>
  </div>
</section>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default All_parties_report;
