import React, {  useState, useEffect  } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar"; // Ensure this path is correct
import Sidebar from "./Sidebar"; // Ensure this path is correct
import config from "../../functions/config"; // Ensure this path is correct
import "./styles/Staff.css"; // Ensure this path is correct
import "./SalesOrderDetails.css"; // Assuming external CSS file
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Select from 'react-select';





function Addsalesorder() {
  const navigate = useNavigate();
  const [priceListId, setPriceListId] = useState(null);
  const [salesOrderNo, setSalesOrderNo] = useState('');
  const [subTotal, setSubTotal] = useState(0);
  const user = Cookies.get("role");
  const ID = Cookies.get("user_id");
  const [terms, setTerms] = useState([]);
  const [igst, setIgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [adjustment, setAdjustment] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [paid, setPaid] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [balance, setBalance] = useState(0);
  const [items, setItems] = useState([]);
  const [newTermName, setNewTermName] = useState('');
  const [newTermDays, setNewTermDays] = useState('');
 
    // Define the states
    const [companyState, setCompanyState] = useState(''); // Assuming you want to store the company's state here
    const [showIGST, setShowIGST] = useState(false); // To show/hide IGST field
    const [showCGSTSGST, setShowCGSTSGST] = useState(false); // To show/hide CGST/SGST f
  

  
 
  const [cmpState, setCmpState] = useState("");
  const [placeOfSupply, setPlaceOfSupply] = useState("");
  const [priceList, setPriceList] = useState([]);
  const [date, setDate] = useState(null);
  const [term, setTerm] = useState("");
  const [shipmentDate, setShipmentDate] = useState(null);
  const [newUnit, setNewUnit] = useState('');

    
  const refreshIndexes = () => {
    // Your logic here
  };
  




  // Component to display the sales order items table
const [salesOrderItems, setSalesOrderItems] = useState([
  {
    id: "1",
    item: "",
    itemId: "",
    hsnSac: "",
    quantity: 0,
    price: 0,
    taxGst: "",
    discount: 0,
    total: 0,
    taxAmount: 0,
  },
]);
  const [customerPriceLists, setCustomerPriceLists] = useState([]);
  const [priceLists, setPriceLists] = useState([]); // Initialize priceLists state

  const [formData, setFormData] = useState({
    sales_order_no: "",
    customer_name: "",
    customer_id:"",
    customer_email: "",
    billing_address: "",
    billing_street: "",
    billing_city: "",
    billing_state: "",
    billing_pincode: "",
    billing_country: "",
    gst_type: "",
    gstin: "",
    place_of_supply: "",
    reference_no: "",
    payment_terms: "",
    sales_order_date: "",
    exp_ship_date: "",
    price_list_applied: false,
    price_list: "",
    payment_method: "",
    cheque_no: "",
    upi_no: "",
    bank_acc_no: "",
    subtotal: "",
    igst: "",
    cgst: "",
    sgst: "",
    tax_amount: "",
    adjustment: "",
    shipping_charge: "",
    grandtotal: "",
    paid_off: "",
    balance: "",
    converted_to_invoice: "",
    converted_to_rec_invoice: "",
    note: "",
    file: "",
    
    status: "",
  });

  useEffect(() => {
    // Set today's date as the default value
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    setFormData((prevData) => ({
      ...prevData,
      sales_order_date: today, // Set today's date
    }));
  }, []); // Run once on component mount

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const fetchPriceList = () => {
    axios
      .get(`${config.base_url}/pricelist_dropdown/${ID}/`)  // Correct endpoint
      .then((res) => {
        if (res.data.status) {
          const priceLists = res.data.pricelist; // Match with API response
          setPriceLists(priceLists);  // Use state to store price lists
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  // Fetch price lists when ID changes
  useEffect(() => {
    if (ID) {
      fetchPriceList();
    }
  }, [ID]);
  

  const fetchPaymentTerms = () => {
    axios
      .get(`${config.base_url}/paymentterm_dropdown/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const paymentTerms = res.data.terms; // Corrected to match API response
          setTerms(paymentTerms); // Set terms directly from API response
        }
      })
      .catch((err) => {
        console.log(err);
      });
};

// Fetch payment terms when ID changes
useEffect(() => {
    if (ID) {
      fetchPaymentTerms();
    }
}, [ID]);


 

function handleTermModalSubmit(e) {
  e.preventDefault();

  const term = newTermName.trim();
  const days = parseInt(newTermDays, 10);

  if (term && !isNaN(days) && days >= 0) {
    const payload = {
      Id: ID,  // Ensure ID is correctly populated
      term_name: term,
      days: days,
    };

    console.log("Payload being sent:", payload); // Log the payload

    axios
      .post(`${config.base_url}/create_new_payment_term/`, payload)
      .then((res) => {
        console.log("New Term Response=", res);
        if (res.data.status) {
          Swal.fire({
            icon: "success",
            title: "Term Created",
            text: "The new payment term has been successfully created.",
            timer: 2000,
            showConfirmButton: false,
          });
          fetchPaymentTerms();  // Refresh payment terms list
          setNewTermName("");   // Reset form values
          setNewTermDays("");

          document.getElementById("termModalDismiss").click(); // Close modal
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (err.response && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: "Failed to create term",
            text: `${err.response.data.message || 'An error occurred while creating the term.'}`,
          });
        }
      });
  }
}


const setStatus = (statusValue) => {
  setFormData({ ...formData, status: statusValue });
};

const handleSaveDraft = () => {
  setStatus("Draft");  // Set status to "Draft"
  handleSubmit();  // Call submit without an event
};

const handleSubmit = (e) => {
  if (e) e.preventDefault(); // Prevent default form submission behavior
  
  // Create a new FormData instance
  const data = new FormData();

  // Combine billing address fields into one
  const billingAddress = `${formData.billing_street}, ${formData.billing_city}, ${formData.billing_state}, ${formData.billing_pincode}, ${formData.billing_country}`;

  // Append general form data (sales order details)
  Object.keys(formData).forEach((key) => {
    data.append(key, formData[key]);
  });

  // Append the combined billing address
  data.append("billing_address", billingAddress);
  
  // Append additional necessary fields
  data.append("Id", ID); 
  data.append("salesOrderItems", JSON.stringify(salesOrderItems));
  data.append("subtotal", subTotal);
  data.append("tax_amount", Number(taxAmount).toFixed(2));
  data.append("shipping_charge", shippingCharge);
  data.append("adjustment", adjustment);
  data.append("grandtotal", grandTotal);
  data.append("paid_off", paid);
  data.append("balance", balance);
  data.append("exp_ship_date", shipmentDate);
  data.append("customer_id", formData.customer_id);

  // Check if IGST or CGST/SGST should be applied
  if (formData.place_of_supply === companyState) {
    // Apply CGST and SGST, set IGST as an empty string
    data.append("cgst", (Number(taxAmount) / 2).toFixed(2));
    data.append("sgst", (Number(taxAmount) / 2).toFixed(2));
    data.append("igst", ""); // Use empty string instead of null
  } else {
    // Apply IGST, set CGST and SGST as empty strings
    data.append("igst", Number(taxAmount).toFixed(2));
    data.append("cgst", ""); // Use empty string instead of null
    data.append("sgst", ""); // Use empty string instead of null
  }

  // Include the price list ID if it's applied
  if (formData.applyPriceList) {
    data.append("price_list", formData.price_list);
    data.append("price_list_applied", true);
  } else {
    data.append("price_list_applied", false);
  }

  // Perform the POST request using axios
  axios
    .post(`${config.base_url}/sales_orders/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      Swal.fire({
        icon: "success",
        title: "Sales Order Added",
        text: "The sales order has been successfully added.",
      });
      navigate("/Salesorder");
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: err.response?.data?.message || "Error adding sales order",
      });
    });
};
const applyPriceList = (selectedValue) => {
  // Logic for applying the selected price list
  console.log("Applying price list with ID:", selectedValue);
  
  // You can add more logic here based on your requirements
};


  

  // Add new row function
const addNewRow = () => {
  setSalesOrderItems([
    ...salesOrderItems,
    {
      id: salesOrderItems.length + 1,
      item: '',
      itemId: "",
      hsnSac: '',
      quantity: 0,
      price: 0,
      taxGst: '',
      discount: 0,
      total: 0,
      taxAmount: 0,
    },
  ]);
};

// Remove row function
const removeRow = (id) => {
  setSalesOrderItems(salesOrderItems.filter((item) => item.id !== id));
};

function refreshValues() {
  checkPriceList(priceList);
  refreshTax2();
  calc();
}

function resetItem(id) {
  setSalesOrderItems((prevItems) =>
    prevItems.map((item) => (item.id === id ? { ...item, item: "" } : item))
  );
}

//calculations anoopcreate
  // Handle input change for sales order items
  const handleSalesOrderItemsInputChange = (rowId, field, value) => {
    setSalesOrderItems(prevItems => {
      const updatedItems = prevItems.map(item => {
        if (item.id === rowId) {
          const updatedItem = { ...item, [field]: value };
  
          // Recalculate total and taxAmount
          const quantity = parseFloat(updatedItem.quantity) || 0;
          const price = parseFloat(updatedItem.price) || 0;
          const discount = parseFloat(updatedItem.discount) || 0;
          const taxGst = parseFloat(updatedItem.taxGst) || 0;
  
          updatedItem.total = (price * quantity) - discount;
          updatedItem.taxAmount = (updatedItem.total * (taxGst / 100)).toFixed(2);
  
          return updatedItem;
        }
        return item;
      });
  
      // Calculate subtotal
      const newSubTotal = updatedItems.reduce((acc, item) => acc + (parseFloat(item.total) || 0), 0);
      setSubTotal(newSubTotal.toFixed(2));
  
      // Calculate tax amounts
      const totalTaxAmount = updatedItems.reduce((acc, item) => acc + (parseFloat(item.taxAmount) || 0), 0);
      setTaxAmount(totalTaxAmount.toFixed(2));
  
      // Update grand total
      const newGrandTotal = (parseFloat(newSubTotal) + parseFloat(totalTaxAmount) + parseFloat(shippingCharge || 0) + parseFloat(adjustment || 0)).toFixed(2);
      setGrandTotal(newGrandTotal);
  
      // Update balance
      const newBalance = (newGrandTotal - parseFloat(paid || 0)).toFixed(2);
      setBalance(newBalance);
  
      return updatedItems;
    });
  };
  
// Handle Shipping Charge
const handleShippingCharge = (value) => {
  setShippingCharge(value);
  const newGrandTotal = parseFloat(subTotal) + parseFloat(taxAmount) + parseFloat(value || 0) + parseFloat(adjustment || 0);
  setGrandTotal(newGrandTotal.toFixed(2));
  const newBalance = newGrandTotal - parseFloat(paid || 0);
  setBalance(newBalance.toFixed(2));
};

// Handle Adjustment
const handleAdjustment = (value) => {
  setAdjustment(value);
  const newGrandTotal = parseFloat(subTotal) + parseFloat(taxAmount) + parseFloat(shippingCharge || 0) + parseFloat(value || 0);
  setGrandTotal(newGrandTotal.toFixed(2));
  const newBalance = newGrandTotal - parseFloat(paid || 0);
  setBalance(newBalance.toFixed(2));
};

// Handle Paid amount
const handlePaid = (value) => {
  setPaid(value);
  const newBalance = parseFloat(grandTotal) - parseFloat(value || 0);
  setBalance(newBalance.toFixed(2));
};

  


  
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'purple' : 'gray',
      boxShadow: state.isFocused ? '0 0 0 1px purple' : 'none',
      '&:hover': {
        borderColor: 'purple',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'purple' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'lightgray',
      },
    }),
    // Add other styles as needed
  };
  
   // Handle change for the file input
   const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };
  
   

  // Function to calculate grand total
  const calculateGrandTotal = (shipping, adjustment) => {
    const total = subTotal + igst + cgst + sgst + taxAmount + shipping - adjustment;
    setGrandTotal(total);
    setBalance(total - paid);
  };

   // Function to show success notifications
   const notifySuccess = (message) => {
    toast.success(message);
  };

  // Function to show error notifications
  const notifyError = (message) => {
    toast.error(message);
  };



   // Fetch items from the backend

  /// Fetch items from the backend
  const fetchItems = async () => {
    if (!ID) return; // Ensure ID is available
    try {
      const response = await axios.get(`${config.base_url}/item_dropdown/${ID}/`);
      const fetchedItems = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
        hsnSac: item.hsn || item.sac,
        price: item.selling_price,
        inter_state_tax: item.inter_state_tax, // Tax field included
      }));
      setItems(fetchedItems);
    } catch (error) {
      console.error("Error fetching items:", error);
      Swal.fire({
        icon: "error",
        title: "Error fetching items",
        text: error.response?.data?.message || "Something went wrong while fetching items.",
      });
    }
  };
  
  useEffect(() => {
    fetchItems();
  }, [ID]);



  // Handle item selection change
const handleItemChange = (selectedItem, rowId) => {
  const updatedItems = salesOrderItems.map((item) => {
    if (item.id === rowId) {
      const price = selectedItem ? selectedItem.price : 0;
      const quantity = item.quantity;
      const total = price * quantity;

      return {
        ...item,
        item: selectedItem ? selectedItem.label : "",
        itemId: selectedItem ? selectedItem.value : "", // Capture correct itemId
        hsnSac: selectedItem ? selectedItem.hsnSac : "",
        price: price,
        taxGst: selectedItem ? selectedItem.inter_state_tax : "",
        total: total, // Update total based on price and quantity
      };
    }
    return item;
  });
  setSalesOrderItems(updatedItems);
};

  
  


  

  

const fetchCustomers = async () => {
  try {
    const response = await axios.get(`${config.base_url}/customer_dropdown/${ID}/`);
    if (response.data.status) {
      setCustomers(response.data.data);
      setCompanyState(response.data.company_state); // Store the company state
    }
  } catch (error) {
    console.error('Error fetching customers:', error);
  }
};

// Call fetchCustomers on component mount
useEffect(() => {
  fetchCustomers();
}, [ID]);

// Function to handle tax display logic
const handleTaxDisplay = () => {
  if (formData.place_of_supply === companyState) {
    // Show CGST and SGST, hide IGST
    setShowIGST(false);
    setShowCGSTSGST(true);
  } else {
    // Show IGST, hide CGST and SGST
    setShowIGST(true);
    setShowCGSTSGST(false);
  }
};

// Add useEffect to trigger tax display logic when place_of_supply or companyState changes
useEffect(() => {
  handleTaxDisplay();
}, [formData.place_of_supply, companyState]);


  const handleCustomerSelect = (event) => {
    const selectedCustomerName = event.target.value;
    const selectedCustomer = customers.find(customer => customer.name === selectedCustomerName);

    if (selectedCustomer) {
        setFormData({
            ...formData,
            customer_name: selectedCustomer.name,
            customer_email: selectedCustomer.email,
            billing_street: selectedCustomer.billing_street || '',
            billing_city: selectedCustomer.billing_city || '',
            billing_state: selectedCustomer.billing_state || '',
            billing_pincode: selectedCustomer.billing_pincode || '',
            billing_country: selectedCustomer.billing_country || '',
            place_of_supply: selectedCustomer.place_of_supply || '',
            gst_type: selectedCustomer.gst_type || '',
            gstin: selectedCustomer.gstin || '',
            customer_id: selectedCustomer.id // Add this line
        });
    } else {
        setFormData({
            ...formData,
            customer_name: '',
            customer_email: '',
            billing_street: '',
            billing_city: '',
            billing_state: '',
            billing_pincode: '',
            billing_country: '',
            place_of_supply: '',
            gst_type: '',
            gstin: '',
            customer_id: '' // Reset the customer_id as well
        });
    }
};




  
  // NEW CUSTOMER

  const [newPaymentTerm, setNewPaymentTerm] = useState("");
  const [newPaymentTermDays, setNewPaymentTermDays] = useState("");
  function handlePaymentTermModalSubmit(e) {
    e.preventDefault();
    var name = newPaymentTerm;
    var dys = newPaymentTermDays;
    if (name != "" && dys != "") {
      var u = {
        Id: ID,
        term_name: newPaymentTerm,
        days: newPaymentTermDays,
      };
      axios
        .post(`${config.base_url}/create_new_company_payment_term/`, u)
        .then((res) => {
          console.log("PTRM RES=", res);
          if (!res.data.status && res.data.message != "") {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
          if (res.data.status) {
            toast.fire({
              icon: "success",
              title: "Term Created",
            });
            fetchPaymentTerms();

            setNewPaymentTerm("");
            setNewPaymentTermDays("");
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    } else {
      alert("Invalid");
    }
  }

  const [title, setTitle] = useState("Mr");

  
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [customerPlaceOfSupply, setCustomerPlaceOfSupply] = useState("");
  const [customerGstType, setCustomerGstType] = useState("");
  const [customerGstIn, setCustomerGstIn] = useState("");
  const [panNo, setPanNo] = useState("");
  const [oBalType, setOBalType] = useState("");
  const [oBal, setOBal] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  const [paymentTerm, setPaymentTerm] = useState("");
  const [customerPriceList, setCustomerPriceList] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [mobile, setMobile] = useState("");

  const [bStreet, setBStreet] = useState("");
  const [bCity, setBCity] = useState("");
  const [bState, setBState] = useState("");
  const [bPincode, setBPincode] = useState("");
  const [bCountry, setBCountry] = useState("");

  const [sStreet, setSStreet] = useState("");
  const [sCity, setSCity] = useState("");
  const [sState, setSState] = useState("");
  const [sPincode, setSPincode] = useState("");
  const [sCountry, setSCountry] = useState("");

  function placeShipAddress() {
    var chkbtn = document.getElementById("shipAddress");
    if (chkbtn.checked == true) {
      setSStreet(bStreet);
      setSCity(bCity);
      setSPincode(bPincode);
      setSCountry(bCountry);
      setSState(bState);
    } else {
      setSStreet("");
      setSCity("");
      setSPincode("");
      setSCountry("");
      setSState("");
    }
  }

  function checkLastName() {
    var fName = firstName.replace(/\d/g, "");
    var lName = lastName.replace(/\d/g, "");
    if (fName != "" && lName != "") {
      checkCustomerName(fName, lName);
    } else {
      alert("Please enter a valid Full Name.!");
      return false;
    }
  }
  function checkFirstName() {
    var fName = firstName.replace(/\d/g, "");
    var lName = lastName.replace(/\d/g, "");
    if (fName != "" && lName != "") {
      checkCustomerName(fName, lName);
    } else if (fName == "" && lName != "") {
      alert("Please enter a valid First Name.!");
    }
  }

  function checkCustomerName(fname, lname, cmp) {
    if (fname !== "" && lname !== "" && cmp !== "") {
      const params = {
        fName: fname,
        lName: lname,
        cmp: cmp,
      };
  
      axios
        .get(`${config.base_url}/check_customer_name/${ID}/`, { params })
        .then((res) => {
          console.log(res);
          if (res.data.is_exist) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status && err.response.data.message) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }
  

  function checkCustomerGSTIN(gstin) {
    var gstNo = gstin;
    if (gstNo != "") {
      var u = {
        Id: ID,
        gstin: gstNo,
      };
      axios
        .get(`${config.base_url}/check_gstin/${ID}/`, { params: u })
        .then((res) => {
          console.log(res);
          if (res.data.is_exist) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status && err.response.data.message) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }

  function checkCustomerPAN(pan) {
    var panNo = pan;
    if (panNo != "") {
      var u = {
        Id: ID,
        pan: panNo,
      };
      axios
        .get(`${config.base_url}/check_pan/${ID}/`, { params: u })
        .then((res) => {
          console.log(res);
          if (res.data.is_exist) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status && err.response.data.message) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }

  function checkCustomerPhone(phone) {
    var phoneNo = phone;
    if (phoneNo != "") {
      var u = {
        Id: ID,
        phone: phoneNo,
      };
      axios
        .get(`${config.base_url}/check_phone/${ID}/`, { params: u })
        .then((res) => {
          console.log(res);
          if (res.data.is_exist) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status && err.response.data.message) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }

  function checkCustomerEmail(email) {
    var custEmail = email;
    if (custEmail != "") {
      var u = {
        Id: ID,
        email: custEmail,
      };
      axios
        .get(`${config.base_url}/check_email/${ID}/`, { params: u })
        .then((res) => {
          console.log(res);
          if (res.data.is_exist) {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status && err.response.data.message) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }

  function handleGstType(value) {
    setCustomerGstType(value);
    checkGstType(value);
  }

  function checkGstType(value) {
    var gstTypeElement = document.getElementById("gstType");
    var gstINElement = document.getElementById("gstIN");
    var gstRowElements = document.getElementsByClassName("gstrow");

    var x = value;
    if (x === "Unregistered Business" || x === "Overseas" || x === "Consumer") {
      Array.prototype.forEach.call(gstRowElements, function (element) {
        element.classList.remove("d-block");
        element.classList.add("d-none");
      });
      gstINElement.required = false;
    } else {
      gstINElement.required = true;
      Array.prototype.forEach.call(gstRowElements, function (element) {
        element.classList.remove("d-none");
        element.classList.add("d-block");
      });
    }
  }

  function checkgst(val) {
    var gstinput = val;
    var gstregexp =
      "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}";

    if (gstinput.length === 15) {
      if (gstinput.match(gstregexp)) {
        document.getElementById("warngst").innerHTML = "";
        checkCustomerGSTIN(val);
      } else {
        document.getElementById("warngst").innerHTML =
          "Please provide a valid GST Number";
        alert("Please provide a valid GST Number");
      }
    } else {
      document.getElementById("warngst").innerHTML =
        "Please provide a valid GST Number";
      alert("Please provide a valid GST Number");
    }
  }

  function checkpan(val) {
    var paninput = val;
    var panregexp = ["[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"];
    if (val != "") {
      if (paninput.match(panregexp)) {
        document.getElementById("warnpan").innerHTML = "";
        checkCustomerPAN(val);
      } else {
        document.getElementById("warnpan").innerHTML =
          "Please provide a valid PAN Number";
        alert("Please provide a valid PAN Number");
      }
    }
  }

  function checkweb(val) {
    var webinput = val;
    var webregexp = "www.";
    if (val != "") {
      if (webinput.startsWith(webregexp)) {
        document.getElementById("warnweb").innerHTML = "";
      } else {
        document.getElementById("warnweb").innerHTML =
          "Please provide a valid Website Address";
        alert("Please provide a valid Website Address");
      }
    }
  }

  function checkphone(val) {
    var phoneinput = val;
    var phoneregexp = /^\d{10}$/;
    if (val != "") {
      if (phoneinput.match(phoneregexp)) {
        document.getElementById("warnphone").innerHTML = "";
        checkCustomerPhone(val);
      } else {
        document.getElementById("warnphone").innerHTML =
          "Please provide a valid Phone Number";
        alert("Please provide a valid Phone Number");
      }
    }
  }

  function checkemail(val) {
    var emailinput = val;
    var emailregexp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (val != "") {
      if (emailinput.match(emailregexp)) {
        //   document.getElementById("warnemail").innerHTML = "";
        checkCustomerEmail(val);
      } else {
        //   document.getElementById("warnemail").innerHTML =
        //     "Please provide a valid Email ID";
        alert("Please provide a valid Email id");
      }
    }
  }

  function setOpeningBalanceValue(value) {
    var openbal = value;
    if (oBalType == "credit") {
      if (openbal.slice(0, 1) != "-") {
        if (parseFloat(openbal) != 0) {
          setOBal(-1 * openbal);
        } else {
          setOBal(openbal);
        }
      } else {
        if (parseFloat(openbal) != 0) {
          setOBal(openbal);
        } else {
          setOBal(-1 * parseFloat(openbal));
        }
      }
    } else {
      setOBal(openbal);
    }
  }

  function handleOpenBalType(val) {
    setOBalType(val);
    changeOpenBalType(val);
  }

  function changeOpenBalType(type) {
    var openbal = oBal;
    if (openbal != "") {
      if (type == "credit") {
        if (parseFloat(openbal) != 0) {
          setOBal(-1 * openbal);
        } else {
          setOBal(openbal);
        }
      } else {
        if (parseFloat(openbal) < 0) {
          setOBal(Math.abs(openbal));
        } else {
          setOBal(openbal);
        }
      }
    }
  }


 // Handle form submission
const handleNewCustomerModalSubmit = async (e) => {
  e.preventDefault();

  const dt = {
    Id: ID,
    title: title,
    first_name: firstName,
    last_name: lastName,
    company: company,
    location: location,
    place_of_supply: customerPlaceOfSupply,
    gst_type: customerGstType,
    gstin: customerGstIn,
    pan_no: panNo,
    email: customerEmail,
    mobile: mobile,
    website: website,
    price_list: customerPriceList,
    payment_terms: paymentTerm,
    opening_balance: oBal,
    open_balance_type: oBalType,
    current_balance: oBal,
    credit_limit: creditLimit,
    billing_street: bStreet,
    billing_city: bCity,
    billing_state: bState,
    billing_pincode: bPincode,
    billing_country: bCountry,
    ship_street: sStreet,
    ship_city: sCity,
    ship_state: sState,
    ship_pincode: sPincode,
    ship_country: sCountry,
    status: "Active",
  };

  try {
    const res = await axios.post(`${config.base_url}/create_new_customer/`, dt);
    console.log("CUST RES=", res);
    
    if (res.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Customer Created",
      });
      // Clear customer input fields or reset state as necessary
      fetchCustomers();
    } else {
      Swal.fire({
        icon: "error",
        title: res.data.error || "Failed to create customer.",
      });
    }
  } catch (err) {
    console.log("ERROR=", err);
    if (err.response && err.response.data) {
      Swal.fire({
        icon: "error",
        title: err.response.data.error || "An error occurred.",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "An unexpected error occurred.",
      });
    }
  }
};

// Optional: useEffect to load any required data on component mount
useEffect(() => {
  // Load any necessary data here, if needed
}, []);
  

  // New Item

  function validateHSN() {
    var hsnField = document.getElementById("hsnField");
    var errorText = document.getElementById("hsnError");
    var hsnValue = hsnField.value;

    if (hsnValue.length < 6) {
      errorText.innerText = "HSN must contain at least 6 digits";
      hsnField.setCustomValidity("HSN must contain at least 6 digits");
      hsnField.style.borderColor = "red";
    } else {
      errorText.innerText = "";
      hsnField.setCustomValidity("");
      hsnField.style.borderColor = "";
    }
  }

  function validateSAC() {
    var sacField = document.getElementById("sacField");
    var errorText = document.getElementById("sacError");
    var sacValue = sacField.value;

    if (sacValue.length < 6) {
      errorText.innerText = "SAC must contain at least 6 digits";
      sacField.setCustomValidity("SAC must contain at least 6 digits");
      sacField.style.borderColor = "red";
    } else {
      errorText.innerText = "";
      sacField.setCustomValidity("");
      sacField.style.borderColor = "";
    }
  }

  function showdiv() {
    document.getElementById("taxableDiv").style.display = "flex";
  }

  function hidediv() {
    document.getElementById("taxableDiv").style.display = "none";
  }

  function itemTypeChange() {
    var value = document.getElementById("itemType").value;
    var sacField = document.getElementById("sacField");
    var hsnField = document.getElementById("hsnField");
    var hsnDiv = document.getElementById("hsnDiv");
    var sacDiv = document.getElementById("sacDiv");
    var sacError = document.getElementById("sacError");
    var hsnError = document.getElementById("hsnError");
    if (value === "Goods") {
      sacField.value = "";
      hsnField.required = true;
      sacField.required = false;
      hsnDiv.style.display = "block";
      sacDiv.style.display = "none";
      sacError.textContent = "";
      sacField.style.borderColor = "white";
    } else {
      hsnField.value = "";
      hsnField.required = false;
      sacField.required = true;
      sacDiv.style.display = "block";
      hsnDiv.style.display = "none";
      hsnError.textContent = "";
      hsnField.style.borderColor = "white";
    }
  }

  const [units, setUnits] = useState([]);
  const [accounts, setAccounts] = useState([]);

  
  const fetchItemUnits = () => {
    axios
      .get(`${config.base_url}/unit_dropdown/${ID}/`)
      .then((res) => {
        console.log("UNITS==", res);
        if (res.data.status) {
          let unitList = res.data.units;
          setUnits([]);
          unitList.forEach((unitItem) => {
            let obj = {
              id: unitItem.id,
              name: unitItem.name,
            };
            setUnits((prevState) => [...prevState, obj]);
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchItemUnits();
  }, []);

  const fetchPurchaseAccounts = () => {
    axios
      .get(`${config.base_url}/get_company_accounts/${ID}/`)
      .then((res) => {
        console.log("ACCNTS==", res);
        if (res.data.status) {
          let acc = res.data.accounts;
          setAccounts([]);
          acc.map((i) => {
            let obj = {
              account_name: i.account_name,
            };
            setAccounts((prevState) => [...prevState, obj]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchPurchaseAccounts();
  }, []);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [unit, setUnit] = useState("");
  const [hsn, setHsn] = useState("");
  const [sac, setSac] = useState("");
  const [taxRef, setTaxRef] = useState("");
  const [interStateTax, setInterStateTax] = useState("");
  const [intraStateTax, setIntraStateTax] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [purchaseAccount, setPurchaseAccount] = useState("");
  const [purchaseDescription, setPurchaseDescription] = useState("");
  const [salesPrice, setSalesPrice] = useState(0);
  const [salesAccount, setSalesAccount] = useState("");
  const [salesDescription, setSalesDescription] = useState("");
  const [inventoryAccount, setInventoryAccount] = useState("");
  const [stock, setStock] = useState(0);
  const [stockUnitRate, setStockUnitRate] = useState(0);
  const [minStock, setMinStock] = useState(0);

  const handleItemModalSubmit = (e) => {
    e.preventDefault();

    var dt = {
      Id: ID,
      name: name,
      item_type: type,
      unit: unit,
      hsn: hsn,
      sac: sac,
      tax_reference: taxRef,
      intra_state_tax: intraStateTax,
      inter_state_tax: interStateTax,
      sales_account: salesAccount,
      selling_price: salesPrice,
      sales_description: salesDescription,
      purchase_account: purchaseAccount,
      purchase_price: purchasePrice,
      purchase_description: purchaseDescription,
      min_stock: minStock,
      inventory_account: inventoryAccount,
      opening_stock: stock,
      current_stock: stock,
      stock_in: 0,
      stock_out: 0,
      stock_unit_rate: stockUnitRate,
      status: "Active",
    };

    axios
      .post(`${config.base_url}/create_new_Item/`, dt)
      .then((res) => {
        console.log("ITM RES=", res);
        if (res.data.status) {
          Swal.fire({
            icon: "success",
            title: "Item Created",
          });
        fetchItems()  
        }
        if (!res.data.status && res.data.message != "") {
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };
  
  

  //calculations

  

  function resetItem(id) {
    setSalesOrderItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, item: "" } : item))
    );
  }

  function refreshTax(plc) {
    var cmp = cmpState;
    if (cmp == plc) {
      document.querySelectorAll(".tax_ref").forEach(function (ele) {
        ele.style.display = "none";
      });
      document.querySelectorAll(".tax_ref_gst").forEach(function (ele) {
        ele.style.display = "block";
      });
      document.getElementById("taxamountCGST").style.display = "flex";
      document.getElementById("taxamountSGST").style.display = "flex";
      document.getElementById("taxamountIGST").style.display = "none";
    } else {
      document.querySelectorAll(".tax_ref").forEach(function (ele) {
        ele.style.display = "none";
      });
      document.querySelectorAll(".tax_ref_igst").forEach(function (ele) {
        ele.style.display = "block";
      });
      document.getElementById("taxamountCGST").style.display = "none";
      document.getElementById("taxamountSGST").style.display = "none";
      document.getElementById("taxamountIGST").style.display = "flex";
    }
    calc2(plc);
  }

  function refreshTax2() {
    if (cmpState == placeOfSupply) {
      document.querySelectorAll(".tax_ref").forEach(function (ele) {
        ele.style.display = "none";
      });
      document.querySelectorAll(".tax_ref_gst").forEach(function (ele) {
        ele.style.display = "block";
      });
      document.getElementById("taxamountCGST").style.display = "flex";
      document.getElementById("taxamountSGST").style.display = "flex";
      document.getElementById("taxamountIGST").style.display = "none";
    } else {
      document.querySelectorAll(".tax_ref").forEach(function (ele) {
        ele.style.display = "none";
      });
      document.querySelectorAll(".tax_ref_igst").forEach(function (ele) {
        ele.style.display = "block";
      });
      document.getElementById("taxamountCGST").style.display = "none";
      document.getElementById("taxamountSGST").style.display = "none";
      document.getElementById("taxamountIGST").style.display = "flex";
    }
  }

  function handleOrderDateChange(date) {
    setDate(date);
    findShipmentDate();
  }

  // Function to handle payment term change and calculate shipment date
function handlePaymentTermChange(e) {
  const selectedTerm = e.target.options[e.target.selectedIndex];
  const days = parseInt(selectedTerm.getAttribute("data-days"), 10) || 0;

  setFormData((prevData) => ({
    ...prevData,
    payment_terms: selectedTerm.value,
  }));

  findShipmentDate(formData.sales_order_date, days);
}

// Function to find the expected shipment date
function findShipmentDate(orderDate, days) {
  if (orderDate && !isNaN(days)) {
    const orderDateObj = new Date(orderDate);
    orderDateObj.setDate(orderDateObj.getDate() + days);

    const formattedDate = orderDateObj.toISOString().slice(0, 10); // YYYY-MM-DD format
    setShipmentDate(formattedDate);
  } else {
    alert("Please enter a valid order date and select a payment term.");
  }
}

  const calc3 = (salesOrderItems) => {
    const updatedItems = salesOrderItems.map((item) => {
      console.log("CALC3==", item);

      let qty = parseInt(item.quantity || 0);
      let price = priceList
        ? parseFloat(item.priceListPrice || 0)
        : parseFloat(item.price || 0);
      let dis = parseFloat(item.discount || 0);

      let tax =
        placeOfSupply === cmpState
          ? parseInt(item.taxGst || 0)
          : parseInt(item.taxIgst || 0);

      let total = parseFloat(qty) * parseFloat(price) - parseFloat(dis);
      let taxAmt = (qty * price - dis) * (tax / 100);

      return {
        ...item,
        total: total,
        taxAmount: taxAmt,
      };
    });

    calc_total(updatedItems);
  };

  function calc2(placeOfSupply) {
    const updatedItems = salesOrderItems.map((item) => {
      var qty = parseInt(item.quantity || 0);
      if (priceList) {
        var price = parseFloat(item.priceListPrice || 0);
      } else {
        var price = parseFloat(item.price || 0);
      }
      var dis = parseFloat(item.discount || 0);

      if (placeOfSupply == cmpState) {
        var tax = parseInt(item.taxGst || 0);
      } else {
        var tax = parseInt(item.taxIgst || 0);
      }
      let total = parseFloat(qty) * parseFloat(price) - parseFloat(dis);
      let taxAmt = (qty * price - dis) * (tax / 100);
      return {
        ...item,
        total: total,
        taxAmount: taxAmt,
      };
    });

    setSalesOrderItems(updatedItems);
    refreshIndexes(updatedItems)
    calc_total2(updatedItems, placeOfSupply);
  }

  const calc = () => {
    const updatedItems = salesOrderItems.map((item) => {
      var qty = parseInt(item.quantity || 0);
      if (priceList) {
        var price = parseFloat(item.priceListPrice || 0);
      } else {
        var price = parseFloat(item.price || 0);
      }
      var dis = parseFloat(item.discount || 0);

      if (placeOfSupply == cmpState) {
        var tax = parseInt(item.taxGst || 0);
      } else {
        var tax = parseInt(item.taxIgst || 0);
      }
      let total = parseFloat(qty) * parseFloat(price) - parseFloat(dis);
      let taxAmt = (qty * price - dis) * (tax / 100);
      return {
        ...item,
        total: total,
        taxAmount: taxAmt,
      };
    });

    setSalesOrderItems(updatedItems);
    refreshIndexes(updatedItems);
    calc_total(updatedItems);
  };

  function calc_total(salesOrderItems) {
    var total = 0;
    var taxamount = 0;
    salesOrderItems.map((item) => {
      total += parseFloat(item.total || 0);
    });
    salesOrderItems.map((item) => {
      taxamount += parseFloat(item.taxAmount || 0);
    });
    setSubTotal(total.toFixed(2));
    setTaxAmount(taxamount.toFixed(2));

    var ship = parseFloat(shippingCharge || 0);
    var adj_val = parseFloat(adjustment || 0);
    var gtot = taxamount + total + ship + adj_val;

    setGrandTotal(gtot.toFixed(2));

    var adv_val = parseFloat(paid || 0);
    var bal = gtot - adv_val;
    setBalance(bal.toFixed(2));
    splitTax(taxamount, placeOfSupply);
  }

  function splitTax(taxamount, placeOfSupply) {
    var d = 0;
    if (placeOfSupply == cmpState) {
      var gst = taxamount / 2;
      setCgst(parseFloat(gst.toFixed(2)));
      setSgst(parseFloat(gst.toFixed(2)));
      setIgst(parseFloat(d.toFixed(2)));
    } else {
      setIgst(taxamount.toFixed(2));
      setCgst(d.toFixed(2));
      setSgst(d.toFixed(2));
    }
  }

  function calc_total2(salesOrderItems, placeOfSupply) {
    let total = 0;
    let taxAmount = 0;

    // Calculate total and tax amount
    salesOrderItems.forEach((item) => {
        total += parseFloat(item.total || 0);
        taxAmount += parseFloat(item.taxAmount || 0);
    });

    // Set the subtotal and tax amount
    setSubTotal(total.toFixed(2));
    setTaxAmount(taxAmount.toFixed(2));

    // Calculate shipping and adjustment
    const ship = parseFloat(shippingCharge || 0);
    const adjVal = parseFloat(adjustment || 0);
    const grandTotal = taxAmount + total + ship + adjVal;

    // Set the grand total and balance
    setGrandTotal(grandTotal.toFixed(2));
    
    const advVal = parseFloat(paid || 0);
    const balance = grandTotal - advVal;
    setBalance(balance.toFixed(2));

    // Call the tax splitting function
    splitTax2(taxAmount, placeOfSupply);
}


  function splitTax2(taxamount, placeOfSupply) {
    var d = 0;
    if (placeOfSupply == cmpState) {
      var gst = taxamount / 2;
      setCgst(parseFloat(gst.toFixed(2)));
      setSgst(parseFloat(gst.toFixed(2)));
      setIgst(parseFloat(d.toFixed(2)));
    } else {
      setIgst(taxamount.toFixed(2));
      setCgst(d.toFixed(2));
      setSgst(d.toFixed(2));
    }
  }

 

 

 

  function updateGrandTotalShip(val) {
    var subtot = subTotal;
    var tax = taxAmount;
    var sh = val;
    var adj = adjustment;
    var gtot = (
      parseFloat(subtot || 0) +
      parseFloat(tax || 0) +
      parseFloat(sh || 0) +
      parseFloat(adj || 0)
    ).toFixed(2);
    setGrandTotal(gtot);
    setBalance((parseFloat(gtot) - parseFloat(paid)).toFixed(2));
  }

  function updateGrandTotalAdj(val) {
    var subtot = subTotal;
    var tax = taxAmount;
    var sh = shippingCharge;
    var adj = val;
    var gtot = (
      parseFloat(subtot || 0) +
      parseFloat(tax || 0) +
      parseFloat(sh || 0) +
      parseFloat(adj || 0)
    ).toFixed(2);
    setGrandTotal(gtot);
    setBalance((parseFloat(gtot) - parseFloat(paid)).toFixed(2));
  }

  function updateBalance(val) {
    var tot_val = grandTotal;
    var adv_val = val;
    if (adv_val != "") {
      if (parseFloat(tot_val) < parseFloat(adv_val)) {
        setPaid(parseFloat(tot_val));
        setBalance(0);
        alert("Advance Greater than Total Amount");
      } else {
        var bal = parseFloat(tot_val) - parseFloat(adv_val);
        setBalance(bal.toFixed(2));
      }
    } else {
      setBalance(parseFloat(tot_val));
    }
  }
  function checkPriceList() {
    // function logic here
}



const handleSalesOrderNoChange = (e) => {
  const { name, value } = e.target; // Destructure name and value
  setSalesOrderNo(value); // Set the sales order number state
  checkSalesOrderNo(value); // Check the sales order number
  setFormData((prevData) => ({
    ...prevData,
    [name]: value, // Update the corresponding field in formData
  }));
};

// Validate Sales Order Number
// Validate Sales Order Number
function checkSalesOrderNo(val) {
  const errorElement = document.getElementById("SONoErr"); // Get the element
  if (errorElement) {
    errorElement.innerText = ""; // Clear the error message if the element exists
  }

  if (val !== "") {
    axios
      .get(`${config.base_url}/check_sales_order_no/`, { params: { Id: ID, SONum: val } })
      .then((res) => {
        if (!res.data.status && errorElement) {
          // Set the error message if validation fails and element exists
          errorElement.innerText = res.data.message;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}


const [nextSalesOrderNo, setNextSalesOrderNo] = useState('');
const [referenceNo, setReferenceNo] = useState('');

// Fetch the next sales order number when the component mounts
useEffect(() => {
  const fetchNextSalesOrderNo = async () => {
    try {
      const response = await axios.get(`${config.base_url}/get_next_sales_order_no/`, { params: { Id: ID } });
      if (response.data.status) {
        setNextSalesOrderNo(response.data.next_sales_order_no);
        setFormData((prevData) => ({
          ...prevData,
          sales_order_no: response.data.next_sales_order_no, // Set initial sales order number
        }));
      }
    } catch (error) {
      console.error('Error fetching next sales order number:', error);
    }
  };

  fetchNextSalesOrderNo();
}, []);

// Fetch the reference number
useEffect(() => {
  const fetchReferenceNo = async () => {
    try {
      const response = await axios.get(`${config.base_url}/get_reference_no/`, { params: { Id: ID } });
      if (response.data.status) {
        setReferenceNo(response.data.next_reference_no);
        setFormData((prevData) => ({
          ...prevData,
          reference_no: response.data.next_reference_no, // Set initial reference number
        }));
      }
    } catch (error) {
      console.error('Error fetching reference number:', error);
    }
  };

  fetchReferenceNo();
}, []);




function handleUnitModalSubmit(e) {
  e.preventDefault();
  var name = newUnit;

  if (name !== "") {
    var u = {
      Id: ID,  // Ensure that this `ID` exists and matches a valid user in your DB
      name: newUnit,
    };

    axios
      .post(`${config.base_url}/create_new_unit/`, u)
      .then((res) => {
        console.log("UNIT RES=", res);
        if (res.data.status) {
          Swal.fire({
            icon: "success",
            title: "Unit Created",
            text: "The new unit has been successfully created.",
            timer: 2000,
            showConfirmButton: false,
          });
          fetchItemUnits();  // Fetch the updated list of units
          setUnit(u.name);   // Set the newly created unit
          setNewUnit("");    // Clear the input field for new unit
        } else {
          Swal.fire({
            icon: "error",
            title: "Creation Failed",
            text: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response?.data?.message || "An error occurred while creating the unit.",
        });
      });
  } else {
    alert("Please enter a valid unit name.");
  }
}






function handlePriceListIdChange(val) {
  setPriceListId(val);
  // applyPriceList(val);
}


function checkForNull(val) {
  return val !== "" ? val : null;
}

function checkForZero(val) {
  return val !== "" ? val : 0.0;
}

function checkBalanceVal(val) {
  return val !== "" ? val : grandTotal;
}



  
  
  
  
  

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel" style={{ background: "#a9a9a961", maxWidth: "110vw" }}>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "110vh" }}>
              <div className="container-fluid">
                <div className="card radius-18">
                <button
  className="btn btn-dark btn-sm ml-auto"
  onClick={() => navigate('/Salesorder')}
>
  <i className="fa fa-close"></i> {/* Icon for Close Button */}
</button>

                  <div className="card-body">
                    <h2 className="mt-3 text-center">Add Sales Order</h2>
                    </div>
                    </div>
                    <div style={{ width: "100%", margin: "0 auto" }} className="card radius-15 mt-3">
          <div className="card-body"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        {/* Sales Order No */}
                     
{/* Customer Name Dropdown */}
<div className="col-md-4" style={{ marginLeft: "12px" }}> {/* Adjusted margin to move right */}
  <div className="form-group">
    <label htmlFor="customer_name">Customer Name</label>
    <div className="d-flex align-items-center">
      <select
        className="form-control"
        id="customer_name"
        name="customer_name"
        value={formData.customer_name}
        onChange={handleCustomerSelect}
        required
        style={{ backgroundColor: 'white', width: '90%', padding: '10px' }} // Adjust width and padding here
      >
        <option value="">Select Customer</option>
        {customers.map((customer) => (
          <option key={customer.id} value={customer.name}>
            {customer.name}
          </option>
        ))}
      </select>
      <button
        type="button"
        data-toggle="modal"
        data-target="#newCustomer"
        className="btn btn-outline-secondary ml-2"
        style={{ width: "fit-content", height: "fit-content" }}
      >
        +
      </button>
    </div>
  </div>
</div>




 {/* Customer Email */}
 <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="customer_email">Customer Email</label>
        <input
          type="email"
          className="form-control"
          id="customer_email"
          name="customer_email"
          value={formData.customer_email}
          onChange={handleChange}
          required
          style={{ backgroundColor: 'white', width: '90%', padding: '10px' }} // Adjust width and padding here
        />
      </div>
    </div>

    {/* Billing Address */}
    <div className="col-md-3" >
      <div className="form-group">
        <label htmlFor="billing_address">Billing Address</label>
        <textarea
          className="form-control"
          id="billing_address"
          name="billing_address"
          value={`${formData.billing_street} ${formData.billing_city} ${formData.billing_state} ${formData.billing_pincode} ${formData.billing_country}`}
          onChange={handleChange}
          required
          style={{ backgroundColor: 'white', width: '110%', padding: '10px' }} // Adjust width and padding here
        />
      </div>
    </div>

    {/* GST Type */}
    <div className="col-md-4" style={{ marginLeft: "10px" }}>
      <div className="form-group">
        <label htmlFor="gst_type">GST Type</label>
        <input
          type="text"
          className="form-control"
          id="gst_type"
          name="gst_type"
          value={formData.gst_type}
          onChange={handleChange}
          style={{ backgroundColor: 'white', width: '90%', padding: '10px' }} // Adjust width and padding here
        />
        
      </div>
    </div>

    {/* GSTIN */}
{formData.gstin && (
  <div className="col-md-3">
    <div className="form-group">
      <label htmlFor="gstin">GSTIN</label>
      <input
        type="text"
        className="form-control"
        id="gstin"
        name="gstin"
        value={formData.gstin}
        onChange={handleChange}
        readOnly // This makes the field read-only
        style={{ backgroundColor: 'white', width: '90%', padding: '10px' }} // Adjust width and padding here 
      />
    </div>
  </div>
)}


<div className="col-md-4">
  <div className="form-group">
    <label htmlFor="place_of_supply">Place of Supply</label>
    <select
      className="custom-select form-control"
      id="place_of_supply"
      name="place_of_supply"
      value={formData.place_of_supply} // Binds to formData
      onChange={(e) =>
        handleChange({ target: { name: "place_of_supply", value: e.target.value } }) // Updates formData
      }
      required
      style={{ backgroundColor: 'white', width: '70%',  padding: '10px' }} // Adjust width and padding here
    >
      <option value="">Select Place of Supply</option>
      <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
      <option value="Andhra Pradesh">Andhra Pradesh</option>
      <option value="Arunachal Pradesh">Arunachal Pradesh</option>
      <option value="Assam">Assam</option>
      <option value="Bihar">Bihar</option>
      <option value="Chandigarh">Chandigarh</option>
      <option value="Chhattisgarh">Chhattisgarh</option>
      <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
      <option value="Daman and Diu">Daman and Diu</option>
      <option value="Delhi">Delhi</option>
      <option value="Goa">Goa</option>
      <option value="Gujarat">Gujarat</option>
      <option value="Haryana">Haryana</option>
      <option value="Himachal Pradesh">Himachal Pradesh</option>
      <option value="Jammu and Kashmir">Jammu and Kashmir</option>
      <option value="Jharkhand">Jharkhand</option>
      <option value="Karnataka">Karnataka</option>
      <option value="Kerala">Kerala</option>
      <option value="Ladakh">Ladakh</option>
      <option value="Lakshadweep">Lakshadweep</option>
      <option value="Madhya Pradesh">Madhya Pradesh</option>
      <option value="Maharashtra">Maharashtra</option>
      <option value="Manipur">Manipur</option>
      <option value="Meghalaya">Meghalaya</option>
      <option value="Mizoram">Mizoram</option>
      <option value="Nagaland">Nagaland</option>
      <option value="Odisha">Odisha</option>
      <option value="Puducherry">Puducherry</option>
      <option value="Punjab">Punjab</option>
      <option value="Rajasthan">Rajasthan</option>
      <option value="Sikkim">Sikkim</option>
      <option value="Tamil Nadu">Tamil Nadu</option>
      <option value="Telangana">Telangana</option>
      <option value="Tripura">Tripura</option>
      <option value="Uttar Pradesh">Uttar Pradesh</option>
      <option value="Uttarakhand">Uttarakhand</option>
      <option value="West Bengal">West Bengal</option>
      <option value="Other Territory">Other Territory</option>
    </select>
  </div>
</div>


{/* Sales Order No */}
<div className="col-md-4" style={{ marginLeft: "10px" }}>
  <div className="form-group">
    <label htmlFor="sales_order_no">Sales Order No.</label>
    <input
      type="text"
      className="form-control"
      id="sales_order_no"
      name="sales_order_no"
      onChange={handleSalesOrderNoChange}
      placeholder={nextSalesOrderNo}
      required
      style={{ backgroundColor: 'white', width: '90%', padding: '10px' }}
    />
    <span id="SONoErr" style={{ color: 'red' }}></span>
  </div>
</div>

{/* Reference No */}
<div className="col-md-4">
  <div className="form-group">
    <label htmlFor="reference_no">Reference No.</label>
    <input
      type="text"
      className="form-control"
      id="reference_no"
      name="reference_no"
      value={referenceNo || ""}
      readOnly
      style={{ backgroundColor: 'white', width: '100%', padding: '10px' }}
    />
  </div>
</div>

{/* Sales Order Date */}
<div className="col-md-3">
  <div className="form-group">
    <label htmlFor="sales_order_date">Sales Order Date</label>
    <input
      type="date"
      className="form-control"
      id="sales_order_date"
      name="sales_order_date"
      value={formData.sales_order_date}
      onChange={handleChange}
      style={{ backgroundColor: 'white', width: '110%', padding: '10px' }}
    />
  </div>
</div>

{/* Expected Ship Date */}
<div className="col-md-4" style={{ marginLeft: "10px" }}>
  <div className="form-group">
    <label htmlFor="exp_ship_date">Expected Ship Date</label>
    <input
      type="date"
      className="form-control"
      id="exp_ship_date"
      name="exp_ship_date"
      value={shipmentDate}
      onChange={handleChange}
      style={{ backgroundColor: 'white', width: '90%', padding: '10px' }}
    />
  </div>
</div>

{/* Payment Terms */}
<div className="col-md-4">
  <div className="form-group">
    <label htmlFor="payment_terms">Payment Terms</label>
    <div className="input-group">
      <select
        className="form-control"
        id="payment_terms"
        name="payment_terms"
        value={formData.payment_terms}
        onChange={handlePaymentTermChange}
      >
        <option value="">Select Payment Term</option>
        {terms.map((term) => (
          <option key={term.id} value={term.id} data-days={term.days}>
            {term.name}
          </option>
        ))}
      </select>
      <div className="input-group-append">
        <button
          type="button"
          data-toggle="modal"
          data-target="#newPaymentTerm"
          className="btn btn-outline-secondary ml-2"
        >
          +
        </button>
      </div>
    </div>
  </div>
</div>

{/* Payment Method */}
<div className="col-md-3">
  <div className="form-group">
    <label htmlFor="payment_method">Payment Method</label>
    <select
      className="form-control"
      id="payment_method"
      name="payment_method"
      value={formData.payment_method}
      onChange={handleChange}
      style={{ backgroundColor: 'white', width: '110%', padding: '10px' }}
    >
      <option value="">Select Payment Method</option>
      <option value="cash">Cash</option>
      <option value="cheque">Cheque</option>
      <option value="upi">UPI</option>
     
    </select>
  </div>
</div>

{/* Conditional Fields */}
{formData.payment_method === "cheque" && (
  <div className="col-md-4" style={{ marginLeft: "10px" }}>
    <div className="form-group">
      <label htmlFor="cheque_no">Cheque No.</label>
      <input
        type="text"
        className="form-control"
        id="cheque_no"
        name="cheque_no"
        value={formData.cheque_no}
        onChange={handleChange}
        style={{ backgroundColor: 'white', width: '90%', padding: '10px' }}
      />
    </div>
  </div>
)}

{formData.payment_method === "upi" && (
  <div className="col-md-4" style={{ marginLeft: "10px" }}>
    <div className="form-group">
      <label htmlFor="upi_no">UPI No.</label>
      <input
        type="text"
        className="form-control"
        id="upi_no"
        name="upi_no"
        value={formData.upi_no}
        onChange={handleChange}
        style={{ backgroundColor: 'white', width: '90%', padding: '10px' }}
      />
    </div>
  </div>
)}








  


<div className="row clearfix"style={{ marginLeft: "10px",marginRight:"10px" }}>
  {/* Price List Checkbox and Dropdown */}

<div className="col-md-3" >
  <div className="form-group mt-2">
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id="applyPriceList"
        name="applyPriceList"
        checked={formData.applyPriceList}
        onChange={(e) => setFormData({ ...formData, applyPriceList: e.target.checked, price_list: null })}
      />
      <label className="form-check-label" htmlFor="applyPriceList">
        Apply Price List
      </label>
    </div>
  </div>

  {formData.applyPriceList && (
    <div className="form-group mt-2" style={{ marginLeft: "10px" }}>
      <label htmlFor="price_list">Select Price List</label>
      <select
        className="form-control"
        id="priceListIds"
        name="price_list"
        value={formData.price_list || ""}
        onChange={(e) => {
          const selectedValue = parseInt(e.target.value, 10);
          handlePriceListIdChange(selectedValue);
          setFormData({ ...formData, price_list: selectedValue });
          applyPriceList(selectedValue);
        }}
      >
        <option value="" disabled>Choose Price List</option>
        {priceLists.map((p) => (
          <option key={p.id} value={p.id}>{p.name}</option>
        ))}
      </select>
    </div>
  )}
</div>

  <div className="col-md-12 table-responsive-md mt-3">
  <table className="table table-bordered table-hover mt-3" id="salesOrderItemsTable">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th className="text-center">PRODUCT / SERVICE</th>
              <th className="text-center">HSN / SAC</th>
              <th className="text-center">QTY</th>
              <th className="text-center">PRICE</th>
              <th className="text-center">TAX (%)</th>
              <th className="text-center">DISCOUNT</th>
              <th className="text-center">TOTAL</th>
            </tr>
          </thead>
          <tbody id="items-table-body">
            {salesOrderItems.map((row) => (
              <tr key={row.id} id={`tab_row${row.id}`}>
                <td className="nnum" style={{ textAlign: "center" }}>{row.id}</td>
                <td style={{ width: "20%" }}>
                  <div className="d-flex align-items-center">
                    <Select
                      options={items}
                      styles={customStyles}
                      name="item"
                      className="w-100"
                      id={`item${row.id}`}
                      required
                      value={items.find((item) => item.label === row.item) || null}
                      onChange={(selectedOption) =>
                        handleItemChange(selectedOption, row.id)
                      }
                      isClearable
                      isSearchable
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary ml-1"
                      data-target="#newItem"
                      data-toggle="modal"
                      style={{ width: "fit-content", height: "fit-content" }}
                    >
                      +
                    </button>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    name="hsnSac"
                    value={row.hsnSac}
                    id={`hsn${row.id}`}
                    placeholder="HSN/SAC Code"
                    style={{ backgroundColor: 'white' }}
                    className="form-control HSNCODE"
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="quantity"
                    id={`qty${row.id}`}
                    className="form-control qty"
                    step="0"
                    min="1"
                    value={row.quantity}
                    onChange={(e) =>
                      handleSalesOrderItemsInputChange(row.id, "quantity", e.target.value)
                    }
                    required
                  />
                  
                </td>
                <td>
                  <input
                    type="number"
                    name="price"
                    id={`price${row.id}`}
                    className="form-control price"
                    step="0.00"
                    min="0"
                    style={{ backgroundColor: 'white' }}
                    value={row.price}
                    readOnly
                  />
                </td>
                {showIGST && (
                <td style={{ width: "13%" }}>
  <select
    name="taxGST"
    id={`taxGST${row.id}`}
    className="form-control tax_ref tax_ref_gst"
    value={row.taxGst} // Make sure this is updated correctly
    onChange={(e) =>
      handleSalesOrderItemsInputChange(row.id, "taxGst", e.target.value)
    }
  >
    <option value="">Select IGST</option>
    <option value="28">28.0% IGST</option>
    <option value="18">18.0% IGST</option>
    <option value="12">12.0% IGST</option>
    <option value="5">5.0% IGST</option>
    <option value="3">3.0% IGST</option>
    <option value="0">0.0% IGST</option>
  </select>
</td>
)}
{showCGSTSGST && (
<td style={{ width: "13%" }}>
  <select
    name="taxGST"
    id={`taxGST${row.id}`}
    className="form-control tax_ref tax_ref_gst"
    value={row.taxGst} // Make sure this is updated correctly
    onChange={(e) =>
      handleSalesOrderItemsInputChange(row.id, "taxGst", e.target.value)
    }
  >
    <option value="">Select GST</option>
    <option value="28">28.0% GST</option>
    <option value="18">18.0% GST</option>
    <option value="12">12.0% GST</option>
    <option value="5">5.0% GST</option>
    <option value="3">3.0% GST</option>
    <option value="0">0.0% GST</option>
  </select>
</td>
)}
                <td>
                  <input
                    type="number"
                    name="discount"
                    placeholder="Enter Discount"
                    id={`disc${row.id}`}
                    value={row.discount}
                    onChange={(e) =>
                      handleSalesOrderItemsInputChange(row.id, "discount", e.target.value)
                    }
                    className="form-control disc"
                    step="0"
                    min="0"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    name="total"
                    id={`total${row.id}`}
                    className="form-control total"
                    value={row.total}
                    style={{ backgroundColor: 'white' }}
                    readOnly
                  />
                  <input
                    type="hidden"
                    id={`taxamount${row.id}`}
                    className="form-control itemTaxAmount"
                    value={row.taxAmount}
                  />
                </td>
                <td>
                  <button
                    type="button"
                    id={`${row.id}`}
                    style={{ width: "fit-content", height: "fit-content" }}
                    onClick={() => removeRow(row.id)}
                    className="btn btn-danger remove_row px-2 py-1 mx-1 fa fa-close"
                    title="Remove Row"
                  ></button>
                </td>
              </tr>
            ))}
          </tbody>
          <tr>
            <td style={{ border: "none" }}>
              <a
                className="btn btn-secondary ml-1"
                role="button"
                id="add"
                onClick={addNewRow}
                style={{ width: "fit-content", height: "fit-content" }}
              >
                +
              </a>
            </td>
          </tr>
        </table>

  </div>
</div>
<div className="modal fade" id="newCustomer">
        <div className="modal-dialog modal-xl">
        <div className="modal-content" style={{ backgroundColor: "white" }}>
            <div className="modal-header">
              <h5 className="m-3">New Customer</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form method="post" id="newCustomerForm" className="px-1">
                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="title">Title</label>
                      <select
                        name="title"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="form-control"
                      >
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Miss">Miss</option>
                        <option value="Ms">Ms</option>
                      </select>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-4">
  <label htmlFor="firstName">First Name*</label>
  <input
    type="text"
    className="form-control"
    id="firstName"
    name="first_name"
    value={firstName}
    onChange={(e) => setFirstName(e.target.value)}
    onBlur={() => checkCustomerName(firstName, lastName, company)}
    required
  />
</div>
<div className="col-md-4">
  <label htmlFor="lastName">Last Name*</label>
  <input
    type="text"
    className="form-control"
    id="lastName"
    name="last_name"
    value={lastName}
    onChange={(e) => setLastName(e.target.value)}
    onBlur={() => checkCustomerName(firstName, lastName, company)}
    required
  />
</div>
<div className="col-md-4">
  <label htmlFor="company">Company*</label>
  <input
    type="text"
    className="form-control"
    id="company"
    name="company"
    value={company}
    onChange={(e) => setCompany(e.target.value)}
    onBlur={() => checkCustomerName(firstName, lastName, company)}
    required
  />
</div>

                    <div className="col-md-4">
                      <label for="location">Location</label>
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        name="location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-4">
                      <label for="custPlaceOfSupply">Place of Supply*</label>
                      <select
                        className="custom-select form-control"
                        id="custPlaceOfSupply"
                        name="place_of_supply"
                        value={customerPlaceOfSupply}
                        onChange={(e) =>
                          setCustomerPlaceOfSupply(e.target.value)
                        }
                        
                        required
                      >
                        <option selected value="">
                          Select Place of Supply
                        </option>
                        <option value="Andaman and Nicobar Islads">
                          Andaman and Nicobar Islands
                        </option>
                        <option value="Andhra Predhesh">Andhra Predhesh</option>
                        <option value="Arunachal Predesh">
                          Arunachal Predesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadra and Nagar Haveli">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Damn anad Diu">Damn anad Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Predesh">
                          Himachal Predesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Ladakh">Ladakh</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Madhya Predesh">Madhya Predesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Predesh">Uttar Predesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                        <option value="Other Territory">Other Territory</option>
                      </select>
                      <div className="invalid-feedback">
                        Please select a valid registration type.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="gstType">GST Type*</label>
                      <select
                        className="form-control"
                        id="custGstType"
                        name="gst_type"
                        value={customerGstType}
                        onChange={(e) => handleGstType(e.target.value)}
                        
                        required
                      >
                        <option selected value="">
                          Select GST Type
                        </option>
                        <option value="Registered Business - Regular">
                          Registered Business - Regular{" "}
                          <span>
                            <i>(Business that is registered under gst)</i>
                          </span>
                        </option>
                        <option value="Registered Business - Composition">
                          Registered Business - Composition (Business that is
                          registered under composition scheme in gst)
                        </option>
                        <option value="Unregistered Business">
                          Unregistered Business (Business that has not been
                          registered under gst)
                        </option>
                        <option value="Overseas">
                          Overseas (Import/Export of supply outside india)
                        </option>
                        <option value="Consumer">Consumer</option>
                        <option value="Special Economic Zone (SEZ)">
                          Special Economic Zone (SEZ) (Business that is located
                          in a special economic zone of india or a SEZ
                          developer)
                        </option>
                        <option value="Demed Exports">
                          Demed Exports (Supply of woods to an exports oriented
                          unit or againsed advanced authorization or export
                          promotion capital woods)
                        </option>
                        <option value="Tax Deductor">
                          Tax Deductor (State of central gov,government agencies
                          or local authority)
                        </option>
                        <option value="SEZ Developer">
                          SEZ Developer (A person or organization who owns
                          atleast 26% equality in creating business units in
                          special economic zone)
                        </option>
                      </select>
                      <div className="invalid-feedback">
                        Please select a valid registration type.
                      </div>
                    </div>

                    <div className="col-md-4 gstrow d-block" id="gstInValue">
                      <div>
                        <label for="custGstIN">GSTIN*</label>
                        <input
                          type="text"
                          className="form-control"
                          value={customerGstIn}
                          onChange={(e) => setCustomerGstIn(e.target.value)}
                          onBlur={(e) => checkgst(e.target.value)}
                          id="gstIN"
                          name="gstin"
                          
                          placeholder="29APPCK7465F1Z1"
                        />
                        <a
                          data-toggle="modal"
                          href="#exampleModal"
                          style={{ color: "#3dd5f3" }}
                        >
                          Get Taxpayer Details
                        </a>
                        <div className="text-danger m-2" id="warngst"></div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <label for="panNo">PAN No.*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="panNo"
                        name="pan_no"
                       
                        required
                        value={panNo}
                        onChange={(e) => setPanNo(e.target.value)}
                        onBlur={(e) => checkpan(e.target.value)}
                        placeholder="APPCK7465F"
                      />
                      <div className="text-danger m-2" id="warnpan"></div>
                    </div>
                  </div>

                  <div className="row w-100">
                    <div className="col-md-4 mt-3">
                      <label for="validationCustom05">Opening Balance</label>
                      <div className="d-flex">
                      <select
        name="balance_type"
        id="bal"
        className="form-select mr-1 px-1"
        value={oBalType}
        onChange={(e) => handleOpenBalType(e.target.value)}
        style={{
          width: "25%",
          borderRadius: "5px",
          // Set the background color to black
          color: "black", // Set the text color to white for better visibility
        }}
      >
        <option value="debit">Debit</option>
        <option value="credit">Credit</option>
      </select>
                        <input
                          type="text"
                          className="form-control"
                          name="open_balance"
                          id="openbalance"
                          value={oBal}
                          onChange={(e) => setOBal(e.target.value)}
                          onBlur={(e) => setOpeningBalanceValue(e.target.value)}
                          step="any"
                         
                        />
                        <div className="text-danger m-2"></div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-3">
                      <label for="creditLimit">Credit Limit</label>
                      <input
                        type="text"
                        className="form-control"
                        name="credit_limit"
                     
                        step="any"
                        value={creditLimit}
                        onChange={(e) => setCreditLimit(e.target.value)}
                        id="creditLimit"
                      />
                      <div className="text-danger m-2"></div>
                    </div>

                    <div className="col-md-4 mt-3">
                      <label for="custPaymentTerms">Payment Terms</label>
                      <div className="d-flex align-items-center">
                      <select
        className="form-control"
        id="payment_terms"
        name="payment_terms"
        value={formData.payment_terms}
        onChange={handlePaymentTermChange}
      >
        <option value="">Select Payment Term</option>
        {terms.map((term) => (
          <option key={term.id} value={term.id} data-days={term.days}>
            {term.name}
          </option>
        ))}
      </select>
                        <a
                          href="#newPaymentTermcustomer"
                          data-dismiss="modal"
                          data-toggle="modal"
                         
                          className="btn btn-outline-secondary ml-1"
                        >
                          +
                        </a>
                      </div>
                    </div>

                    <div className="col-md-4 mt-3">
                      <label for="priceList">Price List</label>
                      <select
      id="price_list"
      name="price_list"
      value={formData.price_list}
      onChange={(e) => setFormData({ ...formData, price_list: e.target.value })}
      className="form-control"
    >
      <option value="">Select a Price List</option>
      {priceLists.map((list) => (
        <option key={list.id} value={list.id}>
          {list.name} ({list.currency})
        </option>
      ))}
    </select>
                    </div>
                  </div>

                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="custEmail">Email*</label>
                      <input
                        type="email"
                        className="form-control"
                        required
                        id="custEmail"
                        name="email"
                        value={customerEmail}
                        onChange={(e) => setCustomerEmail(e.target.value)}
                        onBlur={(e) => checkemail(e.target.value)}
                      
                        placeholder="accuhub@gmail.com"
                      />
                      <div id="warnemail" className="text-danger"></div>
                    </div>
                    <div className="col-md-4">
                      <label for="custWebsite">Website</label>
                      <input
                        type="text"
                        className="form-control"
                        id="custWebsite"
                        required
                        placeholder="www.accuhub.com"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        onBlur={(e) => checkweb(e.target.value)}
                        name="website"
                      
                      />
                      <div id="warnweb" className="text-danger"></div>
                    </div>
                    <div className="col-md-4">
                      <label for="custMobile">Mobile*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="custMobile"
                        required
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        onBlur={(e) => checkphone(e.target.value)}
                        name="mobile"
                      
                      />
                      <div className="text-danger m-2" id="warnphone"></div>
                    </div>
                  </div>
                  <hr />
                  <div className="row mt-5 w-100">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 card-title">
                          <h5 className="mb-0">Billing Address</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="form-row">
                            <label for="street">Street*</label>
                            <textarea
                              className="form-control street"
                              required
                              id="street"
                              value={bStreet}
                              onChange={(e) => setBStreet(e.target.value)}
                              name="street"
                            
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Street
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="city">City*</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="city"
                              name="city"
                              value={bCity}
                              onChange={(e) => setBCity(e.target.value)}
                             
                              placeholder="City"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid City
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="state">State*</label>
                            <select
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                              required
                              value={bState}
                              onChange={(e) => setBState(e.target.value)}
                            
                            >
                              <option value="" selected hidden>
                                Choose
                              </option>
                              <option value="Andaman and Nicobar Islads">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Andhra Predhesh">
                                Andhra Predhesh
                              </option>
                              <option value="Arunachal Predesh">
                                Arunachal Predesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadra and Nagar Haveli">
                                Dadra and Nagar Haveli
                              </option>
                              <option value="Damn anad Diu">
                                Damn anad Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Predesh">
                                Himachal Predesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Ladakh">Ladakh</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Madhya Predesh">
                                Madhya Predesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Predesh">
                                Uttar Predesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                              <option value="Other Territory">
                                Other Territory
                              </option>
                            </select>
                            <div className="invalid-feedback">
                              Please provide a valid State
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="pinco">Pin Code*</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="pinco"
                              value={bPincode}
                              onChange={(e) => setBPincode(e.target.value)}
                              name="pincode"
                             
                              placeholder="PIN code"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Pin Code
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="country">Country*</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="country"
                              name="country"
                              value={bCountry}
                              onChange={(e) => setBCountry(e.target.value)}
                             
                              placeholder="Country"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Country
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 d-flex">
                          <h5>Shipping Address</h5>
                          <input
                            className="ml-4 ml-5"
                            type="checkbox"
                            onClick={placeShipAddress}
                            id="shipAddress"
                            name="ship_address"
                          />
                          <label className="ml-2 mt-1 ml-2" for="shipAddress">
                            Same As Billing Address
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="form-row">
                            <label for="shipstreet">Street</label>
                            <textarea
                              className="form-control"
                              id="shipstreet"
                              name="shipstreet"
                              value={sStreet}
                              onChange={(e) => setSStreet(e.target.value)}
                            
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Street
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shipcity">City</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shipcity"
                              value={sCity}
                              onChange={(e) => setSCity(e.target.value)}
                              name="shipcity"
                            
                              placeholder="City"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid City
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shipstate">State</label>
                            <select
                              type="text"
                              className="form-control"
                              id="shipState"
                              value={sState}
                              onChange={(e) => setSState(e.target.value)}
                              name="shipstate"
                             
                            >
                              <option value="" selected>
                                Choose
                              </option>
                              <option value="Andaman and Nicobar Islads">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Andhra Predhesh">
                                Andhra Predhesh
                              </option>
                              <option value="Arunachal Predesh">
                                Arunachal Predesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadra and Nagar Haveli">
                                Dadra and Nagar Haveli
                              </option>
                              <option value="Damn anad Diu">
                                Damn anad Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Predesh">
                                Himachal Predesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Ladakh">Ladakh</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Madhya Predesh">
                                Madhya Predesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Predesh">
                                Uttar Predesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                              <option value="Other Territory">
                                Other Territory
                              </option>
                            </select>
                            <div className="invalid-feedback">
                              Please provide a valid State
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shippinco">Pin Code</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shippinco"
                              value={sPincode}
                              onChange={(e) => setSPincode(e.target.value)}
                              name="shippincode"
                             
                              placeholder="PIN code"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Pin Code
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shipcountry">Country</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shipcountry"
                              name="shipcountry"
                              value={sCountry}
                              onChange={(e) => setSCountry(e.target.value)}
                             
                              placeholder="Country"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Country
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 w-100">
                    <div className="col-4"></div>
                    <div className="col-4 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-secondary text-grey w-75"
                        onClick={handleNewCustomerModalSubmit}
                        data-dismiss="modal"
                        type="button"
                        id="newCustomerSave"
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-4"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


 {/* New Ietm */}
 <div className="modal fade" id="newItem">
        <div className="modal-dialog modal-xl">
        <div className="modal-content">
            <div className="modal-header">
              <h5 className="m-3">New Item</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form
                  className="needs-validation px-1"
                  onSubmit={handleSubmit}
                  validate
                >
                  <div className="row w-100">
                    <div className="col-md-12 mx-0">
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label for="itemName" >
                            Name
                          </label>
                          <input
                            type="text"
                            id="itemName"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                           
                            autocomplete="off"
                            required
                          />
                        </div>
                        <div className="col-md-6 mt-3">
                          <label for="itemType">
                            Type
                          </label>
                          <select
                            name="type"
                            className="form-control"
                            id="itemType"
                            value={type}
                            onChange={(e) => {
                              setType(e.target.value);
                              itemTypeChange();
                            }}
                           
                            required
                          >
                            <option selected disabled value="">
                              Choose...
                            </option>
                            <option value="Goods">Goods</option>
                            <option value="Services">Services</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label for="itemUnit">
                            Unit
                          </label>
                          <div className="d-flex align-items-center">
                          <select
      className="custom-select"
      name="unit"
      id="itemUnit"
      value={unit}
      onChange={(e) => setUnit(e.target.value)}
      required
    >
      <option selected disabled value="">
        Choose...
      </option>
      {units &&
        units.map((i) => (
          <option key={i.id} value={i.name} className="text-uppercase">
            {i.name}
          </option>
        ))}
    </select>
                              <button
                                type="button"
                                className="btn btn-outline-secondary ml-1"
                                data-toggle="modal"
                                data-dismiss="modal"
                                data-target="#createNewUnit"
                               
                              >
                                +
                              </button>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3" id="hsnDiv">
                          <label for="hsnField" >
                            HSN Code
                          </label>
                          <input
                            type="number"
                            name="hsn"
                            className="form-control"
                            
                            placeholder="Enter a valid HSN code"
                            required
                            value={hsn}
                            onChange={(e) => setHsn(e.target.value)}
                            id="hsnField"
                            onInput={validateHSN}
                          />
                          <div id="hsnError" style={{ color: "red" }}></div>
                        </div>
                        <div
                          className="col-md-6 mt-3"
                          id="sacDiv"
                          style={{ display: "none" }}
                        >
                          <label for="sacField" >
                            SAC Code
                          </label>
                          <input
                            type="number"
                            name="sac"
                            className="form-control"
                           
                            placeholder="Enter a valid SAC code"
                            required
                            value={sac}
                            onChange={(e) => setSac(e.target.value)}
                            id="sacField"
                            onInput={validateSAC}
                          />
                          <div id="sacError" style={{ color: "red" }}></div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-3 mt-3">
                          <label >
                            Tax Reference
                          </label>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check mt-1">
                            <input
                              className="form-check-input"
                              name="taxref"
                              type="radio"
                              id="inclusive"
                              value="taxable"
                              onChange={(e) => setTaxRef(e.target.value)}
                              onClick={showdiv}
                              required
                            />
                            <label  for="inclusive">
                              taxable
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check mt-1">
                            <input
                              className="form-check-input"
                              name="taxref"
                              type="radio"
                              value="non taxable"
                              onChange={(e) => setTaxRef(e.target.value)}
                              id="check"
                              onClick={hidediv}
                            />
                            <label  for="check">
                              non taxable
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        id="taxableDiv"
                       
                      >
                        <div className="col-md-6 mt-3">
                          <label for="intraStateTax">
                            Intra State Tax Rate
                          </label>
                          <select
                            name="intra_st"
                            className="form-control"
                           
                            id="intraStateTax"
                            value={intraStateTax}
                            onChange={(e) => setIntraStateTax(e.target.value)}
                          >
                            <option value="0">GST 0 (0%)</option>
                            <option value="3">GST 3 (3%)</option>
                            <option value="5">GST 5 (5%)</option>
                            <option value="12">GST 12 (12%)</option>
                            <option value="18">GST 18 (18%)</option>
                            <option value="28">GST 28 (28%)</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label for="interStateTax" >
                            Inter State Tax Rate
                          </label>
                          <select
                            name="inter_st"
                            className="form-control"
                           
                            id="interStateTax"
                            value={interStateTax}
                            onChange={(e) => setInterStateTax(e.target.value)}
                          >
                            <option value="0">IGST 0 (0%)</option>
                            <option value="3">IGST 3 (3%)</option>
                            <option value="5">IGST 5 (5%)</option>
                            <option value="12">IGST 12 (12%)</option>
                            <option value="18">IGST 18 (18%)</option>
                            <option value="28">IGST 28 (28%)</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label >
                            Purchase Price
                          </label>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <input
                                type="text"
                                className="form-control mr-1"
                                value="INR"
                               
                              />
                              <input
                                type="number"
                                name="pcost"
                                className="form-control"
                                id="purprice"
                               
                                value={purchasePrice}
                                onChange={(e) =>
                                  setPurchasePrice(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label >Sales Price</label>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <input
                                type="text"
                                className="form-control mr-1"
                                value="INR"
                                
                              />
                              <input
                                type="text"
                                name="salesprice"
                                className="form-control"
                                id="saleprice"
                               
                                value={salesPrice}
                                onChange={(e) => setSalesPrice(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label
                            for="purchaseAccount"
                           
                          >
                            Account
                          </label>
                          <div className="d-flex align-items-center">
                            <select
                              name="pur_account"
                              className="form-control"
                              
                              id="purchaseAccount"
                              value={purchaseAccount}
                              onChange={(e) =>
                                setPurchaseAccount(e.target.value)
                              }
                            >
                              <option value="" selected disabled>
                                --Choose--
                              </option>
                              {accounts &&
                                accounts.map((i) => (
                                  <option
                                    value={i.account_name}
                                    className="text-uppercase"
                                  >
                                    {i.account_name}
                                  </option>
                                ))}
                            </select>
                              <button
                                type="button"
                                className="btn btn-outline-secondary ml-1"
                                data-toggle="modal"
                                data-dismiss="modal"
                                data-target="#createNewAccount"
                               
                              >
                                +
                              </button>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label for="salesAccount">
                            Account
                          </label>
                          <select
                            name="sale_account"
                            className="form-control"
                           
                            id="salesAccount"
                            value={salesAccount}
                            onChange={(e) => setSalesAccount(e.target.value)}
                          >
                            <option value="" selected disabled>
                              --Choose--
                            </option>
                            <option value="General Income">
                              General Income
                            </option>
                            <option value="Interest Income">
                              Interest Income
                            </option>
                            <option value="Late Fee Income">
                              Late Fee Income
                            </option>
                            <option value="Discount Income">
                              Discount Income
                            </option>
                            <option value="Shipping Charges">
                              Shipping Charges
                            </option>
                            <option value="Other Charges">Other Charges</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label
                            for="purchaseDescription"
                          
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            name="pur_desc"
                            id="purchaseDescription"
                           
                            value={purchaseDescription}
                            onChange={(e) =>
                              setPurchaseDescription(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-6 mt-3">
                          <label
                            for="salesDescription"
                           
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            name="sale_desc"
                            id="salesDescription"
                           
                            value={salesDescription}
                            onChange={(e) =>
                              setSalesDescription(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="row" id="inventorytrack">
                        <div className="col-md-6 mt-3">
                          <label >
                            Inventory Account
                          </label>
                          <select
                            name="invacc"
                            className="form-control"
                            
                            required
                            value={inventoryAccount}
                            onChange={(e) =>
                              setInventoryAccount(e.target.value)
                            }
                          >
                            <option selected disabled value="">
                              Choose...
                            </option>
                            <option value="Inventory Assets">
                              Inventory Assets
                            </option>
                          </select>
                        </div>
                        <div className="col-md-3 mt-3">
                          <label >
                            Stock on hand
                          </label>
                          <input
                            type="number"
                            name="stock"
                            className="form-control"
                          
                            value={stock}
                            onChange={(e) => setStock(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label >
                            Stock Rate per Unit
                          </label>
                          <input
                            type="number"
                            name="stock_rate"
                            className="form-control"
                           
                            value={stockUnitRate}
                            onChange={(e) => setStockUnitRate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <label >
                            Minimum Stock to maintain
                          </label>
                          <input
                            type="number"
                            name="min_stock"
                            className="form-control"
                           
                            value={minStock}
                            onChange={(e) => setMinStock(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row mt-4 w-100">
                        <div className="col-4"></div>
                        <div className="col-4 d-flex justify-content-center">
                          <button
                            className="btn btn-outline-secondary text-grey w-75"
                            onClick={handleItemModalSubmit}
                            data-dismiss="modal"
                            type="button"
                            id="newItemSave"
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-4"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="newPaymentTerm">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="m-3">New Payment Term</h5>
            <button
              type="button"
              className="close"
              id="termModalDismiss"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body w-100">
            <div className="card p-3">
              <form id="newTermForm" onClick={handleTermModalSubmit}>
                <div className="row mt-2 w-100">
                  <div className="col-6">
                    <label htmlFor="termName">Term Name</label>
                    <input
                      type="text"
                      name="term_name"
                      id="termName"
                      value={newTermName}
                      onChange={(e) => setNewTermName(e.target.value)}
                      className="form-control w-100"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="termDays">Days</label>
                    <input
                      type="number"
                      name="days"
                      id="termDays"
                      value={newTermDays}
                      onChange={(e) => setNewTermDays(e.target.value)}
                      className="form-control w-100"
                      min="0"
                      step="1"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-4 w-100">
                  <div className="col-4"></div>
                  <div className="col-4 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-secondary text-grey w-75"
                      type="button"
                      id="savePaymentTerm"
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-4"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>


     {/* customer payment term*/}

    <div className="modal fade" id="newPaymentTermcustomer">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="m-3">New Payment Term</h5>
            <button
              type="button"
              className="close"
              id="termModalDismiss"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body w-100">
            <div className="card p-3">
              <form id="newTermForm" onClick={handleTermModalSubmit}>
                <div className="row mt-2 w-100">
                  <div className="col-6">
                    <label htmlFor="termName">Term Name</label>
                    <input
                      type="text"
                      name="term_name"
                      id="termName"
                      value={newTermName}
                      onChange={(e) => setNewTermName(e.target.value)}
                      className="form-control w-100"
                      required
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="termDays">Days</label>
                    <input
                      type="number"
                      name="days"
                      id="termDays"
                      value={newTermDays}
                      onChange={(e) => setNewTermDays(e.target.value)}
                      className="form-control w-100"
                      min="0"
                      step="1"
                      required
                    />
                  </div>
                </div>
                <div className="row mt-4 w-100">
                  <div className="col-4"></div>
                  <div className="col-4 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-secondary text-grey w-75"
                      type="button"
                      id="savePaymentTerm"
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-4"></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

     {/* <!-- Item Unit Create Modal --> */}

     <div className="modal fade" id="createNewUnit">
        <div className="modal-dialog">
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="m-3">New Item Unit</h5>
              <button
                type="button"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                data-target="#newItem"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form
                  onSubmit={handleUnitModalSubmit}
                  id="newUnitForm"
                  className="px-1"
                >
                  <div className="row mt-2 w-100">
                    <div className="col-12">
                      <label for="name">Unit Name</label>
                      <input
                        name="name"
                        id="unit_name"
                        value={newUnit}
                        onChange={(e) => setNewUnit(e.target.value)}
                        className="form-control text-uppercase w-100"
                      />
                    </div>
                  </div>
                  <div className="row mt-4 w-100">
                    <div className="col-12 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-info text-grey"
                        type="submit"
                        data-toggle="modal"
                        data-target="#newItem"
                        onClick={handleUnitModalSubmit}
                        id="saveItemUnit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

 

                      
                       <div className="container-fluid" style={{ marginLeft: "8px" }}>
  <div className="row">
    {/* Left Side - Note Section */}
    <div className="col-md-4">
      <div className="form-group">
        <label htmlFor="note">Note</label>
        <textarea
          className="form-control"
          id="note"
          name="note"
          value={formData.note}
          onChange={handleChange}
          rows="5"
          style={{
            backgroundColor: "white",
            border: "1px solid #ced4da",
            padding: "10px",
          }}
        />
      </div>
    </div>

    {/* Right Side - Paid and Balance Details */}
<div className="col-md-8">
  <div
    className="table-responsive-md mt-3"
    id="salesOrderItemsTablePaid"
    style={{
      backgroundColor: "#A9A9A9",
      border: "1px solid rgba(128, 128, 128, 0.6)",
      padding: "20px",
    }}
  >
    {/* Sub Total */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Sub Total</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          step="any"
          name="subtotal"
          value={subTotal}
          readOnly
          className="form-control"
          style={{ backgroundColor: "white" }}
          id="sub_total"
        />
      </div>
    </div>

    {showIGST && (
  <div className="row container-fluid p-2 m-0">
    <div className="col-sm-4 mt-2 text-right">
      <label>IGST</label>
    </div>
    <div className="col-sm-1 mt-2 text-center">:</div>
    <div className="col-sm-7 mt-2">
      <input
        type="number"
        name="igst"
        step="any"
        id="igstAmount"
        value={Number(taxAmount).toFixed(2)}  // Calculate IGST
        readOnly
        className="form-control"
        style={{ backgroundColor: "white" }}
      />
    </div>
  </div>
)}

{showCGSTSGST && (
  <>
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>CGST</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          name="cgst"
          step="any"
          id="cgstAmount"
          value={(Number(taxAmount) / 2).toFixed(2)}  // Calculate CGST
          readOnly
          className="form-control"
          style={{ backgroundColor: "white" }}
        />
      </div>
    </div>

    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>SGST</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          name="sgst"
          step="any"
          id="sgstAmount"
          value={(Number(taxAmount) / 2).toFixed(2)}  // Calculate SGST
          readOnly
          className="form-control"
          style={{ backgroundColor: "white" }}
        />
      </div>
    </div>
  </>
)}

    {/* Tax Amount */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Tax Amount</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          step="any"
          name="taxamount"
          id="tax_amount"
          value={Number(taxAmount).toFixed(2)}  // Ensure taxAmount is a number
          readOnly
          className="form-control"
          style={{ backgroundColor: "white" }}
        />
      </div>
    </div>

    {/* Shipping Charge */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Shipping Charge</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          step="any"
          name="ship"
          id="ship"
          value={shippingCharge}
          onChange={(e) => handleShippingCharge(e.target.value)}
          className="form-control"
        />
      </div>
    </div>

    {/* Adjustment */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Adjustment</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          step="any"
          name="adj"
          id="adj"
          value={adjustment}
          onChange={(e) => handleAdjustment(e.target.value)}
          className="form-control"
        />
      </div>
    </div>

    {/* Grand Total */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Grand Total</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          name="grandtotal"
          id="grandtotal"
          value={grandTotal}
          readOnly
          className="form-control"
          style={{ backgroundColor: "white" }}
        />
      </div>
    </div>
  </div>

  {/* Separate Box for Paid Off and Balance */}
  <div
    className="table-responsive-md mt-3"
    style={{
      backgroundColor: "#A9A9A9",
      border: "1px solid rgba(128, 128, 128, 0.6)",
      padding: "20px",
    }}
  >
    {/* Paid Off */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Paid Off</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          step="any"
          name="advance"
          id="advance"
          value={paid}
          onChange={(e) => handlePaid(e.target.value)}
          className="form-control"
        />
      </div>
    </div>

    {/* Balance */}
    <div className="row container-fluid p-2 m-0">
      <div className="col-sm-4 mt-2 text-right">
        <label>Balance</label>
      </div>
      <div className="col-sm-1 mt-2 text-center">:</div>
      <div className="col-sm-7 mt-2">
        <input
          type="number"
          name="balance"
          id="balance"
          value={balance}
          readOnly
          className="form-control"
          style={{ backgroundColor: "white" }}
        />
      </div>
    </div>
  </div>
</div>

 




    {/* Note and File Upload - Aligned Below the Balance Section */}
    <div className="row mt-3"style={{ marginLeft: "8px" }}>
    
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="file">Upload File</label>
          <input
            type="file"
            className="form-control"
            id="file"
            name="file"
            onChange={handleFileChange}
          />
        </div>
      </div>
    </div>
  </div>
</div>


                      
 
  </div>
  <div className="col-md-11 d-flex justify-content-end align-items-center" style={{ paddingBottom: "30px" }}>
  <input
    type="button"
    className="btn btn-outline-secondary mr-2"
    onClick={handleSaveDraft}
   

    value="Draft"
    style={{ height: "fit-content", width: "150px" }} 
  />
  <input
    type="submit"
    className="btn btn-outline-secondary"
    onClick={() => setStatus("Saved")}
    value="Save"
    style={{ height: "fit-content", width: "150px" }} 
  />
</div>
</form>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
          
        
     
     

    



  );
}

export default Addsalesorder;
