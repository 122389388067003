import React, { useEffect, useState } from "react";
import { Link, useNavigate,useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import "../styles/salarydetails.css";

import Navbar from "../Navbar";
import Sidebar from "../Sidebar";


function View_salarydetails(){
    const ID = Cookies.get("user_id");
    const user = Cookies.get("User");
    const { salaryId } = useParams();
    const [salaryDetails, setSalaryDetails] = useState({});
    const [otherDetails, setOtherDetails] = useState({});
    const [comments, setComments] = useState([]);
    const [history, setHistory] = useState({
      action: "",
      date: "",
      doneBy: "",
    });
  
    const [fileUrl, setFileUrl] = useState(null);
    const fetchSalaryDetails = () => {
        axios
          .get(`${config.base_url}/fetch_salary_details/${salaryId}/`)
          .then((res) => {
            console.log("EXP DATA=", res);
            if (res.data.status) {
              var salary = res.data.salary;
              var hist = res.data.history;
              var cmt = res.data.comments;
           
              var other = res.data.otherDetails;
              if (salary.file) {
                var url = `${config.base_url}/${salary.file}`;
                setFileUrl(url);
              }
    
              setOtherDetails(other);
           
              setComments([]);
             
              cmt.map((c) => {
                setComments((prevState) => [...prevState, c]);
              });
              setSalaryDetails(salary);
              if (hist) {
                setHistory(hist);
              }
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      };
    
      useEffect(() => {
        fetchSalaryDetails();
      }, []);
    
      const currentUrl = window.location.href;
      const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        currentUrl
      )}`;
    
      const navigate = useNavigate();

      function handleConvertSalarydetails() {
        Swal.fire({
          title: `Convert salary Details - ${otherDetails.EmployeeName}?`,
          text: "Are you sure you want to convert this.!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#3085d6",
          confirmButtonColor: "#d33",
          confirmButtonText: "Convert",
        }).then((result) => {
          if (result.isConfirmed) {
            var st = {
              id: salaryId,
            };
            axios
              .post(`${config.base_url}/change_salarydetails_status/`, st)
              .then((res) => {
                if (res.data.status) {
                  Toast.fire({
                    icon: "success",
                    title: "Converted",
                  });
                  fetchSalaryDetails();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      }
      function handleDeleteSalarydetails(id) {
        Swal.fire({
          title: `Delete Salary Details - ${otherDetails.EmployeeName}?`,
          text: "Data cannot be restored.!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#3085d6",
          confirmButtonColor: "#d33",
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`${config.base_url}/delete_salarydetails/${id}/`)
              .then((res) => {
                console.log(res);
    
                Toast.fire({
                  icon: "success",
                  title: "Salary Details Deleted.",
                });
                navigate("/salarydetails");
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      }
      function overview() {
        document.getElementById("overview").style.display = "block";
        document.getElementById("template").style.display = "none";
        document.getElementById("printBtn").style.display = "none";
        document.getElementById("pdfBtn").style.display = "none";
        document.getElementById("shareBtn").style.display = "none";
        document.getElementById("editBtn").style.display = "block";
        document.getElementById("deleteBtn").style.display = "block";
        document.getElementById("historyBtn").style.display = "block";
        document.getElementById("commentBtn").style.display = "block";
        if (salaryDetails.status == "draft") {
          document.getElementById("statusBtn").style.display = "block";
        }
        document.getElementById("overviewBtn").style.backgroundColor =
          "gray";
        document.getElementById("templateBtn").style.backgroundColor =
          "transparent";
        
      }
      function payslip() {
        document.getElementById("overview").style.display = "none";
        document.getElementById("template").style.display = "block";
        document.getElementById("printBtn").style.display = "block";
        document.getElementById("pdfBtn").style.display = "block";
        document.getElementById("shareBtn").style.display = "block";
        document.getElementById("editBtn").style.display = "none";
        document.getElementById("deleteBtn").style.display = "none";
        document.getElementById("historyBtn").style.display = "none";
        document.getElementById("commentBtn").style.display = "none";
       
        if (salaryDetails.status == "draft") {
          document.getElementById("statusBtn").style.display = "none";
        }
        document.getElementById("overviewBtn").style.backgroundColor =
          "transparent";
        document.getElementById("templateBtn").style.backgroundColor =
          "gray";
         }

         function printSheet() {
            var divToPrint = document.getElementById("whatToPrints");
            var printWindow = window.open("", "", "height=700,width=1000");
        
            printWindow.document.write("<html><head><title></title>");
            printWindow.document.write(`
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
            `);
            printWindow.document.write("</head>");
            printWindow.document.write("<body>");
            printWindow.document.write(divToPrint.outerHTML);
            printWindow.document.write("</body>");
            printWindow.document.write("</html>");
            printWindow.document.close();
            printWindow.print();
            printWindow.addEventListener("afterprint", function () {
              printWindow.close();
            });
          }

          const [comment, setComment] = useState("");
          const savesalarydetailsComment = (e) => {
            e.preventDefault();
            var cmt = {
              Id: ID,
              salary_details: salaryId,
              comments: comment,
            };
            axios
              .post(`${config.base_url}/add_salarydetails_comment/`, cmt)
              .then((res) => {
                console.log(res);
                if (res.data.status) {
                  Toast.fire({
                    icon: "success",
                    title: "Comment Added",
                  });
                  setComment("");
                  fetchSalaryDetails();
                }
              })
              .catch((err) => {
                console.log("ERROR=", err);
                if (!err.response.data.status) {
                  Swal.fire({
                    icon: "error",
                    title: `${err.response.data.message}`,
                  });
                }
              });
          };  

          function deleteComment(id) {
            Swal.fire({
              title: "Delete Comment?",
              text: "Are you sure you want to delete this.!",
              icon: "warning",
              showCancelButton: true,
              cancelButtonColor: "#3085d6",
              confirmButtonColor: "#d33",
              confirmButtonText: "Delete",
            }).then((result) => {
              if (result.isConfirmed) {
                axios
                  .delete(`${config.base_url}/delete_salarydetails_comment/${id}/`)
                  .then((res) => {
                    console.log(res);
        
                    Toast.fire({
                      icon: "success",
                      title: "Comment Deleted",
                    });
                    fetchSalaryDetails();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            });
          }
        
          function salarydetailsPdf() {
            var data = {
              Id: ID,
              sal_id: salaryId,
            };
            axios
              .get(`${config.base_url}/salarydetails_pdf/`, {
                responseType: "blob",
                params: data,
              })
              .then((res) => {
                console.log("PDF RES=", res);
        
                const file = new Blob([res.data], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                const a = document.createElement("a");
                a.href = fileURL;
                a.download = `Payslip_${otherDetails.EmployeeName}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              })
              .catch((err) => {
                console.log("ERROR=", err);
                if (err.response && err.response.data && !err.response.data.status) {
                  Swal.fire({
                    icon: "error",
                    title: `${err.response.data.message}`,
                  });
                }
              });
          }

          const [emailIds, setEmailIds] = useState("");
          const [emailMessage, setEmailMessage] = useState("");
        
          function handleShareEmail(e) {
            e.preventDefault();
        
            var emailsString = emailIds.trim();
        
            var emails = emailsString.split(",").map(function (email) {
              return email.trim();
            });
        
            var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        
            var invalidEmails = [];
            if (emailsString === "") {
              alert("Enter valid email addresses.");
            } else {
              for (var i = 0; i < emails.length; i++) {
                var currentEmail = emails[i];
        
                if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
                  invalidEmails.push(currentEmail);
                }
              }
        
              if (invalidEmails.length > 0) {
                alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
              } else {
                // document.getElementById("share_to_email_form").submit();
                var em = {
                  exp_id: salaryId,
                  Id: ID,
                  email_ids: emailIds,
                  email_message: emailMessage,
                };
                axios
                  .post(`${config.base_url}/share_salarydetails_email/`, em)
                  .then((res) => {
                    if (res.data.status) {
                      Toast.fire({
                        icon: "success",
                        title: "Shared via mail.",
                      });
                      setEmailIds("");
                      setEmailMessage("");
                    }
                  })
                  .catch((err) => {
                    console.log("ERROR=", err);
                    if (
                      err.response &&
                      err.response.data &&
                      !err.response.data.status
                    ) {
                      Swal.fire({
                        icon: "error",
                        title: `${err.response.data.message}`,
                      });
                    }
                  });
              }
            }
          }
        
    
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
    
    return(
        <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper d-flex">
          <Sidebar />
          <div
        className="main-panel px-3 py-2"
        style={{ background: "#a9a9a961", maxWidth: "100vw" }}
      >
        <Link
          className="d-flex justify-content-end p-2"
          style={{ cursor: "pointer" }}
          to="/salarydetails"
        >
          <i
            className="fa fa-times-circle text-white"
            style={{ fontSize: "1.2rem" }}
          ></i>
        </Link>
        <div className="card radius-15 mt-3">
                  <div className="card-body" style={{ width: "100%" }}>
                    <div className="card-title">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 col-md-6 mb-2">
                                  <a
                                    style={{
                                      padding: "10px",
                                      cursor: "pointer",
                                      borderRadius: "1vh",
                                    }}
                                    onClick={overview}
                                    id="overviewBtn"
                                  >
                                    Overview
                                  </a>
                                  <a
                                    style={{
                                      padding: "10px",
                                      cursor: "pointer",
                                      borderRadius: "1vh",
                                    }}
                                    onClick={payslip}
                                    id="templateBtn"
                                  >
                                    Payslip
                                  </a>
                                  
                                </div>

                                <div className="col-12 col-md-6 d-md-flex justify-content-end mb-2">
                                  {salaryDetails.status == "draft" ? (
                                    <a
                                      onClick={handleConvertSalarydetails}
                                      id="statusBtn"
                                      style={{
                                        display: "block",
                                        height: "fit-content",
                                        width: "fit-content",
                                      }}
                                      className="ml-2 fa fa-check btn btn-outline-secondary text-grey "
                                      role="button"
                                    >
                                      &nbsp;Convert
                                    </a>
                                  ) : null}
                                  <a
                                    onClick={salarydetailsPdf}
                                    className="ml-2 btn btn-outline-secondary text-grey fa fa-file"
                                    role="button"
                                    id="pdfBtn"
                                    style={{
                                      display: "none",
                                      height: "fit-content",
                                      width: "fit-content",
                                    }}
                                  >
                                    &nbsp;PDF
                                  </a>
                                 
                                  <a
                                    className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                                    role="button"
                                    id="printBtn"
                                    style={{
                                      display: "none",
                                      height: "fit-content",
                                      width: "fit-content",
                                    }}
                                    onClick={() => printSheet()}
                                  >
                                    &nbsp;Print
                                  </a>

                                 
                                  <div
                                    className="dropdown p-0 nav-item"
                                    id="shareBtn"
                                    style={{ display: "none" }}
                                  >
                                    <li
                                      className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                                      data-toggle="dropdown"
                                      style={{
                                        height: "fit-content",
                                        width: "fit-content",
                                      }}
                                    >
                                      &nbsp;Share
                                    </li>
                                    <ul
                                      className="dropdown-menu"
                                      style={{ backgroundColor: "black" }}
                                      id="listdiv"
                                    >
                                      <a
                                        href={shareUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <li
                                          style={{
                                            textAlign: "center",
                                            color: "#e5e9ec",
                                            cursor: "pointer",
                                          }}
                                        >
                                          WhatsApp
                                        </li>
                                      </a>
                                      <li
                                        style={{
                                          textAlign: "center",
                                          color: "#e5e9ec",
                                          cursor: "pointer",
                                        }}
                                        data-toggle="modal"
                                        data-target="#shareToEmail"
                                      >
                                        Email
                                      </li>
                                    </ul>
                                  </div>
                                  <Link
                                    to={`/edit_salarydetails/${salaryId}/`}
                                    className="ml-2 fa fa-pencil btn btn-outline-secondary text-grey"
                                    id="editBtn"
                                    role="button"
                                    style={{ height: "fit-content", width: "fit-content" }}
                                  >
                                    &nbsp;Edit
                                  </Link>
                                  <a
                                    className="ml-2 btn btn-outline-secondary text-grey fa fa-trash"
                                    id="deleteBtn"
                                    role="button"
                                    onClick={() =>
                                        handleDeleteSalarydetails(salaryId)
                                    }
                                    style={{ height: "fit-content", width: "fit-content" }}
                                  >
                                    &nbsp;Delete
                                  </a>
                                  <a
                                    href="#"
                                    className="ml-2 btn btn-outline-secondary text-grey fa fa-comments"
                                    id="commentBtn"
                                    role="button"
                                    style={{
                                      display: "block",
                                      height: "fit-content",
                                      width: "fit-content",
                                    }}
                                    data-toggle="modal"
                                    data-target="#commentModal"
                                  >
                                    &nbsp;Comment
                                  </a>
                                  
                                  <Link
                                    to={`/salarydetails_history/${salaryId}/`}
                                    className="ml-2 btn btn-outline-secondary text-grey fa fa-history"
                                    id="historyBtn"
                                    role="button"
                                    style={{ height: "fit-content", width: "fit-content" }}
                                  >
                                    &nbsp;History
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <center>
                              <h3
                                className="card-title mt-3"
                                style={{ textTransform: "Uppercase" }}
                              >
                                SALARY DETAILS OVERVIEW
                              </h3>
                            </center>
                          </div>
                        </div>
                      </div>
                      
                      <div
                       className="card card-registration card-registration-2 mt-3"
                      style={{ borderRadius: "15px" }}
             >
          <div className="card-body p-0">
            <div id="overview">
              <div
                className="row g-0"
                style={{ marginLeft: "1px", marginRight: "1px" }}
              >
                <div className="col-lg-8">
                  <div className="history_highlight px-4 pt-4 d-flex">
                    <div className="col-8 d-flex justify-content-start">
                      {history.action == "Created" ? (
                        <p
                          className="text-success"
                          style={{ fontSize: "1.07rem", fontWeight: "500" }}
                        >
                          Created by :
                        </p>
                      ) : (
                        <p
                          className="text-warning"
                          style={{ fontSize: "1.07rem", fontWeight: "500" }}
                        >
                          Last Edited by :
                        </p>
                      )}
                      <span
                        className="ml-2"
                        style={{ fontSize: "1.15rem", fontWeight: "500" }}
                      >
                        {history.doneBy}
                      </span>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      <span>{history.date}</span>
                    </div>
                  </div>
                  <div className="pb-3 px-2">
                    <div className="card-body">
                      <div className="card-title">
                        <div className="row">
                          <div className="col mt-3">
                            <h2 className="mb-0">
                               {otherDetails.EmployeeName}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="row mb-3 d-flex justify-content-between align-items-center">
                        <div className="col-md-4 mt-3">
                          
                        </div>
                        <div className="col-md-3 mt-3"></div>
                        <div className="col-md-3 mt-3"></div>

                        <div className="col-md-3 mt-3">
                          <h6 className="mb-0">Employee ID</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.employee_id}
                          </p>
                        </div>
                        <div className="col-md-3 mt-3 vl">
                          <h6 className="mb-0">Designation</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.designation}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 d-flex justify-content-between align-items-center">
                        <div className="col-md-4 mt-3">
                          
                        </div>
                        <div className="col-md-3 mt-3"></div>
                        <div className="col-md-3 mt-3"></div>

                        <div className="col-md-3 mt-3">
                          <h6 className="mb-0">Age</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.age}
                          </p>
                        </div>
                        <div className="col-md-3 mt-3 vl">
                          <h6 className="mb-0">Date of joining</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.DOJ}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 d-flex justify-content-between align-items-center">
                        <div className="col-md-4 mt-3">
                          
                        </div>
                        <div className="col-md-3 mt-3"></div>
                        <div className="col-md-3 mt-3"></div>

                        <div className="col-md-3 mt-3">
                          <h6 className="mb-0">Contact</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.Contact}
                          </p>
                        </div>
                        <div className="col-md-3 mt-3 vl">
                          <h6 className="mb-0">Email</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.EmployeeEmail}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-3 d-flex justify-content-between align-items-center">
                        <div className="col-md-3 mt-3">
                          <h6 className="mb-0">Holidays</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                            {salaryDetails.holiday}
                          </p>
                        </div>
                        <div className="col-md-3 mt-3 vl">
                          <h6 className="mb-0">Leave</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                          {salaryDetails.leave}

                          </p>
                        </div>
                        </div>
                        
                      <div className="row mb-3 d-flex justify-content-between align-items-center">
                        <div className="col-md-4 mt-3">
                          
                        </div>
                        <div className="col-md-3 mt-3"></div>
                        <div className="col-md-3 mt-3"></div>

                        <div className="col-md-3 mt-3">
                          <h6 className="mb-0">Working Days</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                          {salaryDetails.total_working_days}
                          </p>
                        </div>
                        <div className="col-md-3 mt-3 vl">
                          <h6 className="mb-0">Casual Leave</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                          {salaryDetails.casual_leave}
                          </p>
                        </div>
                      </div>
                      
                      
                       <div className="row mb-3 d-flex justify-content-between align-items-center">
                        <div className="col-md-3 mt-3">
                          <h6 className="mb-0">Salary</h6>
                        </div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0 text-right">
                          {salaryDetails.total_salary}
                          </p>
                        </div>
                        <div className="col-md-3 mt-3 "></div>
                        <div className="col-md-3 mt-3"></div>
                        </div>
                        </div>
                      </div>
                      </div>
                      
                <div
                  className="col-md-4"
                  style={{
                    backgroundColor: "#a9a9a961",
                    borderTopRightRadius: "2vh",
                    borderBottomRightRadius: "2vh",
                  }}
                >
                  <div className="px-3 py-4">
                    <h4 className="fw-bold mb-2 mt-4 pt-1">Salary Details</h4>
                    <hr className="my-4" />
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Status</h6>
                      {salaryDetails.status == "draft" ? (
                        <span className="text-info h5 font-weight-bold">
                          DRAFT
                        </span>
                      ) : (
                        <span className="text-success h5 font-weight-bold">
                          SAVED
                        </span>
                      )}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Basic Salary</h6>
                      {salaryDetails.basic_salary}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                    <h6 className="">Salary Date</h6>
                      {salaryDetails.salary_date}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Month-Year</h6>
                      {salaryDetails.month}-  {salaryDetails.year}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Add Bonous</h6>
                      {salaryDetails.add_bonus}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Other Cuttings</h6>
                      {salaryDetails.other_cuttings}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">HRA</h6>
                      {salaryDetails.hra}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Conveyance Allowance</h6>
                      {salaryDetails.conveyance_allowance}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Other Allowance</h6>
                      {salaryDetails.other_allowance}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
     


    <div id="template" className="container1" style={{display: "none"}}>
            <div id="printContent">
                    
                <div id="whatToPrints" className="print-onlys">
                    <div className="page pagesizea4" size="A4" >
                        

                        <h1>PAYSLIP</h1>
                     
                        <div className="employee-details">
                            <div>
                                <ul>                                   
                                    
                                    <li><span>Employee Name</span><span>:</span><span> {otherDetails.EmployeeName}</span></li>
                                    <li><span>Employee Code</span><span>:</span><span>  {otherDetails.employee_id}</span></li>
                                    <li><span>Month-Year</span><span>:</span><span>{salaryDetails.month}-  {salaryDetails.year}</span></li>
                                    <li><span>Designation</span><span>:</span><span>{otherDetails.designation}</span></li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                                                       
                                    <li><span>Location</span><span>:</span><span>{otherDetails.City}</span> </li>
                                    <li><span>Date of Joining</span><span>:</span><span> {otherDetails.DOJ}</span></li>
                                    <li><span>Working Days</span><span>:</span><span>{salaryDetails.total_working_days}</span> </li>
                                    <li><span>Leave</span><span>:</span> {salaryDetails.leave}<span>
                                      </span> </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="salary-section">
                            <h3>Confirmed Salary</h3>
                            
                            <table className="table salary-table ">
                                <thead>
                                    <tr style={{borderRight: "1px solid #161515"}}>
                                        <th className="pay" style={{ color:"#161515" }} >Particulars</th>
                                        <th className="pay" style={{ color:"#161515" }}>Gross Amount (Rs)</th>
                                        <th className="pay" style={{ color:"#161515" }}>Deduction Amount (Rs)</th>
                                        <th className="pay"  style={{ color:"#161515" }}>Amount (Rs)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className="pay">Basic Salary</p>
                                            <p className="pay">Conveyance Allowance</p>
                                            <p className="pay">HRA</p>
                                            <p className="pay">Other Allowance</p>
                                            <p className="pay">
                                              Bonus</p>
                                        </td>
                                        <td >
                                            <p className="pay">{salaryDetails.basic_salary}</p>
                                            <p className="pay"> {salaryDetails.conveyance_allowance}</p>
                                            <p className="pay">{salaryDetails.hra}</p>
                                            <p className="pay">{salaryDetails.other_allowance}</p>
                                            <p className="pay">{salaryDetails.add_bonus}</p>
                                        </td>
                                        
                                        <td>
                                            <p className="pay" style={{ height: '50px',color:"#161515" }}>Leave Deduction</p>
                                            <p className="pay" style={{ height: '50px',color:"#161515" }}>Other Cuttings</p>

                                        </td>
                                        <td>
                                            <p className="pay" style={{ height: '50px',color:"#161515" }}>{salaryDetails.leave_deduction}</p>
                                            <p className="pay" style={{ height: '50px',color:"#161515" }}>{salaryDetails.other_cuttings}</p>
                                        </td>
                                    </tr>
        
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td className="pay"style={{ height: '50px',color:"#161515" }}>Total Deduction</td>
                                        <td className="pay" style={{ height: '50px',color:"#161515" }}>{salaryDetails.Total_deduction}</td>
                                    </tr>
                                    <tr>
                                        <td className="pay" colspan="3" style={{ height: '50px',color:"#161515" }}>Net salary</td>
                                     
                                        <td className="pay"  style={{ height: '50px',color:"#161515" }}>{salaryDetails.total_salary}</td>
                                    </tr>
                                </tbody>
                            </table>
                         
                       
                        </div>
                        <div className="authorization-section">
                            <div class="authorization-seal">
                                <img src="" alt="Company Seal" style={{maxWidth:"150px",color:" #161515"}}/>
                            </div>
                            <div className="authorization-sign">
                                <div style={{display:"flex",justifyContent:"flex-end"}}>
                                    <img src="" alt="Authorized Signature" />
                                </div>
                                {/* <!-- <p>Authorized Signature</p> --> */}
                            </div>
                        </div>
                    </div>
                </div>
        
            </div> 
        </div>


             
            
          </div>
        </div>
      </div>
      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="m-3">Share Salary Details</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Salary details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add Comments Modal --> */}
      <div
        className="modal fade"
        id="commentModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content" >
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Add Comments
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form  
            onSubmit={savesalarydetailsComment}  
            className="px-1">
              <div className="modal-body w-100">
                <textarea
                  type="text"
                  className="form-control"
                  name="comment"
                  value={comment}
                  required
                  onChange={(e) => setComment(e.target.value)}
                />
                {comments.length > 0 ? (
                  <div className="container-fluid">
                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th className="text-center">sl no.</th>
                          <th className="text-center">Comment</th>
                          <th className="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {comments.map((c, index) => (
                          <tr className="table-row">
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{c.comments}</td>
                            <td className="text-center">
                              <a
                                className="text-danger"
                                onClick={() => deleteComment(`${c.id}`)}
                              >
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    fontSize: "1.1rem",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="my-2 font-weight-bold d-flex justify-content-center">
                    No Comments.!
                  </span>
                )}
              </div>

              <div className="modal-footer w-100">
                <button
                  type="button"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn"
                  id="commentSaveBtn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

     
          
          </div>
          </div>

    );
}
export default View_salarydetails;