import React, { useState, useRef, useEffect } from "react";
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import '../styles/Sales.css'
import axios from "axios";
import config from "../../../functions/config";
import Cookies from "js-cookie";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

function SalesByCustomer() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [companyName, setCompanyName] = useState("COMPANY NAME");
    const [salesData, setSalesData] = useState("");
    const [otherData, setOtherData] = useState("")
    const [showModal, setShowModal] = useState(false);
    
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [trans, settrans] = useState('');
  const [report, setReport] = useState("");
  const buttonRef = useRef(null);  // Create a reference to the button
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  // Function to handle opening modal and setting its position
  const handleOpenModal = (event) => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    setModalPosition({
      top: buttonRect.top + window.scrollY, // Adjust 20px down from the button
      left: buttonRect.left + window.scrollX,    // Align to the left of the button
    });
    setShowModal(true);
  };

  const fetchSalesByCustomReport = () => {
    axios
      .get(`${config.base_url}/get_customers_by_company_or_user/${ID}/`)
      .then((res) => {
        console.log("CUST REPRT DATA=", res);
        if (res.data.status) {
          setSalesData(res.data.filtered);
          setOtherData(res.data.others);
          setStartDate("");
          setEndDate("");
          setReport("");
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };
  

  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      report: report,
      transaction: trans,
    };

    axios
      .post(`${config.base_url}/sales_by_customer_report_customized/`, det)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setSalesData(res.data.filtered);
          setOtherData(res.data.others);
          setReport(res.data.report);

            setShowModal(false)

        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        
          Swal.fire({
            icon: "error",
            title: `${err.response}`,
          });
        
      });
  };

  const handleCancel = () => {
    setShowModal(false); // Close the modal on cancel
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const ID = Cookies.get("user_id");

  function fetchCompanyDetails() {
    axios
      .get(`${config.base_url}/check_payment_term/${ID}/`)
      .then((res) => {
        console.log("HOME RESPONSE==", res);
          setCompanyName(res.data.companyName);
      })
      .catch((err) => {
        console.log("HOME ERROR==", err);
      });
  }

  useEffect(() => {
    fetchCompanyDetails();
    fetchSalesByCustomReport()
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Report_SalesByCustomer" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Report_SalesByCustomer" + date + ".xlsx");
  }

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function convertPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Report_SalesByItem" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
  
    // Generate PDF as Blob
    return html2pdf().set(opt).from(element).toPdf().output('blob');
  }

  function handleShareEmail(e) {
    e.preventDefault();
  
    var st = startDate;
    var end = endDate;
    var rpt = report;
  
    if ((st != "" && end == "") || (st == "" && end != "")) {
      Swal.fire({
        icon: "warning",
        title: "Please select both date inputs or select none",
      });
      return;
    } else {
      var emailsString = emailIds.trim();
      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });
  
      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      var invalidEmails = [];
  
      if (emailsString === "") {
        Swal.fire({
          icon: "error",
          title: "Enter valid email addresses.",
        });
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];
          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }
  
        if (invalidEmails.length > 0) {
          Swal.fire({
            icon: "error",
            title: "Invalid emails",
            text: `Please check: ${invalidEmails.join(", ")}`,
          });
        } else {
          // Show a spinner while the request is processing
          Swal.fire({
            title: "Sending...",
            html: "Please wait while the email is being sent.",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
  
          // Generate the PDF as a Blob
          convertPDF().then((pdfBlob) => {
            var reader = new FileReader();
            reader.readAsDataURL(pdfBlob);
            reader.onloadend = function () {
              var base64data = reader.result; // Convert Blob to Base64
  
              // Prepare the payload
              var em = {
                Id: ID,
                start: st,
                end: end,
                report: rpt,
                email_ids: emailIds,
                email_message: emailMessage,
                transaction_type: trans,
                pdf_report: base64data, 
              };
  
              // Send email with PDF attachment
              axios
                .post(`${config.base_url}/share_salesByItemReport_email/`, em)
                .then((res) => {
                  Swal.close(); // Close the spinner after request completes
  
                  if (res.data.status) {
                    Swal.fire({
                      icon: "success",
                      title: "Shared via mail.",
                    });
                    setEmailIds("");
                    setEmailMessage("");
                  }
                })
                .catch((err) => {
                  Swal.close(); // Close the spinner on error
  
                  console.log("ERROR=", err);
                  if (
                    err.response &&
                    err.response.data &&
                    !err.response.data.status
                  ) {
                    Swal.fire({
                      icon: "error",
                      title: `${err.response.data.message}`,
                    });
                  }
                });
            };
          });
        }
      }
    }
  }

  let sortDirection = true; 
let lastSortedColumn = -1;

function sortTable(columnIndex) {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("reportTable");
    switching = true;

    while (switching) {
        switching = false;
        rows = table.rows;

        for (i = 1; i < rows.length - 1; i++) {
            shouldSwitch = false;
            x = rows[i].getElementsByTagName("td")[columnIndex].textContent.toLowerCase();
            y = rows[i + 1].getElementsByTagName("td")[columnIndex].textContent.toLowerCase();

            if (sortDirection ? x > y : x < y) {
                shouldSwitch = true;
                break;
            }
        }
        if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
        }
    }

    updateArrowIcons(columnIndex);
    sortDirection = !sortDirection; // Toggle the sort direction after each sort
}

function updateArrowIcons(columnIndex) {
  const arrows = document.querySelectorAll(".arrow");
  
  // Reset all arrow icons to "mdi-menu-up"
  arrows.forEach(arrow => {
      arrow.classList.remove("mdi-menu-up", "mdi-menu-down");
      arrow.classList.add("mdi-menu-up"); // Default arrow (up) for all columns
  });

  // Check if we're toggling the same column
  if (columnIndex === lastSortedColumn) {
      const arrow = arrows[columnIndex];
      // Toggle the arrow between up and down based on sortDirection
      if (arrow) {
          arrow.classList.toggle("mdi-menu-up", sortDirection); // Ascending (up)
          arrow.classList.toggle("mdi-menu-down", !sortDirection); // Descending (down)
      }
  } else {
      // If it's a new column, show the down arrow initially
      const arrow = arrows[columnIndex];
      if (arrow) {
          arrow.classList.add("mdi-menu-down"); // Default to descending for new column
      }
      lastSortedColumn = columnIndex; // Track the newly sorted column
  }
}

  return (
    <>
    <div className="container-scroller">
  <Navbar />
  <div className="container-fluid page-body-wrapper d-flex">
    <Sidebar />
    <div className="main-panel px-3 py-2" style={{ background: "#a9a9a961" }}>
      <div className="card radius-15">
        <div className="card-body">
          <div className="card-title">
            <div className="row w-100">
              <div className="col"></div>
              <div className="col text-center">
                <label
                  style={{
                    textAlign: "center",
                    fontSize: "21px",
                    textTransform: "uppercase",
                  }}
                >
                  <b>Sales By Customer</b>
                </label>
              </div>
              <div className="col"></div>
            </div>
            <hr />
            <div className="d-flex justify-content-between mt-3" >
            <>
      <button
        ref={buttonRef}  // Attach the ref to the button
        className="custom-button"
        onClick={handleOpenModal}
      >
        <i className="mdi mdi-file-document-box-outline"></i> Customize Report
      </button>

      {/* Modal */}
      {showModal && (
        <div
          className="custom-modal-overlay"
          style={{
            top: `${modalPosition.top}px`,   // Use dynamic top position
          }}
        >
          <div className="custom-modal-dialog">
            <div className="custom-modal-content">
              <div className="custom-modal-header">
                <h5>Customize Report</h5>
                <button
                  type="button"
                  className="custom-modal-close"
                  onClick={() => setShowModal(false)}
                >
                  &times;
                </button>
              </div>
              <div className="custom-modal-body">
                <form onSubmit={handleCustomize}>
                  <div className="form-group" >
                    <label style={{padding: '5px'}}>From</label>
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      required={endDate !== "" ? true : false}
                      style={{padding: '2px'}}
                    />
                  </div>
                  <div className="form-group">
                    <label style={{padding: '5px'}}>To</label>
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      required={startDate !== "" ? true : false}
                      style={{padding: '2px'}}
                    />
                  </div>
                  <div className="form-group">
                    <label style={{padding: '5px'}}>Transaction</label>
                    <select
                      value={trans}
                      onChange={(e) => settrans(e.target.value)}
                      required
                      style={{padding: '2px'}}
                    >
                      <option value="All">All</option>
                      <option value="Invoice">Invoice</option>
                      <option value="Recurring Invoice">Recurring Invoice</option>
                      <option value="Credit Note">Credit Note</option>
                    </select>
                  </div>
                  <div className="custom-modal-footer">
                    <button type="button" onClick={handleCancel}>
                      Cancel
                    </button>
                    <button type="submit">
                      Run Report
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
              <div className="ml-auto d-flex">
                <button className="btn btn-secondary mr-2" role="button"
                  id="pdfBtn"
                  onClick={reportPDF} style={{ height: '40px' }}>
                  <i className="mdi mdi-file-pdf"></i> PDF
                </button>
                <button className="btn btn-secondary mr-2" role="button"
                  id="printBtn"
                  onClick={printSection} style={{ height: '40px' }}>
                  <i className="mdi mdi-printer"></i> Print
                </button>
                <button className="btn btn-secondary mr-2" role="button"
                  id="exportBtn"
                  onClick={ExportToExcel} style={{ height: '40px' }}>
                  <i className="mdi mdi-file-export"></i> Export
                </button>
                <div className="relative inline-block">
                  <button
                    className="btn btn-secondary"
                    style={{ height: '40px' }}
                    onClick={toggleDropdown}
                    onBlur={() => setTimeout(() => setDropdownOpen(false), 200)}
                  >
                    <i className="mdi mdi-share-variant"></i> Share
                    <i className="mdi mdi-chevron-down ml-2"></i>
                  </button>
                  <div className={`dropdown-menu ${dropdownOpen ? 'show' : 'hide'}`}>
                    <a
                       href={shareUrl}
                       target="_blank"
                       rel="noopener noreferrer"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => setDropdownOpen(false)}
                    >
                      <i className="mdi mdi-whatsapp"></i> WhatsApp
                    </a>
                    <a
                      className="block px-4 py-2 text-sm hover:bg-gray-100 text-black"
                      onClick={() => setDropdownOpen(false)}
                      data-toggle="modal"
                      data-target="#shareToEmail"
                    >
                      <i className="mdi mdi-email"></i> Email
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* New Table Section */}
      <div className="main-panel w-100 px-3 py-2 mt-3" id="printReport" style={{ background: "#f0f0f0", fontWeight: "bold" }}>
        {companyName && (
          <>
          <div style={{ background: "#d3d3d3", padding: "10px", borderRadius: "5px", textAlign: 'center', fontSize: '30px'}}>
            <div>{companyName}</div> {/* First line */}
            <div><b>Sales By Customer</b></div> {/* Second line */}
          </div>
          {startDate && (
            <div style={{ background: "#d3d3d3", padding: "10px", borderRadius: "5px", textAlign: 'center', fontSize: '20px'}}>
            <div><b>{startDate} - {endDate}</b></div>
            <div><b>{trans}</b></div>
          </div>
          )}
        </>
        )}
        <div className="table-responsive mt-3">
          <table className="table table-striped" id="reportTable">
            <thead>
              <tr>
                <th>CUSTOMER
                <i className="mdi mdi-menu-up arrow" style={{fontSize: '25px'}} onClick={() => sortTable(0)}></i>
                </th>
                <th>INVOICE COUNT</th>
                <th>SALES WITH TAX</th>
              </tr>
            </thead>
            {salesData && salesData.length > 0 ? (
              <tbody>
              {salesData.map((item, index) => {
                // Conditional rendering based on selected transaction type
                let invoiceCount = 0;
                let grandTotal = 0;
            
                if (trans === "Invoice") {
                  invoiceCount = item.invoice_count;
                  grandTotal = item.invoice_grand_total || 0.00;
                } else if (trans === "Recurring Invoice") {
                  invoiceCount = item.recurring_invoice_count;
                  grandTotal = item.recurring_invoice_grand_total || 0.00;
                } else if (trans === "Credit Note") {
                  invoiceCount = item.credit_note_count;
                  grandTotal = 0.00; // Assuming grand total is not relevant for Credit Notes
                } else {
                  // For "All" or any other selection, display the total values
                  invoiceCount = item.total_invoice;
                  grandTotal = item.total_grand_total || 0.00;
                }
            
                return (
                  <tr key={index}>
                    <td>{item.first_name}</td>
                    <td>{invoiceCount}</td>
                    <td>{grandTotal}</td>
                  </tr>
                );
              })}
            </tbody>
            
            ) : (
              <p className="text-center mt-3">No data available</p>
            )}
          </table>
        </div>

        {/* Financial Summaries */}
        <div className="row" style={{ marginTop: '50px', fontSize: '15px' }}>
          {/* First column for the first three summaries */}
          <div className="col-12 col-md-3"> {/* Adjusted column size */}
            <div className="mb-3"><b>Total Customers:</b><br />{otherData.total_customers || 0}</div>
            <div className="mb-3"><b>Invoice Sale:</b><br />₹ {otherData.invoice_sale || 0}</div>
            <div className="mb-3"><b>Sub Total:</b><br />₹ {otherData.subtotal || 0}</div>
          </div>

          {/* Second column */}
          <div className="col-12 col-md-3"> {/* Adjusted column size */}
              <div className="mb-3"></div>
            <div className="mb-3"><b>Recurring Invoice Sale:</b><br />₹ {otherData.recurring_invoice_sale || 0}</div>
            <div className="mb-3"><b>Subtotal Without Credit Note:</b><br />₹ {otherData.subtotal_wc || 0}</div>
          </div>

          {/* Third column */}
          <div className="col-12 col-md-3"> {/* Adjusted column size */}
            <div className="mt-3"><b>Credit Note:</b><br />₹ {otherData.credit_note_grandtotal || 0}</div>
            <div className="mt-3"><b>Total Sale (Without Credit Note):</b><br />₹ {otherData.total_sale_wc || 0}</div>
          </div>

          {/* Fourth column for the bold and large Total Sale */}
          <div className="col-12 col-md-3" style={{ textAlign: 'right' }}> {/* Same size as others */}
            <div style={{ fontSize: "1.5em", fontWeight: "bold" }}>
              <b>Total Sale:</b><br />₹ { (otherData.total_sale || 0).toFixed(2) }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* Share To Email Modal */}
<div className="modal fade" id="shareToEmail">
  <div className="modal-dialog modal-lg">
    <div className="modal-content" style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>
      <div className="modal-header" style={{ background: "#d3d3d3", padding: "10px", borderRadius: "5px", textAlign: 'center', fontSize: '30px' }}>
        <h5 className="m-3" style={{ margin: 0 }}>Share Report</h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form
          onSubmit={handleShareEmail}
          className="needs-validation px-1"
          id="share_to_email_form"
        >
          <div className="card p-3 w-100" style={{ background: "#f0f0f0", fontWeight: "bold" }}>
            <div className="form-group">
              <label htmlFor="emailIds">Email IDs</label>
              <textarea
                className="form-control"
                name="email_ids"
                id="emailIds"
                rows="3"
                placeholder="Multiple emails can be added by separating with a comma(,)."
                value={emailIds}
                onChange={(e) => setEmailIds(e.target.value)}
                required
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="item_unitname">Message (optional)</label>
              <textarea
                name="email_message"
                id="email_message"
                className="form-control"
                rows="4"
                value={emailMessage}
                onChange={(e) => setEmailMessage(e.target.value)}
                placeholder="This message will be sent along with Report details."
              />
            </div>
          </div>
          <div
            className="modal-footer d-flex justify-content-center w-100"
            style={{ borderTop: "1px solid #d3d3d3", background: "#d3d3d3" }}
          >
            <button
              type="submit"
              id="share_with_email"
              className="submitShareEmailBtn text-uppercase"
              style={{ background: "#213b52", color: "#fff", fontWeight: "bold", padding: '10px 30px' }}
            >
              SEND MAIL
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
    </>


  )
}

export default SalesByCustomer