import React, { useEffect, useState,useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import { Link, useNavigate,useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "../styles/Staff.css"
import * as XLSX from "xlsx";


 



function Repayment() {
    const user = Cookies.get("role");
    const ID = Cookies.get("user_id");
  const navigate = useNavigate();
  
  const { loanId } = useParams();
  
  const [loan, setLoan] = useState([]);
  const [emp, setEmp] = useState({});
  const [intrst,setInterst] = useState('')


  const [employees, setEmployees] = useState([]); // Initialize employees state as an empty array
  

  
  const [banks,setbanks]=useState([])
  const [selectedBank,setSelectedBank]=useState('')
  const [bankDetail, setBankDetail] = useState({ accountNumber: ''});
  

  
  
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const loanref = useRef(null)
  const empref = useRef(null)

  //const [date,setDate] = useState('')
  const [loanAmount, setLoanAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState(''); 

  const [loanDate, setLoanDate] = useState(''); 
  
 
  const [paymentType, setPaymentType] = useState(''); 
  const [chequeNumber, setChequeNumber] = useState(''); 
  const [upiId, setUpiId] = useState(''); 
  const [accno,setAccno] = useState('')
  const [principle,setPrinciple] = useState({pr:''})
  const [princ,setPrinc] = useState('')
  console.log('accc nnoo=',bankDetail.accountNumber)
  

  const fetchLoan = () => {
    axios
      .get(`${config.base_url}/fetch_loan_data/${loanId}/`)
      .then((res) => {
        console.log("HOLDER HISTtttttt=", res);
        if (res.data.status) {
          const {monthly_cutting_amount} = res.data.loan;
          var em = res.data.loan.employee;
          console.log('emp=',em)
          setPrinciple({ pr: monthly_cutting_amount });
          
          //setLoan(hldr);
          setEmp(em)
         
        
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (err.response && err.response.data && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "An unexpected error occurred.",
          });
        }
      });
  };

  useEffect(() => {
    fetchLoan();
  }, []);

 

  
  


  
    
    
   

    const fetchEmployee = () => {
      axios
        .get(`${config.base_url}/get_loan_employee/${ID}/`)
        .then((res) => {
          console.log("employee==", res);
          if (res.data.status) {
            
            setEmployees(res.data.employee);
           
              
           
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const fetchbanks = () => {
      axios
        .get(`${config.base_url}/get_banks/${ID}/`)
        .then((res) => {
          console.log("banks==", res);
          if (res.data.status) {
            
            setbanks(res.data.bank);
           
              
           
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  

    const fetchBankDetails = (bankId) => {
      axios
        .get(`${config.base_url}/get_bank_details/${bankId}/${ID}/`)
        .then((res) => {
          console.log("bank details==", res);
          if (res.data.status) {
            const { account_number } = res.data.bank[0];
            setBankDetail({ 
              accountNumber: account_number, 
              
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    

    
  
  
   
    useEffect(() => {
      //fetchLoanDurations();
      fetchEmployee();
      fetchbanks();
      fetchBankDetails();
      
        }, []);
        useEffect(() => {
    
          const getCurrentDate = () => {
            const date = new Date();
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
      
            
            if (month < 10) {
              month = `0${month}`;
            }
            if (day < 10) {
              day = `0${day}`;
            }
      
            return `${year}-${month}-${day}`;
          };
      
      
      
          
          setLoanDate(getCurrentDate());
          
        }, []); 
        const handleBankChange = (id) => {
          
          setSelectedBank(id);
          fetchBankDetails(id);
          //setBank(e.target.value)
        };
        const maskAccountNumber = (accountNumber) => {
          
          return '*'.repeat(accountNumber.length - 4) + accountNumber.slice(-4);
        };


        

        
        const handleLoanDate = (event) => {
          const du = event.target.value;
          console.log('du=',du)
          
          setLoanDate(du);
          
        };
        

      
      
       
       

        
    const handleEmployeeChange = (event) => {

      const employeeId = event.target.value;
      setSelectedEmployee(employeeId);
     
    };
  

  
  
 

  

  const handlePaymentType = (event) => {
    
      const value = event.target.value;
      console.log('baaank=',value)
      setPaymentType(value);
      //setSelectedBank(value); 
  
      if (value !== 'Cash' || value !== 'Cheque' || value !== 'UPI') {
        setSelectedBank(value);
        handleBankChange(value)  
      } else {
        setSelectedBank('');  // Clear the selected bank if the value is not valid
      }
    };
  

  const handleChequeNumber = (event) => {
    setChequeNumber(event.target.value);
  };

  const handleUpiId = (event) => {
    setUpiId(event.target.value);
  };

 

  
 
  const handleSubmit = (e) => {
    console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh')
    e.preventDefault();
    //console.log('loannnn amount=',loanref.current.value)
    console.log('principle=',principle)
    console.log('emp=',empref.current.value)
    var u = {
        Id: ID,
        loan:loanId,
        employee : empref.current.value,
        interest_amount : intrst,
        payment_method : paymentType,
        cheque_number : chequeNumber,
        upi :upiId,
        account_number: bankDetail.accountNumber,
        loan_amount : principle.pr,
       
        date : loanDate,
        total_amount : totalAmount

        
      };
   

      axios.post(`${config.base_url}/add_repayment/`,u)
      
      .then((res) => {
        console.log("LN RES=", res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Repayment Success",
          });
          navigate(`/viewloan/${loanId}/`);
        }
        if (!res.data.status && res.data.message != "") {
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }; 


  const changeInterest = (e) => {
    const newInterest = e.target.value;
    setInterst(newInterest);
    calculateTotalAmount(principle.pr, newInterest);
  };
  
  const calculateTotalAmount = (principal, interest) => {
    
    const principalAmount = parseFloat(principal) || 0;
    const interestAmount = parseFloat(interest) || 0;
  
   
    const total = principalAmount + interestAmount;
    setTotalAmount(total);
  };



  
    
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  
  
  
  return (
    <>
      <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961", maxWidth:"100vw" }}
        >
        <div className="d-flex justify-content-end mb-1">
          <Link to={`/viewloan/${loanId}/`}>
          
            <i
              className="fa fa-times-circle text-white mx-4 p-1"
              style={{ fontSize: "1.2rem", marginRight: "0rem !important" }}
            ></i>
          </Link>
        </div>
        <div className="card radius-15 h-20 mb-5">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="mt-3">REPAYMENT DUE</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        <div className="card radius-15">
  <div className="card-body">
  
  <form
              className="needs-validation px-1 "
              onSubmit={handleSubmit}
              validate
            >
      <div className="row w-100">
      <div className="col-md-12 mx-0">
        <div className="row">
        <div className="col-md-6">
          {/* Column 1: Employee selection, loan details */}
          <div className="form-group">
            <label htmlFor="employee">Employee</label>
            <div className="d-flex align-items-center">
                
            <select
              id="employee"
              className="form-control"
              value={emp.id}
              ref={empref}
              onChange={handleEmployeeChange}
            >
              <option value="">Select Employee</option>
            {employees.map(emp => (
              <option key={emp.id} value={emp.id}>
                {emp.first_name} {emp.last_name}
              </option>
              ))}
            </select>
           

           
           
                       
            </div>
          </div>
         
          
          
          <div className="form-group">
            <label htmlFor="loanAmount">Interest Amount</label>
            <input
              type="text"
              id="loanAmount"
              className="form-control"
              
              onChange={changeInterest}
              required
            />
          </div>


          <div className="form-group">
            <label htmlFor="paymentType">Payment Type</label>
            <select
              id="paymentType"
              className="form-control"
              value={paymentType}
              onChange={handlePaymentType}
            >
              <option value="">Select Payment Type</option>
              <option value="Cash">Cash</option>
              <option value="Cheque">Cheque</option>
              <option value="UPI">UPI</option>
             
              {banks.map((bank) => (
          <option key={bank.id} value={bank.id} className="text-uppercase">
           {bank.bank_name} - {maskAccountNumber(bank.account_number)}
          </option>
        ))}
            </select>
          </div>
          {paymentType === 'Cheque' && (
            <div className="form-group">
              <label htmlFor="chequeNumber">Cheque Number</label>
              <input
                type="text"
                id="chequeNumber"
                className="form-control"
                value={chequeNumber}
                onChange={handleChequeNumber}
              />
            </div>
          )}
          {paymentType === 'UPI' && (
            <div className="form-group">
              <label htmlFor="upiId">UPI ID</label>
              <input
                type="text"
                id="upiId"
                className="form-control"
                value={upiId}
                onChange={handleUpiId}
              />
            </div>
          )}
          

          {selectedBank && !['Cash', 'Cheque', 'UPI'].includes(paymentType) && (
  <div className="form-group">
    <label htmlFor="accountNumber">Account Number</label>
    <input
      type="text"
      id="accountNumber"
      className="form-control"
      value={bankDetail.accountNumber}
      onChange={(e) => setAccno(e.target.value)}
      readOnly
    />
  </div>
)}
{intrst ? (
        <div className="form-group">
          <label htmlFor="totalAmount">Total Amount</label>
          <input
            type="text"
            id="totalAmount"
            className="form-control"
            value={totalAmount}// Make Total Amount read-only
          />
        </div>
      ) : (
        <div className="form-group">
          <label htmlFor="totalAmount">Total Amount</label>
          <input
            type="text"
            id="totalAmount"
            className="form-control"
            value={principle.pr} // Show only Principal Amount
            readOnly // Make Total Amount read-only
          />
        </div>
      )}


         
        </div>
        <div className="col-md-6">

        <div className="form-group">
            <label htmlFor="loanAmount">Principal Amount</label>
            <input
              type="number"
              id="loanAmount"
              className="form-control"
              value={principle.pr}
              onChange={(e) => setPrinciple({ pr: e.target.value })}
            />
          </div>
       
          {/* Column 2: Payment details, monthly cutting, file upload */}
         
          <div className="form-group">
            <label htmlFor="loanDate">Date</label>
            <input
              type="date"
              id="loanDate"
              className="form-control"
              value={loanDate}
              onChange={handleLoanDate}
            />
          </div>
         </div>
      </div>
      <div className="row mt-5 mb-5">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 d-flex justify-content-center">
                    <button
                        className="btn btn-outline-secondary "
                        type="submit"
                       
                        style={{ width: "50%", height: "fit-content" }}
                      >
                        SAVE
                      </button>
                      <Link
                        to="/loanlist"
                        className="btn btn-outline-secondary ml-1 "
                        style={{ width: "fit-content", height: "fit-content" }}
                      >
                        CANCEL
                      </Link>
                    </div>
      </div>
      </div>
      </div>
    </form>
    </div>
    </div>
    </div></div>
    </div>
    
    

   
    
    


</>
  );
}


 

  


export default Repayment;