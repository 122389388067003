import React, { useEffect, useState } from "react";

import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../functions/config";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";


function Purchase_order () {

  const ID = Cookies.get('user_id');
  const [purchaseorders, setPurchaseorder] = useState([]);

  const fetchpurchaseorder = () => {
    axios.get(`${config.base_url}/fetch_purchase_order/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          setPurchaseorder(res.data.purchaseorder);
        }
      })
      .catch((err) => {
        console.log('ERR', err);
      });
  };
  
  useEffect(()=>{
    fetchpurchaseorder();
  },[])
  const navigate = useNavigate();
  function exportToExcel() {
    const Table = document.getElementById("purchaseOrderTableExport");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "PurchaseOrders.xlsx");
  }
  function searchTable(){
    var rows = document.querySelectorAll('#purchaseOrderTable tbody tr');
    var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }
  function refreshAll(){
    setPurchaseorder([])
    fetchpurchaseorder();
  }
  function sortTable(columnIndex, isNumeric = false, isDate = false) {
    const sortedData = [...purchaseorders].sort((a, b) => {
      let x = a[columnIndex];
      let y = b[columnIndex];
  
      // Handle date sorting
      if (isDate) {
        x = new Date(x);
        y = new Date(y);
      }
  
      // Handle numeric sorting
      if (isNumeric) {
        x = parseFloat(x);
        y = parseFloat(y);
      }
  
      // Default to string comparison
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
  
    setPurchaseorder(sortedData);
  }
  
  
  function filterTable(row, filterValue) {
    const table1 = document.getElementById("purchaseOrderTable");
    const rows1 = table1.getElementsByTagName("tr");
  
    for (let i = 1; i < rows1.length; i++) {
      const statusCell = rows1[i].getElementsByTagName("td")[row];
  
      if (statusCell) {
        const cellValue = statusCell.textContent.trim().toLowerCase();
  
        if (filterValue === "all" || cellValue === filterValue.toLowerCase()) {
          rows1[i].style.display = "";
        } else {
          rows1[i].style.display = "none";
        }
      }
    }
  }
  
  
  
  
    return (
        <>
        <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961" }}
        >
        <div className="card radius-15 h-20">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="mt-3">PURCHASE ORDERS</h2>
              </center>
              <hr />
            </div>
          </div>
        </div>

        <div className="card radius-15 mt-3">
          <div className="card-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      placeholder="Search.."
                      autoComplete="off"
                      onKeyUp={searchTable}
                    />
                    <div
                      className="dropdown ml-1"
                      style={{ justifyContent: "left" }}
                    >
                      <button
                        type="button"
                        style={{ width: "fit-content", height: "fit-content" }}
                        className="btn btn-outline-secondary dropdown-toggle text-grey"
                        data-toggle="dropdown"
                      >
                        <i className="fa fa-sort"></i> Sort by
                      </button>
                      <div
                        className="dropdown-menu"
                        style={{ backgroundColor: "black" }}
                      >
                        <a
    className="dropdown-item"
    onClick={refreshAll}
    style={{
      height: "40px",
      fontSize: "15px",
      color: "white",
    }}
  >
    All
  </a>
  <a
    className="dropdown-item"
    style={{
      height: "40px",
      fontSize: "15px",
      color: "white",
      cursor: "pointer",
    }}
    onClick={() => sortTable('vendor_name')} // Sorting by string (vendor name)
  >
    Vendor Name
  </a>
  <a
    className="dropdown-item"
    style={{
      height: "40px",
      fontSize: "15px",
      color: "white",
      cursor: "pointer",
    }}
    onClick={() => sortTable('purchase_order_no', true)} // Sorting by number (purchase order no.)
  >
    Purchase Order No.
  </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-6 d-flex justify-content-end">
                  <button
                    type="button"
                    style={{ width: "fit-content", height: "fit-content" }}
                    className="btn btn-outline-secondary text-grey"
                    id="exportBtn"
                    onClick={exportToExcel}
                  >
                    <i className="fa fa-table"></i> Export To Excel
                  </button>
                  <div className="dropdown ml-1">
  <button
    type="button"
    style={{ width: "fit-content", height: "fit-content",marginLeft: "10px" }}
    className="btn btn-outline-secondary dropdown-toggle text-grey"
    data-toggle="dropdown"
  >
    <i className="fa fa-filter"></i> filter by
  </button>
  <div className="dropdown-menu" style={{ backgroundColor: "black" }}>
  <a
    className="dropdown-item"
    style={{
      height: "40px",
      fontSize: "15px",
      color: "white",
      cursor: "pointer",
      marginLeft: "10px"
    }}
    onClick={() => filterTable(6, 'all')}  // Update the column index to match the "Status" column
  >
    All
  </a>
  <a
    className="dropdown-item"
    style={{
      height: "40px",
      fontSize: "15px",
      color: "white",
      cursor: "pointer",
    }}
    onClick={() => filterTable(6, 'Saved')}  // Adjust column index for status column
  >
    Saved
  </a>
  <a
    className="dropdown-item"
    style={{
      height: "40px",
      fontSize: "15px",
      color: "white",
      cursor: "pointer",
    }}
    onClick={() => filterTable(6, 'Draft')}  // Adjust column index for status column
  >
    Draft
  </a>
</div>

</div>


                  <Link to="/add_purchase_order" className="ml-1">
                    <button
                      type="button"
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="btn btn-outline-secondary text-grey"
                    >
                      <i className="fa fa-plus font-weight-light"></i> Purchase Order
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table
              className="table table-responsive-md table-hover mt-4"
              id="purchaseOrderTable"
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                <th>#</th>
                <th>DATE</th>
                <th>PURCHASE ORDER NO.</th>
                <th>VENDOR NAME</th>
                <th>MAIL ID</th>
                <th>AMOUNT</th>
                <th>STATUS</th>
                <th>BALANCE</th>
                <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
              {purchaseorders &&
  purchaseorders.map((i, index) => {
    console.log("Purchase Order Object:", i); // Log the entire object

    return (
      <tr
        className="clickable-row"
        onDoubleClick={() => navigate(`/view_purchase_order/${i.id}/`)}
        style={{ cursor: "pointer" }}
        key={i.id}
      >
        <td>{index + 1}</td>
        <td>{i.date}</td>
        <td>{i.purchase_order_no}</td>
        <td>{i.vendor_name}</td>
        <td>{i.vendor_mail}</td>
        <td>{i.grandtotal}</td>
        <td>{i.status}</td>
        <td>{i.balance}</td>
        <td>
                        {i.converted ? (
                          <span
                            className="text-info font-weight-bolder text-center"
                            onClick={() => navigate(i.link)}
                          >
                            Converted to <br />
                            {i.type} - #{i.number}
                          </span>
                        ) : (
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              style={{
                                width: "fit-content",
                                height: "fit-content",
                              }}
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Convert
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  to={`/purchaseorder_to_bill/${i.id}/`}
                                  className="dropdown-item fw-bold text-white"
                                >
                                  To Bill
                                </Link>
                              </li>
                              <li>
                              <Link
                                  to={`/purchaseorder_to_recbill/${i.id}/`}
                                  className="dropdown-item fw-bold text-white"
                                >
                                  To Rec. Bill
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}
                      </td>



      </tr>
    );
  })}

</tbody>




            </table>
          </div>
        </div>
      </div>
      <table className="purchaseOrderTable" id="purchaseOrderTableExport" hidden>
      <thead>
        <tr>
          <th>#</th>
          <th>DATE</th>
          <th>PURCHASE ORDER NO.</th>
          <th>VENDOR NAME</th>
          <th>MAIL ID</th>
          <th>AMOUNT</th>
          <th>STATUS</th>
          <th>BALANCE</th>
        </tr>
      </thead>
      <tbody>
        {purchaseorders && purchaseorders.map((i,index)=>(
          <tr>
            <td>{index+1}</td>
            <td>{i.date}</td>
            <td>{i.purchase_order_no}</td>
            <td>{i.vendor_name}</td>
            <td>{i.vendor_mail}</td>
            <td>{i.grandtotal}</td>
            <td>{i.status}</td>
            <td>{i.balance}</td>
          </tr>
        ))}
      </tbody>
      </table>
      </div>
      </div>
        </>
    )

}
export default Purchase_order;