import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminPrivateRoutes from "./components/routes/AdminPrivateRoutes";
import Index from "./components/index/Index";
import SignIn from "./components/SignIn/SignIn";
import AdminHome from "./components/admin/AdminHome";
import CompanyReg from "./components/company/CompanyReg";
import PaymentTerms from "./components/admin/PaymentTerms";
import CompanyReg2 from "./components/company/CompanyReg2";
import Modules from "./components/company/Modules";
import StaffReg from "./components/staff/StaffReg";
import StaffReg2 from "./components/staff/StaffReg2";
import DistributorReg from "./components/distributor/DistributorReg";
import DistributorReg2 from "./components/distributor/DistributorReg2";
import AllClients from "./components/admin/AllClients";
import Clients from "./components/admin/Clients";
import ClientsReq from "./components/admin/ClientsReq";
import Distributors from "./components/admin/Distributors";
import AllDistributors from "./components/admin/AllDistributors";
import DistributorsReq from "./components/admin/DistributorsReq";
import DistributorReqOverview from "./components/admin/DistributorReqOverview";
import ClientReqOverview from "./components/admin/ClientReqOverview";
import AllDistributorsOverview from "./components/admin/AllDistributorsOverview";
import AllClientsOverview from "./components/admin/AllClientsOverview";
import DistributorPrivateRoutes from "./components/routes/DistributorPrivateRoutes";
import DistributorHome from "./components/distributor/DistributorHome";
import DAllClients from "./components/distributor/DAllClients";
import DClientReq from "./components/distributor/DClientReq";
import DClientReqOverview from "./components/distributor/DClientReqOverview";
import DClientOverview from "./components/distributor/DClientOverview";
import DistributorProfile from "./components/distributor/DistributorProfile";
import DistributorProfileEdit from "./components/distributor/DistributorProfileEdit";
import CompanyPrivateRoutes from "./components/routes/CompanyPrivateRoutes";
import CompanyStaffPrivateRoutes from "./components/routes/CompanyStaffPrivateRoutes";
import CompanyHome from "./components/company/CompanyHome";
import CompanyProfile from "./components/company/CompanyProfile";
import EditCompanyProfile from "./components/company/EditCompanyProfile";
import EditStaffProfile from "./components/staff/EditStaffProfile";
import StaffReq from "./components/company/StaffReq";
import AllStaffs from "./components/company/AllStaffs";
import EditModules from "./components/company/EditModules";
import Wrong from "./components/company/Wrong";
import AdminNotifications from "./components/admin/AdminNotifications";
import NotificationOverview from "./components/admin/NotificationOverview";
import DistNotifications from "./components/distributor/DistNotifications";
import DistNotificationOverview from "./components/distributor/DistNotificationOverview";
import Items from "./components/company/items/Items";
import AddItem from "./components/company/items/AddItem";
import ViewItem from "./components/company/items/ViewItem";
import ItemHistory from "./components/company/items/ItemHistory";
import EditItem from "./components/company/items/EditItem";
import PriceList from "./components/company/pricelist/PriceList";
import AddPriceList from "./components/company/pricelist/AddPriceList";
import ViewPriceList from "./components/company/pricelist/ViewPriceList";
import PriceListHistory from "./components/company/pricelist/PriceListHistory";
import EditPriceList from "./components/company/pricelist/EditPriceList";
import AddEmployee from"./components/company/employee/addemployee";
import ListoutEmployee from"./components/company/employee/employeelistout";
import EditEmployee from"./components/company/employee/editemployee";
import OverviewEmployee from"./components/company/employee/employeeoveriew";
import EmployeeHistory from"./components/company/employee/employeehistory"; 
import Customer from "./components/company/Customer";
import Add_Customer from "./components/company/Add_Customer";
import Customer_view from "./components/company/Customer_view";
import Customer_Edit from "./components/company/Customer_Edit";
import Customer_History from "./components/company/Customer_History";
import BankingListout from "./components/company/Banking/BankingListout";
import AddBank from "./components/company/Banking/AddBank";
import ViewBank from "./components/company/Banking/ViewBank";
import BankToCash from "./components/company/Banking/BankToCash";
import CashToBank from "./components/company/Banking/CashToBank";
import BankToBank from "./components/company/Banking/BankToBank";
import AdjustBank from "./components/company/Banking/AdjustBank";
import EditTransactions from "./components/company/Banking/EditTransactions";
import EditBank from "./components/company/Banking/EditBank";
import BankTransactionHistory from "./components/company/Banking/BankTransactionHistory";
import StockAdjustment from "./components/company/StockAdjustment";
import New_Adjust from "./components/company/New_Adjust";
import Stock_AdjustOverview from "./components/company/Stock_AdjustOverview";
import EditStockAdjust from "./components/company/EditStockAdjust";
import Stock_AjustHistory from "./components/company/Stock_AjustHistory";
import Recurringinvoice_listoutpage from './components/company/recurringinvoice/Recurringinvoice_listout';
import Add_recurr_invoice from './components/company/recurringinvoice/Add_recurr_invoice';
import View_rec_invoice from './components/company/recurringinvoice/Viewrecurr_invoice';
import Rec_invoice_history from './components/company/recurringinvoice/RecurrInvoice_history';
import EditRec_invoice from './components/company/recurringinvoice/EditRecurr_invoice';
import RetInvoice from './components/company/retainerinvoice/RetInvoice';
import AddRetInvoice from './components/company/retainerinvoice/AddRetInvoice';
import ViewRetInvoice from './components/company/retainerinvoice/ViewRetInvoice';
import RetInvoiceHistory from './components/company/retainerinvoice/RetInvoiceHistory';
import EditRetInvoice from './components/company/retainerinvoice/EditRetInvoice';	
import Invoice from "./components/company/invoice/invoice";
import Addinvoice from "./components/company/invoice/addinvoice";
import ViewInvoice from "./components/company/invoice/invoiceview";
import InvoiceHistory from "./components/company/invoice/invoicehistory";
import EditInvoice from "./components/company/invoice/editinvoice";
import Estimate from "./components/company/estimate/Estimate";
import AddEstimate from "./components/company/estimate/AddEstimate";
import ViewEstimate from "./components/company/estimate/ViewEstimate";
import EditEstimate from "./components/company/estimate/EditEstimate";
import EstimateHistory from "./components/company/estimate/EstimateHistory";
import ConvertEstimateToInvoice from "./components/company/estimate/ConvertEstimateToInvoice";
import ConvertEstimateToRecInvoice from "./components/company/estimate/ConvertEstimateToRecInvoice";
import ConvertEstimateToSalesOrder from "./components/company/estimate/ConvertEstimateToSalesOrder";
import ManualJournal from './components/company/manualjournal/ManualJournal';
import ManualJournalCreation from './components/company/manualjournal/ManualJournalCreation';
import ViewManualJournal from './components/company/manualjournal/viewjournal';
import ManualJournalHistory from './components/company/manualjournal/ManualJournalHistory';
import EditManualJournal from './components/company/manualjournal/EditManualJournal';
import Vendor from "./components/company/Vendor/Vendor";
import Add_vendor from "./components/company/Vendor/Add_vendor";
import View_vendor from "./components/company/Vendor/View_vendor";
import VendorHistory from "./components/company/Vendor/VendorHistory";
import Edit_vendor from "./components/company/Vendor/Edit_vendor";
import Expense from './components/company/Expense/Expense';
import Add_expense from './components/company/Expense/Add_expense';
import View_expense from './components/company/Expense/View_expense';
import Expense_history from './components/company/Expense/Expense_history';
import Edit_expense from './components/company/Expense/Edit_expense';
import Banklist from "./components/company/bankholder/banklist";
import Addbankholder from "./components/company/bankholder/add_bankholder";
import Viewholder from "./components/company/bankholder/viewholder";
import BankHistory from "./components/company/bankholder/bankhistory";
import Editholder from "./components/company/bankholder/editholder";
import Chart_accounts from './components/company/Chart_accounts';
import AddAccount from './components/company/AddAccount';
import ViewAccount from './components/company/ViewAccount';
import AccountHistory from './components/company/AccountHistory';
import EditAccount from './components/company/EditAccount';
import Holiday from "./components/company/Holiday/Holiday";
import Add_Holiday from "./components/company/Holiday/Add_Holiday";
import ViewHolidays from "./components/company/Holiday/ViewHolidays";
import HolidayHistoryPage from "./components/company/Holiday/HolidayHistoryPage";
import Edit_Holiday from "./components/company/Holiday/Edit_Holiday";
import Attendence from './components/company/payroll_attendence/Attendence';
import Add_Attendece from './components/company/payroll_attendence/add_attendence';
import View_Attendence from './components/company/payroll_attendence/view_attendence';
import Edit_Attendence from './components/company/payroll_attendence/edit_attendence';
import Attendence_history from './components/company/payroll_attendence/attendence_history';
import Recurr_Expense from './components/company/recurringexpense/Recurr_expense';
import Add_recurr_expense from './components/company/recurringexpense/Add_recurr_expense';
import View_recurr_expense from './components/company/recurringexpense/View_recurr_expense';
import RecExpense_history from './components/company/recurringexpense/Recurr_expense_history';
import Edit_recurr_expense from './components/company/recurringexpense/Edit_recurr_expense';
import Bill from "./components/company/bill/Bill";
import AddBill from "./components/company/bill/AddBill";
import ViewBill from "./components/company/bill/ViewBill";
import EditBill from "./components/company/bill/EditBill";
import BillHistory from "./components/company/bill/BillHistory";
import CreditNote from "./components/company/creditnote/CreditNote";
import AddCreditNote from "./components/company/creditnote/AddCreditNote";
import ViewCreditNote from "./components/company/creditnote/ViewCreditNote";
import CreditNoteHistory from "./components/company/creditnote/CreditNoteHistory";
import EditCreditNote from "./components/company/creditnote/EditCreditNote";
import DebitNote from "./components/company/debitnote/DebitNote";
import AddDebitNote from "./components/company/debitnote/AddDebitNote";
import ViewDebitNote from "./components/company/debitnote/ViewDebitNote";
import DebitNoteHistory from "./components/company/debitnote/DebitNoteHistory";
import EditDebitNote from "./components/company/debitnote/EditDebitNote";
import ReceiptNote from './components/company/receiptnote/ReceiptNote';
import AddReceiptNote from './components/company/receiptnote/AddReceiptNote';
import ViewReceiptNote from './components/company/receiptnote/ViewReceiptNote';
import ReceiptNoteHistory from './components/company/receiptnote/ReceiptNoteHistory';
import EditReceiptNote from './components/company/receiptnote/EditReceiptNote';
import ConvertReceiptToBill from './components/company/receiptnote/ConvertReceiptToBill';
import ConvertReceiptToRecBill from './components/company/receiptnote/ConvertReceiptToRecBill';
import PaymentReceived from "./components/company/paymentreceived/PaymentReceived";
import AddPaymentReceived from "./components/company/paymentreceived/AddPaymentReceived";
import ViewPayment from "./components/company/paymentreceived/ViewPayment";
import PaymentHistory from "./components/company/paymentreceived/PaymentHistory";
import EditPaymentReceived from "./components/company/paymentreceived/EditPaymentReceived";
import CashInHand from "./components/company/cashinhand/CashInHand";
import CashInHandStatement from "./components/company/cashinhand/CashInHandStatement";
import AddCash from "./components/company/cashinhand/AddCash";
import Cashinhand_Graph from "./components/company/cashinhand/Cashinhand_Graph";
import Cashinhand_history from "./components/company/cashinhand/Cashinhand_history";
import Edit_cashinhand from "./components/company/cashinhand/Edit_cashinhand";
import Purchase_Order from "./components/company/Purchase_Order";
import Add_Purchase_Order from "./components/company/Add_Purchase_order";
import View_Purchase_Order from "./components/company/View_Purchase_Order";
import Purchase_Order_History from "./components/company/Purchase_Order_History";
import Purchase_Order_Edit from "./components/company/Purchase_Order_Edit";
import PurchaseOrder_to_Bill from "./components/company/PurchaseOrder_to_Bill";
import PurchaseOrder_to_Recbill from "./components/company/PurchaseOrder_to_Recbill";
import Rec_bill from './components/company/recurring_bill/Rec_bill';
import Add_rec_bill from './components/company/recurring_bill/Add_rec_bill';
import View_rec_bill from './components/company/recurring_bill/View_rec_bill';
import EditRecBill from './components/company/recurring_bill/EditRecBill';
import RecBillHistory from './components/company/recurring_bill/RecBillHistory';
import SalesByCustomer from "./components/company/Sales/SalesByCustomer";
import SalesByItem from "./components/company/Sales/SalesByItem";
import ChequePayments from "./components/company/cheque/ChequePayments";
import ChequeStatement from "./components/company/cheque/ChequeStatement";
import UPIPayments from "./components/company/upi/UPIPayments";
import UPIStatement from "./components/company/upi/UPIStatement";
import Loanlist from "./components/company/loan/loanlist";
import Addloan from "./components/company/loan/addloan";
import ViewLoan from "./components/company/loan/viewloan";
import Repayment from "./components/company/loan/repayment";
import Newloan from "./components/company/loan/newloan";
import LoanHistory from "./components/company/loan/loanhistory";
import RepaymentHistory from "./components/company/loan/repaymenthistory";
import RepaymentEdit from "./components/company/loan/repaymentedit";
import Loanedit from "./components/company/loan/loanedit";
import AdditionalHistory from "./components/company/loan/additionalhistory";
import Additionaledit from "./components/company/loan/additionaledit";
import PaymentMade from "./components/company/paymentmade/PaymentMade";
import AddPaymentMade from "./components/company/paymentmade/AddPaymentMade";
import ViewPaymentMade from "./components/company/paymentmade/ViewPaymentMade";
import PaymentMadeHistory from "./components/company/paymentmade/PaymentMadeHistory";
import EditPaymentMade from "./components/company/paymentmade/EditPaymentMade";
import PurchaseByVendor from "./components/company/Purchases/PurchaseByVendor";
import PurchaseByItem from "./components/company/Purchases/PurchaseByItem";
import InvoiceReport from "./components/company/reports/InvoiceReport";
import RecInvoiceReport from "./components/company/reports/RecInvoiceReport";
import Salarydetails from './components/company/Salarydetails/Salarydetails';
import Add_salarydetails from './components/company/Salarydetails/Add_salarydetails';
import View_salarydetails from './components/company/Salarydetails/View_salarydetails';
import Salarydetails_history from './components/company/Salarydetails/Salarydetails_history';
import Edit_salarydetails from './components/company/Salarydetails/Edit_salarydetails';
import Estimate_details from './components/company/Reports/Estimate_details';
import Retainer_invoice_Details from './components/company/Reports/Retainer_invoice_Details';
import CustomerBalanceReport from "./components/company/reports/customer_balance_report";
import VendorBalanceReport from "./components/company/reports/vendor_balance_report";
import Expensereport from './components/company/reports/expensereport';
import Purchase_Order_Details from "./components/company/Purchase_Order_Details";
import Purchase_Order_Vendor from "./components/company/Purchase_Order_Vendor";
import Ewaybill from "./components/company/Ewaybills/Ewaybill";
import Add_eway from "./components/company/Ewaybills/Add_eway";
import Overview_eway from "./components/company/Ewaybills/Overview_eway";
import EwayHistory from "./components/company/Ewaybills/EwayHistory";
import Edit_ewaybill from "./components/company/Ewaybills/Edit_ewaybill";
import Grnreport from './components/company/reports/grnreport';
import BillReport from "./components/company/reports/billReport";
import RecBillReport from "./components/company/reports/reccurringbillReport";
import DeliveryChallan from "./components/company/deliverychallan/DeliveryChallan";
import AddDeliveryChallan from "./components/company/deliverychallan/AddDeliveryChallan";
import ConvertChallanToInvoice from "./components/company/deliverychallan/ConvertChallanToInvoice";
import ViewChallan from "./components/company/deliverychallan/ViewChallan";
import ChallanHistory from "./components/company/deliverychallan/ChallanHistory";
import EditDeliveryChallan from "./components/company/deliverychallan/EditDeliveryChallan";
import ConvertChallanToRecInvoice from "./components/company/deliverychallan/ConvertChallanToRecInvoice";
import Agingsummary from "./components/company/reports/agingsummaryreport";
import AgingDetails from "./components/company/reports/agingDetails";
import CreditNoteDetails from "./components/company/reports/CreditNoteDetails";
import DebitNoteDetails from "./components/company/reports/DebitNoteDetails";
import Payment_Made_Summary from "./components/company/Payment_Made_Summary";
import Payment_Made_Details from "./components/company/Payment_Made_Details";
import Journal_report from './components/company/reports/Journal_reports';
import Purchase_orderitem_report from './components/company/reports/Purchase_orderitem';
import Salesorder from "./components/company/Salesorder";
import Addsalesorder from "./components/company/Addsalesorder";
import SalesOrderDetail from "./components/company/SalesOrderDetail";
import Editsalesorder from "./components/company/Editsalesorder";
import SALESTOINVOICE from "./components/company/SalesToInvoice";
import SALESTORECURRING from "./components/company/SalesToRecurring";
import Outstanding_receivable from './components/company/reports/Outstanding_receivable';
import Outstanding_payable from './components/company/reports/Outstanding_payable';
import LoanHolderlist from './components/company/LoanHolderlist';
import Addholderloan from './components/company/Addholderloan';
import ViewLoanholder from './components/company/ViewLoanholder';
import LoanRepayment from './components/company/LoanRepayment';
import Loanadditional from './components/company/Loanadditional';
import Loanholderhistory from './components/company/Loanholderhistory';
import LoanAccountEdit from './components/company/LoanAccountEdit';
import RepaymentLoanEdit from "./components/company/RepaymetLoanEdit";
import Payment_Received_Details from "./components/company/Payment_received_report/Payment_Received_Details";
import Payment_Received_Summary from "./components/company/Payment_received_report/Payment_Received_Summary";
import BankStatement from "./components/company/BankStatement";
import AccountTypeSummary from "./components/company/AccountTypeSummary";
import Sales_by_hsn from './components/company/reports/sales_by_hsn';
import DiscountReport from './components/company/reports/DiscountReport';
import Item_report_by_party from './components/company/Reports/Item_report_by_party';
import Party_report_by_item from './components/company/Reports/Party_report_by_item';
import Stocksummary from './components/company/reports/stocksummary';
import LowStockDetails from "./components/company/LowStockDetails";
import StockDetails from "./components/company/StockDetails";
import Daybook_report from './components/company/Reports/Daybook';
import Cashflow_report from './components/company/Reports/Cashflow';
import GSTR1 from "./components/company/reports/gstr1";
import GSTR2 from "./components/company/reports/gstr2";
import SalesPurchaseByParty from "./components/company/reports/SalesPurchaseByParty";
import AllTransactions from "./components/company/reports/AllTransactions";
import Saleorderdetails_report from './components/company/reports/Saleorder_details';
import Saleorderitem_report from './components/company/reports/Salesorder_Item';
import GSTR3B from "./components/company/reports/gstr3b";
import GSTR9 from "./components/company/reports/gstr9";
import Partystatement from './components/company/reports/Partystatement';
import E_way_bill_details from "./components/company/E_way_bill_details";
import Reccurring_Expense_report from "./components/company/Reccurring_Expense_report";
import Horizontal_profit_and_loss from "./components/company/reports/Horizontal_profit_and_loss";
import Vertical_profit_and_loss from './components/company/reports/Vertical_profit_and_loss';
import Trialbalance from './components/company/reports/Trialbalance';
import DeliveryChallanReport from "./components/company/reports/DeliveryChallanReport";
import LoanAccountStatement from "./components/company/reports/LoanAccountStatement";
import Stock_valuation_summary from './components/company/reports/Stock_valuation_summary';
import Sales_Item_Discount_Details from './components/company/reports/Sales_Item_Discount_Details';
import Horizontal_balancesheet_report from './components/company/reports/Horizontal_balancesheet';
import Vertical_balancesheet_report from './components/company/reports/Vertical_balancesheet';
import Salesordercustomer from "./components/company/reports/Salesordercustomer";
import Employeeloanstatement from "./components/company/reports/Employee_loan_statement";
import Sales_summary_report from "./components/company/reports/sales_summary_report";
import Purchase_summary_report from "./components/company/reports/purchase_summary_report";
import All_parties_report from "./components/company/reports/All_parties_report";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />}></Route>
          <Route path="/login" element={<SignIn />}></Route>
          <Route path="/company_registration" element={<CompanyReg />}></Route>
          <Route path="/company_registration2" element={<CompanyReg2 />}></Route>
          <Route path="/modules_list" element={<Modules />}></Route>
          <Route path="/wrong" element={<Wrong />}></Route>
          {/* <Route path="/term_update_modules" element={<TermUpdateModules />}></Route> */}

          <Route path="/distributor_registration" element={<DistributorReg />}></Route>
          <Route path="/distributor_registration2" element={<DistributorReg2 />}></Route>
          
          <Route path="/staff_registration" element={<StaffReg />}></Route>
          <Route path="/staff_registration2" element={<StaffReg2 />}></Route>

          <Route element={<AdminPrivateRoutes />}>
            <Route path="/admin_home" element={<AdminHome />}></Route>
            <Route path="/payment_terms/new_term" element={<PaymentTerms />}></Route>
            <Route path="/clients" element={<Clients />}></Route>
            <Route path="/all_clients" element={<AllClients />}></Route>
            <Route path="/clients_requests" element={<ClientsReq />}></Route>
            <Route path="/client_request_overview/:id/" element={<ClientReqOverview />}></Route>
            <Route path="/all_clients_overview/:id/" element={<AllClientsOverview />}></Route>
            <Route path="/distributors" element={<Distributors />}></Route>
            <Route path="/all_distributors" element={<AllDistributors />}></Route>
            <Route path="/distributors_requests" element={<DistributorsReq />}></Route>
            <Route path="/distributors_request_overview/:id/" element={<DistributorReqOverview />}></Route>
            <Route path="/all_distributors_overview/:id/" element={<AllDistributorsOverview />}></Route>
            <Route path="/admin_notifications" element={<AdminNotifications />}></Route>
            <Route path="/anotification_overview/:id/" element={<NotificationOverview />}></Route>
          </Route>
          <Route element={<DistributorPrivateRoutes />}>
            <Route path="/distributor_home" element={<DistributorHome />}></Route>
            <Route path="/distributor_notifications" element={<DistNotifications />}></Route>
            <Route path="/dnotification_overview/:id/" element={<DistNotificationOverview />}></Route>
            <Route path="/distributor_profile" element={<DistributorProfile />}></Route>
            <Route path="/edit_distributor_profile" element={<DistributorProfileEdit />}></Route>
            <Route path="/DClient_req" element={<DClientReq />}></Route>
            <Route path="/DClients" element={<DAllClients />}></Route>
            <Route path="/DClient_request_overview/:id/" element={<DClientReqOverview />}></Route>
            <Route path="/DClient_overview/:id/" element={<DClientOverview />}></Route>
          </Route>
          <Route element={<CompanyPrivateRoutes />}>
            <Route path="/company_staffs/staff_requests" element={<StaffReq />}></Route>
            <Route path="/company_staffs/all_staffs" element={<AllStaffs />}></Route>
            <Route path="/edit_company_profile" element={<EditCompanyProfile />}></Route>
            <Route path="/edit_modules" element={<EditModules />}></Route>
          </Route>
          <Route element={<CompanyStaffPrivateRoutes />}>
            <Route path="/company_home" element={<CompanyHome />}></Route>
            <Route path="/company_profile" element={<CompanyProfile />}></Route>
            <Route path="/edit_staff_profile" element={<EditStaffProfile />}></Route>
            <Route path="/sales_by_customer" element={<SalesByCustomer />}></Route>
            <Route path="/sales_by_item" element={<SalesByItem />}></Route>
            <Route path="/purchases_by_vendor" element={<PurchaseByVendor />}></Route>
            <Route path="/purchases_by_item" element={<PurchaseByItem />}></Route>
          </Route>
          {/* Items */}
          <Route path="/Items" element={<Items />}></Route>
          <Route path="/AddItem" element={<AddItem />}></Route>
          <Route path="/ViewItem/:itemId/" element={<ViewItem />}></Route>
          <Route path="/ItemHistory/:itemId/" element={<ItemHistory />}></Route>
          <Route path="/EditItem/:itemId/" element={<EditItem />}></Route>

          <Route path="/PriceList" element={<PriceList />}></Route>
          <Route path="/AddPriceList" element={<AddPriceList />}></Route>
          <Route path="/ViewPriceList/:priceListId/" element={<ViewPriceList />}></Route>
          <Route path="/PriceListHistory/:priceListId/" element={<PriceListHistory />}></Route>
          <Route path="/EditPriceList/:priceListId/" element={<EditPriceList />}></Route>

          {/* employee */}
          <Route path="/employeeadd" element={<AddEmployee />}></Route>
          <Route path="/listoutemployee" element={<ListoutEmployee />}></Route>
          <Route path="/overviewemployee/:itemId/" element={<OverviewEmployee />}></Route>
          <Route path="/employee_history/:itemId/" element={<EmployeeHistory />}></Route>
          <Route path="/edit_employee/:itemId/" element={<EditEmployee />}></Route>

          <Route path="/customers" element={<Customer />}></Route>
          <Route path="/add_customer" element={<Add_Customer />}></Route>
          <Route path="/customer_view/:customerId/" element={<Customer_view />}></Route>
          <Route path="/customer_edit/:customerId/" element={<Customer_Edit/>}></Route>
          <Route path="/customer_history/:customerId/" element={<Customer_History/>}></Route>

          <Route path="/banking/bankinglistout" element={<BankingListout />}></Route>
          <Route path="/banking/add_bank" element={<AddBank />}></Route>
          <Route path="/banking/view_bank/:bankId/" element={<ViewBank />}></Route>
          <Route path="/banking/bank_to_cash/:bankId/" element={<BankToCash />}></Route>
          <Route path="/banking/cash_to_bank/:bankId/" element={<CashToBank />}></Route>
          <Route path="/banking/bank_to_bank/:bankId/" element={<BankToBank />}></Route>
          <Route path="/banking/bank_adjust/:bankId/" element={<AdjustBank />}></Route>
          <Route path="/edit_transaction/:transId/" element={<EditTransactions />}></Route>
          <Route path="/banking/edit_bank/:bankId/" element={<EditBank />}></Route>
          <Route path="/bank_transaction_history/:transactionId/" element={<BankTransactionHistory />}></Route>

          <Route path="/stock_adjustment" element={<StockAdjustment />}></Route>
          <Route path="/new_adjust" element={<New_Adjust />}></Route>
          <Route path="/stock_adjustment_overview/:stockId/" element={<Stock_AdjustOverview/>}></Route>
          <Route path="/edit_stock_adjust/:stockId/" element={<EditStockAdjust/>}></Route>
          <Route path="/stock_adjust_history/:stockId/" element={<Stock_AjustHistory/>}></Route>

          <Route path="/recurringinvoice_listoutpage" element={<Recurringinvoice_listoutpage />}></Route>
          <Route path="/add_recurr_invoice" element={<Add_recurr_invoice/>}></Route>
          <Route path="/view_rec_invoice/:invoiceId/" element={<View_rec_invoice/>}></Route>
          <Route path="/rec_invoice_history/:invoiceId/" element={<Rec_invoice_history/>}></Route>
          <Route path="/edit_rec_invoice/:invoiceId/" element={<EditRec_invoice/>}></Route>

          {/* Retainer Invoice */}
          <Route path="/ret_invoice" element={<RetInvoice />}></Route>
          <Route path="/add_ret_invoice" element={<AddRetInvoice />}></Route>
          <Route path="/view_ret_invoice/:invoiceId/" element={<ViewRetInvoice />}></Route>
          <Route path="/ret_invoice_history/:invoiceId/" element={<RetInvoiceHistory />}></Route>
          <Route path="/edit_ret_invoice/:invoiceId/" element={<EditRetInvoice />}></Route>

          <Route path="/invoice" element={<Invoice />}></Route>
          <Route path="/addinvoice" element={<Addinvoice />}></Route>
          <Route path="/invoiceview/:invoiceId/" element={<ViewInvoice />}></Route>
          <Route path="/invoicehistory/:invoiceId/" element={<InvoiceHistory />}></Route>
          <Route path="/editinvoice/:invoiceId/" element={<EditInvoice />}></Route>

          <Route path="/Estimate" element={<Estimate />}></Route>
          <Route path="/AddEstimate" element={<AddEstimate />}></Route>
          <Route path="/ViewEstimate/:estimateId/" element={<ViewEstimate />}></Route>
          <Route path="/EditEstimate/:estimateId/" element={<EditEstimate />}></Route>
          <Route path="/EstimateHistory/:estimateId/" element={<EstimateHistory />}></Route>
          <Route path="/convert_estimate_to_sales_order/:estimateId/" element={<ConvertEstimateToSalesOrder />}></Route>
          <Route path="/convert_estimate_to_invoice/:estimateId/" element={<ConvertEstimateToInvoice />}></Route>
          <Route path="/convert_estimate_to_rec_invoice/:estimateId/" element={<ConvertEstimateToRecInvoice />}></Route>

          {/* ManualJournal */}
          <Route path="/manualjournal" element={<ManualJournal />} />
          <Route path="/manualjournalcreation" element={<ManualJournalCreation />} />
          <Route path="/view_manual_journal/:journalId/" element={<ViewManualJournal />} />
          <Route path="/edit_manual_journal/:journalId/" element={<EditManualJournal />} />
          <Route path="/manual_journal_history/:journalId/" element={<ManualJournalHistory />} />

          <Route path="/vendor" element={<Vendor />}></Route>
          <Route path="/add_vendor" element={<Add_vendor />}></Route>
          <Route path="/view_vendors/:vendorId/" element={<View_vendor />}></Route>
          <Route path="/vendor_history/:vendorId/" element={<VendorHistory />}></Route>
          <Route path="/edit_vendor/:vendorId/" element={<Edit_vendor />}></Route>

          <Route path="/expense" element={<Expense />}></Route>
          <Route path="/add_expense" element={<Add_expense />}></Route>
          <Route path="/view_expense/:expenseId/" element={<View_expense/>}></Route>
          <Route path="/expense_history/:expenseId/" element={<Expense_history/>}></Route>
          <Route path="/edit_expense/:expenseId/" element={<Edit_expense/>}></Route>

          <Route path="/banklist" element={<Banklist />}></Route>
          <Route path="/add_bankholder" element={<Addbankholder />}></Route>
          <Route path="/viewholder/:holderId/" element={<Viewholder />}></Route>
          <Route path="/bankhistory/:holderId/" element={<BankHistory />}></Route>
          <Route path="/editholder/:holderId/" element={<Editholder />}></Route>

          <Route path="/chart_accounts" element={<Chart_accounts />}></Route>
          <Route path="/add_account" element={<AddAccount />}></Route>
          <Route path="/view_account/:accountId/" element={<ViewAccount />}></Route>
          <Route path="/edit_account/:accountId/" element={<EditAccount />}></Route>      
          <Route path="/account_history/:accountId/" element={<AccountHistory />}></Route>

          <Route path="/holiday" element={<Holiday />} />
          <Route path="/add_holiday" element={<Add_Holiday />} />
          <Route path="/view_holidays/:ID/:month/:year" element={<ViewHolidays />} />
          <Route path="/holiday_history/:holidayId" element={<HolidayHistoryPage />} />
          <Route path="/edit_holidays/:holidayId" element={<Edit_Holiday />} />

          {/* Attendence */}
          <Route path="/attendence" element={<Attendence />} />
          <Route path="/add_attendence" element={<Add_Attendece />} />
          <Route path="/view_attendence/:id/:mn/:yr/" element={<View_Attendence />} />
          <Route path="/edit_attendence/:id/:mn/:yr/:Id/" element={<Edit_Attendence />} />
          <Route path="/attendence_history/:id/:mn/:yr/:Id/" element={<Attendence_history />} />

          <Route path="/recurr_expense" element={<Recurr_Expense />}></Route>
          <Route path="/add_recurr_expense" element={<Add_recurr_expense />}></Route>
          <Route path="/view_recurr_expense/:expenseId/" element={<View_recurr_expense/>}></Route>
          <Route path="/recurr_expense_history/:expenseId/" element={<RecExpense_history/>}></Route>
          <Route path="/edit_rec_expense/:expenseId/" element={<Edit_recurr_expense/>}></Route>

          <Route path="/bill" element={<Bill />}></Route>
          <Route path="/add_bill" element={<AddBill />}></Route>
          <Route path="/view_bill/:billId/" element={<ViewBill />}></Route>
          <Route path="/edit_bill/:billId/" element={<EditBill />}></Route>
          <Route path="/bill_history/:billId/" element={<BillHistory />}></Route>

          <Route path="/credit_note" element={<CreditNote />}></Route>
          <Route path="/add_credit_note" element={<AddCreditNote />}></Route>
          <Route path="/view_credit_note/:creditNoteId/" element={<ViewCreditNote />}></Route>
          <Route path="/credit_note_history/:creditNoteId/" element={<CreditNoteHistory />}></Route>
          <Route path="/edit_credit_note/:creditNoteId/" element={<EditCreditNote />}></Route>

          {/* Debit Note */}
          <Route path="/debitnote" element={<DebitNote />} />
          <Route path="/add_debit_note" element={<AddDebitNote />}></Route>
          <Route path="/view_debit_note/:debitNoteId/" element={<ViewDebitNote />}></Route>
          <Route path="/debit_note_history/:debitNoteId/" element={<DebitNoteHistory />}></Route>
          <Route path="/edit_debit_note/:debitNoteId/" element={<EditDebitNote />}></Route>   

          {/* Receipt note */}
          <Route path="/receipt_note" element={<ReceiptNote />} />
          <Route path="/add_receipt" element={<AddReceiptNote />} />
          <Route path="/view_receipt_note/:receiptId/" element={<ViewReceiptNote />} />
          <Route path="/receipt_note_history/:receiptId/" element={<ReceiptNoteHistory />} />
          <Route path="/edit_receipt_note/:receiptId/" element={<EditReceiptNote />} />
          <Route path="/convert_receipt_to_bill/:receiptId/" element={<ConvertReceiptToBill />} />
          <Route path="/convert_receipt_to_rec_bill/:receiptId/" element={<ConvertReceiptToRecBill />} />

          {/* Payment Received */}
          <Route path="/payment_received" element={<PaymentReceived />}></Route>
          <Route path="/add_payment" element={<AddPaymentReceived />}></Route>
          <Route path="/view_payment_received/:paymentId/" element={<ViewPayment />}></Route>
          <Route path="/payment_received_history/:paymentId/" element={<PaymentHistory />}></Route>
          <Route path="/edit_payment_received/:paymentId/" element={<EditPaymentReceived />}></Route> 

          {/* CashInHand */}
          <Route path="/cashinhand" element={<CashInHand />}></Route>
          <Route path="/addcash" element={<AddCash />}></Route>
          <Route path="/cashinhandstatement" element={<CashInHandStatement />}></Route>
          <Route path="/cashinhand_graph" element={<Cashinhand_Graph />}></Route>
          <Route path="/edit_cashinhand/:id" element={<Edit_cashinhand />} />
          <Route path="/cashinhand_history/:id" element={<Cashinhand_history />} /> 

          <Route path="/purchase_order" element={<Purchase_Order/>}></Route>
          <Route path="/add_purchase_order" element={<Add_Purchase_Order/>}></Route>
          <Route path="/view_purchase_order/:id" element={<View_Purchase_Order/>}></Route>
          <Route path="/purchase_order_history/:id" element={<Purchase_Order_History/>}></Route>
          <Route path="/purchase_order_edit/:id" element={<Purchase_Order_Edit/>}></Route>
          <Route path="/purchaseorder_to_bill/:purchaseId/" element={<PurchaseOrder_to_Bill/>}></Route>
          <Route path="/purchaseorder_to_recbill/:billId/" element={<PurchaseOrder_to_Recbill/>}></Route>

          <Route path="/Rec_bill" element={<Rec_bill />}></Route>
          <Route path="/Add_rec_bill" element={<Add_rec_bill />}></Route>
          <Route path="/View_rec_bill/:billId/" element={<View_rec_bill />}></Route>
          <Route path="/edit_rec_bill/:billId/" element={<EditRecBill />}></Route>
          <Route path="/rec_bill_history/:billId/" element={<RecBillHistory />}></Route>

          {/* Cheque */}
          <Route path="/ChequePayments" element={<ChequePayments />}></Route>
          <Route path="/ChequeStatement" element={<ChequeStatement />}></Route>

          {/* UPI */}
          <Route path="/UPIPayments" element={<UPIPayments />}></Route>
          <Route path="/UPIStatement" element={<UPIStatement/>}></Route>

          <Route path="/loanlist" element={<Loanlist />}></Route>
          <Route path="/addloan" element={<Addloan />}></Route>
          <Route path="/viewloan/:itemId/" element={<ViewLoan />}></Route>
          <Route path="/repayment/:loanId/" element={<Repayment />}></Route>
          <Route path="/newloan/:loanId/" element={<Newloan />}></Route> 
          <Route path="/loanhistory/:loanId/" element={<LoanHistory />}></Route>
          <Route path="/repaymenthistory/:rpayId/:loanId/" element={<RepaymentHistory />}></Route> 
          <Route path="/repaymentedit/:rpayId/:loanId/" element={<RepaymentEdit />}></Route> 
          <Route path="/loanedit/:loanId/" element={<Loanedit />}></Route> 
          <Route path="/additionalhistory/:addId/:loanId/" element={<AdditionalHistory />}></Route> 
          <Route path="/additionaledit/:addId/:loanId/" element={<Additionaledit />}></Route>

          {/* payment_made */}
          <Route path="/payment_made" element={<PaymentMade />}></Route>
          <Route path="/add_payment_made" element={<AddPaymentMade />}></Route>
          <Route path="/view_payment_made/:paymentId/" element={<ViewPaymentMade />}></Route>
          <Route path="/payment_made_history/:paymentId/" element={<PaymentMadeHistory />}></Route>
          <Route path="/edit_payment_made/:paymentId/" element={<EditPaymentMade />}></Route>
          {/* payment_made_end */}

          {/* Invioce Report*/}
          <Route path="/invoice_report" element={<InvoiceReport />}></Route>

          {/* Recurring Invioce Report*/}
          <Route path="/rec_invoice_report" element={<RecInvoiceReport />}></Route>

          <Route path="/salarydetails" element={<Salarydetails />}></Route>
          <Route path="/add_salarydetails" element={<Add_salarydetails />}></Route>
          <Route path="/view_salarydetails/:salaryId/" element={<View_salarydetails/>}></Route>
          <Route path="/salarydetails_history/:salaryId/" element={<Salarydetails_history/>}></Route>
          <Route path="/edit_salarydetails/:salaryId/" element={<Edit_salarydetails/>}></Route>

          <Route path="/Estimate_details" element={<Estimate_details />}></Route>
          <Route path="/Retainer_invoice_Report" element={<Retainer_invoice_Details />}></Route>

          {/* customer balance  && vendor balance - report */}
          <Route path="/customer_balance_report" element={<CustomerBalanceReport />}></Route>
          <Route path="/vendor_balance_report" element={<VendorBalanceReport />}></Route>

          {/*  Expensereport */}
          <Route path="/expensereport" element={<Expensereport />}></Route>

          <Route path="/purchase_order_details" element={<Purchase_Order_Details/>}></Route>
          <Route path="/purchase_order_by_vendor" element={<Purchase_Order_Vendor/>}></Route>

          <Route path="/eway" element={<Ewaybill />}></Route>
          <Route path="/add_eway" element={<Add_eway />}></Route>
          <Route path="/view_eway/:ewayId/" element={<Overview_eway />}></Route>
          <Route path="/eway_history/:ewayId/" element={<EwayHistory />}></Route>
          <Route path="/edit_eway/:ewayId/" element={<Edit_ewaybill />}></Route>

          <Route path="/grnreport" element={<Grnreport />}></Route>

          <Route path="/billReport" element={<BillReport />}></Route>
          <Route path="/recurringbillReport" element={<RecBillReport/>}></Route>

          <Route path="/delivery_challan" element={<DeliveryChallan />}></Route>
          <Route path="/add_delivery_challan" element={<AddDeliveryChallan />}></Route>
          <Route path="/convert_challan_to_invoice/:challanId/" element={<ConvertChallanToInvoice />}></Route>
          <Route path="/view_delivery_challan/:challanId/" element={<ViewChallan />}></Route>
          <Route path="/delivery_challan_history/:challanId/" element={<ChallanHistory />}></Route>
          <Route path="/edit_delivery_challan/:challanId/" element={<EditDeliveryChallan />}></Route>
          <Route path="/convert_challan_to_rec_invoice/:challanId/" element={<ConvertChallanToRecInvoice />}></Route>

          {/* Agingsummary */}
          <Route path="/agingsummary" element={<Agingsummary />}></Route>
          <Route path="/agingdetails" element={<AgingDetails />}></Route>

          {/* credit_note_details, debit_node_details */}
          <Route path="/credit_note_details" element={<CreditNoteDetails />}></Route>
          <Route path="/debit_note_details" element={<DebitNoteDetails />}></Route>
          {/* end */}

          <Route path="/payment_made_summary" element={<Payment_Made_Summary/>}></Route>
          <Route path="/payment_made_details" element={<Payment_Made_Details/>}></Route>

          <Route path="/journal_report" element={<Journal_report />}></Route>
          <Route path="/purchase_orderitem_report" element={<Purchase_orderitem_report />}></Route>

          <Route path="/Salesorder" element={<Salesorder />}></Route>
          <Route path="/Addsalesorder" element={<Addsalesorder />}></Route>
          <Route path="/Editsalesorder/:orderId/" element={<Editsalesorder />}></Route>
          <Route path="/SalesToInvoice/:orderId/" element={<SALESTOINVOICE />}></Route>
          <Route path="/SalesToRecurring/:orderId/" element={<SALESTORECURRING />}></Route>
          <Route path="/SalesOrderDetail/:orderId/" element={<SalesOrderDetail />} />

          {/* Outstanding receivable and payable */}
          <Route path="/outstandingreceivable" element={<Outstanding_receivable />}></Route>
          <Route path="/outstanding_payable" element={<Outstanding_payable />}></Route>

          <Route path="/loanholderlist" element={<LoanHolderlist />}></Route>
          <Route path="/addholderloan" element={<Addholderloan />}></Route>
          <Route path="/viewloanholder/:itemId/" element={<ViewLoanholder />}></Route>
          <Route path="/loanrepayment/:loanId/" element={<LoanRepayment />}></Route>
          <Route path="/loanadditional/:loanId/" element={<Loanadditional />}></Route>
          <Route path="/loanholderhistory/:loanId/" element={<Loanholderhistory />}></Route>
          <Route path="/loanaccountedit/:loanId/" element={<LoanAccountEdit />}></Route>
          <Route path="/repaymentLoanEdit/:loanId/" element={<RepaymentLoanEdit/>}></Route>

          <Route path="/payment_received_details" element={<Payment_Received_Details />} />
          <Route path="/payment_received_summary" element={<Payment_Received_Summary />} />

          <Route path="/company_staffs/bank_statements" element={<BankStatement />}></Route>
          <Route path="/company_staffs/account_type_summary" element={<AccountTypeSummary/>}></Route>

          {/* Discount report and sales summary HSN*/}
          <Route path="/sales_by_hsn" element={<Sales_by_hsn />}></Route>
          <Route path="/discountreport" element={<DiscountReport />}></Route>

          <Route path="/Item_report_by_party" element={<Item_report_by_party />}></Route>
          <Route path="/Party_report_by_item" element={<Party_report_by_item />}></Route>

          {/* stocksummary */}
          <Route path="/stocksummary" element={<Stocksummary />}></Route>

          <Route path="/low_stock_details" element={<LowStockDetails/>}></Route>
          <Route path="/stock_details" element={<StockDetails/>}></Route>

          <Route path="/daybook" element={<Daybook_report />}></Route>
          <Route path="/cashflow" element={<Cashflow_report />}></Route>

          <Route path="/gstr1" element={<GSTR1 />}></Route>
          <Route path="/gstr2" element={<GSTR2 />}></Route>

          {/* sales_purchase_by_party, all_transactions */}
          <Route path="/sales_purchase_by_party" element={<SalesPurchaseByParty />}></Route>
          <Route path="/all_transactions" element={<AllTransactions />}></Route>
          {/* end */}

          <Route path="/saleorderdetailsreport" element={<Saleorderdetails_report />}></Route>
          <Route path="/salesorderitemreport" element={<Saleorderitem_report />}></Route>

          <Route path="/gstr3b" element={<GSTR3B />}></Route>
          <Route path="/gstr9" element={<GSTR9 />}></Route>

          {/* Partystatement */}
          <Route path="/Partystatement" element={<Partystatement />}></Route>

          <Route path="/e_way_bill_details" element={<E_way_bill_details/>}></Route>
          <Route path="/reccurring_expense_details" element={<Reccurring_Expense_report/>}></Route>

          <Route path="/Horizontal_profit_loss" element={<Horizontal_profit_and_loss />}></Route>
          <Route path="/Vertical_profit_and_loss" element={<Vertical_profit_and_loss />}></Route>

          {/* Trialbalance */}
          <Route path="/Trialbalance" element={<Trialbalance />}></Route>

          <Route path="/delivery_challan_report" element={<DeliveryChallanReport/>}></Route>
          <Route path="/loan_account_statement" element={<LoanAccountStatement/>}></Route>

          <Route path="/Stock_valuation_summary" element={<Stock_valuation_summary/>}></Route>
          <Route path="/Sales_Item_Discount_Details" element={<Sales_Item_Discount_Details/>}></Route>

          <Route path="/horizontal_balancesheet" element={<Horizontal_balancesheet_report />}></Route>
          <Route path="/vertical_balancesheet" element={<Vertical_balancesheet_report />}></Route>

          <Route path="/salesorder_by_customer" element={<Salesordercustomer />}></Route>
          <Route path="/employee loan statement" element={<Employeeloanstatement />}></Route>

          <Route path="/sales_summary_report" element={<Sales_summary_report />}></Route>
          <Route path="/purchase_summary_report" element={<Purchase_summary_report />}></Route>

          <Route path="/all_parties_report" element={<All_parties_report />}></Route>


        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
