import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

function HolidayHistoryPage() {
    const { holidayId } = useParams();
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const [holiday, setHoliday] = useState({});
    const [edate, setEDate] = useState("");
    const [sdate, setSDate] = useState("");

    const fetchHistory = () => {
        axios
            .get(`${config.base_url}/fetch_holiday_history/${holidayId}/`)
            .then((res) => {
                if (res.data.status) {
                    const { holiday, history: historyItems, end_date, start_date } = res.data;
                    setHoliday(holiday);
                    setHistory(historyItems);
                    setEDate(end_date);
                    setSDate(start_date);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: res.data.message,
                    });
                }
            })
            .catch((err) => {
                console.log("ERROR=", err);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: err.response?.data?.message || "An error occurred while fetching data.",
                });
            });
    };

    useEffect(() => {
        fetchHistory();
    }, [holidayId]);

    return (
        <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper d-flex">
          <Sidebar />
            
            <div className="page-content" style={{ backgroundColor: "#a9a9a961", minHeight: "100vh", width: '100%', padding:'20px' }}>
                <div
                    className="d-flex justify-content-end p-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                >
                    <i className="fa fa-times-circle text-white" style={{ fontSize: "1.2rem" }}></i>
                </div>
                <div className="card radius-15">
                    <div className="card-body" style={{ width: "100%" }}>
                        <div className="card-title">
                            <center>
                                <h3 className="card-title" style={{ textTransform: "Uppercase" }}>
                                    HOLIDAY HISTORY
                                </h3>
                            </center>
                        </div>
                    </div>
                </div>
                <div className="card card-registration card-registration-2" style={{ borderRadius: "15px",marginTop:"20px" }}>
                    <div className="card-body p-0">
                        <div id="history">
                            <center>
                                <h3 className="mt-3 text-uppercase">
                                    {sdate} - {edate}
                                </h3>
                            </center>
                            <div className="table-responsive px-2">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="text-center">SL NO.</th>
                                            <th className="text-center">DATE</th>
                                            <th className="text-center">ACTION</th>
                                            <th className="text-center">DONE BY</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {history.map((h, index) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                                <td style={{ textAlign: "center" }}>{h.date}</td>
                                                <td className={`text-center ${h.action === "Created" ? "text-success" : "text-warning"}`}>
                                                    {h.action}
                                                </td>
                                                <td style={{ textAlign: "center" }}>{h.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default HolidayHistoryPage;
