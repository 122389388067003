import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { Link, useNavigate ,useParams} from "react-router-dom";
import Select from "react-select";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";

function Saleorder_details_report() {
  const ID = Cookies.get("user_id");

  const [reportData, setReportData] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [report, setReport] = useState("");

  const fetchSalesorderReportDetails = () => {
    axios
      .get(`${config.base_url}/fetch_salesorder_report_details/${ID}/`)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setOtherDetails(res.data.otherDetails);
          setStartDate("");
          setEndDate("");
          setStatus("");
          setReport("");
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchSalesorderReportDetails();
  }, []);

  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      report: report,
      status: status,
    };

    axios
      .post(`${config.base_url}/fetch_Salesorder_report_details_customized/`, det)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setOtherDetails(res.data.otherDetails);
          setStartDate(res.data.startDate);
          setEndDate(res.data.endDate);
          setStatus(res.data.filterStatus);
          setReport(res.data.report);

          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
   
    }
  }

  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Report__salesorder_Details" + date + ".xlsx");
  }

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Report_salesorder_Details" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var stat = status;
    var rpt = report;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
         
          var em = {
            Id: ID,
            start: st,
            end: end,
            status: stat,
            report: rpt,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/share_salesorederreport_email/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }

    return(
        <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper d-flex flex-column flex-md-row">
            <Sidebar />
            <div
            className="main-panel"
            style={{ background: "#a9a9a961", maxWidth: "100vw" }}
            >
                 <div className="content-wrapper">
        <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
          <div className="container-fluid">
            <div className="page-content">
              <div className="card radius-15 h-20">
                <div className="card-body">
                  <div className="card-title">
                    <center>
                      <h2 className="mt-3">SALES ORDER DETAILS</h2>
                    </center>
                    <hr />
                  </div>
                  <div className="bars">
                    <div className="row justify-content-between align-items-center ">
                      <div className="col-12 col-md-6 d-flex justify-content-start mb-3 mb-md-0">
                        <div className="position-relative mr-2">
                          <button
                            className="btn btn-secondary"
                            style={{
                              width: "fit-content",
                              height: "fit-content",
                            }}
                            onClick={toggleContent}
                          >
                            <i className="fa-solid fa fa-gear"></i>
                            &nbsp;Customize Report
                          </button>

                          <div
                            id="contentDiv"
                            className="journalcontent"
                            style={{ display: "none" }}
                          >
                            <h6>Customize Report</h6>
                            <form 
                            onSubmit={handleCustomize}
                            className="form reportCustomizeform px-1" method="get">
                              <div className="px-2 w-100">
                                <label>From</label>
                                <input
                                  className="inputdate form-control"
                                  type="date"
                                  name="start_date"
                                  id="startDate"
                                  value={startDate}
                                  onChange={(e) => setStartDate(e.target.value)}
                                  required={endDate != "" ? true : false}
                                />
                              </div>
                              <div className="px-2 w-100">
                                <label>To</label>
                                <input
                                  className="inputdate form-control"
                                  type="date"
                                  name="end_date"
                                  id="endDate"
                                  value={endDate}
                                  onChange={(e) => setEndDate(e.target.value)}
                                  required={startDate != "" ? true : false}
                                />
                              </div>
                              <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>Status</label>
                        <br />
                        <select
                          name="status"
                          id="salesStatus"
                          className="form-control"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          
                          <option value="all">All</option>
                          <option value="saved">Saved</option>
                          <option value="draft">Draft</option>
                          <option value="invoice">Converted to Invoice</option>
                          <option value="recurring_invoice">Converted to Rec. Invoice</option>
                        </select>
                      </div>

                              <div className="d-flex px-2 mt-3 mb-4 w-100">
                                <button
                                  type="submit"
                                  className="btn btn-outline-secondary w-100"
                                >
                                  Run Report
                                </button>
                                <button
                                  type="reset"
                                  className="btn btn-outline-secondary ml-1 w-100"
                                  onClick={toggleContent}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="right d-flex flex-column flex-md-row align-items-md-center">
                        <a
                            className="btn btn-outline-secondary text-grey fa fa-file mb-2 mb-md-0"
                            role="button"
                            id="pdfBtn"
                            style={{ width: "100%", maxWidth: "fit-content" }}
                            onClick={reportPDF}
                        >
                           {" "}
                            &nbsp;PDF
                        </a>
                        <a
                            className="ml-0 ml-md-2 btn btn-outline-secondary text-grey fa fa-print mb-2 mb-md-0"
                            role="button"
                            id="printBtn"
                            style={{ width: "100%", maxWidth: "fit-content" }}
                            onClick={printSection}
                        >
                            &nbsp;Print
                        </a>
                        <a
                            className="ml-0 ml-md-2 btn btn-outline-secondary text-grey fa fa-table mb-2 mb-md-0"
                            role="button"
                            id="exportBtn"
                            style={{ width: "100%", maxWidth: "fit-content" }}
                            onClick={ExportToExcel}
                        >
                            &nbsp;Export
                        </a>
                        <div className="dropdown p-0 nav-item" id="shareBtn">
                            <li
                            className="ml-0 ml-md-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                            data-toggle="dropdown"
                            style={{
                                height: "fit-content",
                                width: "100%",
                                maxWidth: "fit-content",
                            }}
                            >
                            &nbsp;Share
                            </li>
                            <ul
                            className="dropdown-menu"
                            style={{ backgroundColor: "black" }}
                            id="listdiv"
                            >
                            <a
                                href={shareUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <li
                                style={{
                                    textAlign: "center",
                                    color: "#e5e9ec",
                                    cursor: "pointer",
                                }}
                            
                                >
                                WhatsApp
                                </li>
                            </a>
                            <li
                                style={{
                                textAlign: "center",
                                color: "#e5e9ec",
                                cursor: "pointer",
                                }}
                                data-toggle="modal"
                                data-target="#shareToEmail"
                            >
                                Email
                            </li>
                            </ul>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                <div className="card radius-15 print-only mt-3" id="pdf-card">
                  <div className="card-body">
                    <div className="container-fluid">
                    <div
                        id="printReport"
                        className="printReportTemplate"
                        style={{ display: "block " }}
                    >
                        <div className="my-5 page" size="A4">
                        <div id="printdiv2">
                            <div className="py-4 bg-secondary">
                            <div className="col-12">
                                <center className="h5 text-white">
                                <b>{otherDetails.cmpName}</b>
                                </center>
                                <center className="h3 text-white">
                                <b>SALES ORDER DETAILS</b>
                                </center>
                                {startDate && endDate ? (
                                <center className="h6 text-white">
                                    {startDate} {"TO"} {endDate}
                                </center>
                                ) : null}
                            </div>
                            </div>
                            <div className="row px-1 py-1">
                                        <div className="col-12">
                                            <section className="product-area mt-2 py-1">
                                            <table
                                                className="table table-responsive-md mt-4 table-hover"
                                                id="reportTable"
                                            >
                                                <thead>
                                                <tr>
                                                    
                                                <th className="text-center">STATUS</th>
                                                <th className="text-center">DATE</th>
                                                <th className="text-center">SHIPMENT DATE</th>
                                                <th className="text-center">SALES ORDER NO.</th>
                                                <th className="text-center">CUSTOMER NAME</th>
                                                <th className="text-center">TOTAL AMOUNT</th>
                                                
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {reportData.length > 0 &&
                                                reportData.map((i) => (
                                                <tr>
                                                    <td className="text-center">{i.status}</td>
                                                    <td className="text-center">{i.date}</td>
                                                    <td className="text-center">
                                                    {i.ship_date}
                                                    </td>
                                                    <td className="text-center">{i.sales_no}</td>
                                                    <td className="text-center">
                                                    {i.name}
                                                    </td>
                                                

                                                    <td className="text-center">
                                                    Rs. {i.total}
                                                    </td>
                                                
                                                </tr>
                                                ))}
                                                </tbody>
                                            </table>

                                            {reportData.length == 0 ? (
                                                <center>
                                                <h4 className="text-dark">No data available.!</h4>
                                                </center>
                                            ) : null}
                                            </section>


                                            <section className="balance-info text-dark pt-1 pb-1">
                                            <div className="row p-4">
                                                <div className="col-10">
                                                
                                                <div className="row ">
                                                    <div className="col-4 "></div>
                                                    <div className="col-4"></div>
                                                
                                                </div>
                                                </div>
                                                <div className="col-2 text-center">
                                                <h5
                                                    style={{
                                                    paddingTop: "70px",
                                                    color: "#000",
                                                    fontWeight: "bold ",
                                                    }}
                                                >
                                                    Total Sales Order Amount:
                                                </h5>
                                                <h4
                                                    className="text-dark"
                                                    style={{ fontWeight: "600", fontFamily: "'Open Sans', sans-serif" }}
                                                    id="totvalue"
                                                >
                                                    ₹
                                                    <span id="superTotal">
                                                    {otherDetails.totalBalance}
                                                    </span>
                                                </h4>
                                                </div>
                                            </div>
                                            </section>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                        </div>
                                    </div>
                                    </div>
                {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
              </div>

            </div>
          </div>
        
        </div>
      
      </div>

    </div>
 
                      
    );
}
export default Saleorder_details_report;