import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";

function ReceiptNote() {
  const navigate = useNavigate();
  function exportToExcel() {
    const Table = document.getElementById("receiptNoteTableExport");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "ReceiptNote.xlsx");
  }

  function sortTable(columnIndex) {
    var table, rows, table2, rows2, switching, i, x, y, shouldSwitch;
    table = document.getElementById("PurchasebillTable");
    table2 = document.getElementById("receiptNoteTableExport");
    switching = true;

    while (switching) {
      switching = false;
      rows = table.rows;
      rows2 = table2.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();
        y = rows[i + 1]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();

        if (x > y) {
          shouldSwitch = true;
          break;
        }
      }

      for (i = 1; i < rows2.length - 1; i++) {
        shouldSwitch = false;
        x = rows2[i]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();
        y = rows2[i + 1]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();

        if (x > y) {
          shouldSwitch = true;
          break;
        }
      }

      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        rows2[i].parentNode.insertBefore(rows2[i + 1], rows2[i]);
        switching = true;
      }
    }
  }

  function filterTable(row, filterValue) {
    var table1 = document.getElementById("PurchasebillTable");
    var table2 = document.getElementById("receiptNoteTableExport");
    var rows1 = table1.getElementsByTagName("tr");
    var rows2 = table1.getElementsByTagName("tr");

    for (var i = 1; i < rows1.length; i++) {
      var statusCell = rows1[i].getElementsByTagName("td")[row];

      if (
        filterValue == "all" ||
        statusCell.textContent.toLowerCase() == filterValue
      ) {
        rows1[i].style.display = "";
      } else {
        rows1[i].style.display = "none";
      }
    }

    for (var i = 1; i < rows2.length; i++) {
      var statusCell = rows2[i].getElementsByTagName("td")[row];

      if (
        filterValue == "all" ||
        statusCell.textContent.toLowerCase() == filterValue
      ) {
        rows2[i].style.display = "";
      } else {
        rows2[i].style.display = "none";
      }
    }
  }

  function searchTable() {
    var rows = document.querySelectorAll("#PurchasebillTable tbody tr");
    var val = document
      .getElementById("search")
      .value.trim()
      .replace(/ +/g, " ")
      .toLowerCase();
    rows.forEach(function (row) {
      var text = row.textContent.replace(/\s+/g, " ").toLowerCase();
      row.style.display = text.includes(val) ? "" : "none";
    });
  }

  const ID = Cookies.get('user_id');
  const [receiptNotes, setReceiptNotes] = useState([]);

  const fetchBill = () => {
    axios
      .get(`${config.base_url}/fetch_receipt_notes/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          var bil = res.data.receiptNotes;
          setReceiptNotes([]);
          bil.map((i) => {
            setReceiptNotes((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  useEffect(() => {
    fetchBill();
  }, []);

  function refreshAll() {
    setReceiptNotes([]);
    fetchBill();
  }
  return (
    <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper d-flex">
      <Sidebar />
          <div className="page-content" style={{ backgroundColor: "#a9a9a961", minHeight: "100vh", width: '100%', padding:'20px' }}>
              <div className="card radius-15 h-20">
                  <div className="row">
                      <div className="col-md-12">
                          <center>
                              <h2 className="mt-3">ALL GOODS RECEIPT NOTES</h2>
                          </center>
                          <hr />
                      </div>
                  </div>
              </div>
       <br></br>
    
        <div className="card radius-15">
          <div className="card-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      placeholder="Search.."
                      autoComplete="off"
                      onKeyUp={searchTable}
                    />
                    <div
                      className="dropdown ml-1"
                      style={{ justifyContent: "left" }}
                    >
                      <button
                        type="button"
                        style={{ width: "fit-content", height: "fit-content" }}
                        className="btn btn-outline-secondary dropdown-toggle text-grey"
                        data-toggle="dropdown"
                      >
                        <i className="fa fa-sort"></i> Sort by
                      </button>
                      <div
                        className="dropdown-menu"
                        style={{ backgroundColor: "white" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={refreshAll}
                          style={{
                            height: "40px",
                            fontSize: "15px",
                            color: "black",
                          }}
                        >
                          All
                        </a>
                        <a
                          className="dropdown-item"
                          style={{
                            height: "40px",
                            fontSize: "15px",
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={() => sortTable(3)}
                        >
                          Vendor Name
                        </a>
                        <a
                          className="dropdown-item"
                          style={{
                            height: "40px",
                            fontSize: "15px",
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={() => sortTable(2)}
                        >
                          Receipt Number
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-6 d-flex justify-content-end">
                  <button
                    type="button"
                    style={{ width: "fit-content", height: "fit-content" }}
                    className="btn btn-outline-secondary text-grey"
                    id="exportBtn"
                    onClick={exportToExcel}
                  >
                    <i className="fa fa-table"></i> Export To Excel
                  </button>
                  <div className="dropdown ml-1">
                    <button
                      type="button"
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="btn btn-outline-secondary dropdown-toggle text-grey"
                      data-toggle="dropdown"
                    >
                      <i className="fa fa-filter"></i> filter by
                    </button>
                    <div
                      className="dropdown-menu"
                      style={{ backgroundColor: "white" }}
                    >
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(6, "all")}
                      >
                        All
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(6, "saved")}
                      >
                        Saved
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "black",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(6, "draft")}
                      >
                        Draft
                      </a>
                    </div>
                  </div>
                  <Link to="/add_receipt" className="ml-1">
                    <button
                      type="button"
                      style={{ width: "fit-content", height: "fit-content" }}
                      className="btn btn-outline-secondary text-grey"
                    >
                      <i className="fa fa-plus font-weight-light"></i> GRN
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table
              className="table table-responsive-md table-hover mt-4"
              id="PurchasebillTable"
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>DATE</th>
                  <th>GRN NO.</th>
                  <th>VENDOR NAME</th>
                  <th>MAIL ID</th>
                  <th>AMOUNT</th>
                  <th>STATUS</th>
                  <th>BALANCE</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {receiptNotes &&
                  receiptNotes.map((i, index) => (
                    <tr
                      className="clickable-row"
                      onDoubleClick={() =>
                        navigate(`/view_receipt_note/${i.id}/`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>{index + 1}</td>
                      <td>{i.grn_date}</td>
                      <td>{i.grn_no}</td>
                      <td>{i.vendor_name}</td>
                      <td>{i.vendor_email}</td>
                      <td>{i.grandtotal}</td>
                      <td>{i.status}</td>
                      <td>{i.balance}</td>
                      <td>
                        {i.converted ? (
                          <span
                            className="text-info font-weight-bolder text-center"
                            onClick={() => navigate(i.link)}
                          >
                            Converted to <br />
                            {i.type} - #{i.number}
                          </span>
                        ) : (
                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              style={{
                                width: "fit-content",
                                height: "fit-content",
                                backgroundcolor:"white",
                                color:"white"
                              }}
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Convert
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  to={`/convert_receipt_to_bill/${i.id}/`}
                                  className="dropdown-item fw-bold bg-light text-dark"
                                >
                                  To Bill
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={`/convert_receipt_to_rec_bill/${i.id}/`}
                                  className="dropdown-item fw-bold bg-light text-dark"
                                >
                                  To Rec. Bill
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <table
        className="table table-responsive-md table-hover mt-4"
        id="receiptNoteTableExport"
        style={{ textAlign: "center" }}
        hidden
      >
        <thead>
          <tr>
            <th>#</th>
            <th>DATE</th>
            <th>RECEIPT NUMBER</th>
            <th>VENDOR NAME</th>
            <th>MAIL ID</th>
            <th>AMOUNT</th>
            <th>STATUS</th>
            <th>BALANCE</th>
          </tr>
        </thead>
        <tbody>
          {receiptNotes &&
            receiptNotes.map((i, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{i.grn_date}</td>
                <td>{i.grn_no}</td>
                <td>{i.vendor_name}</td>
                <td>{i.vendor_email}</td>
                <td>{i.grandtotal}</td>
                <td>{i.status}</td>
                <td>{i.balance}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
    </div>
    
  );
}

export default ReceiptNote;
