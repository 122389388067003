import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import * as XLSX from "xlsx";
import { Link, useNavigate,useParams,useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";


function View_vendor() {
  const ID = Cookies.get("user_id");
  const { vendorId } = useParams();
  const [vendorDetails, setVendorDetails] = useState({});
  const [extraDetails, setExtraDetails] = useState({
    paymentTerms: 'Nill',
    priceList: 'Nill'
  })
  const [comments, setComments] = useState([]);
  const [history, setHistory] = useState({
    action: "",
    date: "",
    doneBy: "",
  });
  const [balance, setBalance] = useState(0);

  const [transactions, setTransactions] = useState([]);
  const [companyDetails,setCompanyDetails] = useState([]);
  
  const fetchVendorDetails = () => {
    axios
      .get(`${config.base_url}/view_vendor/${vendorId}/${ID}`)
      .then((res) => {
        console.log("VEND DATA=", res);
        if (res.data.status) {
          var itm = res.data.vendor;
          var ext = res.data.extraDetails;
          var hist = res.data.history;
          var cmt = res.data.comments;
          var company = res.data.company;
          var trans = res.data.all_transactions;
          var bal = res.data.Balance;

          setComments([]);
          cmt.map((c) => {
            setComments((prevState) => [...prevState, c]);
          });
          setCompanyDetails(company || []);
          setExtraDetails(ext);
          setVendorDetails(itm);
          if (hist) {
            setHistory(hist);
          }
          if(trans){
            setTransactions(trans);
          }
          setBalance(bal)
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchVendorDetails();
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  const navigate = useNavigate();

  const changeStatus = (status) => {
    var st = {
      id: vendorId,
      status: status,
    };
    axios
      .post(`${config.base_url}/changeVendorStatus/`, st)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Status Updated",
          });
          fetchVendorDetails();
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };
  const [comment, setComment] = useState("");
  const saveItemComment = (e) => {
    e.preventDefault();
    var cmt = {
      Id: ID,
      Vendor: vendorId,
      comments: comment,
    };
    axios
      .post(`${config.base_url}/add_vendor_comment/`, cmt)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Comment Added",
          });
          setComment("");
          fetchVendorDetails();
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  function handleDeleteVendor() {
    Swal.fire({
      title: `Delete Vendor- ${vendorDetails.First_name}?`,
      text: "All transactions will be deleted.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_vendor/${vendorId}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Vendor Deleted successfully",
            });
            navigate("/vendor");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  function deleteComment(id) {
    Swal.fire({
      title: "Delete Comment?",
      text: "Are you sure you want to delete this.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_vendor_comment/${id}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Comment Deleted",
            });
            fetchVendorDetails();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  function overview() {
    document.getElementById("overview").style.display = "block";
    document.getElementById("transaction").style.display = "none";
    document.getElementById('statement').style.display = 'none';
    document.getElementById("printBtn").style.display = "none";
    document.getElementById("pdfBtn").style.display = "none";
    document.getElementById("shareBtn").style.display = "none";
    document.getElementById("editBtn").style.display = "block";
    document.getElementById("deleteBtn").style.display = "block";
    document.getElementById("exportBtn").style.display = "none";
    document.getElementById("overviewBtn").style.backgroundColor ="#a9a9a961";
    document.getElementById("transactionBtn").style.backgroundColor ="transparent";
    document.getElementById("statementBtn").style.backgroundColor ="transparent";
    document.getElementById("historyBtn").style.display = "block";
    document.getElementById("statusBtn").style.display = "block";
    document.getElementById("commentsBtn").style.display = "block";
  }
  function transaction() {
    document.getElementById("overview").style.display = "none";
    document.getElementById("transaction").style.display = "block";
    document.getElementById('statement').style.display = 'none';
    document.getElementById("printBtn").style.display = "none";
    document.getElementById("pdfBtn").style.display = "none";
    document.getElementById("shareBtn").style.display = "none";
    document.getElementById("editBtn").style.display = "none";
    document.getElementById("deleteBtn").style.display = "none";
    document.getElementById("exportBtn").style.display = "block";
    document.getElementById("overviewBtn").style.backgroundColor ="transparent";
    document.getElementById("transactionBtn").style.backgroundColor ="#a9a9a961";
    document.getElementById('statementBtn').style.backgroundColor='transparent';
    document.getElementById("historyBtn").style.display = "none";
    document.getElementById("statusBtn").style.display = "none";
    document.getElementById("commentsBtn").style.display = "none";
  }
  function statement() {
    document.getElementById('overview').style.display = 'none';
    document.getElementById('transaction').style.display = 'none';
    document.getElementById('statement').style.display = 'block';
    document.getElementById('overviewBtn').style.backgroundColor='transparent';
    document.getElementById('transactionBtn').style.backgroundColor='transparent';
    document.getElementById('statementBtn').style.backgroundColor='#a9a9a961';
    document.getElementById('shareBtn').style.display = 'block';
    document.getElementById('printBtn').style.display = 'block';
    document.getElementById('pdfBtn').style.display = 'block';
    document.getElementById('editBtn').style.display = 'none';
    document.getElementById('exportBtn').style.display = 'none';
    document.getElementById('deleteBtn').style.display = 'none';
    document.getElementById('historyBtn').style.display = 'none';
    document.getElementById('statusBtn').style.display = 'none';
    document.getElementById('commentsBtn').style.display = 'none';
   
}
  function ExportToExcel(type, fn, dl) {
    var elt = document.getElementById("transactionTable");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(
          wb,
          fn || `${vendorDetails.First_name}_transactions.` + (type || "xlsx")
        );
  }

  function printSheet() {
    var divToPrint = document.getElementById("printContent");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  async function handleShareEmail(e) {
    e.preventDefault();

    const emails = emailIds.trim().split(",").map(email => email.trim());
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const invalidEmails = emails.filter(email => !emailRegex.test(email));

    if (invalidEmails.length > 0) {
      alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
      return;
    }

    const element = document.getElementById("printContent");
    const opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: `${vendorDetails.First_name} ${vendorDetails.Last_name}_transactions`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };

    const pdfBlob = await html2pdf().set(opt).from(element).outputPdf('blob');

    const formData = new FormData();
    formData.append('Id', ID);
    formData.append("pdf", pdfBlob, `${vendorDetails.First_name} ${vendorDetails.Last_name}_transactions.pdf`);
    formData.append("email_ids", emailIds);
    formData.append("id",vendorId,)

    axios
      .post(`${config.base_url}/vendorTransactionsToEmail/`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Shared via mail.",
          });
          setEmailIds("");
          setEmailMessage("");
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && !err.response.data.status) {
          Toast.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }
  function searchTable(){
    var rows = document.querySelectorAll('#transactionTable tbody tr');
    var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [filteredBalance, setFilteredBalance] = useState(0);
  
  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };
  
  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };
  
  const calculateBalance = (transactions) => {

    let initialBalance = 0; 
    transactions.forEach(transaction => {
      if(transaction.Type === 'Opening Balance'){
        initialBalance += parseFloat(transaction.Balance);
      }
      else if(transaction.Type === 'Purchase Order'){
        initialBalance -= parseFloat(transaction.Balance);
      }
      else if(transaction.Type === 'Bill'){
        initialBalance -= parseFloat(transaction.Balance);
      }
      else if(transaction.Type === 'Expense'){
        
        if(transaction.Amounttype === 'Credit'){
          initialBalance -= parseFloat(transaction.Balance);
        }
        else{
          initialBalance += parseFloat(transaction.Balance);
        }
        
      }
      else if(transaction.Type === 'Debit Note'){
        initialBalance += parseFloat(transaction.Balance);
      }
      else if(transaction.Type === 'Recurring Bill'){
        initialBalance -= parseFloat(transaction.Balance);
      }
    });
    
    return initialBalance;
  };
  
  const filterTransactions = () => {
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const parseTransactionDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return new Date(`${year}-${month}-${day}`);
    };

    const filtered = transactions.filter((transaction) => {
        const transactionDate = parseTransactionDate(transaction.Date);

        return transactionDate >= from && transactionDate <= to;
    });

    
    setFilteredTransactions(filtered);
    const newBalance = calculateBalance(filtered);
    setFilteredBalance(newBalance);
  };

  useEffect(() => {
   
    setFilteredTransactions(transactions);
    setFilteredBalance(calculateBalance(transactions));
  }, [transactions]);


  function reportPDF() {

    var element = document.getElementById("printContent");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: `${vendorDetails.First_name} ${vendorDetails.Last_name}_transactions` ,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }


  return (
    <>
     <div className="container-scroller">
            <Navbar />
        <div className="container-fluid page-body-wrapper d-flex">
                <Sidebar />
      <div className="main-panel px-3 py-2" style={{ background: "#a9a9a961", width: "100%" }}>
    
        <Link
          className="d-flex justify-content-end p-2"
          style={{ cursor: "pointer" }}
          to="/vendor"
        >
          <i
            className="fa fa-times-circle text-white"
            style={{ fontSize: "1.2rem" }}
          ></i>
        </Link>
      
        <div className="card radius-15">
          <div className="card-body w-100">
            <div className="card-title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-5">
                    <a
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                        backgroundColor: "#a9a9a961",
                      }}
                      onClick={overview}
                      id="overviewBtn"
                    >
                      Overview
                    </a>
                    <a
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                      }}
                      onClick={transaction}
                      id="transactionBtn"
                    >
                      {" "}
                      Transactions
                    </a>
                  
                    <a
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                      }}
                      onClick={statement}
                      id="statementBtn"
                    >
                      {" "}
                      Statement
                    </a>
                  </div>


                  <div className="col-12 col-md-7 d-flex justify-content-end flex-wrap">
                    {vendorDetails.status == "Inactive" ? (
                      <a
                        onClick={() => changeStatus("Active")}
                        id="statusBtn"
                        className="ml-2 fa fa-ban btn btn-outline-secondary text-grey"
                        role="button"
                        style={{ height: "fit-content", width: "fit-content" }}
                      >
                        &nbsp;Inactive
                      </a>
                    ) : (
                      <a
                        onClick={() => changeStatus("Inactive")}
                        id="statusBtn"
                        className="ml-2 fa fa-check-circle btn btn-outline-secondary text-grey"
                        role="button"
                        style={{ height: "fit-content", width: "fit-content" }}
                      >
                        &nbsp;Active
                      </a>
                    )}
                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
                      role="button"
                      id="exportBtn"
                      style={{
                        display: "none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                      onClick={() => ExportToExcel("xlsx")}
                    >
                      &nbsp;Export
                    </a>
                    <a
                      onClick={reportPDF}
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-file"
                      role="button"
                      id="pdfBtn"
                      style={{
                        display: "none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                    >
                      {" "}
                      &nbsp;PDF
                    </a>
                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                      role="button"
                      id="printBtn"
                      onClick={() => printSheet()}
                      style={{
                        display: "none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                    >
                      &nbsp;Print
                    </a>
                    <div
                      className="dropdown p-0 nav-item"
                      id="shareBtn"
                      style={{ display: "none" }}
                    >
                      <li
                        className="ml-2 dropdown-toggle btn btn-outline-secondary text-dark fa fa-share-alt"
                        data-toggle="dropdown"
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                        }}
                      >
                        &nbsp;Share
                      </li>
                      <ul
                        className="dropdown-menu"
                        style={{ backgroundColor: "black" }}
                        id="listdiv"
                      >
                        <a
                          href={shareUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li
                            style={{
                              textAlign: "center",
                              color: "#e5e9ec",
                              cursor: "pointer",
                            }}
                          >
                            WhatsApp
                          </li>
                        </a>
                        <li
                          style={{
                            textAlign: "center",
                            color: "#e5e9ec",
                            cursor: "pointer",
                          }}
                          data-toggle="modal"
                          data-target="#shareToEmail"
                        >
                          Email
                        </li>
                      </ul>
                    </div>
                    <Link
                      to={`/edit_vendor/${vendorId}/`}
                      className="ml-2 fa fa-pencil btn btn-outline-secondary text-dark"
                      id="editBtn"
                      role="button"
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;Edit
                    </Link>
                    <a
                      className="ml-2 btn btn-outline-secondary text-dark fa fa-trash"
                      id="deleteBtn"
                      role="button"
                      onClick={handleDeleteVendor}
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;Delete
                    </a>
                    <a
                      href="#"
                      className="ml-2 btn btn-outline-secondary text-dark fa fa-comments"
                      id="commentsBtn"
                      role="button"
                      data-toggle="modal"
                      data-target="#commentModal"
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;Comment
                    </a>
                    <Link
                      to={`/vendor_history/${vendorDetails.id}/`}
                      className="ml-2 btn btn-outline-secondary text-dark fa fa-history"
                      id="historyBtn"
                      role="button"
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;History
                    </Link>
                  </div>
                </div>
              </div>
              <center>
                <h3 className="card-title mt-2">VENDOR OVERVIEW</h3>
              </center>
            </div>
          </div>
        </div>

        <div
          className="card card-registration card-registration-2 mt-3"
          style={{ borderRadius: "15px" }}
        >
          <div className="card-body p-0">
            <div id="overview">
              <div className="row g-0 mx-0">
                <div className="col-lg-8">
                  <div className="history_highlight pt-3 px-2 d-flex">
                    <div className="col-12 d-flex justify-content-start align-items-center">
                      {history.action == "Created" ? (
                        <p
                          className="text-success m-0"
                          style={{ fontSize: "1.07rem", fontWeight: "500" }}
                        >
                          Created by :
                        </p>
                      ) : (
                        <p
                          className="text-warning m-0"
                          style={{ fontSize: "1.07rem", fontWeight: "500" }}
                        >
                          Last Edited by :
                        </p>
                      )}
                      <span
                        className="ml-2"
                        style={{ fontSize: "1.15rem", fontWeight: "500" }}
                      >
                        {history.doneBy}
                      </span>
                      <span className="ml-5">{history.date}</span>
                    </div>
                  </div>

                  <div className="pb-3 px-2">
                    <div className="card-body">
                      <div className="card-title">
                        <div className="row">
                          <div className="col mt-3">
                            <h2 className="mb-0">
                              {vendorDetails.Title}
                              {"."} {vendorDetails.First_name}{" "}
                              {vendorDetails.Last_name}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Company</h6>
                        </div>
                        <div className="col-md-1 mt-3">:</div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0">{vendorDetails.Company_Name}</p>
                        </div>
                        <div className="col-md-2 mt-3 vl">
                          <h6 className="mb-0">Email</h6>
                        </div>
                        <div className="col-md-1 mt-3">:</div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0">{vendorDetails.Vendor_email }</p>
                        </div>
                      </div>

                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Mobile</h6>
                        </div>
                        <div className="col-md-1 mt-3">:</div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0">{vendorDetails.Mobile}</p>
                        </div>
                        {vendorDetails.Website ? (
                          <>
                            <div className="col-md-2 mt-3 vl">
                              <h6 className="mb-0">Website</h6>
                            </div>
                            <div className="col-md-1 mt-3">:</div>
                            <div className="col-md-3 mt-3">
                              <p className="mb-0">{vendorDetails.Website? vendorDetails.Website : 'Nill'}</p>
                            </div>
                          </>
                        ): (
                          <>
                            <div className="col-md-2 mt-3 vl">
                              <h6 className="mb-0"></h6>
                            </div>
                            <div className="col-md-1 mt-3"></div>
                            <div className="col-md-3 mt-3">
                            </div>
                          </>
                        ) }
                      </div>

                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Location</h6>
                        </div>
                        <div className="col-md-1 mt-3">:</div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0">{vendorDetails.Location}</p>
                        </div>
                        <div className="col-md-2 mt-3 vl">
                          <h6 className="mb-0">Place of Supply</h6>
                        </div>
                        <div className="col-md-1 mt-3">:</div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0">
                            {vendorDetails.Place_of_supply}
                          </p>
                        </div>
                      </div>

                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Payment Terms</h6>
                        </div>
                        <div className="col-md-1 mt-3">:</div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0">
                            {extraDetails.paymentTerms}
                          </p>
                        </div>
                        <div className="col-md-2 mt-3 vl">
                          <h6 className="mb-0">Price List</h6>
                        </div>
                        <div className="col-md-1 mt-3">:</div>
                        <div className="col-md-3 mt-3">
                          <p className="mb-0">
                            {extraDetails.priceList}
                          </p>
                        </div>
                      </div>

                      <hr />
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="card-content bg-transparent border-0 ml-4">
                            <div className="row">
                              <div className="col-md-12">
                                <h5
                                  className="ml-3"
                                  style={{ textAlign: "center" }}
                                >
                                  Billing Address
                                </h5>
                                <hr />
                              </div>
                            </div>
                            <br />
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Street</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-4">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {vendorDetails.Billing_street}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">City</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-4">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {vendorDetails.Billing_city}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">State</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-4">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {vendorDetails.Billing_state}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Pincode</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-4">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {vendorDetails.Billing_pincode}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Country</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-4">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {vendorDetails.Billing_country}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card-content bg-transparent border-0">
                            <div className="row">
                              <div className="col-md-12">
                                <h5
                                  className="ml-3"
                                  style={{ textAlign: "center" }}
                                >
                                  Shipping Address
                                </h5>
                                <hr />
                              </div>
                            </div>
                            <br />
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Street</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-4">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {vendorDetails.Shipping_street}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">City</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-4">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {vendorDetails.Shipping_city}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">State</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-4">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {vendorDetails.Shipping_state}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Pincode</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-4">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {vendorDetails.Shipping_pincode}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <h6 className="mb-0">Country</h6>
                              </div>
                              <div className="col-md-1">:</div>
                              <div className="col-md-4">
                                <p
                                  className="mb-0"
                                  style={{ textAlign: "right" }}
                                >
                                  {vendorDetails.Shipping_country}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 "
                  style={{
                    backgroundColor: "#a9a9a999",
                    borderTopRightRadius: "2vh",
                    borderBottomRightRadius: "2vh",
                  }}
                >
                  <div className="px-3">
                    <h3 className="fw-bold mb-2 mt-4 pt-1">Vendor Details</h3>
                    <hr className="my-4" />
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Status</h6>
                      {vendorDetails.status == "Active" ? (
                        <i className="fa fa-check-circle text-success">
                          &nbsp;ACTIVE
                        </i>
                      ) : (
                        <i className="fa fa-ban text-danger">&nbsp;INACTIVE</i>
                      )}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">GST Type</h6>
                      {vendorDetails.GST_Treatment}
                    </div>
                    {vendorDetails.GST_Number ? (
                      <div className="d-flex justify-content-between mb-4">
                        <h6 className="">GSTIN</h6>
                        {vendorDetails.GST_Number ? vendorDetails.GST_Number : 'Nill'}
                      </div>
                    ):null}
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">PAN</h6>
                      {vendorDetails.Pan_Number}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Opening Bal.</h6>
                      {vendorDetails.Opening_balance}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Credit Limit</h6>
                      {vendorDetails.Credit_limit}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Balance</h6>
                      {vendorDetails.Current_balance}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="transaction" style={{ display: "none" }}>
              <div id="printContent1">
                <center>
                  <h3 className="mt-3 text-uppercase">
                  {vendorDetails.First_name} {vendorDetails.Last_name} - TRANSACTIONS
                  </h3>
                </center>
                <div className="row mt-5">
                  <div className="col d-flex justify-content-between px-5">
                    <div className="customer_data ">
                    <p >EMAIL: {vendorDetails.Vendor_email}</p>
                      {vendorDetails.GST_Number? <p>GSTIN: {vendorDetails.GST_Number}</p>:<p></p>}
                      <p >
                        ADDRESS:
                        <br />
                        {vendorDetails.Billing_street}, {vendorDetails.Billing_city}, {vendorDetails.Billing_state}
                        <br />
                        {vendorDetails.Billing_country}-{vendorDetails.Billing_pincode}
                      </p>
                    </div>
                    <div className="customer_data">
                      <p>MOBILE: +91 {vendorDetails.Mobile}</p>
                      <p>CREDIT LIMIT: {vendorDetails.Credit_limit}</p>
                      <strong><p style={{fontWeight:900}}>BALANCE: {balance}</p></strong>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row mt-4 d-flex align-items-center" style={{gap: '5px'}}>
                    <div className="col-md-4">
                        <h5 style={{marginLeft: '50px'}}><strong>TRANSACTIONS</strong></h5>
                    </div>
                    <div className="col-md-4 ml-auto mb-3">
                        <input 
                            type="text" 
                            id="search" 
                            className="form-control w-100" 
                            placeholder="Search.." 
                            autoComplete="off" 
                            onKeyUp={searchTable}
                        />
                    </div>
                    <div className="col-1"></div>
                </div>

                <div className="table-responsive px-2">
                  <table className="table table-bordered" id="transactionTable">
                    <thead>
                      <tr>
                        
                        <th className="text-center text-uppercase">Sl No.</th>
                        <th className="text-center text-uppercase">Type</th>
                        <th className="text-center text-uppercase">Number</th>
                        <th className="text-center text-uppercase">Date</th>
                        <th className="text-center text-uppercase">Total</th>
                        <th className="text-center text-uppercase">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                    {transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{transaction.Type}</td>
                        <td style={{ textAlign: "center" }}>{transaction.Number}</td>
                        <td style={{ textAlign: "center" }}>{transaction.Date}</td>
                        <td style={{ textAlign: "center" }}>{transaction.Total}</td>
                        <td style={{ textAlign: "center" }}>{transaction.Balance}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
      <div id="statement" style={{display:'none'}} >
                <div className="container-fluid" >
                    <div className="py-5">
                    <div className="row">
  <div className="col-12 col-md-4 d-flex flex-column flex-md-row justify-content-between align-items-center">
    <div className="date_range d-flex flex-column flex-md-row">
      <div className="form-group">
        <label htmlFor="from">From</label>
        <input
          type="date"
          className="form-control"
          id="fromDate"
          value={fromDate}
          onChange={handleFromDateChange}
        />
      </div>
      <div className="form-group ml-md-2 mt-3 mt-md-0">
        <label htmlFor="to">To</label>
        <input
          type="date"
          className="form-control"
          id="toDate"
          value={toDate}
          onChange={handleToDateChange}
        />
      </div>
    </div>
    <div className="form-group ml-md-2 mt-3 mt-md-4">
      <button
        className="btn btn-outline-secondary btn-sm"
        onClick={filterTransactions}
      >
        Run Report
      </button>
    </div>
  </div>
</div>

             <hr />
             <div className="row mt-4">
  <div className="col-md-8 mx-auto">
    <div className="card">
      <div className="card-block1">
        <div id="printContent" className="print-container card-body">
          <div className="container">
            <div className="row">
              <div className="col-12" style={{ padding: '20px' }}>
                <div className="widget-box">
                  <div className="widget-header widget-header-large text-center py-3 px-4" style={{ backgroundColor: '#343a40' }}>
                    <h5 className="widget-title text-white mb-4">
                      <strong>Statement of {vendorDetails.Title}. {vendorDetails.First_name} {vendorDetails.Last_name}</strong>
                    </h5>
                  </div>

                  <div className="widget-body bg-white py-3 px-2">
                    <div className="widget-main">
                      <div className="row text-center" style={{ padding: '20px' }}>
                        <div className="col-md-6">
                          <p>
                            <strong className="text-dark" style={{fontSize:'1.3rem'}}>{companyDetails.company_name}</strong><br />
                            {companyDetails.address}, {companyDetails.city}<br />
                            {companyDetails.state}, {companyDetails.country}<br />
                            {companyDetails.pincode}<br />
                            {companyDetails.email}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p>
                            <strong  className="text-dark" style={{fontSize:'1.3rem'}}>{vendorDetails.First_name} {vendorDetails.Last_name}</strong><br />
                            {vendorDetails.Billing_street}, {vendorDetails.Billing_city}<br />
                            {vendorDetails.Billing_state}, {vendorDetails.Billing_country}<br />
                            {vendorDetails.Billing_pincode}<br />
                            {vendorDetails.Vendor_email}
                          </p>
                        </div>
                      </div>

                      <div className="row">
                      <div className="col-12 text-right">
  <table className="table border-0">
    <thead>
      <tr className="border-0">
        <th className="border-0">Account Summary</th>
        <th className="border-0"></th> 
      </tr>
    </thead>
    <tbody>
      <tr className="border-0">
        <td className="border-0">Opening balance</td>
        <td className="border-0">{vendorDetails.Opening_balance}</td>
      </tr>
      <tr className="border-0">
        <td className="border-0">Balance</td>
        <td className="border-0">{filteredBalance}</td>
      </tr>
    </tbody>
  </table>
</div>

                      </div>

                      <div className="table-responsive">
                        <table id="logic" className="table table-hover text-center">
                          <thead style={{ backgroundColor: '#22b8d1' }}>
                            <tr>
                              <th>Date</th>
                              <th>Type</th>
                              <th>Number</th>
                              <th>Total</th>
                              <th>Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredTransactions.map((transaction, index) => (
                              <tr key={index}>
                                <td>{transaction.Date}</td>
                                <td><strong>{transaction.Type}</strong></td>
                                <td>{transaction.Number}</td>
                                <td>{transaction.Total}</td>
                                <td>{transaction.Balance}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

                    </div>
                </div>
            </div>
            </div>
            </div>

      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "white" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Vendor Transactions</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Bill details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add Comments Modal --> */}
      <div
        className="modal fade"
        id="commentModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content" >
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Add Comments
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form onSubmit={saveItemComment} className="px-1">
              <div className="modal-body w-100">
                <textarea
                  type="text"
                  className="form-control"
                  name="comment"
                  value={comment}
                  required
                  onChange={(e) => setComment(e.target.value)}
                />
                {comments.length > 0 ? (
                  <div className="container-fluid">
                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th className="text-center">sl no.</th>
                          <th className="text-center">Comment</th>
                          <th className="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {comments.map((c, index) => (
                          <tr className="table-row">
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{c.comments}</td>
                            <td className="text-center">
                              <a
                                className="text-danger"
                                onClick={() => deleteComment(`${c.id}`)}
                              >
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    fontSize: "1.1rem",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="my-2 font-weight-bold d-flex justify-content-center">
                    No Comments.!
                  </span>
                )}
              </div>

              <div className="modal-footer w-100">
                <button
                  type="button"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn"
                  id="commentSaveBtn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
      </div>
       {/* </div> */}
    </>
  );
}

export default View_vendor;